import {SerializedError} from "@reduxjs/toolkit";
import {Button, Loader} from "../index";
import styles from "./RequestWrapper.module.scss";
import React, {Fragment, ReactNode} from "react";

type Action = () => any
type Error = SerializedError
type ClassNames = {
	wrapper?: string
	top?: string
	bottom?: string
}

type Slots = {
	action?: ActionSlot
	loader?: LoaderSlot
	error?: ErrorSlot
}

type ActionSlot = {
	description?: ReactNode
	button?: ReactNode
} | ReactNode

type LoaderSlot = {
	description?: ReactNode
	button?: ReactNode
} | ReactNode

type ErrorSlot = {
	description?: ReactNode | ErrorRenderer
	button?: ReactNode | ErrorRenderer
} | ReactNode | ErrorRenderer

type ErrorRenderer = ({action, error, classNames}: {action: Action, error: Error, classNames: ClassNames}) => ReactNode

export function RequestWrapper(props: {action?: Action, isLoading: boolean, error: Error | undefined, children?: Slots, classNames?: ClassNames}){
	const {children, isLoading, error, action = () => {}} = props;
	const classNames: ClassNames = {wrapper: styles.wrapper, top: styles.top, bottom: styles.bottom, ...props.classNames};
	if (isLoading) return <RenderLoader classNames={classNames} slot={children?.loader}/>
	if (error) return <RenderError action={action} error={error} classNames={classNames} slot={children?.error}/>
	return <RenderAction action={action} classNames={classNames} slot={children?.action}/>
}

function RenderAction(props: {action: Action, classNames: ClassNames, slot: ActionSlot | undefined}) {
	const {action, classNames, slot} = props;
	if (!slot) return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}></div>
			<div className={classNames.bottom}>
				<Button onClick={action}>Send request</Button>
			</div>
		</div>
	);
	if (typeof slot === 'object') return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}>
				{'description' in slot && slot.description}
			</div>
			<div className={classNames.bottom}>
				{'button' in slot ? slot.button : <Button onClick={action}>Send request</Button>}
			</div>
		</div>
	);
	return (
		<Fragment>{slot}</Fragment>
	);
}

function RenderLoader(props: {classNames: ClassNames, slot: LoaderSlot | undefined}) {
	const {classNames, slot} = props;
	if (!slot) return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}>
				<div>Your request has been sent</div>
				<div>Please wait</div>
			</div>
			<div className={classNames.bottom}>
				<Loader size='2rem'/>
			</div>
		</div>
	);
	if (typeof slot === 'object') return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}>
				{'description' in slot ? (
					slot.description
				): (
					<Fragment>
						<div>Your request has been sent</div>
						<div>Please wait</div>
					</Fragment>
				)}
			</div>
			<div className={classNames.bottom}>
				{'button' in slot ? slot.button : <Loader size='2rem'/>}
			</div>
		</div>
	);
	return (
		<Fragment>{slot}</Fragment>
	);
}

function RenderError(props: {action: Action, error: Error, classNames: ClassNames, slot: ErrorSlot | undefined}) {
	const {action, error, classNames, slot} = props;
	if (!slot) return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}>
				<div>Sorry. Something went wrong</div>
				<div>{error.message}</div>
			</div>
			<div className={classNames.bottom}>
				<Button onClick={action}>Retry</Button>
			</div>
		</div>
	);
	if (typeof slot === 'function') return (
		<Fragment>{slot({action, error, classNames})}</Fragment>
	)
	if (typeof slot === 'object') return (
		<div className={classNames.wrapper}>
			<div className={classNames.top}>
				{'description' in slot ? (
					typeof slot.description === 'function' ? slot.description({action, error, classNames}) : slot.description
				) : (
					<Fragment>
						<div>Sorry. Something went wrong</div>
						<div>{error.message}</div>
					</Fragment>
				)}
			</div>
			<div className={classNames.bottom}>
				{'button' in slot ? (
					typeof slot.button === 'function' ? slot.button({action, error, classNames}) : slot.button
				) : (
					<Button onClick={action}>Retry</Button>
				)}
			</div>
		</div>
	);
	return (
		<Fragment>{slot}</Fragment>
	);
}