import {getContract} from "../util";


const abi = [
  'function performCustomMint(string[] memory domainsToMint) external',
];

export const DomainMarket = getContract(abi, (read, write) => ({
  async mintToken(domainName: string) {
    return await write('performCustomMint', [[domainName]]);
  }
}));
