import React from 'react';
import {createRoot} from "react-dom/client";
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import {Provider} from "react-redux";
import Router from "./router";
import {gapi} from "gapi-script";
import {CookiesProvider} from "react-cookie";


const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<Provider store={store}>
		<CookiesProvider>
			<Router/>
		</CookiesProvider>
	</Provider>
);

gapi.load('client:auth2', () => {
	gapi.auth2.init({
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
		plugin_name: 'chat',
	});
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
