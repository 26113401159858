import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentSystem} from "shared/model";
import {Currency} from "shared/types";
import {Order} from "./model/Order";
import {OrderStatus} from "./model/OrderStatus";
import {PaymentOption} from "../../cart/store/model/PaymentOption";
import {clear, payment} from "./actions";
import {Payment, PaymentStatus} from "./model/Payment";
import {Chain} from "features/web3";

export interface CartState {
  order: Order | undefined
  payment: Payment
}

const initialState: CartState = {
  order: undefined,
  payment: { status: PaymentStatus.UNINITIALIZED }
}

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    createOrder: (state, action: PayloadAction<Omit<Order, 'status' | 'paymentSystem' | 'currency'> & {paymentOption: PaymentOption}>) => {
      const { paymentOption, ...rest } = action.payload;
      const [paymentSystem, currency, chain] = mapPaymentOption(paymentOption)
      state.order = {
        ...rest,
        paymentSystem,
        currency,
        chain,
        status: OrderStatus.INITIAL,
      } as Order;
    },
    deleteOrder: (state) => {
      state.order = undefined;
    },
    updateOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    }
  },
  extraReducers: builder =>
    builder
    .addCase(clear, () => {
      return initialState;
    })
    .addCase(payment.update, (state, {payload}) => {
      state.payment = payload;
    })
});

export const { actions, reducer } = slice;

function mapPaymentOption(paymentOption: PaymentOption): [PaymentSystem, Currency, Chain?] {
  switch (paymentOption) {
    case PaymentOption.Heropayments: return [PaymentSystem.Heropayments, Currency.BUSD]
    case PaymentOption.USDT_BSC: return [PaymentSystem.Chain, Currency.USDT, Chain.BinanceSmartChain]
    case PaymentOption.USDT_Polygon: return [PaymentSystem.Chain, Currency.USDT, Chain.Polygon]
    case PaymentOption.Wert: return [PaymentSystem.Wert, Currency.BUSD]
  }
}
