import {Fragment} from "react";
import {Chain, formatToken, parseWei, useFetchBalanceQuery} from "features/web3";
import {Currency} from "shared/types";
import {Button, Loader} from "components";
import {useWeb3} from 'hooks';


export default function CheckBalance(props: {amount: string, currency: Currency, chain: Chain}) {
  const {amount, currency, chain} = props;
  const {address} = useWeb3();
  const {data: balance, error, refetch} = useFetchBalanceQuery({account: address!, currency, chain}, {skip: !address});

  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div>{error.message}</div>
    </Fragment>
  );
  if (balance && parseWei(balance).lt(amount)) return (
    <Fragment>
      <div>The purchase amount exceeds your balance</div>
      <div style={{marginBottom: '2rem'}}>Your balance is: {formatToken(balance)}&nbsp;${currency}</div>
      <Button onClick={refetch} shape='squared'>Check again</Button>
    </Fragment>
  )
  return (
    <Fragment>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2rem'}}><Loader size='2rem'/></div>
      <div>Checking balance</div>
    </Fragment>
  )
}
