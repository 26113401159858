import React from 'react'
import styled from "styled-components";

import CardBody from './../../../../components/slider/CardBody';

interface Comment {
    author_avatar: string,
    author_name: string,
    pub_title: string,
    pub_url: string,
    pub_date: string,
    izd_title: string,
    image: string,
}

export default function WhatOthersSayCard(comment: Comment) {
    return (
        <CardBody>
            <div>
                <Person>
                    <Avatar src={comment.author_avatar} />
                    <PersonInfo>
                        <Name>{comment.author_name}</Name>
                        <Pubdate>{comment.pub_date}</Pubdate>
                    </PersonInfo>
                </Person>
                <Image href={comment.pub_url} target='_blank' rel='noreferrer'>
                    <img src={comment.image} alt={comment.pub_title}/>
                </Image>
            </div>
            <Title>{comment.pub_title}</Title>
            <Url href={comment.pub_url} target='_blank' rel='noreferrer'>{comment.izd_title}</Url>
        </CardBody>
    )
}

const Url = styled.a`
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    text-decoration: none;
    color: #037BDE;
    
    @media (min-width: 776px) AND (max-width: 999px) {
        font-size: 10px;
    }
`

const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 120.6%;
    color: #0F0F0F;
    user-select: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
    @media (min-width: 776px) AND (max-width: 999px) {
        font-size: 16px;
    }
`

const Name = styled.div`
    font-weight: 700;
    font-size: 16.2985px;
    line-height: 22px;
    color: #0F0F0F;
    
    @media (min-width: 750px) AND (max-width: 999px) {
        font-size: 10px;
    }
`

const Person = styled.div`
   display: flex;
   gap: 0.5em;
   margin-bottom: 0.5em;
`

const PersonInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const Pubdate = styled.div`
    font-weight: 500;
    font-size: 12.3424px;
    line-height: 17px;
    color: #A4A4A4;
    
    @media (min-width: 750px) AND (max-width: 999px) {
        font-size: 9px;
    }
`

const Avatar = styled.img`
    width: 54px;
    height: 54px;
    border: 1px solid #BCB8F8;
    border-radius: 100%;
    user-select: none;
`

const Image = styled.a`
    img {
        width: 100%;
        aspect-ratio: 2 /1;
        object-fit: cover;
        user-select: none;
    }
`
