import React, {Fragment, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Closed} from "./Closed";
import {Opened} from "./Opened";
import {PasswordChanged} from "./PasswordChanged";
import {Modal} from "features/modal/Modal";
import {CheckLink} from "./CheckLink";
import {useAuth} from "hooks";
import {Initial} from "./Initial";
import styles from "./ChangePassword.module.scss"


export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const {subject} = useAuth();
  const email = subject && 'email' in subject && subject.email;
  const [state, setState] = useState(getInitialState(searchParams));
  const [isModalOpen, setIsModalOpen] = useState(true);

  if (!email) return null;

  switch (state.status) {
    case Status.INITIAL:
      return (
        <Initial email={email} onSuccess={() => setState({status: Status.LINK_SENT, email})}/>
      );
    case Status.LINK_SENT:
      return (
        <Fragment>
          <Closed/>
          <Modal isOpen={isModalOpen} onRequestClose={() => {
            setIsModalOpen(false);
            setState({status: Status.INITIAL});
          }} className={styles.wrap} contentLabel="">
            <div className={styles.text}>
              To change your password, please go to the link that we sent you to <span className={styles.text__email}>{state.email}</span>
            </div>
          </Modal>
        </Fragment>
      );
    case Status.CHECKING_LINK:
      return (
        <CheckLink
          code={state.code}
          onSuccess={() => setState({status: Status.IN_PROGRESS, code: state.code})}
          onFail={(error) => setState({status: Status.LINK_ERROR, error})}
        />
      )
    case Status.LINK_ERROR:
      return (
        <Fragment>
          <Closed/>
          <Modal isOpen={isModalOpen} onRequestClose={() => {
            setIsModalOpen(false);
            setState({status: Status.INITIAL});
          }} className={styles.wrap} contentLabel="">
            <div className={styles.modal}>
              <div className={styles.img}></div>
              <div className={styles.text}>{state.error}</div>
            </div>
          </Modal>
        </Fragment>
      )
    case Status.IN_PROGRESS:
      return (
        <Opened code={state.code} onSuccess={() => setState({status: Status.SUCCESS})}
                onRequestClose={() => setState({status: Status.INITIAL})}/>
      )
    case Status.SUCCESS:
      return (
        <PasswordChanged/>
      )
  }
}

const Status = {
  INITIAL: 'INITIAL',
  LINK_SENT: 'LINK_SENT',
  CHECKING_LINK: 'CHECKING_LINK',
  LINK_ERROR: 'LINK_ERROR',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS'
} as const;

type Status = typeof Status[keyof typeof Status]


type State = {
  status: typeof Status.INITIAL
} | {
  status: typeof Status.LINK_SENT,
  email: string
} | {
  status: typeof Status.CHECKING_LINK
  code: string
} | {
  status: typeof Status.LINK_ERROR
  error: string
} | {
  status: typeof Status.IN_PROGRESS
  code: string
} | {
  status: typeof Status.SUCCESS
}

function getInitialState(searchParams: URLSearchParams): State {
  const action = searchParams.get('action');
  const code = searchParams.get('code');
  if (action === 'password' && code) return {status: Status.CHECKING_LINK, code};
  else return {status: Status.INITIAL};
}