import * as actions from "../store/actions";
import {AuthType} from "../store/types";
import {PATHS} from "../../../router/paths";
import {useDispatch} from "react-redux";
import {useSafeNavigate} from "../../../hooks";
import BaseLayout from "../../../components/layout/BaseLayout/BaseLayout";
import styles from "./SignUp.module.scss";
import React from "react";

export function Success() {
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const goToLoginPrimary = () => {
    dispatch(actions.clear());
    dispatch(actions.init(AuthType.PRIMARY));
    navigate(PATHS.auth.index);
  }
  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className={styles.wrapper}>
          <div className="registration">
            <div className="registration__title">Sign Up</div>
            <div style={{textAlign: 'center'}}>Success!</div>
            <div style={{textAlign: 'center'}}><a href="" onClick={goToLoginPrimary}>Go to Log In page</a></div>
          </div>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  )
}