import {ChangeEvent, FormEvent, Fragment, useState} from "react";
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import {Coupon} from "../store/model/Coupon";
import {CouponStatus} from "../store/model/CouponStatus";
import {useDispatch, useSelector} from "../../../store/hooks";
import {actions as cartActions, selectors as cartSelectors} from "../store";
import styled from "styled-components";


export function CouponWidget() {
  const dispatch = useDispatch();
  const coupon = useSelector(cartSelectors.selectCoupon);
  const [value, setValue] = useState(coupon?.value ?? '');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const handleSubmit = (e: FormEvent<HTMLFormElement & {elements: {coupon: HTMLInputElement}}>) => {
    e.preventDefault();
    const value = e.currentTarget.elements.coupon.value;
    if (!value.length) return;
    dispatch(cartActions.coupon.check({value}));
  }
  const handleClear = () => {
    setValue('');
    dispatch(cartActions.coupon.clear());
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldWrapper>
        <Field type="text" name="coupon" placeholder="Enter coupon/promo code" value={value} onChange={handleChange} disabled={isInputDisabled(coupon)}/>
      </FieldWrapper>
      {shouldShowClearButton(coupon) ? (
        <Button type="button" onClick={handleClear}>Clear</Button>
      ) : (
        <Button type="submit" disabled={isInputDisabled(coupon)}>Apply</Button>
      )}
      <Feedback>
        {(() => {
          switch (coupon?.status) {
            case CouponStatus.VALID: return <Fragment>Coupon applied successfully!</Fragment>
            case CouponStatus.INVALID: return <Fragment>invalid code</Fragment>;
            case CouponStatus.ERROR: return <Fragment>{coupon.error.message}</Fragment>
            case CouponStatus.AUTH_REQUIRED: return <Fragment>You must <NavLink to={PATHS.auth.index}>log in</NavLink> to apply your coupon</Fragment>
            default: return null;
          }
        })()}
      </Feedback>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const FieldWrapper = styled.div`
  flex: 1;
  position: relative;
`
const Field = styled.input`
  width: 100%;
  height: 45px;
  border: 1px solid #4C37CA;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 16px;
  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #A4A4A4;
  }
  @media (min-width: 768px) {
    height: 50px;
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 134px;
  height: 45px;
  margin-left: 16px;
  background: #4C37CA;
  font-weight: 800;
  font-size: 14px;
  color: #fff;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  @media (min-width: 768px) {
    font-size: 16px;
    height: 50px;
  }
`
const Feedback = styled.div`
  width: 100%;
  font-size: 14px;
  min-height: 19px;
  position: absolute;
  left: 0;
  bottom: 3px;
`

function isInputDisabled(coupon?: Coupon): boolean {
  switch (coupon?.status) {
    case CouponStatus.VALID:
    case CouponStatus.AUTH_REQUIRED:
    case CouponStatus.SENDING_REQUEST:
      return true;
    default:
      return false;
  }
}

function shouldShowClearButton(coupon?: Coupon): boolean {
  switch (coupon?.status) {
    case CouponStatus.VALID:
    case CouponStatus.AUTH_REQUIRED:
      return true;
    default:
      return false;
  }
}