import {PATHS} from "../../../router/paths";
import DomainItem from "./DomainItem";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import {useFetchDomainsQuery} from "../../api/sales/slice";
import {groupBy} from "lodash";
import {Dictionary} from "@reduxjs/toolkit";
import {Chain} from "features/web3/model";
import {UserDomainNameInfo} from "../../api/sales/model/UserDomainNameInfo";
import {UserDomainNameInfoStatus} from "../../api/sales/model/UserDomainNameInfoStatus";
import styles from './MyDomains.module.scss'
import {useAccount} from "hooks";


export default function MyDomains() {
  const account = useAccount(3000);
  const {domains, hasDomains, isLoading} = useFetchDomainsQuery(undefined, {
    selectFromResult: ({data, isLoading}) => {
      const filtered = (data?.data.domainNameInfos ?? []).filter(({status}) => status !== UserDomainNameInfoStatus.RESERVED)
      const domains = groupBy(filtered.map(toDomain).sort(({id: a}, {id: b}) => BigInt(a) > BigInt(b) ? -1 : 1), domain => domain.name);
      return {domains, hasDomains: !!Object.keys(domains).length, isLoading};
    },
    pollingInterval: 3000
  });

  return (
    <SidebarLayout>
      <div className={styles.wrapper}>
        <h1>Domains</h1>
        {isLoading
          ? <div className={styles.loader}><Loader size='2rem'/></div>
          : hasDomains
          ? <DomainsTable domains={domains} account={account?.address}/>
          : emptyList()
        }
      </div>
    </SidebarLayout>
  );
}

function DomainsTable(props: {domains: Dictionary<Domain[]>, account?: string}) {
  const {domains, account} = props;
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.header__number}>#</div>
        <div>Domain</div>
        <div></div>
      </div>
      {Object.keys(domains).map((name, i) => <DomainItem key={i} tokens={domains[name]!} name={name} num={i + 1} account={account}/>)}
    </div>
  )
}

function emptyList() {
  return (
    <div className={styles.empty}>
      <h4>You haven't bought any domains yet</h4>
      <Button type='route' to={PATHS.root.index} size='lg'>Search domain</Button>
    </div>
  );
}

interface Domain {
  id: number
  chain: Chain
  name: string
  status: UserDomainNameInfoStatus
  tokenId: string | undefined
}

function toDomain(obj: UserDomainNameInfo): Domain {
  const {id, domainName, chain, status, tokenId} = obj;
  return { id, name: domainName, chain: chain as Chain, status, tokenId } as Domain;
}
