import {Theme} from "styled-components";

export const light: Theme  = {
  name: 'light',
  palette: {
    common: {
      black: '#000',
      grey: '#62626a',
      white: '#fff'
    },
    primary: {
      main: '#4C37CA',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgb(0,0,0)',
      secondary: 'rgba(0,0,0,.6)',
      disabled: 'rgba(0,0,0,.4)'
    }
  }
}