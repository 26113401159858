import {Fragment, useEffect} from 'react';
import Button from "components/button/Button";
import {Order} from "../store/model/Order";
import {OrderStatus} from "../store/model/OrderStatus";
import {usePlaceOrderMutation} from "../../api/sales/slice";
import Loader from "../../../components/loader/Loader";
import {PATHS} from "../../../router/paths";
import {useSafeNavigate, useWeb3} from 'hooks';
import {TaskCancelCause} from "../../api/sales/model";
import {PaymentSystem} from "shared/model";

export default function (props: {order: Order}) {
  const {order} = props;
  const navigate = useSafeNavigate();
  const {address} = useWeb3();
  const [placeOrder, {data: orderData, error: orderError, isLoading: isOrderLoading, originalArgs: placeOrderOriginalArgs}] = usePlaceOrderMutation();

  useEffect(() => {
    if (order.paymentSystem === PaymentSystem.Chain && !address) return navigate(PATHS.connect);
    if (order.status !== OrderStatus.PLACING_ORDER) return;
    placeOrder({
      domainNames: order.items.map(({name}) => name),
      paymentSystem: order.paymentSystem,
      chain: 'chain' in order ? order.chain : undefined,
      currency: order.currency,
      buyer: address,
      coupon: order.coupon,
      referrer: order.referrer
    })
  }, []);

  if (orderError && placeOrderOriginalArgs) return (
    <Fragment>
      <div>Could not place order</div>
      <div style={{marginBottom: '2rem'}}>{orderError?.message}</div>
      <Button onClick={() => placeOrder(placeOrderOriginalArgs)} shape='squared'>Retry</Button>
    </Fragment>
  );
  if (orderData?.task && 'cancelCause' in orderData.task) return (
    <Fragment>
      <div>Your order was rejected</div>
      <div style={{marginBottom: '2rem'}}>{RenderRejectionReason(orderData.task.cancelCause)}</div>
      <Button onClick={() => navigate(PATHS.cart)} shape='squared'>Return to Cart</Button>
    </Fragment>
  );
  if (isOrderLoading) return (
    <Fragment>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
      <div style={{marginTop: '2rem'}}>Placing order</div>
    </Fragment>
  )

  return null;

}

function RenderRejectionReason(cancelCause: TaskCancelCause | undefined) {
  switch (cancelCause) {
    case TaskCancelCause.INSUFFICIENT_ALLOWANCE: return "Insufficient allowance";
    case TaskCancelCause.INSUFFICIENT_FUNDS: return "Insufficient funds";
    case TaskCancelCause.WRONG_DOMAIN_NAME: return "Invalid domain name";
    default: return "Something went wrong";
  }
}
