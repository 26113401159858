import {useEffect} from 'react';
import {useDispatch, useSafeNavigate, useSelector} from 'hooks';
import {actions as orderActions, selectors as orderSelectors} from "features/order/store";
import OrderPlacement from "./components/OrderPlacement";
import Layout from "./components/Layout/Layout";
import {OrderStatus} from './store/model/OrderStatus';
import {useHistory} from "../../router/BrowserRouter";
import {PATHS} from "../../router/paths";
import {PaymentSystem} from "shared/model";
import OrderFulfilment from "./components/OrderFulfilment";
import Heropayments from "./components/Heropayments";
import Wert from "./components/Wert/Wert";
import CheckBalance from "./components/CheckBalance";
import PayWithCrypto from "./components/PayWithCrypto/PayWithCrypto";
import {Navigate} from "react-router-dom";
import {ChainGuard} from "features/web3";

export default function OrderPage () {

  const history = useHistory()
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const order = useSelector(orderSelectors.selectOrder);

  useEffect(() => {
    if (!order) navigate(-1);
    return history.listen(({location: {pathname}}) => {
      if (pathname === PATHS.connect || pathname === PATHS.auth.index) return;
      dispatch(orderActions.cancelOrder(true));
    });
  }, [])

  switch (order?.status) {
    case OrderStatus.CHECKING_BALANCE: return (
      <Layout order={order} >
        <ChainGuard chain={order.chain}>
          <CheckBalance amount={order.discountedAmount} currency={order.currency} chain={order.chain}/>
        </ChainGuard>
      </Layout>
    )
    case OrderStatus.AWAITING_PAYMENT: switch (order.paymentSystem) {
      case PaymentSystem.Chain: return (
        <Layout order={order}>
          <PayWithCrypto orderId={order.id} amount={order.discountedAmount} currency={order.currency} chain={order.chain}/>
        </Layout>
      )
      case PaymentSystem.Heropayments: return (
        <Heropayments order={order}/>
      )
      case PaymentSystem.Wert: return (
        <Wert order={order}/>
      )
    } break;
    case OrderStatus.PLACING_ORDER: return (
      <Layout order={order} >
        <OrderPlacement order={order}/>
      </Layout>
    )
    case OrderStatus.AWAITING_ORDER_FULFILMENT: return (
      <Layout order={order} >
        <OrderFulfilment orderId={order.id}/>
      </Layout>
    )
    case OrderStatus.ORDER_FULFILLED: return (
      <Navigate to={PATHS.congratulations.index} state={{order}}/>
    )
    case OrderStatus.ORDER_CANCELLED: return (
      <Layout order={order} >
        <OrderFulfilment orderId={order.id}/>
      </Layout>
    )
  }

  return null;

}
