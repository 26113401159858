import './HomeContent.css';
import React, {FormEvent} from "react";
import HomeContentFAQ from "./HomeContentFAQ";
import {HashLink} from "react-router-hash-link";
import {PATHS} from "../../../router/paths";
import {useSafeNavigate} from 'hooks';
import {Magnifier} from "../../../components/icon";
import {analytics, EventTarget, EventType, TargetLocation, TargetName} from 'features/analytics';
import Partners from "../components/partners/Partners";
import {LimitedOffer} from "../components/promo/LimitedOffer";
import News from "../components/news/News";
import WhatOthersSay from "../components/whatOthersSay/WhatOthersSay";
import {SupportedBrowsers} from "../components/SupportedBrowsers";


export default function HomeContent() {
  const navigate = useSafeNavigate();
  const handleFormSubmit = (e: FormEvent<HTMLFormElement & {elements: {domain: HTMLInputElement}}>) => {
    e.preventDefault();
    const domain = e.currentTarget.elements.domain.value;
    navigate(PATHS.search.index(domain));
    analytics.push({type: EventType.Search, term: domain});
  }
  const handleClickChooseDomain = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.ChooseDomain, targetLocation: TargetLocation.Presentation});
  const handleClickChooseName = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.ChooseName, targetLocation: TargetLocation.Presentation});
  const handleClickChooseAccount = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.ChooseAccount, targetLocation: TargetLocation.Presentation});
  const handleClickSubmit = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.Search, targetLocation: TargetLocation.BottomSearchBlock});

  return (
    <div>

      <div className="domain-block" id="about">
        <div className="domain-block__title">Get *DNA for Web3 & Enjoy</div>
        <div className="domain-block__items">
          <div className="domain-block__item _domain">Domain</div>
          /
          <div className="domain-block__item _name">Name</div>/
          <div className="domain-block__item _account">Account</div>
        </div>
        <div className="text-block _domain">
          <div className="text-block__preview _mac">

          </div>
          <div className="text-block__info">
            <div className="text-block__title">
              DOMAIN
            </div>
            <div className="text-block__text">
              Your top level domain to host a decentralized website in any spoken language. Upload your website directly to blockchain to secure it from DDOS-attacks and censorship. Own your domain forever - no renewal and hosting fees.
            </div>
            <HashLink to={PATHS.find_domain_footer} className="text-block__button" onClick={handleClickChooseDomain}>
              Choose Domain
            </HashLink>
          </div>
        </div>
      </div>

      <div className="text-block-wrapper">
        <div className="text-block _name">
          <div className="text-block__preview _name-block">

          </div>
          <div className="text-block__info">
            <div className="text-block__title">
              NAME
            </div>
            <div className="text-block__text">
              Username and identity for Web3 apps: GameFi,
              DEX, DAO, decentralized messengers,
              social networks.
            </div>
            <HashLink to={PATHS.find_domain_footer} className="text-block__button" onClick={handleClickChooseName}>
              Choose Name
            </HashLink>
          </div>
        </div>
      </div>


      <div className="text-block _account">
        <div className="text-block__preview _account">
        </div>
        <div className="text-block__info">
          <div className="text-block__title">
            ACCOUNT
          </div>
          <div className="text-block__text">
            Web3 account to receive funds
            in any chain. Forget about long and confusing
            addresses - use your *DNA instead.
          </div>
          <HashLink to={PATHS.find_domain_footer} className="text-block__button" onClick={handleClickChooseAccount}>
            Choose Account
          </HashLink>
        </div>
      </div>
      <Partners/>
      <div className="be-the-one">
        <div className="be-the-one__title">Be the One in Web3</div>
        <div className="be-the-one__subtitle">Domain without extension</div>
        <div className="be-the-one__text">
          W3DNA reinvented NFT domains offering only top level domains (TLDs). Now everyone has the opportunity to be the one in Web3. The only *Vitalik, *John or *Mike.
        </div>
      </div>
      <News id="news"/>
      <SupportedBrowsers/>
      <div className="capabilities">
        <div className="inner">
          <div className="capabilities__items">
            <div className="capabilities__item">
              <div className="capabilities__item-icon _com"></div>
              <div className="capabilities__item-name">No extension</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _dollar">$</div>
              <div className="capabilities__item-name">No renewal fees</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _any-symbol"></div>
              <div className="capabilities__item-name"> Any symbol</div>
            </div>
            <div className="capabilities__item">
              <div className="capabilities__item-icon _any-language"></div>
              <div className="capabilities__item-name">Any language</div>
            </div>
          </div>
        </div>

      </div>
      <WhatOthersSay/>
      <HomeContentFAQ/>
      <div className="search-block" id="search-footer">
        <div className="inner">
          <div className="search-block__title">Choose your *DNA for Web3</div>
          <form className="search-block__field-wrapper" onSubmit={handleFormSubmit}>
            <input type="text" name="domain" placeholder="Find your domain without Extension" className="dna-block__search-input" autoComplete="off"/>
            <button className="dna-block__search-button" type="submit" onSubmit={handleClickSubmit}><Magnifier/></button>
          </form>
        </div>
      </div>
    </div>

  );
}
