import {AnalyticsEvent} from "./types/AnalyticsEvent";
import {EventType} from "./types/EventType";

const ym = (window as any).ym;
const metrikaID = process.env.REACT_APP_YANDEX_METRIKA_ID;
export const yandex = {
	push: (event: AnalyticsEvent, fields?: Record<string, string>) => {
    try {
      const {type, ...rest} = event;
      ym(metrikaID, 'reachGoal', toYMEvent(type), {...rest, ...fields});
    } catch {}
	}
}

function toYMEvent(event: EventType): string {
	switch (event) {
		case EventType.AddToCart: return 'add_to_cart';
		case EventType.BeginCheckout: return 'begin_checkout';
		case EventType.Click: return 'click';
		case EventType.Purchase: return 'purchase';
		case EventType.Search: return 'search';
		case EventType.SignUp: return 'signup';
		case EventType.LogIn: return 'login';
		default: throw new Error(`Unknown event ${event}`);
	}
}
