import React from "react";
import {Form, FormRenderProps} from "react-final-form";
import FileInput from "../../../account/components/FileInput";
import {Button} from "components";
import styled from "styled-components";

export function UploadForm (props: {onSubmit: (values: any) => Promise<Partial<Record<string, string>>>}) {
  return <Form onSubmit={props.onSubmit} validate={validateForm} render={renderForm}/>
}

function validateForm(values: {[key: string]: any}) {
  const errors: {[key: string]: any} = {};
  if (!values.page) {
    errors.page = "This field is required";
  }
  return errors;
}

function renderForm(props: FormRenderProps) {
  const {submitError, handleSubmit, submitting} = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Label htmlFor="page">Index page archive</Label>
        <FileInput name='page' label='page'/>
      </Row>
      <Row>
        <Label htmlFor="assets">Assets archive (optional)</Label>
        <FileInput name='assets' label='assets'/>
      </Row>
      {submitError && <Error>{submitError}</Error>}
      <Footer>
        <Button type="submit" disabled={submitting} color='lima'>Submit</Button>
      </Footer>
    </form>
  );
}

const Row = styled.div`
  margin-bottom: 1rem;
`
const Label = styled.label`
  margin-bottom: 1rem;
  display: block;
`
const Error = styled.div`
  color: red;
`
const Footer = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #d7d7d7;
`
