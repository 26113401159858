import React, {Fragment} from "react";
import {useSetExternalUrlMutation} from "../store/api";
import {FORM_ERROR} from "final-form";
import {Form, FormRenderProps} from "react-final-form";
import {Button} from "../../../../components";
import styled from "styled-components";
import TextInput from "../../../account/components/TextInput";

export function SubmitExternalUrl(props: {tokenId: string}) {
	const [setExternalUrl] = useSetExternalUrlMutation();
	const handleFormSubmit = async (values: any) => {
		const {url} = values;
		const result = await setExternalUrl({tokenId: props.tokenId, url});
		if ('data' in result) return {};
		const error = result.error.message ?? 'Something went wrong';
		return {[FORM_ERROR]: error};
	}
	return (
		<Fragment>
			<SubmitUrlForm onSubmit={handleFormSubmit}/>
		</Fragment>
	);
}

function SubmitUrlForm (props: {onSubmit: (values: any) => Promise<Partial<Record<string, string>>>}) {
	return <Form onSubmit={props.onSubmit} validate={validateForm} render={renderForm}/>
}

function validateForm(values: {[key: string]: any}) {
	const errors: {[key: string]: any} = {};
	if (!values.url) {
		errors.url = "This field is required";
	}
	return errors;
}

function renderForm(props: FormRenderProps) {
	const {submitError, handleSubmit, submitting} = props;
	return (
		<form onSubmit={handleSubmit}>
			<Row>
				<TextInput name='url' label='content url'/>
			</Row>
			{submitError && <Error>{submitError}</Error>}
			<Footer>
				<Button type="submit" disabled={submitting} color='lima'>Submit</Button>
			</Footer>
		</form>
	);
}

const Row = styled.div`
  margin-bottom: 1rem;
`
const Label = styled.label`
  margin-bottom: 1rem;
  display: block;
`
const Error = styled.div`
  color: red;
`
const Footer = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #d7d7d7;
`
