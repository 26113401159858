import {ChangeEvent, useState} from "react";
import {Contact} from "../../types/Contact";
import {ContactType} from "../../types/ContactType";
import {CloseButton, Heading, Icon, Input, Item, List, Modal} from "./ContactsModal.components";
import {Toggle} from "../Toggle";
import {XmarkIcon} from "../Icon";


type Props = {
	contacts: Contact[]
	isOpen: boolean
	onChange: (contacts: Contact[]) => void
	onClose?: () => void
}

export function ContactsModal (props: Props) {
	const {isOpen, onChange, onClose} = props;
	const [fields, setFields] = useState(toFields(props.contacts));
	const handleChange = (field: ContactField) => {
		const result = fields.map(current => current.type === field.type ? field : current);
		setFields(result);
		onChange(fromFields(result));
	}
	return (
		<Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Contacts">
			<Heading>Contacts</Heading>
			<List>
				{fields.map((field, idx) => <Field field={field} onChange={handleChange} key={idx}/>)}
			</List>
			<CloseButton onClick={onClose}><XmarkIcon/></CloseButton>
		</Modal>
	);
}

type ContactField = {
	type: ContactType
	value: string
	isDisabled: boolean
}

function Field(props: {field: ContactField, onChange: (field: ContactField) => void}) {
	const {field, onChange} = props;
	const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		onChange({...field, value: event.target.value});
	}
	const handleChangeCheckbox = () => {
		const {isDisabled, ...rest} = field;
		onChange({isDisabled: !isDisabled, ...rest});
	}
	return (
		<Item>
			<Icon type={field.type}/>
			<Input type="text" value={field.value} placeholder={Contact.getPlaceholder(field)} onChange={handleChangeInput} disabled={field.isDisabled}/>
			<Toggle checked={!field.isDisabled} onChange={handleChangeCheckbox}/>
		</Item>
	)
}

function toFields(contacts: Contact[]): ContactField[] {
	return Contact.types.map(type => {
		const contact = contacts.find(contact => type === contact.type);
		return {type, value: contact?.value ?? '', isDisabled: !contact?.value};
	})
}

function fromFields(fields: ContactField[]): Contact[] {
	return fields.reduce((acc, {type, value, isDisabled}) => (
		!isDisabled && value.length ? [...acc, {type, value}] : acc
	), [] as Contact[])
}
