import {PropsWithChildren} from "react";
import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import {Order} from "../../store/model/Order";
import styles from './Layout.module.scss';
import {Xmark} from "components/icon";
import {formatToken} from "features/web3";


export default function Layout(props: PropsWithChildren & {order: Order}) {
  const {order} = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <NavLink to={PATHS.root.index} className={styles.logo}><span>w3dna.net</span></NavLink>
          <NavLink to={PATHS.root.index} className={styles.close}><Xmark size='1.75rem' /></NavLink>
        </div>
        <div className={styles.main}>
          <h1 className={styles.domainsTitle}>You are buying domain{order.items.length > 1 ? 's' : ''}:</h1>
          <ul className={styles.domainsList}>
            {order.items.map((item, i) => (<li key={i} className={styles.domainsItem}>{item.name}</li>))}
          </ul>
          <div className={styles.amount}>
            Order total: <span className={styles.amountDiscounted}>$&nbsp;{formatToken(order.discountedAmount)}</span>
            &ensp;
            <span className={styles.amountFull} style={{display: (order.discountedAmount === order.totalAmount ? 'none' : 'inline')}}>($&nbsp;{formatToken(order.totalAmount)})</span>
          </div>
          <div className={styles.content}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}



