import './AuthPage.scss';
import React, {Fragment, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {NavLink, useLocation, useSearchParams} from "react-router-dom";
import {PATHS} from "router/paths";
import {useAuth, useReferrer, useSafeNavigate} from 'hooks';
import {GoogleAuthButton} from "./components/google";
import {Web3AuthButton, Web3AuthPage} from "./components/web3";
import {AuthStatus, AuthType, OAuthData} from "./store/types";
import {FacebookAuthButton} from "./components/facebook";
import {actions as authActions} from './store';
import {useAuthMutation} from "../api/sales/slice";
import {OpenIdProviderType} from "../api/sales/model/OpenIdProviderType";
import {PrimaryAuthButton, PrimaryAuthPage} from "./components/primary";
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {OneTimePassword} from "./components/otp";
import {useHistory} from "../../router/BrowserRouter";
import {UTM, UTMValue} from "features/utm";

enum AuthPageType {
	LogIn,
	SignUp
}
function AuthPage (props : {action : AuthPageType}) {

	const {isAuthorized, state} = useAuth();
	const dispatch = useDispatch();
	const navigate = useSafeNavigate();
	const [searchParams] = useSearchParams();
	const [sendAuthRequest] = useAuthMutation();
	const referrer = useReferrer();
	const history = useHistory()
	const location = useLocation();
	const utmSubtitle = getUTMSubtitle(searchParams.get(UTM.CAMPAIGN));
	const infoSubtitle = getInfoSubtitle(location.state);
	const airdrop = getAirdrop(referrer);

	useEffect(() => {
		const otp = searchParams.get('otp');
		if (!otp) return;
		dispatch(authActions.init(AuthType.OTP));
		dispatch(authActions.setOTP(otp));
	}, []);

	useEffect(() => {
		if (isAuthorized) navigate(-1);
		return history.listen(({location: {pathname}}) => {
			if (isAuthorized && pathname === PATHS.root.index) return navigate(PATHS.account.domains.index);
		});
	}, [isAuthorized]);

	const handleClickAuthButton = (authType: AuthType) => {
		dispatch(authActions.init(authType));
	}

	const handleSuccess = (data: OAuthData) => {
		if (data.authType === AuthType.FACEBOOK) {
			const {id, accessToken} = data;
			sendAuthRequest({token: JSON.stringify({id, accessToken}), providerType: OpenIdProviderType.FACEBOOK, referrer});
		} else if (data.authType === AuthType.GOOGLE) {
			sendAuthRequest({token: data.tokenId, providerType: OpenIdProviderType.GOOGLE, referrer});
		}
	}

	const navigateToPrimarySignUp = (authType: AuthType) => {
		navigate(PATHS.signup.index, {state : {}});
	}

	return (
		<BaseLayout>
			<BaseLayout.Body>
				<div className="inner" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
					<div className="registration">
						<div className="registration__title">{props.action === AuthPageType.LogIn ? "Log In" : "Sign Up"}</div>
						{utmSubtitle && <div className="registration__subtitle">{utmSubtitle}</div>}
						{infoSubtitle && <div className="registration__subtitle__info">{infoSubtitle}</div>}
						{airdrop && <div className="registration__airdrop">{airdrop}</div>}
						<div className="registration__continue-list">
							{(() => {
								if (state.status === AuthStatus.FULFILLED) return <div style={{textAlign: 'center'}}>Authorization successful</div>
								else if (state.status !== AuthStatus.UNINITIALIZED && state.type === AuthType.WEB3) return <Web3AuthPage/>
								else if (state.status !== AuthStatus.UNINITIALIZED && state.type === AuthType.PRIMARY) return <PrimaryAuthPage/>
								else if (state.status !== AuthStatus.UNINITIALIZED && state.type === AuthType.OTP) return <OneTimePassword/>
								else return (
										<Fragment>
											<PrimaryAuthButton onClick={props.action === AuthPageType.LogIn ? handleClickAuthButton : navigateToPrimarySignUp}/>
											<GoogleAuthButton onClick={handleClickAuthButton} onSuccess={handleSuccess}/>
											<FacebookAuthButton onClick={handleClickAuthButton} onSuccess={handleSuccess}/>
											<Web3AuthButton onClick={handleClickAuthButton}/>
										</Fragment>
									)
							})()}
						</div>
						{state.status === AuthStatus.UNINITIALIZED &&
							<Fragment>
								{props.action === AuthPageType.SignUp &&
									<div className="registration__policy">
										By proceeding I agree to w3dna’s <NavLink to={PATHS.policy}>Privacy Policy</NavLink> and <br/>
										<NavLink to={PATHS.terms}> Terms and Conditions</NavLink>
									</div>
								}
								<div className="registration__footer">
									<div className="registration__footer-text">{props.action === AuthPageType.LogIn ? "Dont have an account?" : "Already have account?"}</div>
									<NavLink className="registration__btn" to={props.action === AuthPageType.LogIn ? PATHS.signup.page : PATHS.auth.index}>{props.action === AuthPageType.SignUp ? "Log In" : "Sign Up"}</NavLink>
								</div>
							</Fragment>
						}
					</div>
				</div>
			</BaseLayout.Body>
		</BaseLayout>);
}

function getUTMSubtitle(utm: string | null): string | null {
	switch (utm) {
		case UTMValue.AIRDROP: return 'and get a chance for a huge DNA airdrop!';
		case UTMValue.SHORT_NAME: return 'and give Your Wallet a Short Name';
		case UTMValue.IDENTITY: return 'and get a Username and Identity for Web3 apps';
		default: return null
	}
}

function getInfoSubtitle(state: object): JSX.Element | null {
	const from = (state != null && typeof state === 'object' && 'from' in state && state.from) || null;
	if (from === PATHS.order.index) {
		return (
			<div>
				<div>Please Log in/Sign Up</div>
				<div>to complete your purchase</div>
			</div>
		);
	}
	return null;
}

function getAirdrop(referrer: string | undefined): JSX.Element | null {
	if (referrer === "188190" || referrer === "189353") {
		return (
			<div>
				<div className="registration__airdrop__title">Buy Domain and Take Part in the W3DNA Community Airdrop:</div>
				<div className="registration__airdrop__text">
					<span className="registration__airdrop__text-bold">$40</span> coupon upon registration<br/>
					<span className="registration__airdrop__text-bold">$70 000</span> raffle<br/>
					<span className="registration__airdrop__text-bold">$DNA</span> whitelist<br/>
				</div>
			</div>
		);
	}
	return null;
}


export function LogInPage(){
	return <AuthPage action={AuthPageType.LogIn}/>;
}

export function SignUpPage(){
	return <AuthPage action={AuthPageType.SignUp}/>;
}
