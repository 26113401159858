import styled from "styled-components";
import {ChangeEvent} from "react";
import {XmarkIcon} from "../Icon";
import {Modal as ModalComponent} from "../Modal";
import {backgrounds} from "./backgrounds";
import {Theme} from "../../types/Theme";
import {Asset} from "../../types/Asset";

type Props = {
	image: Asset | undefined
	isOpen : boolean
	onChange: (image: Asset | undefined, theme: Theme) => void
	onClose : () => void
	theme: Theme
}

export function BackgroundModal(props : Props) {
	const {image, isOpen, onClose, onChange, theme} = props;
	const handleChange = (asset: Asset | undefined, theme: Theme) => {
		if (image) Asset.forget(image);
		onChange(asset, theme);
		onClose();
	}
	const handleSelectBackground = (theme: Theme, url: string) => () => {
		const asset = Asset.fromUrl(url);
		handleChange(asset, theme)
	};
	const handleUploadBackground = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		handleChange(file ? Asset.from(file) : undefined, theme);
	}

	return (
		<Modal contentLabel="Backgrounds" isOpen={isOpen}>
			<Heading>Chose a background</Heading>
			<List>{ backgrounds.map(({theme, url}, idx) =>
				<Item key={idx}>
					<Image src={url} alt={`background ${idx + 1}`} onClick={handleSelectBackground(theme, url)}/>
				</Item>
			)}
			</List>
			<UploadButton type="file" name="background" onChange={handleUploadBackground}/>
			<CloseButton onClick={onClose}><XmarkIcon/></CloseButton>
		</Modal>
	);
}

export const Modal = styled(ModalComponent)`
	padding-bottom: 2rem;
`
export const Heading = styled.h1`
	margin-bottom: 1.375rem;
	font-size: 1.25rem;
	text-align: center;
`

const List = styled.ul`
	margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 6rem);
  grid-gap: 10px;
	justify-content: center;
  background-color: #fff;   
	list-style: none;
`

const Item = styled.li`
	width: 6rem;
	height: 6rem;
	overflow: hidden;
	border-radius: .875rem;
	cursor: pointer;
	border: 2px solid transparent;
	:hover { 
		border: 2px solid #4C37CA;
	};
`

const Image = styled.img`
	width: auto;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
`

const UploadButton = styled.input`
	margin: 0 auto;
	display: block;
	text-align: center;
	&::-webkit-file-upload-button {
		visibility: hidden;
	}
	&::before {
		padding: .5rem 1rem;
		display: block;
		font-weight: 800;
		text-align: center;
		white-space: nowrap;
		content: 'Upload image';
		color: #fff;
		background-color: #4C37CA;
		border: 1px solid #4C37CA;
		border-radius: .5rem;
		outline: none;
		cursor: pointer;
		user-select: none;
	}
	&:hover::before {
		opacity: .8;
	}
	&:active::before {
		opacity: .65;
	}
`

export const CloseButton = styled.button`
	position: absolute;
	top: 1rem;
	right: .5rem;
	width: 2rem;
	height: 2rem;
	font-size: 1.25rem;
	border: none;
	background: none;
	color: #a4a4a4;
	cursor: pointer;
	:hover {
		color: #4C37CA;
	}
`