import React from 'react';
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";

const html = `
<style type="text/css">
    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c10 {
        border-right-style: solid;
        padding: 12pt 18pt 12pt 18pt;
        border-bottom-color: #1a0f49;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #1a0f49;
        vertical-align: top;
        border-right-color: #1a0f49;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 200.2pt;
        border-top-color: #1a0f49;
        border-bottom-style: solid
    }

    .c8 {
        border-right-style: solid;
        padding: 12pt 18pt 12pt 18pt;
        border-bottom-color: #1a0f49;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #1a0f49;
        vertical-align: top;
        border-right-color: #1a0f49;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 249.8pt;
        border-top-color: #1a0f49;
        border-bottom-style: solid
    }

    .c5 {
        border-right-style: solid;
        padding: 12pt 18pt 12pt 18pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c7 {
        border-right-style: solid;
        padding: 5pt 5pt 5pt 5pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c4 {
        border-right-style: solid;
        padding: 12pt 18pt 12pt 18pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c12 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c15 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline
    }

    .c19 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c13 {
        margin: 1rem auto;
        background-color: #ffffff;
        width: 100%;
        max-width: 800px;
        padding: 72pt 72pt 72pt 72pt
    }

    .c11 {
        color: inherit;
        text-decoration: inherit
    }

    .c6 {
        height: 11pt
    }

    .c9 {
        height: 111.8pt
    }

    .c2 {
        height: 70.5pt
    }

    .c18 {
        height: 84.8pt
    }

    .c16 {
        height: 57pt
    }

    .c14 {
        height: 84pt
    }

    .c17 {
        height: 69.8pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
</style>
<div class="c13 doc-content"><p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c12">Privacy policy</span></p>
<p class="c3 c6"><span class="c12"></span></p>
<p class="c3"><span class="c0">W3DNA OU&#776; (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, &quot;w3dna&quot;), &#1072; legal entity established under the laws of the Estonia, with its registered office at Harju maakond, Tallinn, Kesklinna linnaosa, Vesiva&#776;rava tn 50-201, 10152 , Estonia , registration number: 1659239, it takes all possible efforts to secure your personal data and preserve its confidentiality.</span>
</p>
<p class="c3"><span class="c0">By this Privacy Policy (&quot;Policy&quot;) we want to inform you concerning the rights you have when accessing the w3dna.net website or using the Services (as defined in the terms and conditions), regarding the manner in which you provide us the consent for your personal data procession as well as particular purposes of the procession.</span>
</p>
<p class="c3"><span class="c0">GENERAL CONSIDERATIONS</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Policy is developed in order to provide you details regarding the types of personal data that we collect, store, and process; the purposes of such operations and other relevant matters that arise when you become a participant of the w3dna&#39;s projects and interact with the Company and its affiliates as well as when using this Website (as defined in the terms and conditions).</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By continuing to use the Website, by creating an account, participating in the w3dna&#39;s projects, you agreed with this Policy and allowed us to collect, store and process your personal data in accordance with this Policy for all purposes defined herein. If you do not agree with this Policy in general or with any part of it, you should not access the Website, or participate in the w3dna&#39;s projects, or other services or products offered on the Website.</span>
</p>
<p class="c3"><span class="c0">You should be aware of the following important matters:</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;what personal data we collect, store, and process;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;how you can make your impact on operations we carry out with your personal data;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in what manner you can enjoy your rights provided hereunder or by the applicable legislation.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">Please read this Policy carefully.</span></p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">PERSONAL DATA THAT WE COLLECT</span></p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">We collect your personal data when you:</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;use this Website;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;become a member of the w3dna&#39;s projects;</span>
</p>
<p class="c3"><span class="c0">&nbsp;</span></p>
<p class="c3"><span class="c0">We may collect the following personal data from you:</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;full name;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;email address;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone number;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;social medias accounts;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;country of residence;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IP-address and utm_source and other technical data;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transaction data including purchases of crypto assets;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;communication information;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;other information about you that you give us by filling in forms on the Websites or necessary by default to use Services or become a holder of any tokens.</span>
</p>
<p class="c3"><span class="c0">PURPOSES FOR THE PERSONAL DATA COLLECTION AND PROCESSING</span></p>
<p class="c3"><span class="c0">We use your personal data in order to:</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;identify you;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;provide you with the full scope of information regarding the w3dna &#39;s projects as well as concerning our Website operation;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;process and complete transactions with w3dna;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;send you notifications concerning your participation in the w3dna &#39;s projects;</span>
</p>
<p class="c3"><span
        class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;protect your legal interests;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;perform our obligations regarding any contracts or other arrangements concluded between you and us;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;administer, improve our Website by data analysis, testing, statistical and research operations as well as developing new options;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ensure that our Website content is presented in the most effective manner;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;provide you with all of our Website functionality and ensure its security;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;handle communications with you and respond to you requests;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fulfill our obligations under the applicable legislation;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;perform any obligatory actions that may be required by any applicable law or any authorized state body;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;detect, investigate and prevent unauthorized activities and fraudulent transactions.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">LEGAL BASIS FOR YOUR PERSONAL DATA PROCESSING</span></p>
<p class="c3"><span class="c0">All actions regarding data processing operations will be performed after you have given us your consent in any of the following ways:</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;expressed directly (e.g., by accepting this Policy or clicking a consent button);</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;resulted from any affirmative actions that indicate your consent (e.g., voluntary filling of any of the forms on the Website).</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please be aware that your personal data procession is our legitimate interest and required for the proper arrangement of the actions between you and us during your participation in the w3dna&#39;s projects, and provision of the services/products.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Keep in mind that in some cases we may request additional information to fulfill the legal demand of banks or authorities or comply with the applicable rules, anti-money laundering (&ldquo;AML&rdquo;), or &ldquo;know-your-customer&rdquo; (&ldquo;KYC&rdquo;) policies. This additional information shall only be necessary if requested and may be required prior to the start of your use of the Website or afterward.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may also sometimes compare the personal data you provide with third-party databases in order to verify its accuracy and confirm your identity.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additionally, we may use your personal data and other information we collect in order to detect, investigate and prevent fraudulent transactions and other illegal activities, develop new products and services, and to verify compliance with our internal policies.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">OPERATIONS THAT WE PERFORM WITH YOUR PERSONAL DATA</span></p>
<p class="c3"><span class="c0">Your personal data is used to make all opportunities of the Website available and convenient for you. For this purpose, we use your personal data in order to:</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;create your account;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;provide necessary support and protection;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;provide Services;</span></p>
<p class="c3"><span
        class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;deliver NFT domains to you;</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bring to you all the functionality of this Website;</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;improve our Website as well as the Services.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We also use your personal data only to identify you and send notifications. Apart from this, we collect and store your personal data in secured registers. Please note that we do not amend, disclose or transfer your personal data.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will store your personal data within the period of your use of the Services, the Website or the w3dna&#39;s projects and up to your request to erase it, unless we have an obligation to save and keep it according to the applicable legislation (e.g., AML). We will keep some of your personal data despite the demand to erase it or restrict its processing if it&rsquo;s requirement by the applicable law or necessary for performing our legal duties or submission, pursuing, or defending a claim.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The information that we collect from you is stored in EU.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We perform required measures to ensure that your information is stored and processed securely in line with this Policy and the applicable legislation.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">YOUR RIGHTS</span></p>
<p class="c3"><span class="c0">With regard to the scope of operations indicated in this Policy, you have the following rights:</span>
</p><a id="t.cc28ed1272414547a84cfd77c2d8ea4d3cdc3945"></a><a id="t.0"></a>
<table class="c19">
    <tr class="c16">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">Rights</span></p></td>
        <td class="c5" colspan="1" rowspan="1"><p class="c1"><span class="c0">Ways of realization</span></p></td>
    </tr>
    <tr class="c17">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">to access your personal data</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1"><p class="c1"><span class="c0">by requesting us directly via any mean indicated in Chapter 12</span>
        </p></td>
    </tr>
    <tr class="c9">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">to restrict the processing of personal data if (&#1110;) the processing of your personal data is unlawful; (&#1110;&#1110;) we do not need longer your personal data to process it.</span>
        </p></td>
        <td class="c7" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
    <tr class="c18">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">to get a copy of your personal data and receive your personal data in a machine-readable format</span>
        </p></td>
        <td class="c7" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
    <tr class="c2">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span
                class="c0">to object the use of your personal data</span></p></td>
        <td class="c7" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
    <tr class="c2">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">to withdraw your consent if you are a resident of the EU</span>
        </p></td>
        <td class="c7" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
    <tr class="c2">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span
                class="c0">to request for your personal data erasure</span></p></td>
        <td class="c7" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
    <tr class="c14">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">the right to lodge a complaint with a supervisory authority</span>
        </p></td>
        <td class="c5" colspan="1" rowspan="1"><p class="c1"><span class="c0">the complaint can be filed both before the relevant Data Protection Authority</span>
        </p></td>
    </tr>
    <tr class="c2">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">to correct your personal data</span></p>
        </td>
        <td class="c5" colspan="1" rowspan="1"><p class="c1"><span class="c0">using the account functionality or by referring to us directly</span>
        </p></td>
    </tr>
    <tr class="c2">
        <td class="c4" colspan="1" rowspan="1"><p class="c1"><span class="c0">other rights in accordance with applicable law</span>
        </p></td>
        <td class="c5" colspan="1" rowspan="1"><p class="c1"><span class="c0">&nbsp;</span></p></td>
    </tr>
</table>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please note that you may also enjoy other rights envisaged by the applicable legislation.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We completely respect and follow, where applicable, the rules and provisions of the EU General Data Protection Regulation (GDPR). Within the scope of GDPR we are the data controller of your personal data, so that we will implement appropriate technical and organizational measures in an effective way in order to meet the requirements of GDPR and protect the rights of all Community participants.</span>
</p>
<p class="c3"><span class="c0">COOKIES</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to provide you all functionality of our Website, ensure its security and improve our performance, we may use Cookies and involve outer services (the &ldquo;Partners&rdquo;) that use Cookies. We use Cookies that are (i) used solely for the purpose of transmitting a communication, (ii) absolutely necessary for Website to provide the Services and other Cookies.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, we cannot influence on our Partners operation strategies, but we provide you all necessary information about such Partners and their privacy policies.</span>
</p><a id="t.81c4e2026c62e7dcf4531534a378cb7beec4fe32"></a><a id="t.1"></a>
<table class="c19">
    <tr class="c16">
        <td class="c10" colspan="1" rowspan="1"><p class="c1"><span class="c0">Title</span></p></td>
        <td class="c8" colspan="1" rowspan="1"><p class="c1"><span class="c0">Privacy Policy Link</span></p></td>
    </tr>
    <tr class="c2">
        <td class="c10" colspan="1" rowspan="1"><p class="c1"><span class="c0">Google Analytics</span></p></td>
        <td class="c8" colspan="1" rowspan="1"><p class="c1"><span class="c15"><a class="c11"
                                                                                  href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den-GB&amp;sa=D&amp;source=editors&amp;ust=1672141086591704&amp;usg=AOvVaw3dOrVP-rc_vx8fM2sJvefU">https://policies.google.com/privacy?hl=en-GB</a></span>
        </p></td>
    </tr>
</table>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can change your browser settings in order to block all files and/or notifications regarding its sending. Unfortunately, some of the website functions may work incorrectly afterwards.</span>
</p>
<p class="c3"><span class="c0">THIRD PARTIES</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please be aware that we will process your personal data not only by ourselves, but also our affiliates, and/or our Partners without any disclosure to any third parties unless such disclosure is expressly envisaged by this Policy.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your personal data may be disclosed exclusively upon request of the competent authority (public, government, regulatory, judicial, and law enforcement) in a strict compliance with the procedure foreseen by the applicable legislation.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For sharing your personal data with other parties, we will request your specific consent for that.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">PROTECTION OF YOUR PERSONAL DATA</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to ensure the secure storage and use of your personal data we apply a number of technical and organizational measures. Along with this, we take measures to prevent non-authorized access and the personal data outflow.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please be aware that apart from our efforts, we expect that you will also apply certain protection measures, as complete security of your personal data can be reached only through our join efforts.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">CHANGES TO THIS POLICY</span></p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Policy may be amended and updated from time to time. Its current version is placed on this Website. Please check updates or amendments to our Policy.</span>
</p>
<p class="c3"><span class="c0">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case of substantial changes that can strongly influence on your rights realization, we will notify your regarding such changes via email.</span>
</p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">OUR CONTACT DETAILS</span></p>
<p class="c3"><span class="c0">If you have any feedback, concerns or complaints, please contact us directly:</span></p>
<p class="c3 c6"><span class="c0"></span></p>
<p class="c3"><span class="c0">Email: info.w3dna@gmail.com</span></p>
<p class="c3"><span class="c0">&nbsp;</span></p>
<p class="c3"><span class="c0">&nbsp;</span></p>
<p class="c3"><span class="c0">&nbsp;</span></p>
<p class="c3 c6"><span class="c0"></span></p>
</div>
`;

export function PrivacyPolicy() {
    return (
        <BaseLayout>
            <BaseLayout.Body>
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </BaseLayout.Body>
        </BaseLayout>
    );
}
