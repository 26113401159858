import {SerializedError} from "@reduxjs/toolkit";
import {errors} from "./errors";


export function serializeError(error: unknown, fallbackError?: SerializedError): SerializedError {
	const serialized: SerializedError = {};
	if (typeof error === 'string') {
		serialized.message = error;
		return serialized;
	}
	const message = (error as any)?.message;
	if (message && typeof message === 'string') {
		serialized.message = message;
	} else if (fallbackError?.message) {
		serialized.message = fallbackError.message;
	}
	const stack = (error as any)?.stack;
	if (stack && typeof stack === 'string') {
		serialized.stack = stack;
	}
	const code = (error as any)?.code;
	if (code && typeof code === 'string') {
		serialized.code = code;
	} else if (code && Number.isInteger(code)) {
		const name = errors.find(({code: errorCode}) => code === errorCode)?.name;
		if (name) serialized.name = name;
		serialized.code = String(code);
	}
	return serialized;
}
