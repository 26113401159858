import {getBrowserUrl} from "../../../../shared/util";
import {analytics, EventTarget, EventType, TargetLocation, TargetName} from "../../../analytics";
import React from "react";
import './SupportedBrowsers.scss';

export function SupportedBrowsers() {
  const handleClickDownload = (targetName: TargetName) => () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetName, targetLocation: TargetLocation.Presentation});
  return (
    <div className="view-supported-wrapper" id="browser">
      <div className="inner">
        <div className="view-supported">
          <div className="view-supported__title">
            Surf the Decentralised <br/>
            Web with *W3DNA
          </div>
          <div className="view-supported__subtitle">
            Supported Browsers
          </div>

          <div className="supported-items">
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">*W3DNA</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b1" ></div>
                </div>
                <div className="supported-item__label-block">
                  <a className="supported-item__label download-browser-button download-browser-button_mac" href={getBrowserUrl('MacIntel')} target={"_blank"} rel="noreferrer" onClick={handleClickDownload(TargetName.DownloadForMac)}>Download for</a>
                  <a className="supported-item__label download-browser-button download-browser-button_win" href={getBrowserUrl('Win32')} target={"_blank"} rel="noreferrer" onClick={handleClickDownload(TargetName.DownloadForWin)}>Download for</a>
                </div>
              </div>
            </div>
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">Google Chrome</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b4"></div>
                </div>
                <div className="supported-item__label-block">
                  <a className="supported-item__label download-extension-button" href='https://chrome.google.com/webstore/detail/w3dna/dpkdjhpbndahakikgobjagdgoekkfojn' target='_blank' rel='noreferrer'>Download&nbsp;extension</a>
                </div>
              </div>
            </div>
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">Opera</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b3"></div>
                </div>
                <div className="supported-item__label-block">
                  <a className="supported-item__label download-extension-button" href='https://addons.opera.com/ru/extensions/details/w3dna-2/' target='_blank' rel='noreferrer'>Download&nbsp;extension</a>
                </div>
              </div>
            </div>
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">Brave</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b2"></div>
                </div>
                <div className="supported-item__label-block">
                  <a className="supported-item__label download-extension-button" href='https://chrome.google.com/webstore/detail/w3dna/dpkdjhpbndahakikgobjagdgoekkfojn' target='_blank' rel='noreferrer'>Download&nbsp;extension</a>
                </div>
              </div>
            </div>
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">Microsoft Edge</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b5"></div>
                </div>
                <div className="supported-item__label-block">
                  <div className="supported-item__label">Coming soon</div>
                </div>

              </div>
            </div>
            <div className="supported-item">
              <div className="supported-item__inner">
                <div className="supported-item__name">Mozilla Firefox</div>
                <div className="supported-item__icon">
                  <div className="supported-item__img b6"></div>
                </div>
                <div className="supported-item__label-block">
                  <a className="supported-item__label download-extension-button" href='https://addons.mozilla.org/ru/firefox/user/17800335/' target='_blank' rel='noreferrer'>Download&nbsp;extension</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}