import './Cookies.scss';
import {FormEvent, PropsWithChildren, useState} from "react";
import {Modal} from "../../components";
import {useCookies} from "react-cookie";
import {PATHS} from "../../router/paths";
import styled from "styled-components";
import {PenLineIcon} from "../website/constructor/components/Icon";


export function CookiesModal () {
  const [cookies, setCookie] = useCookies(['cookies-policy']);
  const [isOpen, setIsOpen] = useState(!cookies['cookies-policy']);
  const handleAgree = () => {
    setCookie('cookies-policy', true, {maxAge: 157680000});
    setIsOpen(false)
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Contacts"
      contentElement={({style, ...props}, children) => <ModalContent {...props}>{children}</ModalContent>}
    >
      <Cookies onAgree={handleAgree} onClose={() => setIsOpen(false)}/>
    </Modal>
  );
}

export function Cookies(props: {onAgree: () => void, onClose: () => void}) {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onAgree();
  }
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(true);
  const isDisabled = !first || !second;
  return (
    <div className="popup-window">
      <button className="close-btn" onClick={props.onClose}></button>
      <form onSubmit={handleSubmit}>
        <div className="popup-description">
          <h1 className="popup-header">Hi traveller, we use cookies.</h1>
          <div className="popup-text">To enhance the functionality of our website and improve your experience, we collect, store, and process some of your personal data and use cookies.</div>
          <div className="popup-text">By continuing to use this website, you are consenting to and accepting our processing of your personal data.</div>
        </div>
        <Checkbox onChange={() => setFirst(!first)} name='privacy_and_terms' checked={first}>
          <span>I’ve read the <a href={PATHS.policy} target='_blank' rel='noreferrer'>Privacy Policy</a> and <a href={PATHS.terms} target='_blank' rel='noreferrer'>Terms and Conditions</a>.</span>
        </Checkbox>
        <Checkbox onChange={() => setSecond(!second)} name='usage_confirmation' checked={second}>
          I confirm that I want to use this website.
        </Checkbox>
        <div className="button">
          <button className="popup-button" disabled={isDisabled}>Accept all</button>
        </div>
      </form>
    </div>
  );
}

const ModalContent = styled.div`
  position: relative;
  margin: auto auto 0;
  padding: 1rem;
  width: 100%;
  border: 1px solid #d9d9d9;
  background: #fff;
  overflow: auto;
  outline: none;
`

const Checkbox = styled((props: PropsWithChildren & {name: string, checked: boolean, onChange: () => void, className?: string}) =>
  <label className={props.className}>
    <input type="checkbox" name={props.name} checked={props.checked} onChange={props.onChange}/>
    {props.children}
  </label>
)`
  margin-bottom: .75rem;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  line-height: 1.1;
  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 1em;
    height: 1em;
    border: 1px solid #4C37CA;
    border-radius: 50%;
    display: grid;
    place-content: center;
    &::before {
      content: "";
      width: .5em;
      height: .5em;
      transform: scale(0);
      transform-origin: center;
      transition: 120ms transform ease-in-out;
      background-color: #4C37CA;
      border: 1px solid #4C37CA;
      border-radius: 50%;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
  }
`