import {ContactType} from "./ContactType";
import {emailRegexp, instagramUsernameRegexp, telegramUsernameRegexp, twitterUsernameRegexp} from "shared/constants";
import {Asset} from "./Asset";
import {Theme} from "./Theme";
import {icons} from "../components/ContactsWidget/icons";

export type Contact = {
	type: ContactType
	value: string
}

const assets: Record<Theme, Partial<Record<ContactType, Asset>>> = {
	[Theme.Dark]: {},
	[Theme.Light]: {}
};

export const Contact = {
	types: Object.values(ContactType),
	getIcon(props: {type: ContactType, theme: Theme}): Asset {
		const {type, theme} = props;
		const cached = assets[theme]?.[type];
		if (cached) return cached;
		const asset = Asset.fromUrl(icons[theme][type]);
		assets[theme][type] = asset;
		return asset;
	},
	getIconUrl(props: {type: ContactType, theme: Theme, isEdit?: boolean}): string {
		const asset = this.getIcon(props);
		return Asset.getUrl(asset, props.isEdit)
	},
	getLink(contact: Contact) {
		switch (contact.type) {
			case ContactType.Email: return `mailto:${contact.value}`;
			case ContactType.Instagram: return `https://www.instagram.com/${contact.value}`;
			case ContactType.Telegram: return `https://t.me/${contact.value}`;
			case ContactType.Twitter: return `https://twitter.com/${contact.value}`;
		}
	},
	getPlaceholder(contact: {type: ContactType}) {
		switch (contact.type) {
			case ContactType.Email:
				return 'example@email.com'
			case ContactType.Instagram:
			case ContactType.Telegram:
			case ContactType.Twitter:
				return '@example'
		}
	},
	isContact(obj: any): obj is Contact {
		return typeof obj === 'object' && 'type' in obj && obj.type in ContactType;
	},
	isContacts(obj: any): obj is Contact[] {
		return Array.isArray(obj) && obj[0] && this.isContact(obj[0]);
	},
	isValid(contact: Contact): boolean {
		switch (contact.type) {
			case ContactType.Email: return !!contact.value.match(emailRegexp)
			case ContactType.Instagram: return !!contact.value.match(instagramUsernameRegexp)
			case ContactType.Telegram: return !!contact.value.match(telegramUsernameRegexp)
			case ContactType.Twitter: return !!contact.value.match(twitterUsernameRegexp)
		}
	},
}
