import {ga} from './google';
import {yandex} from './yandex';
import {AnalyticsEvent} from "./types/AnalyticsEvent";


export function push(event: AnalyticsEvent) {
	try {
		const params = new URLSearchParams(document.location.search);
		const utm: Record<string, string> = {};
		params.forEach((value, key) => { if (/^(utm_\S+)$/.test(key)) utm[key] = value });
		ga.push(event, utm)
		yandex.push(event, utm)
	} catch {}
}