import styled from "styled-components";
import ContentEditable, {ContentEditableEvent} from "./ContentEditable";
import {createElement, useRef, useState} from "react";
import {PenLineIcon} from "../Icon";
import {renderToStaticMarkup} from "react-dom/server";

type Props = {
	isEdit: boolean
	className?: string
	defaultValue?: string
	value: string
	onChange: (value: string) => void
	tagName?: string
}

export function TextWidget(props: Props) {
	return props.isEdit ? <EditMode {...props}/> : <RenderMode {...props}/>
}

function EditMode(props: Props) {
	const {className, value, onChange, tagName} = props;
	const defaultValue = props.defaultValue;
	const isPristine = useRef(!value.length);
	const placeholder = renderToStaticMarkup(<PlaceHolder placeholder={defaultValue ?? ''}/>);
	const [content, setContent] = useState(isPristine ? placeholder : value);
	const handleChange = (e: ContentEditableEvent) => {
		isPristine.current = false;
		onChange(e.target.value);
		setContent(e.target.value);
	}
	const handleFocus = () => {
		if (isPristine.current) setContent('');
	}
	const handleBlur = () => {
		if (isPristine.current) setContent(placeholder)
	}
	return (
		<Editable html={content} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} tagName={tagName} className={className}/>
	)
}

function RenderMode(props: Props) {
	const {className, tagName, value} = props;
 	return createElement(tagName ?? 'div', {className, dangerouslySetInnerHTML: { __html: value }});
}

const PlaceHolder = styled((props: {placeholder: string}) =>
	<div>
		{props.placeholder}
		&ensp;
		<PenLineIcon/>
	</div>
)``

const Editable = styled(ContentEditable)`
	cursor: pointer;
	:hover {
		opacity: .75;
	}
`