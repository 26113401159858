import {Asset} from "../../types/Asset";
import styled from "styled-components";
import {ReactComponent as Placeholder} from './assets/avatar.svg';
import camera from './assets/camera.svg';
import {ChangeEvent} from "react";

type Props = {
	isEdit: boolean
	image?: Asset
	onChange: (image?: Asset) => void
	className?: string
}

export function AvatarWidget(props: Props) {
	const {isEdit, image, onChange, className} = props;
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (image) Asset.forget(image);
		onChange(file ? Asset.from(file) : undefined);
	}
	return (
		<Wrapper className={className}>
			{image ? <Image src={Asset.getUrl(image, isEdit)}/> : <Placeholder/>}
			{isEdit &&
				<UploadButton>
					<input type="file" onChange={handleChange}/>
				</UploadButton>
			}
		</Wrapper>
	)
}
const Wrapper = styled.div`
	position: relative;
	width: 5.25rem;
	height: 5.25rem;
`
const Image = styled.img`
	width: 100%;
	height: 100%;
	clip-path: circle(2.5rem at center);
`
const UploadButton = styled.label`
	position: absolute;
	right: -.25rem;
	bottom: 0;
	width: 1.625rem;
  height: 1.625rem;
	background: #4C37CA url(${camera}) center / 1rem no-repeat;
	border: none;
	border-radius: 100%;
	cursor: pointer;
	:hover {
		opacity: .85;
	}
	input {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
	}
`