import React from 'react'
import styled from "styled-components";
import newsList from "./newsList";
import NewsCard from "./NewsCard";
import Slider from '../../../../components/slider/Slider'


export default function News(props: { id?: string }) {
  const slides = newsList.map((oneNews, index) => {
    return (
      <NewsCard {...oneNews} key={index}/>
    )
  })

  return (
    <Block id={props.id}>
      <Title>
        News
      </Title>
      <Slider slides={slides} id="home_news"/>
    </Block>
  )
}

const Title = styled.div`
  font-family: Fira Sans;
  font-size: 5em;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
`

const Block = styled.div`
  background: #6A35FF;
  padding-block: 4em;
  color: white;

  @media (max-width: 775px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.5vw;
  }
`
