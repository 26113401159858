import React from "react";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import "./Settings.scss";
import ChangePassword from "../password/ChangePassword";

export default function Settings() {

    return (
        <SidebarLayout>
            <h1>Settings</h1>
            <ul>
                <li className="">
                    <ChangePassword/>
                </li>
            </ul>
        </SidebarLayout>
    );
}