import * as t from "io-ts";
import {DomainPrice} from "./DomainPrice";

export const DomainPricesDataCodec = t.type({
  domainPrices: t.array(DomainPrice),
  price: t.string,
  couponDiscount: t.union([t.string, t.undefined]),
  discount: t.number,
  priceWithDiscount: t.string,
  priceWithoutPromo: t.string
});

export type DomainPricesData = t.TypeOf<typeof DomainPricesDataCodec>;
