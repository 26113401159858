const PATHS = {
  all: "*",
  login: "/login",
  terms : "/terms",
  policy : "/policy",
  find_domain_footer: '/#search-footer',
  partners: "/#partner",
  investors: "/investors",
  developers: "/developers",
  documents: "/documents",
  presentation: "/presentation",
  cart: '/cart',
  connect: '/connect',
  sales_forecast: '/sales_forecast',
  welcome: "/welcome",
  blog: "/blog",
  blog_item: "/blog/:path",
  error_404: '/not_found',
  external_presentation: 'https://drive.google.com/file/d/1kNVNvKEu0sDpj3z-1cArV7PXcaC13oXy/view',
  external_sales_forecast: 'https://docs.google.com/spreadsheets/d/1MNvDpjQtbDogp0mYgkvF9Sh14R6vvBMlTZCe-vbFoik/edit',

  account: {
    index: '/account',
    coupons: {
      index: '/account/coupons'
    },
    domains: {
      index: '/account/domains',
      build: (id: string, template?: string) => `/account/domains/${id}/edit${template ? '?template=' + template : ''}`,
      mint: (id: string) => `/account/domains/${id}/mint`,
      setExternalContent: (id: string) => `/account/domains/${id}/external`,
      upload: (id: string) => `/account/domains/${id}/upload`
    },
    password: {
      change: {index: '/account/password/change'},
      recover: {index: '/account/password/recover'}
    },
    settings: {
      index: '/account/settings'
    },
    profile: {index: '/account/profile'},
    referralprogram: {
      index: '/account/referralprogram'
    },
    register: {
      email: {
        index: '/account/register/email',
        confirm: {index: '/account/register/email/confirm'}
      }
    },
  },
  auth: {index: '/auth'},
  claim: {index: '/claim'},
  congratulations: {index: '/congratulations'},
  order: {index: '/order',},
  root: {
    index: "/",
    browser: '/#browser',
    news: '/#news'
  },
  search: {
    index: (term?: string) => `/search${term ? '?search=' + term : ''}`
  },
  signup: {
    index: '/signup',
    page : '/signup/page',
    success: '/signup/success'
  },

};

export {PATHS};
