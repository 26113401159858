import {createAction} from "@reduxjs/toolkit";
import {Auth, AuthType} from "./types";


export const init = createAction<AuthType>('auth/init');
export const invalidate = createAction('auth/invalidate');
export const cancel = createAction('auth/cancel');
export const clear = createAction('auth/clear');
export const update = createAction<Auth>('auth/update');
export const setAddress = createAction<string>('auth/setAddress');
export const setOTP = createAction<string>('auth/setOTP');
