import {cloneElement, FunctionComponent, ReactElement, SVGAttributes} from "react";
import styled from "styled-components";


export interface IconProps extends SVGAttributes<SVGElement> {
	size?: number | string
}

export const Icon = styled((props: IconProps & {children: ReactElement}) => {
	const {children, size, width, height, ...restProps} = props;
	return cloneElement(children, {
		width: width ?? size,
		height: height ?? size,
		...restProps
	});
})`
  display: inline-block;
  height: 1em;
  vertical-align: middle;
`

export function createIcon(component: any) {
	const {ReactComponent}: {ReactComponent: FunctionComponent<SVGAttributes<SVGElement>>} = component;
	return (props: IconProps) => <Icon {...props}><ReactComponent/></Icon>
}