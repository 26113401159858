import {Navigate} from "react-router-dom";
import {Chain, formatTxLink, useWaitForTransactionQuery} from "features/web3";
import {useWeb3} from 'hooks';
import {PATHS} from "router/paths";
import {Button, Loader} from "components";
import {Fragment} from "react";


export default function AwaitTransaction(props: {transactionHash: string, chain: Chain}) {
  const {transactionHash, chain} = props;
  const {address} = useWeb3();
  const {isLoading, error, refetch} = useWaitForTransactionQuery({chain, transactionHash});

  if (!address) return (
    <Navigate to={PATHS.connect}/>
  );
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <Button onClick={refetch} shape='squared'>Try again</Button>
    </Fragment>
  );
  if (isLoading) return (
    <Fragment>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2rem'}}><Loader size='2rem'/></div>
      <div>Please wait until your <a href={formatTxLink(transactionHash!, chain)} target='_blank' rel='noreferrer'>transaction</a> is mined</div>
    </Fragment>
  );
  return null;
}
