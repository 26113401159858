import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ClaimSaga, ClaimSagaStatus} from "./types";


export interface ClaimState {
  saga: ClaimSaga
}

const initialState: ClaimState = {
  saga: { status: ClaimSagaStatus.UNINITIALIZED }
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    createSaga: (state, {payload: {code}}: PayloadAction<{code: string}>) => {
      state.saga = {status: ClaimSagaStatus.SENDING_TRANSACTION, code};
    },
    deleteSaga: (state,{payload: {code}}: PayloadAction<{code: string}>) => {
      if ('code' in state.saga && state.saga.code === code) state.saga = {status: ClaimSagaStatus.UNINITIALIZED};
    },
    updateSaga: (state, action: PayloadAction<ClaimSaga>) => {
      state.saga = action.payload;
    }
  }
});

export const { actions, reducer } = slice;
