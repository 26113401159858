import {AnyAction, PayloadAction} from "@reduxjs/toolkit";
import {slice} from "./slice";
import {BuyTaskStatus, UserDomainsBuyTask} from "./model";


export function isFulfilledOrder(action: AnyAction): action is PayloadAction<UserDomainsBuyTask & {status: BuyTaskStatus.CANCELLED | BuyTaskStatus.FINISHED | BuyTaskStatus.RESERVATION_FINISHED}> {
  if (!slice.endpoints.fetchOrder.matchFulfilled(action)) return false;
  const {payload: {status}} = action;
  return (status === BuyTaskStatus.CANCELLED || status === BuyTaskStatus.FINISHED || status === BuyTaskStatus.RESERVATION_FINISHED);
}
