import styled from "styled-components";
import {Modal as ModalComponent} from '../Modal';
import {ContactType} from "../../types/ContactType";
import {SVGAttributes} from "react";
import {EmailIcon, InstagramIcon, TelegramIcon, TwitterIcon} from "../Icon";


export const Modal = styled(ModalComponent)`
	padding-bottom: 2rem;
`
export const Heading = styled.h1`
	margin-bottom: 1.375rem;
	font-size: 1.25rem;
	text-align: center;
`
export const List = styled.ul`
  padding: 0;
	list-style: none;
`
export const Item = styled.li`
	margin-bottom: .5rem;
	display: flex;
	align-items: center;
`
export const Icon = styled(getIcon)`
	font-size: 2rem;
	color: #4C37CA;
`
export const Input = styled.input`
	margin: 0 .5rem;
	padding: 0 .875rem;
	flex-grow: 1;
	font-size: 1rem;
  line-height: 2;
	border: 1px solid #d9d9d9;
	border-radius: .5rem;
`
export const CloseButton = styled.button`
	position: absolute;
	top: 1rem;
	right: .5rem;
	width: 2rem;
	height: 2rem;
	font-size: 1.25rem;
	border: none;
	background: none;
	color: #a4a4a4;
	cursor: pointer;
	:hover {
		color: #4C37CA;
	}
`

function getIcon(props: {type: ContactType} & SVGAttributes<SVGElement>) {
  const {type, ...rest} = props
  switch (type) {
    case ContactType.Email: return <EmailIcon {...rest}/>
    case ContactType.Instagram: return <InstagramIcon {...rest}/>
    case ContactType.Telegram: return <TelegramIcon {...rest}/>
    case ContactType.Twitter: return <TwitterIcon {...rest}/>
  }
}