import React, {Fragment} from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import styles from './CongratulationsPage.module.scss';
import {Link, useLocation} from "react-router-dom";
import {Order} from "../order/store/model/Order";
import {PaymentSystem} from "../../shared/model";
import {useAuth} from "../../hooks";
import {PATHS} from "../../router/paths";
import {Button} from "../../components";

export default function CongratulationsPage() {
  const {state} = useLocation();
  const {isAuthorized} = useAuth();
  const order: Order | undefined = state?.order;
  const isPlural = state?.order.items > 1;
  const shouldCheckEmail = order?.paymentSystem !== PaymentSystem.Chain && !isAuthorized;
  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>Congratulations!</div>
            <div className={styles.text}>Your domain{isPlural ? 's have' : ' has'} been successfully&nbsp;reserved.</div>
            {shouldCheckEmail ? (
              <div className={styles.text}>Please check your email for&nbsp;further&nbsp;instructions.</div>
            ) : (
              <Fragment>
                <div className={styles.text}>Go to your <Link to={PATHS.account.domains.index}>personal account</Link> for domain management:</div>
                <Button className={styles.button} type='route' to={PATHS.account.domains.index}>Go!</Button>
              </Fragment>
            )}
          </div>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );
}
