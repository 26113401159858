import React, {ButtonHTMLAttributes} from 'react';

export interface SignUpButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
    textButton : string,
    imageUrl : string
}

export function SignUpButton(props: SignUpButtonInterface) {   
    const {textButton, imageUrl, ...other} = props;
    return (
        <button {...other} className="registration__continue-item" >
            <img src={imageUrl} alt=""/>
            {textButton}
        </button>);
}