import {useEffect} from "react";
import {useParams} from "react-router-dom";
import SidebarLayout from "components/layout/SidebarLayout/SidebarLayout";
import {useSafeNavigate} from 'hooks';
import {MintDomain as MintDomainComponent} from "../components/MintDomain";
import styles from "./MintDomain.module.scss";


export function MintDomain() {
	const navigate = useSafeNavigate();
	const {id: tokenId} = useParams();
	useEffect(() => { if (!tokenId) navigate(-1) }, []);
	if (!tokenId) return null;
	return (
		<SidebarLayout className={styles.container}>
			<h1>Mint Domain</h1>
			<MintDomainComponent tokenId={tokenId}/>
		</SidebarLayout>
	);
}

