import './MyProfile.css';
import {Component} from "react";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import AccountSidebar from "../AccountSidebar/AccountSidebar";
import Input from "../../../components/input/Input";
import Textarea from "../../../components/textarea/Textarea";
import Button from "../../../components/button/Button";
import ImageInput from "../../../components/imageInput/ImageInput";


export default class MyProfile extends Component {

  handleClick(e) {
    alert('not implemented');
  }


  render() {
    return (
      <SidebarLayout>
        <div className="myprofile__container">
          <h1>My Profile</h1>

          <div className="myprofile__input-group-1">
            <Input
              label="name"
              placeholder="Add your name"
              name="name"
              defaultValue=""
            />

            <Textarea
              label="bio"
              placeholder="Add short bio"
              name="bio"
              maxLength={200}
            ></Textarea>

            <Input
              label="geo"
              placeholder="Add loction"
              name="geo"
              defaultValue=""
            />

            <Input
              label="site"
              placeholder="https://..."
              name="site"
              defaultValue=""
            />
          </div>

          <h1>Socials</h1>

          <div className="myprofile__input-group-2">
            <Input
              label="twitter"
              placeholder="Add twitter"
              name="twitter"
              defaultValue=""
            />

            <Input
              label="Discord"
              placeholder="Add discord"
              name="discord"
              defaultValue=""
            />

            <Input
              label="Youtube"
              placeholder="Add youtube"
              name="youtube"
              defaultValue=""
            />

            <Input
              label="Reddit"
              placeholder="Add reddit"
              name="reddit"
              defaultValue=""
            />

            <Input
              label="Telegram"
              placeholder="Add telegram"
              name="telegram"
              defaultValue=""
            />

            <ImageInput
              label="Avatar"
              name="avatar"
              defaultValue=""
              onChange={file => {
              }}/>
          </div>


          <h1>Profile metadata</h1>

          <div className="myprofile__input-group-3">
            <Input
              label="Public email"
              placeholder="Add public email"
              name="email-public"
              defaultValue=""
            />

            <Input
              label="Secret email"
              placeholder="Add secret email"
              name="email-secret"
              defaultValue=""
            />
          </div>

          <div className="myprofile__input-group-4">
            <Button
              shape="squared"
              onClick={(e) => this.handleClick(e)}
            >
              Save (Free)
            </Button>
          </div>

        </div>
      </SidebarLayout>
    );
  }
}
