import {useRequestPasswordChangeMutation} from "features/api/sales/slice";
import {Closed} from "./Closed";
import {useEffect} from "react";

export function Initial(props: {email: string, onSuccess: () => void}) {
  const [requestPasswordChange, {data, isLoading, error}] = useRequestPasswordChangeMutation();
  useEffect(() => { if (data) props.onSuccess() }, [data])
  return (
    <Closed isDisabled={isLoading} onClick={() => requestPasswordChange({email: props.email})} error={error && error.message}/>
  );
}