import styled from "styled-components";
import {getCurrentUrl, getReferralUrl} from "shared/util";
import {useAccount} from "hooks";
import {FacebookCircled, FacebookMessengerCircled, TelegramCircled, TwitterCircled} from "components/icon";

export function Socials (props: {domainName: string, className?: string}) {
  const {referralId} = useAccount() ?? {};
  const text = `Awesome! I bought a top level NFT domain: ${props.domainName} and hosted a website on the blockchain. Try this amazing service!`;
  const hashtags = ['W3DNA', 'domains', 'domain', 'domainsforsale', 'nftDomain', 'WEB3domain'];
  const url = referralId ? getReferralUrl(referralId) : undefined;
  return (
    <Wrapper className={props.className}>
      <Title>Share with your friends:</Title>
      <Links>
        {links.map(({name, icon}, key) => <Link name={name} text={text} hashtags={hashtags} url={url} key={key}>{icon}</Link>)}
      </Links>
    </Wrapper>
  )
}

type NetworkName = 'facebook' | 'facebook-messenger' | 'telegram' | 'twitter'

const links: {name: NetworkName, icon: JSX.Element}[] = [
  { name: 'twitter', icon: <TwitterCircled/> },
  { name: 'telegram', icon: <TelegramCircled/> },
  { name: 'facebook-messenger', icon: <FacebookMessengerCircled/> },
  { name: 'facebook', icon: <FacebookCircled/> },
]

function objectToGetParams(object: { [key: string]: string | number | undefined | null; }) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  return params.length > 0 ? `?${params.join('&')}` : '';
}

function renderLink(props: {name: NetworkName, text: string, url: string, hashtags: string[]}) {
  const {name, text, url} = props;
  const hashtags = props.hashtags.length > 0 ? props.hashtags.join(',') : undefined;
  const hashtag = props.hashtags ? `#${props.hashtags[0]}` : undefined;
  switch (name) {
    case 'facebook': return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({u: url, hashtag})}`;
    case 'facebook-messenger': return `https://www.facebook.com/dialog/send${objectToGetParams({link: url, redirect_uri: getCurrentUrl(), app_id: process.env.REACT_APP_FACEBOOK_APP_ID})}`;
    case 'telegram': return `https://telegram.me/share/url${objectToGetParams({url, text})}`;
    case 'twitter': return `https://twitter.com/share${objectToGetParams({url, text, hashtags})}`
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media(min-width: 768px) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.5rem;
    justify-content: space-between;
    align-items: center;
  }
`
const Title = styled.div`
  margin: 1rem 0;
  flex-grow: 1;
  color: #a4a4a4;
`
const Links = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: .5rem;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  list-style: none;
`
const Link = styled((props) => (
  <li className={props.className}>
    <a href={renderLink(props)} target='_blank' rel='noreferrer'>{props.children}</a>
  </li>
))<{name: string, text: string, url?: string, hashtags: string[]}>`
  font-size: 2rem;
  line-height: 1em;
`
