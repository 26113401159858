import {cloneElement, FunctionComponent, ReactElement, SVGAttributes} from "react";
import styles from './Icon.module.scss';


export interface IconProps extends SVGAttributes<SVGElement> {
	size?: number | string
}

export function Icon (props: IconProps & {children: ReactElement}) {
	const {children, className, size, width, height, ...restProps} = props;
	return cloneElement(children, {
		className: `${styles.icon} ${className ?? ''}`,
		width: width ?? size,
		height: height ?? size,
		...restProps
	});
}

export function createIcon(component: any) {
	const {ReactComponent}: {ReactComponent: FunctionComponent<SVGAttributes<SVGElement>>} = component;
	return (props: IconProps) => <Icon {...props}><ReactComponent/></Icon>
}