import {FormEvent} from "react";
import styled from "styled-components";
import Magnifier from "./assets/Magnifier.svg";

type SearchInputProps = {
  value?: string
  isLoading: boolean
  onSubmit: (e: FormEvent<HTMLFormElement & {elements: {domain: HTMLInputElement}}>) => void
  placeholder: string
  className?: string
}

export function SearchInput(props: SearchInputProps) {
  return (
    <Form onSubmit={props.onSubmit} className={props.className}>
      <Input type="text" name="domain" defaultValue={props.value} autoFocus placeholder={props.placeholder}/>
      <Button type="submit" disabled={props.isLoading}><span>search</span></Button>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  align-items: center;
  border: 1px solid #DBE1E8;
  border-radius: 13px;
  overflow: hidden;
`
const Input = styled.input`
  flex-grow: 1;
  min-width: 0;
  padding: 1rem 1.5rem;
  border: none;
  background: none;
  font-size: 1.125rem;
  font-weight: 700;
  @media (min-width: 768px) {
    padding: 1.625rem 1.5rem 1.625rem 4.5rem;
    font-size: 1.25rem;
  }
`
const Button = styled.button`
  margin-right: .75rem;
  padding: 1rem;
  width: 1.625rem;
  height: 1.625rem;
  border: none;
  background: url(${Magnifier}) transparent no-repeat center;
  span {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    opacity: .75;
  }
  @media (min-width: 768px) {
    margin-right: 1.625rem;
    width: 2.125rem;
    height: 2.125rem;
  }
`