import {
	configureChains,
	connect,
	Connector,
	createClient,
	getAccount,
	getNetwork,
	signMessage,
	switchNetwork,
	waitForTransaction,
	watchAccount,
	watchNetwork
} from '@wagmi/core'
import {MetaMaskConnector} from '@wagmi/core/connectors/metaMask';
import {WalletConnectConnector} from '@wagmi/core/connectors/walletConnect';
import {publicProvider} from '@wagmi/core/providers/public'
import {ContentProvider, DomainMarket, DomainNFT, ERC20} from './contracts';
import {Chain, ContractName, Web3Address, Web3ClientName, Web3Hash} from "../../model";
import {getChainById, getChainId, getChains, getContractAddress, getERC20Decimals} from "./util";

const { chains, provider, webSocketProvider } = configureChains(Object.values(getChains()), [publicProvider()]);

const getConnector = (clientName: Web3ClientName): Connector => {
	switch (clientName) {
		case Web3ClientName.MetaMask:
			return new MetaMaskConnector({chains});
		case Web3ClientName.WalletConnect:
			return new WalletConnectConnector({chains, options: {version: '2', projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!}})
	}
};

createClient({ provider, webSocketProvider });

export const wagmi = {
	connect: async function({chain, connector}: {chain?: Chain, connector: Web3ClientName}) {
		const chainId = chain && getChainId(chain);
		return connect({chainId, connector: getConnector(connector)});
	},
	getAccount: async function() {
		return getAccount();
	},
	getNetwork: async function() {
		const {chain} = await getNetwork();
		return chain && getChainById(chain.id);
	},
	isConnected: async function() {
		const {chain} = await getNetwork();
		return !!chain;
	},
	signMessage: async function(message: string) {
		return signMessage({message})
	},
	waitForTransaction: async function(chain: Chain, hash: Web3Hash) {
		return waitForTransaction({hash, chainId: getChainId(chain)})
	},
	switchNetwork: async function(chain: Chain) {
		return switchNetwork({chainId: getChainId(chain)});
	},
	watchAccount: function(callback: (data: {address?: string}) => void) {
		watchAccount(callback);
	},
	watchNetwork: function(callback: () => void) {
		watchNetwork(callback);
	},
};

export const contracts = {
	ContentProvider: (chain: Chain, address?: Web3Address) =>
		ContentProvider(getChainId(chain), address ?? getContractAddress(chain, ContractName.ContentProvider)),
	DomainMarket: (chain: Chain, address?: Web3Address) =>
		DomainMarket(getChainId(chain), address ?? getContractAddress(chain, ContractName.DomainMarket)),
	DomainNFT: (chain: Chain, address?: Web3Address) =>
		DomainNFT(getChainId(chain), address ?? getContractAddress(chain, ContractName.DomainNFT)),
	USDT: ({chain, address, decimals}: {chain: Chain, address?: Web3Address, decimals?: number}) =>
		ERC20(getChainId(chain), address ?? getContractAddress(chain, ContractName.USDT), {decimals: decimals ?? getERC20Decimals(chain, ContractName.USDT)})
}
