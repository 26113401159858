import React, {Fragment, PropsWithChildren} from 'react'
import styled from "styled-components";
import CardBody from 'components/slider/CardBody';
import LinkIcon from './images/link.svg'

interface NewsCardProps {
  pub_title: string,
  pub_url?: string,
  pub_date: string,
  izd_title?: string,
  image: string,
}

export default function NewsCard(props: NewsCardProps) {
  return (
    <CardBody>
      <Image as={props.pub_url ? 'a' : 'div'} href={props.pub_url} target='_blank' rel='noreferrer'>
        <img src={props.image} alt={props.pub_title}/>
      </Image>
      <Title>{props.pub_title}</Title>
      <Footer>
        {props.pub_url &&
          <Fragment>
            <SourceLink href={props.pub_url} target='_blank' rel='noreferrer'>{props.izd_title}</SourceLink>
            <SourceIcon src={LinkIcon} alt=""/>
          </Fragment>
        }
        <Date>{props.pub_date}</Date>
      </Footer>
    </CardBody>
  )
}

const Image = styled.a`
  display: flex;
  height: 10rem;
  max-height: 10rem;
  flex-basis: 10rem;
  & img {
    width: 100%;
    object-fit: contain;
    user-select: none;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  margin-bottom: auto;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #0F0F0F;
  user-select: none;
  overflow: hidden;
  @media (min-width: 776px) AND (max-width: 999px) {
    font-size: 1rem;
  }
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
`

const SourceLink = styled.a`
  margin-bottom: auto;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  color: #037BDE;
  @media (min-width: 776px) AND (max-width: 999px) {
    font-size: .625rem;
  }
`

const Date = styled.div`
  font-weight: 500;
  font-size: .75rem;
  line-height: 1rem;
  color: #A4A4A4;
  @media (min-width: 750px) AND (max-width: 999px) {
    font-size: .5rem;
  }
`

const SourceIcon = styled.img`
  grid-row: span 2;
  align-self: center;
  user-select: none;
`
