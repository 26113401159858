import {createAction} from "@reduxjs/toolkit";
import {actions} from "./slice";
import {Coupon} from "./model/Coupon";

export const {
  addDomain,
  removeDomain,
  removeDomains,
  restore,
  setPaymentOption,
} = actions;

export const coupon = {
  check: createAction<{value: string}>('coupon/check'),
  clear: createAction('coupon/clear'),
  update: createAction<Coupon>('coupon/update')
}
export const clear = createAction('cart/clear');
export const validate = createAction('cart/validate')
