import '../AuthPage.scss';
import React from 'react';
import {Navigate, useSearchParams} from "react-router-dom";
import {useAuth} from 'hooks';
import BaseLayout from "../../../components/layout/BaseLayout/BaseLayout";
import styles from "./SignUp.module.scss";
import ConfirmEmail from "./ConfirmEmail";
import CreateAccount from "./CreateAccount";
import {PATHS} from "../../../router/paths";

export default function SignUp () {

	const {isAuthorized} = useAuth();
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');

	if (isAuthorized) return <Navigate to={PATHS.account.domains.index}/>

	return (
		<BaseLayout>
			<BaseLayout.Body>
				<div className={styles.wrapper}>
					<div className="registration">
						<div className="registration__title">Sign Up</div>
							{(() => {
								if (code) return (
									<ConfirmEmail code={code}/>
								)
								return (
									<CreateAccount/>
								)
							})()}
					</div>
				</div>
			</BaseLayout.Body>
		</BaseLayout>
	)

}
