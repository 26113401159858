import styles from './Loader.module.scss';
import {HTMLAttributes} from "react";

type unit = 'px' | 'em' | 'rem'

export default function Loader(props: HTMLAttributes<HTMLDivElement> & {size?: `${number}${unit}`}) {
	const {style, size, ...rest} = props;
	return (
		<div className={styles.loader} style={{fontSize: size, ...style}} {...rest}/>
	);
}
