import {useEffect} from 'react';
import {ChainGuard, useGetPersonalSignQuery} from "features/web3";
import {useGetJWTTokenMutation, useGetTemporaryTokenQuery} from "features/api/sales/slice";
import {AuthStatus, AuthType} from "../store/types";
import {useDispatch, useReferrer, useSelector, useWeb3} from 'hooks';
import {actions as authActions, selectors as authSelectors} from '../store';
import {SignUpButton} from "./SignUpButton";
import {RequestWrapper} from "components";

export function Web3AuthButton (props: {onClick: (authType: typeof AuthType.WEB3) => void}) {
  return <SignUpButton onClick={() => props.onClick(AuthType.WEB3)} imageUrl="/assets/img/cont-wallet-icon.svg" textButton="Continue with WEB3"/>;
}

export function Web3AuthPage () {
  const state = useSelector(authSelectors.selectAuthState);
  if ('type' in state && state.type !== AuthType.WEB3) return null;
  switch(state.status) {
    case AuthStatus.AWAITING_ADDRESS: return <ChainGuard><GetAddress/></ChainGuard>
    case AuthStatus.AWAITING_TEMPORARY_TOKEN: return <ChainGuard address={state.address}><GetTemporaryToken address={state.address}/></ChainGuard>
    case AuthStatus.AWAITING_SIGNATURE: return <ChainGuard address={state.address}><SignMessage temporaryToken={state.temporaryToken}/></ChainGuard>
    case AuthStatus.AWAITING_TOKEN: return <GetJWTToken signature={state.signature} temporaryToken={state.temporaryToken}/>
    default: return null;
  }
}

function GetAddress () {
  const dispatch = useDispatch();
  const {address} = useWeb3();
  useEffect(() => { if (address) dispatch(authActions.setAddress(address)) }, [address]);
  return null;
}

function GetTemporaryToken (props: {address: string}) {
  const {isFetching, error, refetch} = useGetTemporaryTokenQuery(props.address, {refetchOnMountOrArgChange: true});
  return <RequestWrapper action={refetch} isLoading={isFetching} error={error}/>
}

function SignMessage (props: {temporaryToken: string}) {
  const {isFetching, error, refetch} = useGetPersonalSignQuery(props.temporaryToken);
  return (
    <RequestWrapper action={refetch} isLoading={isFetching} error={error}>
      {{ loader: { description: 'Please, open your wallet and sign message to continue', button: null } }}
    </RequestWrapper>
  );
}

function GetJWTToken (props: {temporaryToken: string, signature: string}) {
  const referrerId = useReferrer();
  const [getToken, {isLoading, error}] = useGetJWTTokenMutation();
  useEffect(() => { getToken({...props, referrerId}) }, []);
  return <RequestWrapper action={() => getToken({...props, referrerId})} isLoading={isLoading} error={error}/>
}
