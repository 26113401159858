import {createAction} from "@reduxjs/toolkit";
import {actions} from "./slice";
import {Currency} from "../../../shared/types";
import {Payment} from "./model/Payment";
import {Chain} from "features/web3";

export const {
  createOrder,
  deleteOrder,
  updateOrder
} = actions;

export const payment = {
  init: createAction<{orderId: number, amount: string, currency: Currency, chain: Chain}>('order/payment/init'),
  cancel: createAction('order/payment/cancel'),
  update: createAction<Payment>('order/payment/update'),
  clear: createAction('order/payment/clear')
}

export const cancelOrder = createAction<boolean | undefined>('order/cancelOrder');
export const clear = createAction('order/clear');
export const markOrderAsPaid = createAction<{id: number}>('order/markAsPaid')
