import {PATHS} from "../../../../router/paths";
import {Fragment} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Button} from "../../../../components";
import {Xmark} from "../../../../components/icon";

export function TemplateSelector(props: {domainId: number, onClickClose?: () => void}) {
  const {domainId, onClickClose} = props;
  return (
    <Fragment>
      <Title>To create a site, select a template or upload your files {onClickClose && <Button onClick={onClickClose} shape='icon'><Xmark/></Button>}</Title>
      <List>
        {getTemplates(domainId).map(({title, description, route}, idx) =>
          <li key={idx}>
            <TemplateLink to={route}>
              <TemplateTitle>{title}</TemplateTitle>
              <TemplateDescription>{description}</TemplateDescription>
            </TemplateLink>
          </li>
        )}
      </List>
    </Fragment>
  )
}

function getTemplates(domainId: number) {
  return [{
    title: 'Profile',
    description: 'When you want to share your contacts or personal details',
    route: PATHS.account.domains.build(domainId.toString(), 'Profile')
  }, {
    title: 'For sale',
    description: 'When you are trying to sell your domain',
    route: PATHS.account.domains.build(domainId.toString(), 'ForSale')
  }, {
    title: 'Coming Soon',
    description: 'When your site is under construction',
    route: PATHS.account.domains.build(domainId.toString(), 'ComingSoon')
  }, {
    title: 'Expert',
    description: 'When you have own index.html or site.zip files',
    route: PATHS.account.domains.upload(domainId.toString())
  }]
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  @media(min-width: 768px) {
    margin-bottom: 1rem;
  }
`
const List = styled.ul`
  display: grid;
  grid-row-gap: .25rem;
  list-style: none;
  @media(min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 2/3;
    grid-column-gap: .625rem;
  }
`
const TemplateLink = styled(Link)`
  padding: 1rem;
  display: block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: inherit;
  border-radius: .5rem;
  box-shadow:
    0 0 .5rem #e9ecef,
    0 0 0 1px #dbe1e8 inset;
  &:hover {
    box-shadow:
      0 0 .5rem #e9ecef,
      0 0 0 2px #4c37ca inset;
  }
`
const TemplateTitle = styled.div`
  margin-bottom: .25rem;
  font-weight: 700;
  color: #4c37ca;
  @media(min-width: 768px) {
    margin-bottom: .75rem;
  }
`
const TemplateDescription = styled.div`
  color: #495057;
  @media(min-width: 768px) {
    font-size: .75rem;
  }
`
