import {createApi} from '@reduxjs/toolkit/query/react'
import {PaymentSystem} from "shared/model";
import {Currency} from "shared/types";
import {stripUndefined} from "shared/util";
import {axiosBaseQuery} from "../util/axios";
import decodeWith from "../util/decodeWith";
import {CreateBuyTaskData, RESTProtocolResponseMessage, UserDomainsBuyTask} from './model';
import {Coupon} from "./model/Coupon";
import {DomainPricesData, DomainPricesDataCodec} from "./model/DomainPricesData";
import {RESTProtocolDataResponse, RESTProtocolDataResponseCodec} from "./model/RESTProtocolDataResponse";
import {CouponValidation, CouponValidationCodec} from "./model/CouponValidation";
import {HeropaymentsInvoice, HeropaymentsInvoiceCodec} from "./model/HeropaymentsInvoice";
import {
  WertPaymentSignedTransactionResponseData,
  WertPaymentSignedTransactionResponseDataCodec
} from "./model/WertPaymentSignedTransactionResponseData";
import {ReferralData, ReferralDataCodec} from "./model/ReferralData";
import {OpenIdProviderType} from "./model/OpenIdProviderType";
import {AuthenticationResponse, AuthenticationResponseCodec} from "./model/AuthenticationResponse";
import {RootState} from "store";
import {AuthStatus} from "features/auth/store/types";
import {actions as authActions} from 'features/auth/store';
import {UserDomainNameInfos, UserDomainNameInfosCodec} from "./model/UserDomainNameInfos";
import {Account, AccountCodec} from "./model/Account";
import {CompetitionsData, CompetitionsDataCodec} from "./model/CompetitionsData";
import {interceptResponseError, nameTheError} from "./util";
import {Referrals, ReferralsCodec} from "./model/Referral";
import {ReplicationTaskData, ReplicationTaskDataCodec} from "./model/ReplicationTaskData";
import {Chain} from "features/web3";

export type AccountId = {accountId: number};

export const tags = {
  account: 'account',
  domains: 'domains',
  referral: 'referral'
} as const;

export const slice = createApi({
  reducerPath: 'api/sales',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/sales',
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as RootState).auth;
      if (auth.status === AuthStatus.FULFILLED) headers.set('authorization', `Bearer ${auth.token}`);
      return headers;
    },
    transformResponse: (response) => {
      const error = interceptResponseError(response);
      if (error) return { error: nameTheError(error) };
      return { data: response.data };
    },
    transformError: (error, api) => {
      if (error.response?.status === 401) {
        api.dispatch(authActions.invalidate());
        api.dispatch(slice.util.invalidateTags([tags.account]));
      }
      const responseError = interceptResponseError(error.response);
      if (responseError) return { error: nameTheError(responseError) };
      return { error: nameTheError(error) }
    }
  }),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    assignAddress: builder.mutation<RESTProtocolResponseMessage, {signature: string, temporaryToken: string, code: string}>({
      query: ({signature, temporaryToken, code}) => ({url: 'registration/address/assign', method: 'post', data: {signature, tempToken: temporaryToken, code}}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    assignAddressWithAuth: builder.mutation<RESTProtocolResponseMessage, {signature: string, token: string}>({
      query: ({signature, token}) => ({url: 'registration/address/assign/auth', method: 'post', data: {signature, tempToken: token}}),
      transformResponse: decodeWith(RESTProtocolResponseMessage),
      invalidatesTags: [tags.account, tags.referral]
    }),
    auth: builder.mutation<AuthenticationResponse, {token: string, providerType: OpenIdProviderType, referrer?: string}>({
      query: ({token, providerType, referrer}) => ({url: `openid`, method: 'post', data: stripUndefined({token, providerType, referrerId: referrer}) }),
      transformResponse: decodeWith(AuthenticationResponseCodec),
      invalidatesTags: [tags.account]
    }),
    checkCoupon: builder.query<RESTProtocolDataResponse<CouponValidation>, {ownerId: number, coupon: string}>({
      query: ({ownerId, coupon}) => ({url: 'coupons/validate', method: 'post', data: stripUndefined({ownerId, couponName: coupon})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(CouponValidationCodec))
    }),
    claimDomains: builder.mutation<RESTProtocolDataResponse<UserDomainsBuyTask>, {address: string, code: string, shouldCreateNewAccount?: boolean}>({
      query: ({address, code, shouldCreateNewAccount}) => ({url: 'domains/task/mint', method: 'post', data: stripUndefined({code, address, should_create_new_account: shouldCreateNewAccount})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(UserDomainsBuyTask))
    }),
    createAccount: builder.mutation<RESTProtocolResponseMessage, {email: string, referrer?: string}>({
      query: ({email, referrer}) => ({url: 'registration/email/new', method: 'post', data: stripUndefined({email, referrerId: referrer})}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    finishAccountRegistration: builder.mutation<RESTProtocolResponseMessage, {password: string, code: string}>({
      query: ({password, code}) => ({url: `registration/email/verify`, method: 'post', data: {password, code}}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    requestPasswordChange: builder.mutation<RESTProtocolResponseMessage, {email: string}>({
      query: ({email}) => ({url: `registration/password/reset`, method: 'post', data: stripUndefined({email})}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    changePassword: builder.mutation<RESTProtocolResponseMessage, {code: string, newPassword: string}>({
      query: ({code, newPassword}) => ({url: `registration/password/reset/set`, method: 'post', data: stripUndefined({code, newPassword})}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    confirmEmail: builder.query<void, {code: string}>({
      query: ({code}) => ({url: `registration/email/verify/${code}`, method: 'get'}),
    }),
    verifyRecoveryCode: builder.query<void, {code: string}>({
      query: ({code}) => ({url: `registration/recovery/verify/${code}`, method: 'get'}),
    }),
    fetchAccount: builder.query<RESTProtocolDataResponse<Account>, {id: number}>({
      query: ({id}) => ({url: `accounts/${id}`, method: 'get'}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(AccountCodec)),
      providesTags: [tags.account]
    }),
    fetchCompetitions: builder.query<RESTProtocolDataResponse<CompetitionsData>, void>({
      query: () => ({url: `coupons/competition`, method: 'get'}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(CompetitionsDataCodec)),
      providesTags: [tags.account]
    }),
    fetchCoupons: builder.query<Coupon[], {accountId: number}>({
      query: ({accountId}) => ({url: `coupons/account/${accountId}`, method: 'get'}),
      transformResponse: ({data: {coupons}}) => coupons.map(decodeWith(Coupon)),
      providesTags: [tags.account]
    }),
    fetchDomains: builder.query<RESTProtocolDataResponse<UserDomainNameInfos>, void>({
      query: () => ({url: `domains/info/account`, method: 'get'}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(UserDomainNameInfosCodec)),
      providesTags: [tags.account, tags.domains]
    }),
    fetchOrder: builder.query<UserDomainsBuyTask, {id: number}>({
      query: ({id}) => ({url: `domains/task/${id}`, method: 'get'}),
      transformResponse: ({data}) => decodeWith(UserDomainsBuyTask)(data)
    }),
    fetchPrices: builder.query<RESTProtocolDataResponse<DomainPricesData>, {domainNames: string[], account?: string, coupon?: string, referrer?: string}>({
      query: ({domainNames, account, coupon, referrer}) => ({url: 'domains/price', method: 'post', data: stripUndefined({domainNames, buyerAddress: account, couponId: coupon, referrerId: referrer})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(DomainPricesDataCodec)),
      providesTags: [tags.account]
    }),
    fetchReferralData: builder.query<RESTProtocolDataResponse<ReferralData>, AccountId>({
      query: ({accountId}) => ({url: `refprogram/reflink/${accountId}`, method: 'get'}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(ReferralDataCodec)),
      providesTags: [tags.account, tags.referral]
    }),
    fetchReferrals : builder.query<RESTProtocolDataResponse<Referrals>,AccountId>({
      query :({accountId}) => ({url : `refprogram/referrals/${accountId}`, method : 'get'}),
      transformResponse : decodeWith(RESTProtocolDataResponseCodec(ReferralsCodec)),
      providesTags: [tags.account]
    }),
    getTemporaryToken: builder.query<string, string>({
      query: address => ({url: `chain/auth/${address}`, method: 'get' })
    }),
    getJWTToken: builder.mutation<string, {temporaryToken: string, signature: string, referrerId?: string}>({
      query: ({temporaryToken, signature, referrerId}) => ({url: `chain/auth`, method: 'post', data: stripUndefined({tempToken: temporaryToken, signature, referrerId: referrerId}) }),
      transformResponse: (response: { token: string }) => response.token,
      invalidatesTags: [tags.account]
    }),
    placeOrder: builder.mutation<CreateBuyTaskData, {domainNames: string[], paymentSystem: PaymentSystem, currency: Currency, chain?: Chain, buyer?: string, coupon?: string, referrer?: string}>({
      query: ({domainNames, paymentSystem, currency, buyer, coupon, referrer, chain}) => ({
        url: 'domains/task',
        method: 'post',
        data: stripUndefined({
          domainNames,
          paymentSystem,
          currency,
          chain,
          buyerAddress: buyer,
          couponId: coupon,
          referrerId: referrer
        })
      }),
      transformResponse: ({data}) => decodeWith(CreateBuyTaskData)(data),
      invalidatesTags: [tags.account, tags.domains]
    }),
    registerCryptoPayment: builder.mutation<RESTProtocolResponseMessage, {orderId: number, transactionHash: string, paymentSystem: PaymentSystem, currency: Currency, chain: Chain}>({
      query: ({orderId, transactionHash, paymentSystem, currency, chain}) => ({url: 'payment/chain/callback', method: 'post', data: {
          taskId: orderId,
          transactionHash,
          paymentSystem,
          chain,
          currency
        }}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    registerEmail: builder.mutation<RESTProtocolResponseMessage, {email: string, referrer?: string}>({
      query: ({email, referrer}) => ({url: 'registration/email', method: 'post', data: stripUndefined({email, referrerId: referrer})}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    requestHeropaymentsInvoice: builder.mutation<RESTProtocolDataResponse<HeropaymentsInvoice>, {orderId: number, successUrl: string, failUrl: string}>({
      query: ({orderId, successUrl, failUrl}) => ({url: 'payment/heropayments/request-invoice', method: 'post', data: {orderId, successUrl, failUrl}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(HeropaymentsInvoiceCodec))
    }),
    requestWertInvoice: builder.mutation<RESTProtocolDataResponse<WertPaymentSignedTransactionResponseData>, {orderId: number, email: string}>({
      query: ({orderId, email}) => ({url: 'payment/wert/signature', method: 'post', data: {taskId: orderId, email}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(WertPaymentSignedTransactionResponseDataCodec))
    }),
    requestTokenRelease: builder.mutation<RESTProtocolResponseMessage, {tokenId: string, address: string}>({
      query: ({tokenId, address}) => ({url: 'mint/rights', method: 'post', data: {tokenId, address}}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    }),
    requestTokenReleaseStatus: builder.query<RESTProtocolDataResponse<ReplicationTaskData>, {tokenId: string}>({
      query: ({tokenId}) => ({url: 'mint/rights/status', method: 'post', data: {tokenId}}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(ReplicationTaskDataCodec)),
      keepUnusedDataFor: 0
    }),
    search: builder.query<RESTProtocolDataResponse<DomainPricesData>, {domainName: string, account?: string, coupon?: string, referrer?: string}>({
      query: ({domainName, account, coupon, referrer}) => ({url: 'domains/search', method: 'post', data: stripUndefined({domainName, buyerAddress: account, couponId: coupon, referrerId: referrer})}),
      transformResponse: decodeWith(RESTProtocolDataResponseCodec(DomainPricesDataCodec)),
      providesTags: [tags.account]
    }),
    signIn: builder.mutation<string, {login: string, password: string}>({
      query: ({login, password}) => ({url: 'auth', method: 'post', data: {login, password}}),
      transformResponse: (response: { token: string }) => response.token
    }),
    signInWithOTP: builder.mutation<string, {oneTimePassword: string}>({
      query: ({oneTimePassword}) => ({url: 'otp/auth', method: 'post', data: {oneTimePassword}}),
      transformResponse: (response: { token: string }) => response.token
    }),
    submitPartnersForm: builder.mutation<RESTProtocolResponseMessage, {name: string, url: string, email: string, company: string, comment: string}>({
      query: (form) => ({url: 'partners/submitForm', method: 'post', data: stripUndefined(form)}),
      transformResponse: decodeWith(RESTProtocolResponseMessage)
    })
  }),
});

export const {
  useAssignAddressMutation,
  useAssignAddressWithAuthMutation,
  useAuthMutation,
  useCheckCouponQuery,
  useLazyCheckCouponQuery,
  useRequestPasswordChangeMutation,
  useChangePasswordMutation,
  useClaimDomainsMutation,
  useConfirmEmailQuery,
  useVerifyRecoveryCodeQuery,
  useCreateAccountMutation,
  useFetchAccountQuery,
  useFetchCompetitionsQuery,
  useFetchCouponsQuery,
  useFetchDomainsQuery,
  useFinishAccountRegistrationMutation,
  useLazyFetchCouponsQuery,
  useFetchOrderQuery,
  useLazyFetchOrderQuery,
  useFetchPricesQuery,
  useFetchReferralDataQuery,
  useGetTemporaryTokenQuery,
  useGetJWTTokenMutation,
  useRegisterCryptoPaymentMutation,
  useRequestHeropaymentsInvoiceMutation,
  useRequestWertInvoiceMutation,
  useLazyFetchPricesQuery,
  usePlaceOrderMutation,
  useRegisterEmailMutation,
  useRequestTokenReleaseMutation,
  useRequestTokenReleaseStatusQuery,
  useSearchQuery,
  useSignInMutation,
  useSignInWithOTPMutation,
  useSubmitPartnersFormMutation,
  useFetchReferralsQuery
} = slice
