import {Chain, ContractName} from "../../model";
import {Chain as WagmiChain} from "@wagmi/chains";
import {bsc, bscTestnet, goerli, mainnet, polygon, polygonMumbai} from "@wagmi/core/chains";
import {prepareWriteContract, readContract, ReadContractResult, writeContract, WriteContractResult} from "@wagmi/core";
import {ContractProvider} from "./types";


export function getContract<Interface, Params>(
	abi: any,
	createInterface: (
		read: (functionName: string, args: unknown[]) => Promise<ReadContractResult>,
		write: (functionName: string, args: unknown[]) => Promise<WriteContractResult>,
		params?: Params
	) => Interface
): ContractProvider<Interface, Params> {
	return (chainId, address, params) => {
		return createInterface(
			async (functionName: string, args: unknown[] = []) => {
				return readContract({address, chainId, abi, functionName, args});
			},
			async (functionName: string, args: unknown[] = []) => {
				const config = await prepareWriteContract({abi, address, chainId, functionName, args});
				return writeContract(config);
			},
			params
		)
	}
}

export function getContractAddress(chain: Chain, contract: ContractName): `0x${string}` {
	const addresses: Record<Chain, Partial<Record<ContractName, string | undefined>>> = {
		[Chain.BinanceSmartChain]: {
			[ContractName.DomainMarket]: process.env.REACT_APP_BSC_DOMAIN_MARKET_ADDRESS,
			[ContractName.DomainNFT]: process.env.REACT_APP_BSC_DOMAIN_NFT_ADDRESS,
			[ContractName.USDT]: process.env.REACT_APP_BSC_USDT_ADDRESS
		},
		[Chain.Ethereum]: {
			[ContractName.DomainMarket]: process.env.REACT_APP_ETH_DOMAIN_MARKET_ADDRESS,
			[ContractName.DomainNFT]: process.env.REACT_APP_ETH_DOMAIN_NFT_ADDRESS
		},
		[Chain.Polygon]: {
			[ContractName.DomainMarket]: process.env.REACT_APP_POLYGON_DOMAIN_MARKET_ADDRESS,
			[ContractName.DomainNFT]: process.env.REACT_APP_POLYGON_DOMAIN_NFT_ADDRESS,
			[ContractName.USDT]: process.env.REACT_APP_POLYGON_USDT_ADDRESS
		}
	}
	const address = addresses[chain][contract];
	if (!address) throw new Error(`Could not find contract address ${contract} for chain ${chain}`);
	return address as `0x${string}`;
}

export function getChains(): Record<Chain, WagmiChain> {
	console.log(process.env.REACT_APP_IS_DEVELOPMENT);
	if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') return {
		[Chain.BinanceSmartChain]: bscTestnet,
		[Chain.Ethereum]: goerli,
		[Chain.Polygon]: polygonMumbai
	}
	else return {
		[Chain.BinanceSmartChain]: bsc,
		[Chain.Ethereum]: mainnet,
		[Chain.Polygon]: polygon
	}
}

export function getChainById(id: number): Chain | undefined {
	const chains = getChains();
	for (const key in chains) {
		const {id: chainId} = chains[key as keyof typeof chains];
		if (chainId === id) return key as Chain;
	}
	return undefined;
}

export function getChainId(chain: Chain) {
	const chains = getChains();
	return chains[chain].id;
}

export function getERC20Decimals(chain: Chain, contract: ContractName): number | undefined {
	const decimals: Partial<Record<Chain, Partial<Record<ContractName, string | undefined>>>> = {
		[Chain.Polygon]: {
			[ContractName.USDT]: process.env.REACT_APP_POLYGON_USDT_DECIMALS
		}
	}
	const result = decimals[chain]?.[contract];
	return result ? Number(result) : undefined;
}
