import styled from "styled-components";

export const CloseButton = styled.button`
	position: absolute;
	top: 1rem;
	right: .5rem;
	width: 2rem;
	height: 2rem;
	font-size: 1.25rem;
	border: none;
	background: none;
	color: #a4a4a4;
	cursor: pointer;
	:hover {
		color: #4C37CA;
	}
`