import React, {Fragment} from "react";
import {CircleCheck} from "components/icon";

export function PasswordChanged() {
  return (
    <Fragment>
      <div className="wrapper summary">
        <div>Password</div>
        <div className="name"><span className="name__wrapper">*********</span></div>
        <CircleCheck style={{color:'green', height: '2em'}}/>
      </div>
      <div className="status status_success">Your password has been successfully changed!</div>
    </Fragment>
  );
}