import {Fragment, useState} from "react";
import {Field, Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {isValidEmail} from "shared/util";
import {CloseButton, Modal} from "../../modal";
import {useRequestPasswordChangeMutation} from "../../api/sales/slice";
import styles from "../components/ResetPasswordModal.module.scss";

export function ResetPasswordModal(props: { isOpen: boolean, onClose: () => void }) {
  const {isOpen, onClose} = props;
  const [email, setEmail] = useState<string | undefined>(undefined);
  return (
    <Modal className={styles.wrapper} isOpen={isOpen} onRequestClose={onClose} contentLabel="Reset Your password">
      {email
        ? <Fragment><SuccessMessage email={email}/><CloseButton onClick={onClose}>x</CloseButton></Fragment>
        : <Fragment><PasswordForm onSuccess={setEmail}/>
          <button className={styles.return} onClick={onClose}>Return to Log In</button>
        </Fragment>
      }
    </Modal>
  )
}

function PasswordForm(props: { onSuccess: (email: string) => void }) {
  const [requestPasswordChange] = useRequestPasswordChangeMutation();
  const handleSubmit = async (values: FormValues) => {
    const {email} = values;
    const result = await requestPasswordChange({email});
    if ('error' in result) return {[FORM_ERROR]: result.error.message};
    props.onSuccess(email);
  }

  const validateForm = (values: FormValues) => {
    const errors: FormErrors = {};
    if (!values.email) errors.email = 'This field is required';
    if (values.email && !isValidEmail(values.email)) errors.email = 'Invalid email address';
    return errors;
  }

  const RenderForm = (props: FormRenderProps<FormValues>) => {
    const {submitError, handleSubmit, submitting} = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.header}>Reset Your password</div>
        <div className={styles.text}>Enter your email and we will help you to reset your password.</div>
        <Field name="email">
          {({input, meta}) => (
            <div className={styles.field}>
              <input {...input} type="email" placeholder="example@email.com" className={styles.input}/>
              {(meta.error || meta.submitError) && meta.touched &&
                  <div className={styles.error}>{meta.error || meta.submitError}</div>}
            </div>
          )}
        </Field>
        {submitError && <div style={{marginTop: '1rem', marginBottom: '2rem'}}>{submitError}</div>}
        <button type="submit" disabled={submitting} className={styles.button}>Send password reset email</button>
      </form>
    )
  }
  return <Form onSubmit={handleSubmit} validate={validateForm} render={RenderForm}/>
}

function SuccessMessage(props: { email: string }) {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__text}>
        To change your password, please go to the link that we sent you to <span
        className={styles.modal__email}>{props.email}</span>
      </div>
    </div>
  );
}

type FormValues = {
  email: string
}

type FormErrors = {
  email?: string
}

