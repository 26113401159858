import {Fragment, useState} from 'react';
import {useCreateAccountMutation} from "../../api/sales/slice";
import '../templates/AuthPage.scss'
import {Field, Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {isValidEmail} from "../../../shared/util";
import {NavLink} from "react-router-dom";
import {PATHS} from "../../../router/paths";
import {useReferrer} from 'hooks';
import {mapError} from "../../api/sales/util";

export default function CreateAccount () {

	const [createAccount] = useCreateAccountMutation();
	const [isSuccess, setIsSuccess] = useState(false);
	const referrer = useReferrer();

	const handleFormSubmit = async (values: {[k: string]: any}) => {
		const {email} = values;
		const result = await createAccount({email, referrer});
		if ('error' in result) return {[FORM_ERROR]: mapError(result.error)};
		setIsSuccess(true);
	}

	const validateForm = (values: {[k: string]: string}) => {
		const errors: {[k: string]: string} = {};
		if (!values.email) errors.email = 'This field is equired';
		if (!isValidEmail(values.email || '')) errors.email = 'Invalid email address';
		return errors;
	}

	if (isSuccess) return (
		<div style={{textAlign: 'center'}}>Success! Please check your email.</div>
	)
	return (
		<Fragment>
			<Form onSubmit={handleFormSubmit} validate={validateForm} render={RenderForm}/>
{/*			<div className="registration__footer">
				<div className="registration__footer-text">Already have an account?</div>
				<NavLink to={PATHS.auth.index} className="registration__btn _white">Sign In</NavLink>
			</div> */}
		</Fragment>
	)
}

function RenderForm(props: FormRenderProps) {
	const {submitError, handleSubmit, submitting} = props;
	return (
		<form className="login-form" onSubmit={handleSubmit}>
			<div className="login-form__fields">
				<Field name="email">
					{({input, meta}) => (
						<div className='login-form__field-wrap'>
							<input {...input} type="s" placeholder="Email" className='login-form__field'/>
							{(meta.error || meta.submitError) && meta.touched &&
								<div className='login-form__field-error _bottom'>{meta.error || meta.submitError}</div>
							}
						</div>
					)}
				</Field>
			</div>
			{submitError && <div style={{marginTop: '1rem', marginBottom: '2rem'}}>{submitError}</div>}
			<button type="submit" disabled={submitting} className='registration__btn'>Submit</button>
		</form>
	)
}
