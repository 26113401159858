import {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {decodeParamForKey, getIsMobile, getParamsFromObject} from './util';
import {ReactFacebookFailureResponse, ReactFacebookLoginInfo, ReactFacebookLoginProps} from "./types";


export function FacebookLogin(props: ReactFacebookLoginProps) {
	const defaults = {
		redirectUri: typeof window !== 'undefined' ? window.location.href : '/',
		scope: 'public_profile,email',
		returnScopes: false,
		xfbml: false,
		cookie: false,
		authType: '',
		fields: 'name',
		version: '3.1',
		language: 'en_US',
		disableMobileRedirect: false,
		isMobile: getIsMobile(),
		state: 'facebookdirect',
		responseType: 'code',
	};
	props = {...defaults, ...props};

	const [isSdkLoaded, setIsSdkLoaded] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		if (document.getElementById('facebook-jssdk')) {
			setIsSdkLoaded(true);
			return;
		}
		setFbAsyncInit();
		loadSdkAsynchronously();
		let fbRoot = document.getElementById('fb-root');
		if (!fbRoot) {
			fbRoot = document.createElement('div');
			fbRoot.id = 'fb-root';
			document.body.appendChild(fbRoot);
		}
	}, [])

	useEffect(() => () => { isMounted.current = false }, [])

	useEffect(() => {
		if (isSdkLoaded && props.autoLoad) window.FB.getLoginStatus(checkLoginAfterRefresh);
	}, [isSdkLoaded, props.autoLoad]);

	function setFbAsyncInit() {
		const { appId, xfbml, cookie, version, autoLoad } = props;
		window.fbAsyncInit = () => {
			window.FB.init({version: `v${version}`, appId, xfbml, cookie});
			if (isMounted.current) setIsSdkLoaded(true);
			if (autoLoad || isRedirectedFromFb()) window.FB.getLoginStatus(checkLoginAfterRefresh);
		};
	}

	function isRedirectedFromFb() {
		const params = window.location.search;
		return (
			decodeParamForKey(params, 'state') === 'facebookdirect' && (decodeParamForKey(params, 'code') ||
				decodeParamForKey(params, 'granted_scopes'))
		);
	}

	function loadSdkAsynchronously() {
		((d, s, id) => {
			const element = d.getElementsByTagName(s)[0];
			const fjs = element;
			let js = element;
			if (d.getElementById(id)) return;
			js = d.createElement(s); js.id = id;
			(js as HTMLScriptElement).src = `https://connect.facebook.net/${props.language}/sdk.js`;
			fjs.parentNode!.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	}

	const responseApi = (authResponse: any) => {
		window.FB.api('/me', { locale: props.language, fields: props.fields }, (me: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
			Object.assign(me, authResponse);
			props.callback(me);
		});
	};

	const checkLoginState = (response: any) => {
		if (isMounted.current) setIsProcessing(false);
		if (response.authResponse) {
			responseApi(response.authResponse);
		} else {
			if (props.onFailure) props.onFailure({ status: response.status });
			else props.callback({ status: response.status });
		}
	};

	const checkLoginAfterRefresh = (response: any) => {
		if (response.status === 'connected') {
			checkLoginState(response);
		} else {
			window.FB.login((loginResponse: any) => checkLoginState(loginResponse), true);
		}
	};

	const click = (e: SyntheticEvent) => {
		if (!isSdkLoaded || isProcessing || props.isDisabled) return;
		setIsProcessing(true);
		const { scope, appId, onClick, returnScopes, responseType, redirectUri, disableMobileRedirect, authType, state } = props;

		if (typeof onClick === 'function') {
			onClick(e);
			if (e.defaultPrevented) {
				setIsProcessing(false)
				return;
			}
		}

		const params = {
			client_id: appId,
			redirect_uri: redirectUri,
			state,
			return_scopes: returnScopes,
			scope,
			response_type: responseType,
			auth_type: authType,
		};

		if (props.isMobile && !disableMobileRedirect) {
			window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
		} else {
			if (!('FB' in window)) {
				if (props.onFailure) props.onFailure({ status: 'facebookNotLoaded' });
				return;
			}
			window.FB.getLoginStatus((response: any) => {
				if (response.status === 'connected') {
					checkLoginState(response);
				} else {
					window.FB.login(checkLoginState, { scope, return_scopes: returnScopes, auth_type: params.auth_type });
				}
			});
		}
	};

	return props.children({onClick: click, isDisabled: !!props.isDisabled, isProcessing, isSdkLoaded});

}
