import React, {FormEvent} from 'react';
import {useSafeNavigate} from 'hooks';
import {PATHS} from "router/paths";
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import {Magnifier} from "components/icon";
import {analytics, EventTarget, EventType, TargetLocation, TargetName} from 'features/analytics';
import './HomePage.scss';
import HomeContent from "./HomeContent/HomeContent";
import {Promo} from "./components/promo/Promo";

export default function HomePage () {

  const navigate = useSafeNavigate();

  const handleClickSubmit = () => {
    analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.Search, targetLocation: TargetLocation.UpperSearchBlock})
  }

  const handleFormSubmit = (e: FormEvent<HTMLFormElement & {elements: {domain: HTMLInputElement}}>) => {
    e.preventDefault();
    const domain = e.currentTarget.elements.domain.value;
    navigate(PATHS.search.index(domain));
    analytics.push({type: EventType.Search, term: domain});
  }

  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className="dna-block">
          <div className="inner">
            <div className="dna-block__content">
              <div className="dna-block__title"><span>*DNA:</span> Domain, Name, Account</div>
              <div className="dna-block__intro">Start with choosing an NFT domain</div>
              <div className="dna-block__any-language">Use any language and symbol</div>
              <div className="dna-block__pay"><span>Pay for 1 get 3 in: </span> BNB Chain, Polygon, Ethereum</div>
              <form className="dna-block__search-wrapper" onSubmit={handleFormSubmit}>
                <input type="text" name="domain" placeholder="Find your domain without Extension" className="dna-block__search-input" autoComplete="off"/>
                <button className="dna-block__search-button" type="submit" onClick={handleClickSubmit}><Magnifier/></button>
              </form>
            </div>
            <div className="dna-block__pictures"></div>
          </div>
        </div>
        <Promo/>
        <HomeContent/>
      </BaseLayout.Body>
    </BaseLayout>
  );

}
