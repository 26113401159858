import {useState} from "react";
import {Chain} from "features/web3/model";
import {UserDomainNameInfoStatus} from "../../api/sales/model/UserDomainNameInfoStatus";
import styles from './DomainItem.module.scss';
import clsx from "clsx";
import {WebsiteWidget} from "./WebsiteWidget";
import {ChainWidget} from "./ChainWidget";
import styled from "styled-components";


export default function DomainItem(props: {tokens: {id: number, chain: Chain, status: UserDomainNameInfoStatus, tokenId?: string}[], name: string, num: number, account?: string}) {
  const {account, name, num} = props;
  const [isOpened, setOpened] = useState(props.num === 1);
  const {id: bscDomainId, tokenId: bscTokenId} = props.tokens.find(({chain}) => chain === Chain.BinanceSmartChain) ?? {};
  return (
    <div className={clsx(styles.wrapper, isOpened && styles.wrapper_opened)}>
      <div className={styles.summary}>
        <div>{num}</div>
        <div className={styles.name}><span className={styles.name__wrapper}>{name}</span></div>
        <div className={clsx(styles.chevron, isOpened && styles.chevron_opened)}>
          <button className={styles.chevron__button} onClick={() => setOpened(!isOpened)}></button>
        </div>
      </div>
      <div className={clsx(styles.dropdown, isOpened && styles.dropdown_opened)}>
        <Block>
          <BlockTitle>Chain</BlockTitle>
          <ChainWidget tokens={props.tokens} account={account}/>
        </Block>
        <Block>
          <BlockTitle>Site</BlockTitle>
          <WebsiteWidget domainId={bscDomainId} domainName={name} isActive={isOpened} tokenId={bscTokenId}/>
        </Block>
      </div>
    </div>
  );
}

const Block = styled.div`
  margin: 0 2rem;
  padding: 1rem 0;
  display: grid;
  grid-row-gap: .375rem;
  font-weight: 400;
  &:not(:last-child) {
    border-bottom: 1px solid #dbe1e8;
  }
  @media(min-width: 768px) {
    padding-right: 2.625rem;
    grid-template-columns: 6.875rem 1fr;
  }
`
const BlockTitle = styled.div`
  font-weight: 700;
`