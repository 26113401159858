import {useEffect} from "react";
import {actions} from "../store";
import {useAuth, useDispatch} from "../../../../hooks";
import {AddressAssignmentStatus} from "../types";


export function InitAddressAssignment() {
	const dispatch = useDispatch();
	const {subject} = useAuth();
	useEffect(() => {
		if (!subject?.address) dispatch(actions.update({status: AddressAssignmentStatus.REQUESTING_ADDRESS}))
	}, [subject?.address])
	return null;
}
