import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Coupon from "./Coupon";
import Arrow from './assets/arrow.svg'
import {coupons} from './coupons'
import useVisibility from "hooks/useVisibility";


export function Promo() {
  const [swiper, setSwiper] = useState<any>();
  const [isVisible, ref] = useVisibility<HTMLDivElement>(0);
  // useEffect(() => { if (swiper && isVisible) swiper.slideNext() }, [swiper, isVisible]);
  const slides = coupons.slice(1, 2).map(Coupon);
  return (
    <div ref={ref} className="coupon-block">
      <StyledSwiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={true}
        modules={[Autoplay, Navigation]}
        loop={true}
        onSwiper={setSwiper}
        // autoplay={{delay: 5000, disableOnInteraction: false}}
      >
        {slides.map((slide, key) => <SwiperSlide key={key}>{slide}</SwiperSlide>)}
      </StyledSwiper>
    </div>
  )
}

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
    opacity: 0.8;
    &:after {
      content: '';
      display: block;
      width: 4vw;
      height: 100%;
      background-image: url(${Arrow});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      opacity: 1;
    }
    @media (min-width: 768px) {
      display: block;
    }
  }
`