import BaseLayout from "../../../components/layout/BaseLayout/BaseLayout";
import {SearchInput} from "../components/SearchInput";
import {SearchResult} from "../components/SearchResult";
import {Nav} from "../components/Nav";
import {Suggestions} from "../components/Suggestions";
import {FormEvent, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {useSearchQuery} from "../../api/sales/slice";
import {CouponStatus} from "../../cart/store/model/CouponStatus";
import {useDispatch, useReferrer, useSelector} from "../../../hooks";
import {actions as cartActions, selectors as cartSelectors} from "features/cart/store";
import {formatToken, useGetAccountQuery} from "../../web3";
import {SearchItem} from "../types/SearchItem";
import styled from "styled-components";
import {UTM, UTMValue} from "../../utm";


export function SearchPage() {
  const dispatch = useDispatch();
  const referrer = useReferrer();
  const {state} = useLocation();
  const [searchParams] = useSearchParams();
  const {data: account} = useGetAccountQuery();
  const coupon = useSelector(cartSelectors.selectCoupon);
  const isInCart = useSelector(cartSelectors.isInCart);
  const isCartEmpty = useSelector(cartSelectors.selectDomainsCount) === 0;
  const [name, setName] = useState(searchParams.get('search') ?? '');
  const {domains, error, isLoading} = useSearchQuery({
    domainName: name,
    account,
    coupon: state?.coupon?.name ?? (coupon?.status === CouponStatus.VALID ? coupon.value : undefined),
    referrer
  }, {
    selectFromResult: ({data, error, isLoading}) => ({ domains: data?.data.domainPrices.map(SearchItem.from), error, isLoading }),
    skip: !name
  });
  const inputPlaceholder = getInputPlaceholder(searchParams.get(UTM.CAMPAIGN));

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement & {elements: {domain: HTMLInputElement}}>) => {
    e.preventDefault();
    const domain = e.currentTarget.elements.domain.value;
    if (!domain.length) return;
    setName(domain);
  }

  const handleClickAddToCart = (domain: SearchItem) => {
    dispatch(cartActions.addDomain(domain.name));
    if (state?.coupon?.name) dispatch(cartActions.coupon.check({value: state?.coupon?.name}));
  }

  const [result, ...suggestions] = domains ?? [];

  return (
    <BaseLayout>
      <BaseLayout.Body>
        <Wrapper>
          <TopNav isGoToCartDisabled={isCartEmpty}/>
          {state?.coupon?.discount && <CouponNotice>Find domain and add it to your cart. We will automatically add your ${formatToken(state.coupon.discount)} coupon to your cart.<br/>You just have to apply it! </CouponNotice>}
          <InputBlock value={name} isLoading={isLoading} onSubmit={handleFormSubmit} placeholder={inputPlaceholder}/>
          {error && <ErrorBlock>{error.message}</ErrorBlock>}
          {result && <ResultBlock item={result} isInCart={isInCart} onClickAddToCart={handleClickAddToCart} showBlockChainNotice/>}
          {suggestions && !!suggestions.length && <SuggestionBlock hasError={result && 'error' in result} suggestions={suggestions} isInCart={isInCart} onClickAddToCart={handleClickAddToCart}/>}
          {suggestions && suggestions.length > 4 && <Nav isGoToCartDisabled={isCartEmpty}/>}
        </Wrapper>
      </BaseLayout.Body>
    </BaseLayout>
  )
}

function getInputPlaceholder(utm: string | null): string {
  switch (utm) {
    case UTMValue.AIRDROP: return 'Find Domain and get Airdrop chance';
    case UTMValue.SHORT_NAME: return 'Find a Short Name for Your Wallet';
    case UTMValue.IDENTITY: return 'Find a Username and Identity for Web3 apps';
    default: return 'Find Your best Domain'
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1.375rem;
  width: 100%;
  max-width: 48.75rem;
  @media (min-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`
const TopNav = styled(Nav)`
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    margin-bottom: 1.5rem;
  }
`
const CouponNotice = styled.p`
  margin-bottom: .75rem;
`
const InputBlock = styled(SearchInput)`
  margin-bottom: .5rem;
  @media (min-width: 768px) {
    margin-bottom: .75rem;
  }
`
const ResultBlock = styled(SearchResult)`
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 1.5rem;
  }
`
const ErrorBlock = styled.div`
  border: 1px solid #DBE1E8;
  border-radius: 13px; 
`
const SuggestionBlock = styled(Suggestions)`
  margin-bottom: .5rem;
  @media (min-width: 768px) {
    margin-bottom: 1.5rem;
  }
`