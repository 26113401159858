export function decodeParamForKey (paramString: string, key: string) {
	return decodeURIComponent(
		paramString.replace(
			new RegExp(
				'^(?:.*[&\\?]' +
				encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
				'(?:\\=([^&]*))?)?.*$', 'i'
			),
			'$1'
		)
	);
}

export function getParamsFromObject(params: object): string {
	return '?' + Object.keys(params)
		.map(param => `${param}=${encodeURIComponent(params[param as keyof typeof params])}`)
		.join('&');
}

export function getIsMobile (): boolean {
	let isMobile = false;
	try {
		isMobile = !!((window.navigator && 'standalone' in window.navigator) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
	} catch (ex) {
		// continue regardless of error
	}
	return isMobile;
}
