import * as t from "io-ts";
import {Utils} from "../../util/util";
import {ResponseStatus} from "./ResponseStatus";


export const RESTProtocolResponseMessage = t.type({
  details: t.union([t.string, t.null, t.undefined]),
  message: t.union([t.string, t.null, t.undefined]),
  status: Utils.enum<ResponseStatus>(ResponseStatus)
});

export type RESTProtocolResponseMessage = t.TypeOf<typeof RESTProtocolResponseMessage>;
