import './MyCoupons.scss'
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import {useFetchCompetitionsQuery, useFetchCouponsQuery} from "../../api/sales/slice";
import {Coupon} from "../../api/sales/model/Coupon";
import {useAuth} from 'hooks';
import {MouseEvent, useState} from "react";
import {CouponStatus} from 'features/api/sales/model/CouponStatus';
import {Copy} from "components/icon";
import {formatToken, parseWei} from "features/web3";
import {Competition} from "./Competition";
import {Tooltip, TooltipContent, TooltipTrigger} from "../../../components/tooltip/Tooltip";

export default function MyCoupons() {
  const {subject} = useAuth();
  const {data: coupons, isFetching} = useFetchCouponsQuery({accountId: subject?.id!}, {skip: !subject});
  const {competitions} = useFetchCompetitionsQuery(undefined, {
    selectFromResult: ({data}) => ({
      competitions: data?.data.competitions.slice().sort(({price: a}, {price: b}) => parseWei(a).gt(b) ? 1 : -1)
    }),
    pollingInterval: 3000
  });

  return (
    <SidebarLayout>
      <h1>Coupons</h1>
      <div className="coupons">
        {!isFetching && coupons && !!coupons.length && RenderCoupons(coupons)}
        <div className="available-coupons">
          {competitions && competitions.map((competition, i) => <Competition data={competition} key={i}/>)}
        </div>
      </div>
    </SidebarLayout>
  );
}

function RenderCoupons(coupons: Coupon[]) {
  const total = coupons.reduce(((total, {discount, status}) => status === CouponStatus.RELEASED ? total.add(discount) : total), parseWei('0'));
  return (
    <>
      <div className="coupons__table-wrapper">
        <div className="coupons__table">
          <div className="coupons__table-row _head">
            <div className="coupons__table-cell _num">#</div>
            <div className="coupons__table-cell _type">Type</div>
            <div className="coupons__table-cell _price">Value</div>
            <div className="coupons__table-cell _code">Coupon</div>
            <div className="coupons__table-cell _button"></div>
          </div>
          {coupons.map((item, i) => <RenderCoupon key={i} row={i + 1} coupon={item}/>)}
          <div className="coupons__table-row _footer">
            <div className="coupons__table-cell _num"></div>
            <div className="coupons__table-cell _type">Total</div>
            <div className="coupons__table-cell _price">${formatToken(total)}</div>
            <div className="coupons__table-cell _code"></div>
            <div className="coupons__table-cell _button"></div>
            <div className="coupons__table-cell _type"></div>
          </div>
        </div>
      </div>
      <div className="coupons__text">
        How to use coupons: find domains, add them to the cart, apply coupon and get extra benefits!
        Attention: 1) only one coupon can be applied to the cart. 2) coupon can pay only up to 50% of the cart value.
      </div>
    </>
  );
}


function RenderCoupon(props: { coupon: Coupon, row: number }) {
  const {coupon, row} = props;
  const isDisabled = coupon.status !== CouponStatus.RELEASED
  const [tooltip, setTooltip] = useState('Copy to clipboard');
  const handleCopy = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigator.clipboard.writeText(coupon.name);
    setTooltip('Copied!')
  }
  return (
    <div className={`coupons__table-row ${isDisabled ? '_disabled' : ''}`}>
      <div className="coupons__table-cell _num">{row}</div>
      <div className="coupons__table-cell _type">{coupon.reason}</div>
      <div className="coupons__table-cell _price">${formatToken(coupon.discount)}</div>
      <div className="coupons__table-cell _code"><div className="ellipsis">{coupon.name}</div></div>
      <Tooltip>
        <TooltipContent>{tooltip}</TooltipContent>
        <TooltipTrigger asChild>
          <button className="coupons__table-cell _button" onClick={handleCopy} onMouseLeave={() => setTooltip('Copy to clipboard')} disabled={isDisabled}>
            <Copy size='1.25rem'/>
          </button>
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
}
