import {Link} from "react-router-dom";
import styled from "styled-components";

export const Button = styled(Link)`
  box-sizing: border-box;
  display: block;
  height: 8vw;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  line-height: 8vw;
  text-decoration: none;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 2.8vw;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 768px) {
    height: 5vw;
    font-size: 2.3vw;
    line-height: 5vw;
  }
  @media (min-width: 1440px) {
    height: 76px;
    font-size: 32px;
    line-height: 76px;
  }
`