import {SearchItem} from "../types/SearchItem";
import {CartCheck, CartPlus, CircleCheck, CircleXmark} from "../../../components/icon";
import styled from "styled-components";
import {Fragment} from "react";
import {formatToken} from "../../web3";
import {BlockchainNotice} from "./BlockchainNotice";

type SearchResultBlock = {
  item: SearchItem
  isInCart: (name: string) => boolean
  onClickAddToCart: (item: SearchItem) => void
  showBlockChainNotice?: boolean
  className?: string
}
export function SearchResult(props: SearchResultBlock) {
  const {item, onClickAddToCart, showBlockChainNotice, className} = props;
  const hasError = 'error' in item;
  const isInCart = props.isInCart(item.name);
  return (
    <Wrapper className={className} $hasError={hasError}>
      <Controls>
      <Icon hasError={hasError}/>
      <Name>{item.name}</Name>
      {hasError ? (
        <ErrorMessage>{item.error}</ErrorMessage>
      ) : (
        <Fragment>
          {item.discountedPrice && <FullPrice>(${formatToken(item.price)})</FullPrice>}
          <DiscountedPrice>${item.discountedPrice ? formatToken(item.discountedPrice) : formatToken(item.price)}</DiscountedPrice>
        </Fragment>
      )}
      <Button type='button' disabled={hasError || isInCart} onClick={() => onClickAddToCart(item)}>
        {isInCart ? <CartCheck/> : <CartPlus/>}
      </Button>
      </Controls>
      {showBlockChainNotice && !hasError && <BlockchainNoticeBlock/>}
    </Wrapper>
  )
}

const Wrapper = styled.div<{$hasError: boolean}>`
  
  padding: 1rem .875rem;
  border: 1px solid #DBE1E8;
  border-radius: 13px;
  line-height: 1;
  color: ${({$hasError}) => $hasError ? '#62626A' : '#000'};
  @media (min-width: 768px) {
    padding: 1.625rem 1.5rem;
  }
`
const Controls = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled((props: {hasError: boolean, className?: string}) => (
  props.hasError ? <CircleXmark className={props.className}/> : <CircleCheck className={props.className}/>
))`
  margin-right: .75rem;
  flex-shrink: 0;
  font-size: 1.5rem;
  color: ${({hasError}) => hasError ? '#a4a4a4' : '#69D714'};
  @media (min-width: 768px) {
    width: 2.25rem;
  }
`
const Name = styled.div`
  margin-right: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125rem;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`
const FullPrice = styled.div`
  margin: 0 .25rem;
  font-weight: 700;
  text-decoration: line-through;
  color: #a4a4a4;
`
const DiscountedPrice = styled.div`
  margin: 0 .25rem;
  min-width: 5rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: right;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`
const ErrorMessage = styled.div`
  margin: 0 .25rem;
`
const Button = styled.button`
  margin-left: 1rem;
  display: flex;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #4C37CA;
  svg {
    width: 1.5rem;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    opacity: .75;
  }
  &:disabled {
    color: #a4a4a4;
  }
`
const BlockchainNoticeBlock = styled(BlockchainNotice)`
  margin-top: .75rem;
  padding-top: .625rem;
  border-top: 1px solid #DBE1E8;
  @media (min-width: 768px) {
    margin-top: 1.75rem;
    margin-left: 2.75rem;
  }
`