import {Subject} from "../../features/auth/store/types";
import {useAccount} from "../../hooks";
import {useState} from "react";
import {CircleUser} from "../icon";
import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import {formatToken} from "../../features/web3";

export function AccountMenu(props: { subject: Subject, onClickLogout: () => {} }) {
	const account = useAccount(3000);
	const couponsSummaryDiscount = account?.couponsSummaryDiscount ?? '0';
	const domainsCount = account?.domainsCount ?? 0;
	const referralsCount = account?.referralsCount ?? 0;
	const [isOpened, setOpened] = useState(false);

	return (
		<div className="header-login">
			<button className="header-login__button" onClick={() => setOpened(!isOpened)}>
				<CircleUser/>
				<span>Account</span>
			</button>
			<div className={`header-login__background ${isOpened && "_open"}`} onClick={() => setOpened(false)}></div>
			<div className={`header-login__menu ${isOpened && "_open"}`}>
				<div className="header-login__user-email">
					{props.subject.email ? props.subject.email : props.subject.address?.replace(/(.{12})(.+)(.{4})/, '$1…$3')}
				</div>
				<div className="header-login__stat-list">
					<NavLink to={PATHS.account.coupons.index} className="header-login__stat-item">
						<div className="header-login__stat-item-name">Coupons</div>
						<div className="header-login__stat-item-value">${formatToken(couponsSummaryDiscount)}</div>
					</NavLink>
					<NavLink to={PATHS.account.domains.index} className="header-login__stat-item">
						<div className="header-login__stat-item-name">Domains</div>
						<div className="header-login__stat-item-value">{domainsCount}</div>
					</NavLink>
					<NavLink to={PATHS.account.referralprogram.index} className="header-login__stat-item">
						<div className="header-login__stat-item-name">Referrals</div>
						<div className="header-login__stat-item-value">{referralsCount}</div>
					</NavLink>
					<NavLink to={PATHS.account.settings.index} className="header-login__stat-item">
						<div className="header-login__stat-item-name">Settings</div>
					</NavLink>
				</div>
				<button className="header-login__sign-out" onClick={props.onClickLogout}>Log out</button>
			</div>
		</div>
	);
}
