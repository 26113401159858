import {NavLink} from "react-router-dom";
import {PATHS} from "router/paths";
import './Wert.scss'
import {OrderStatus} from "../../store/model/OrderStatus";
import {PaymentSystem} from "shared/model";
import {Order} from "../../store/model/Order";
import WertModule from "@wert-io/module-react-component";
import {useRequestWertInvoiceMutation} from "features/api/sales/slice";
import {Fragment, useState} from "react";
import {useAuth, useDispatch} from 'hooks';
import {actions as orderActions} from "../../store";
import Button from "../../../../components/button/Button";
import {Form} from "react-final-form";
import {FORM_ERROR, SubmissionErrors, ValidationErrors} from "final-form";
import {isValidEmail} from "../../../../shared/util";
import {FormInput} from "../../../../components/input/Input";
import Loader from "../../../../components/loader/Loader";


export default function Wert (props: {order: Order}) {

  const {order} = props;
  const {subject} = useAuth();
  const dispatch = useDispatch();
  const [requestInvoice] = useRequestWertInvoiceMutation();
  const isValidState = order?.status === OrderStatus.AWAITING_PAYMENT && order.paymentSystem === PaymentSystem.Wert;
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [options, setOptions] = useState<object>();

  const handleFormSubmit = async (values: {email: string}) => {
    if (!isValidState) return;
    const result = await requestInvoice({orderId: order.id, email: values.email});
    if ('error' in result) return {[FORM_ERROR]: result.error.message};
    setIsWidgetLoading(true);
    setOptions( {
      ...result.data.data,
      width: 400,
      height: 600,
      listeners: {
        'loaded': () => setIsWidgetLoading(false),
        'payment-status': (event: PaymentEvent) => {
          if (event.status === 'success') dispatch(orderActions.markOrderAsPaid({id: order.id}));
        }
      }
    });
  }

  const validateForm = (values: {email: string}) => {
    const errors: {email?: string} = {};
    if (!values.email) {
      errors.email = 'This field is equired';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Must be a valid email format (e.g., username@coolexample.com)';
    }
    return errors;
  }

  return (
    <div className="wert">
      <div className="wert__inner">
        <div className="wert__header">
          <div className="logo">
            <NavLink to={PATHS.root.index} className="logo">
              <img src="/assets/img/header-logo.svg" alt=""/>
            </NavLink>
          </div>
          <NavLink to={PATHS.root.index} className="wert__close">
            <img src="/assets/img/Desktop-retina-close-cart.svg" alt=""/>
          </NavLink>
        </div>
        <div className="wert__content">
          { options ? (
            <Fragment>
              {isWidgetLoading &&
                <div className="wert__loader">
                  <div>Loading payment widget.</div>
                  <div style={{marginBottom: '1rem'}}>Please wait</div>
                  <Loader size='2rem'/>
                </div>
              }
              <WertModule options={options} className='wert__widget'/>
            </Fragment>
          ) : (
            <div className="wert__form">
              <EmailForm initialValues={{email: subject?.email}} onSubmit={handleFormSubmit} validate={validateForm}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );

}

function EmailForm (props: {
  initialValues: {email?: string}
  onSubmit: (values: {email: string}) => Promise<SubmissionErrors> | void,
  validate: (values: {email: string}) => ValidationErrors;
}) {
  return <Form initialValues={props.initialValues} onSubmit={props.onSubmit} validate={props.validate} render={({submitError, handleSubmit, submitting}) => (
    <form onSubmit={handleSubmit}>
      <FormInput name='email' label='Email' placeholder='example@email.com'/>
      {submitError && <div className="">{submitError}</div>}
      <div style={{marginTop: '1rem', textAlign: 'center'}}>
        <Button type="submit" disabled={submitting} shape='squared'>Submit</Button>
      </div>
    </form>
  )}/>
}

type PaymentEvent = {
  status: 'pending' | 'canceled' | 'failed' | 'success' | 'failover'
  payment_id: string
  order_id: string
}
