import type {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import axios from 'axios'
import type {BaseQueryFn} from '@reduxjs/toolkit/query'
import {FetchBaseQueryArgs} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {SerializedError} from "@reduxjs/toolkit";
import {BaseQueryApi} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export type AxiosBaseQueryArgs = FetchBaseQueryArgs & {
  transformResponse?: (response: AxiosResponse, api: BaseQueryApi) => { data: unknown } | { error: SerializedError }
  transformError?: (error: AxiosError, api: BaseQueryApi) => { error: SerializedError }
}

export type AxiosFetchArgs = {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
}

export const axiosBaseQuery = ({
  baseUrl = '',
  prepareHeaders = headers => headers,
  transformResponse = response => response,
  transformError = error => ({error: {name: error.name, message: error.message, stack: error.stack, code: error.code}})
}: AxiosBaseQueryArgs): BaseQueryFn<AxiosFetchArgs, unknown, SerializedError> => async ({ url, method, data, params }, api) => {
  try {
    const preparedHeaders: Headers | void = await prepareHeaders(new Headers(), api);
    const headers = preparedHeaders ? Array.from(preparedHeaders.entries()).reduce((result, entry) => ({...result, [entry[0]]: entry[1]}), {}) : {};
    const response = await axios({ url: `${baseUrl}/${url}`, method, data, params, headers });
    return transformResponse(response, api);
  } catch (axiosError) {
    return transformError(axiosError as AxiosError, api);
  }
}
