import Image1 from './images/news1.png'
import Image2 from './images/news2.svg'
import Image4 from './images/news4.png'
import Image6 from './images/news6.png'
import Image7 from './images/news7.png'

const newsList = [{
  pub_title: 'Now with your W3DNA coupons, you can pay up to 50% of a purchase',
  pub_url: undefined,
  pub_date: 'June 17, 2023',
  izd_title: undefined,
  image: Image7
}, {
  pub_title: 'W3DNA is Introducing Its Token',
  pub_url: 'https://medium.com/@web3dna/w3dna-is-introducing-its-token-4d8768fe2ecd',
  pub_date: 'May 9, 2023',
  izd_title: 'medium.com',
  image: Image4
}, {
  pub_title: 'Your W3DNA Domain is Now Also on Polygon',
  pub_url: 'https://medium.com/@web3dna/mint-your-w3dna-domain-on-polygon-for-free-2b39b9a994b',
  pub_date: 'May 5, 2023',
  izd_title: 'medium.com',
  image: Image6
}, {
  pub_title: 'Receive 20% of Your Referrals’ Purchase.',
  pub_url: 'https://medium.com/@web3dna/receive-20-of-your-referrals-purchase-6da28bedc572',
  pub_date: 'February 23, 2022',
  izd_title: 'medium.com',
  image: Image2,
}, {
  pub_title: 'How to Mint Your W3DNA Domain on BNB.',
  pub_url: 'https://medium.com/@web3dna/how-to-mint-your-w3dna-domain-on-bsc-f5ea15be4bfc',
  pub_date: 'February 8, 2022',
  izd_title: 'medium.com',
  image: Image1,
}]

export default newsList
