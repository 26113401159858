import * as t from "io-ts";
import {Utils} from "../../util/util";
import {ReplicationTaskStatus} from "./ReplicationTaskStatus";
import {Chains} from "./Chains";


export const ReplicationTaskDataCodec = t.type({
	taskId: t.number,
	chain: Utils.enum<Chains>(Chains),
	address: t.string,
	domainName: t.string,
	status: Utils.enum<ReplicationTaskStatus>(ReplicationTaskStatus),
});

export type ReplicationTaskData = t.TypeOf<typeof ReplicationTaskDataCodec>;
