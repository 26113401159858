import {Chain, formatToken, useTransferMutation} from "features/web3";
import {Currency} from "shared/types";
import {Button} from "components";
import {Fragment, useEffect} from "react";


export default function SendTransaction(props: {recipient: string, amount: string, currency: Currency, chain: Chain}) {
  const {recipient, amount, currency, chain} = props;
  const [transfer, {error}] = useTransferMutation();
  useEffect(() => { transfer({recipient, amount, currency, chain}) }, [])
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <Button onClick={() => transfer({recipient, amount, currency, chain})} shape='squared'>Try again</Button>
    </Fragment>
  );
  return (
    <div>Please use your wallet to transfer {formatToken(amount)}&nbsp;{currency}</div>
  );
}
