import {useGetPersonalSignQuery} from "features/web3/store";
import React, {Fragment} from "react";

export default function SignMessage (props: {temporaryToken: string}) {
  const {isFetching, error, refetch} = useGetPersonalSignQuery(props.temporaryToken);
  return (
    <div className='claim__inner'>
      {error ? (
        <Fragment>
          <div>Something went wrong</div>
          <div style={{marginBottom: '2rem'}}>{error.code}</div>
        </Fragment>
      ) : (
        <div style={{marginBottom: '2rem'}}>Please, sign message to continue</div>
      )}
      <div><button type="button" onClick={refetch} disabled={isFetching} className="auth__connect">Sign message</button></div>
    </div>
  );
}
