import {createSlice} from "@reduxjs/toolkit";
import {setExternalContent, upload} from "./actions";
import {SetExternalContentSaga, UploadSaga} from "../types";


export interface UploadState {
  sagas: {[tokenId: string]: UploadSaga}
  externalContentSagas: {[tokenId: string]: SetExternalContentSaga}
}

const initialState: UploadState = {
  sagas: {},
  externalContentSagas: {}
}

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(setExternalContent.update, (state, {payload}) => {
      state.externalContentSagas[payload.tokenId] = payload;
    })
    .addCase(setExternalContent.delete, (state, {payload}) => {
      delete(state.externalContentSagas[payload]);
    })
    .addCase(upload.update, (state, {payload}) => {
      state.sagas[payload.tokenId] = payload;
    })
    .addCase(upload.delete, (state, {payload}) => {
      delete(state.sagas[payload]);
    })
});

export const { actions, reducer } = slice;
