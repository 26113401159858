import {useRef, useEffect, ReactNode, PropsWithChildren, HTMLProps} from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";
import {useMountTransition} from "./hooks";
import clsx from "clsx";
import styles from './Drawer.module.scss'

function createPortalRoot() {
	const drawerRoot = document.createElement("div");
	drawerRoot.setAttribute("id", "drawer-root");
	return drawerRoot;
}

type DrawerProps = PropsWithChildren & HTMLProps<HTMLDivElement> & {
	isOpen: boolean
	children: ReactNode
	onClose: () => void
	position: 'left' | 'right' | 'top' | 'bottom'
	removeWhenClosed?: boolean
}

export function Drawer(props: DrawerProps) {
	const defaults = {position: 'right', removeWhenClosed: true};
	const {isOpen, children, className, onClose, position, removeWhenClosed, ...rest} = {...defaults, ...props};
	const bodyRef = useRef(document.querySelector('body')!);
	const portalRootRef = useRef(document.getElementById("drawer-root") || createPortalRoot());
	const isTransitioning = useMountTransition(isOpen, 300);

	// Append portal root on mount
	useEffect(() => {
		bodyRef.current.appendChild(portalRootRef.current);
		const portal = portalRootRef.current;
		const bodyEl = bodyRef.current;
		return () => {
			// Clean up the portal when drawer component unmounts
			portal.remove();
			// Ensure scroll overflow is removed
			bodyEl.style.overflow = '';
		};
	}, []);

	// Prevent page scrolling when the drawer is open
	useEffect(() => {
		const updatePageScroll = () => {
			if (isOpen) bodyRef.current.style.overflow = 'hidden';
			else bodyRef.current.style.overflow = '';
		};
		updatePageScroll();
	}, [isOpen]);

	// Allow Escape key to dismiss the drawer
	useEffect(() => {
		const onKeyPress = (e: KeyboardEvent) => {
			if (e.key === "Escape") onClose();
		};
		if (isOpen) window.addEventListener("keyup", onKeyPress);
		return () => {
			window.removeEventListener("keyup", onKeyPress);
		};
	}, [isOpen, onClose]);

	if (!isTransitioning && removeWhenClosed && !isOpen) return null;

	return createPortal(
		<FocusTrap active={isOpen}>
			<div
				aria-hidden={isOpen ? "false" : "true"}
				className={clsx(styles.container, isOpen && styles.container_open, isTransitioning && styles.container_in)}
			>
				<div className={clsx(
					styles.drawer,
					styles[`drawer_position_${position}`],
					isOpen && isTransitioning && styles.drawer_open,
					className
				)} role="dialog" {...rest}>
					{children}
				</div>
				<div className={clsx(styles.backdrop, isOpen && isTransitioning && styles.backdrop_open)} onClick={onClose} />
			</div>
		</FocusTrap>,
		portalRootRef.current
	);
}
