import styles from "./AssignAddress.module.scss";
import {formatAddress} from "features/web3";


export function Congratulations(props: {address: string}) {
	return (
		<div className={styles.wrapper}>
			<div className={styles.top}>
				<div>Congratulations!</div>
				<div>Address {formatAddress(props.address)} has been successfully assigned to your account</div>
			</div>
		</div>
	)
}