import {createSlice, SliceCaseReducers} from "@reduxjs/toolkit";
import {actions} from ".";
import {AddressAssignmentState, AddressAssignmentStatus} from "../types";


const initialState: AddressAssignmentState = {
  status: AddressAssignmentStatus.UNINITIALIZED
}

const slice = createSlice<AddressAssignmentState, SliceCaseReducers<AddressAssignmentState>>({
  name: 'assign-address',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(actions.update, (state, {payload}) => {
      return payload;
    })
});

export const { reducer } = slice;
