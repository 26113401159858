export type AddressAssignmentState = {
	status: typeof AddressAssignmentStatus.UNINITIALIZED
} | {
	status: typeof AddressAssignmentStatus.REQUESTING_ADDRESS
} | {
	status: typeof AddressAssignmentStatus.AWAITING_TOKEN
	address: string
} | {
	status: typeof AddressAssignmentStatus.AWAITING_SIGNATURE
	address: string
	token: string
} | {
	status: typeof AddressAssignmentStatus.SENDING_SIGNATURE
	address: string
	token: string
	signature: string
} | {
	status: typeof AddressAssignmentStatus.FULFILLED
	address: string
}

export const AddressAssignmentStatus = {
	UNINITIALIZED: 'UNINITIALIZED',
	REQUESTING_ADDRESS: 'REQUESTING_ADDRESS',
	AWAITING_TOKEN: 'AWAITING_TOKEN',
	AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
	SENDING_SIGNATURE: 'SENDING_SIGNATURE',
	FULFILLED: 'FULFILLED'
} as const

export type AddressAssignmentStatus = typeof AddressAssignmentStatus[keyof typeof AddressAssignmentStatus];