import {Fragment, useState} from "react";
import {Asset} from "../../types/Asset";
import {BackgroundModal} from "./BackgroundModal";
import {Button} from "components";
import {Theme} from "../../types/Theme";


type Props = {
  isEdit: boolean
  image?: Asset
  onChange: (value: Asset | undefined, theme: Theme) => void
  theme: Theme
  className?: string
}

export function BackgroundWidget(props: Props) {
  const {isEdit, image, onChange, theme, className} = props;
  const [IsOpen, setOpen] = useState(false);
  if (!isEdit) return null;
  return (
    <Fragment>
      <Button onClick={() => setOpen(true)} className={className}>Choose a background</Button>
      <BackgroundModal isOpen={IsOpen} image={image} onChange={onChange} onClose={() => setOpen(false)} theme={theme}/>
    </Fragment>
  );
}