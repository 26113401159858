import {BackgroundWidget} from "../components/BackgroundWidget/BackgroundWidget";
import {ContactsWidget} from "../components/ContactsWidget";
import {LayoutWidget} from "../components/LayoutWidget";
import {ComingSoonTemplateProps, TemplateComponentProps} from "../types/Template";
import styled from "styled-components";
import {Asset} from "../types/Asset";
import {backgrounds} from "../components/BackgroundWidget/backgrounds";


export const defaultProps: ComingSoonTemplateProps = {
	contacts: [],
	background: Asset.fromUrl(backgrounds[4].url),
	theme: backgrounds[4].theme
}

export function ComingSoon(props: TemplateComponentProps<ComingSoonTemplateProps>) {
	const {isEdit, props: fields} = props;
	const handlePropChange = (prop: Partial<ComingSoonTemplateProps>) => {
		if ('onPropsChange' in props) props.onPropsChange({...fields, ...prop})
	}
	return (
		<Body isEdit={isEdit} background={fields.background} title='Coming Soon'>
			<Wrapper>
				<Heading>Coming Soon</Heading>
				<Description>My decentralized website is under construction</Description>
				<ContactMe>Contact me:</ContactMe>
				<ContactsWidget isEdit={isEdit} theme={fields.theme} contacts={fields.contacts} onChange={contacts => handlePropChange({contacts})}/>
				<BackgroundButton isEdit={isEdit} theme={fields.theme} onChange={(background, theme) => handlePropChange({background, theme})}/>
			</Wrapper>
		</Body>
	)
}

const Body = styled(LayoutWidget)`
  display: grid;
  grid-template-rows: 38fr min-content 62fr;
`
const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  grid-row-start: 2;
  max-width: 38rem;
  text-align: center;
`
const Heading = styled.h1`
	margin-bottom: 1.75rem;
	font-size: 3rem;
	color: ${props => props.theme.palette.text.primary};
`
const Description = styled.div`
	margin-bottom: 3.5rem;
	font-size: 1.5rem;
	color: ${({theme}) => theme.palette.text.primary};
`
const ContactMe = styled.div`
	margin-bottom: 2.25rem;
	font-size: 1.125rem;
	color: ${({theme}) => theme.palette.text.primary};
`
const BackgroundButton = styled(BackgroundWidget)`
	margin-top: 2rem;
`