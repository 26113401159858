import {AppStartListening} from "store";
import * as cartActions from "./actions";
import * as cartSelectors from './selectors';
import {slice as salesApi} from 'features/api/sales/slice';
import {actions as authActions, selectors as authSelectors} from 'features/auth/store';
import {DomainPriceStatus} from "../../api/sales/model";
import * as orderActions from "../../order/store/actions";
import {take} from "../../../store/util";
import {CouponStatus} from "./model/CouponStatus";
import {isCouponUpdate} from "./matchers";

export default function(startListening: AppStartListening) {

  startListening({
    matcher: cartActions.coupon.check.match,
    effect: async ({payload: {value}}, api) => {
      let authState = authSelectors.selectAuthState(api.getState());
      if (!('subject' in authState)) {
        api.dispatch(cartActions.coupon.update({status: CouponStatus.AUTH_REQUIRED, value}));
      } else {
        api.dispatch(cartActions.coupon.update({status: CouponStatus.SENDING_REQUEST, value, ownerId: authState.subject.id}));
      }
    }
  });

  startListening({
    matcher:  authActions.update.match,
    effect: async ({payload: authState}, api) => {
      if (!('subject' in authState)) return;
      const coupon = cartSelectors.selectCoupon(api.getState());
      if (coupon?.status !== CouponStatus.AUTH_REQUIRED) return;
      api.dispatch(cartActions.coupon.update({status: CouponStatus.SENDING_REQUEST, value: coupon.value, ownerId: authState.subject.id}));
    }
  });

  const sendingRequest = isCouponUpdate(CouponStatus.SENDING_REQUEST);
  startListening({
    matcher: sendingRequest,
    effect: async ({payload: coupon}, api) => {
      const {data, error} = await api.dispatch(salesApi.endpoints.checkCoupon.initiate({ownerId: coupon.ownerId, coupon: coupon.value}));
      if (error) {
        api.dispatch(cartActions.coupon.update({...coupon, error, status: CouponStatus.ERROR}));
      } else if (data) {
        api.dispatch(cartActions.coupon.update({...coupon, status: data.data.isValid ? CouponStatus.VALID : CouponStatus.INVALID}));
      }
    }
  });

  // remove invalid domains from cart if present
  startListening({
    matcher: cartActions.validate.match,
    effect: async (_, api) => {
      const domainNames = cartSelectors.selectDomainNames(api.getState());
      const {data: {domainPrices}} = await take({api, query: salesApi.endpoints.fetchPrices})
      const invalidDomainNames = domainNames.filter(name => !domainPrices.some(({normalizedDomainName, status}) => name === normalizedDomainName && status === DomainPriceStatus.FREE))
      if (invalidDomainNames.length) {
        api.dispatch(cartActions.removeDomains(invalidDomainNames));
      }
    }
  });

  // clear state on logout
  startListening({
    matcher: authActions.invalidate.match,
    effect: async (_, api) => {
      api.dispatch(cartActions.clear());
    }
  });

  // clear state on order creation
  startListening({
    matcher: orderActions.createOrder.match,
    effect: async (_, api) => {
      api.dispatch(cartActions.clear());
    }
  });
}
