export const Currency = {
  BUSD: 'BUSD',
  USD: 'USD',
  USDT: 'USDT'
} as const;

export type Currency = typeof Currency[keyof typeof Currency]






