import {PropsWithChildren} from "react";
import styled from "styled-components";
import {Asset} from "../types/Asset";


type LayoutWidgetProps = PropsWithChildren & {
	isEdit: boolean
	background?: Asset
	className?: string
	title?: string
}

export function LayoutWidget(props: LayoutWidgetProps) {
	const {background, children, isEdit, title, ...rest} = props;
	if (isEdit) return (
		<Body as='div' isEdit={isEdit} background={background} {...rest}>{children}</Body>
	)
	else return (
		<html lang="en">
			<head>
				<meta charSet="UTF-8"/>
				<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"/>
				<meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
				<title>{title}</title>
			</head>
			<Body isEdit={isEdit} background={background} {...rest}>{children}</Body>
		</html>
	)
}

const Body = styled.body<{isEdit: boolean, background?: Asset}>`
	min-height: 100vh;
 	background: ${({isEdit, background}) => background ? `url(${Asset.getUrl(background, isEdit)}) center / cover` : undefined};
`
