import {useEffect} from "react";
import {useDispatch, useSelector} from "store/hooks";
import {AwaitTransaction, Chain, ChainGuard} from "features/web3";
import {actions as uploadActions, selectors} from "../store";
import {SetExternalContentSagaStatus} from "../types";
import {UploadCustomBundle} from "./UploadCustomBundle";
import {SendRouteTransaction} from "./SendRouteTransaction";
import {Congratulations} from "./Congratulations";
import {SubmitExternalUrl} from "./SubmitExternalUrl";


export function SetExternalContent(props: {account: string, tokenId: string, domainName: string}) {
	const {account, tokenId, domainName} = props;
	const saga = useSelector(selectors.selectExternalContentState)(tokenId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!saga) dispatch(uploadActions.setExternalContent.update({status: SetExternalContentSagaStatus.INITIAL, tokenId}))
	}, []);

	switch (saga?.status) {
		case SetExternalContentSagaStatus.INITIAL: return (
			<SubmitExternalUrl tokenId={saga.tokenId}/>
		)
		case SetExternalContentSagaStatus.SENDING_ROUTE_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<SendRouteTransaction tokenId={saga.tokenId} route={saga.route}/>
			</ChainGuard>
		)
		case SetExternalContentSagaStatus.AWAITING_ROUTE_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<AwaitTransaction transactionHash={saga.transactionHash} chain={Chain.BinanceSmartChain}/>
			</ChainGuard>
		)
		case SetExternalContentSagaStatus.FULFILLED: return (
			<Congratulations domainName={domainName}/>
		)
		default:
			return null;
	}
}