import React from 'react';
import {selectModalState} from "./store/selectors";
import {useSelector} from 'hooks';
import {ModalName, ModalStatus} from "./store/types";
import SearchDomain from "./components/SearchDomain/SearchDomain";


export default function ModalRoot() {

  const state = useSelector(selectModalState);

  if (state.status === ModalStatus.CLOSED) return null;

  switch (state.name) {
    case ModalName.SEARCH_DOMAIN: return <SearchDomain {...state.params}/>
  }

}
