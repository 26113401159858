import OKX from './images/okx.svg';
import Arctic from './images/arctic.svg';
import Wombat from './images/wombat.svg';
import Orbits from './images/orbits.png';
import Chiko from './images/ChikoRoko.svg'
import FreeCity from './images/freecity.png';
import Kommunitas from './images/Kommunitas.svg'
import TapFantasy from './images/tap-fantasy.svg'
import BitMart from './images/Bitmart.svg';
import Cheelee from './images/Cheelee.svg'
import Finance from './images/Finance.svg'
import ComeEarth from './images/Comearth.svg'
import Liquidifty from './images/Liquidifty.svg'
import P2PBet from './images/P2pbet.svg'
import Xandar from './images/Xandar.svg'
import Bitzen from './images/Bitzen.svg'
import Nordek from './images/Nordek.png'
import Towerswap from './images/Towerswap.png'


type Partner = {
    title: string
    image: string
    url: string
}

const Partner = {
    from: (title: string, image: string, url: string): Partner => ({title, image, url})
}

export const list: Partner[] = [
  Partner.from('OKX', OKX, 'https://www.okx.com'),
  Partner.from('Arctic', Arctic, 'https://arcticwallet.io/about'),
  Partner.from('Wombat', Wombat, 'https://www.wombat.app'),
  Partner.from('Orbits', Orbits, 'https://twitter.com/Orbits_DEX'),
  Partner.from('Chiko & Roko', Chiko, 'https://chikoroko.art'),
  Partner.from('Free City', FreeCity, 'https://www.free-city.io'),
  Partner.from('Kommunitas', Kommunitas, 'https://kommunitas.net'),
  Partner.from('Tap Fantasy', TapFantasy, 'https://tapfantasy.io'),
  Partner.from('BitMart', BitMart, 'https://www.bitmart.com'),
  Partner.from('8.Finance', Finance, 'https://8.finance'),
  Partner.from('Comearth', ComeEarth, 'https://www.comearth.world/'),
  Partner.from('Liquidifty', Liquidifty, 'https://lifty.io'),
  Partner.from('P2PBet', P2PBet, 'https://p2pbet.io'),
  Partner.from('Xandar Games', Xandar, 'https://xandar.games'),
  Partner.from('Cheelee', Cheelee, 'https://cheelee.io'),
  Partner.from('Bitzen', Bitzen, 'https://bitizen.org'),
  Partner.from('Nordek', Nordek, 'https://nordek.io'),
  Partner.from('Towerswap', Towerswap, 'https://towerswap.finance'),
]