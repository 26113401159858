import {ApiEndpointQuery} from "@reduxjs/toolkit/dist/query/core/module";
import {isAnyOf, ListenerEffectAPI} from "@reduxjs/toolkit";
import {QueryDefinition} from "@reduxjs/toolkit/query";

export async function take<QueryArg, ResultType>(params: {
  api: ListenerEffectAPI<any, any>,
  query: ApiEndpointQuery<QueryDefinition<QueryArg, any, any, ResultType>, any>,
  matcher?: (originalArgs: QueryArg) => boolean,
  condition?: (payload: ResultType) => boolean
}): Promise<ResultType> {
  const {api, query, matcher, condition} = params;
  const [action] = await api.take(isAnyOf(query.matchFulfilled, query.matchRejected));
  if (matcher && !matcher(action.meta.arg.originalArgs)) return take(params);
  let payload;
  if (query.matchFulfilled(action)) payload = action.payload;
  if (query.matchRejected (action) && action.meta.condition) payload = query.select(action.meta.arg.originalArgs)(api.getState()).data;
  if (payload && (!condition || condition(payload))) return payload;
  return take(params);
}
