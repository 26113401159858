import {Link, useSearchParams} from "react-router-dom";
import {useChangePasswordMutation, useVerifyRecoveryCodeQuery} from "features/api/sales/slice";
import React, {Fragment, useEffect, useState} from "react";
import {useSafeNavigate} from "hooks";
import {PATHS} from "router/paths";
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import styles from "./ResetPassword.module.scss";
import {Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {PasswordField} from "./components/PasswordField";

export function ResetPassword() {
  const navigate = useSafeNavigate();
  const [state, setState] = useState<State>({status: Status.CHECKING_LINK});
  const handleError = (error: string) => setState({status: Status.LINK_ERROR, error});
  const handleCheckSuccess = (code: string) => setState({status: Status.FILLING_FORM, code});
  const handleSuccess = () => navigate(PATHS.account.domains.index);


  return <BaseLayout>
    <BaseLayout.Body>
      <div className="inner" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div className={styles.wrapper}>
          {(() => {
            switch (state.status) {
              case Status.FILLING_FORM:
                return <ShowForm code={state.code} onSuccess={handleSuccess}/>
              case Status.LINK_ERROR:
                return <ShowError error={state.error}/>
              default:
                return <CheckLink onSuccess={handleCheckSuccess} onError={handleError}/>
            }
          })()}
        </div>
      </div>
    </BaseLayout.Body>
  </BaseLayout>
}

function CheckLink(props: { onSuccess: (code: string) => void, onError: (error: string) => void }) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const {data, error} = useVerifyRecoveryCodeQuery({code: code!}, {skip: !code});
  useEffect(() => {
    if (!code) props.onError('Code not found');
    else if (error) props.onError(error.message ?? 'Something went wrong');
    else if (data) props.onSuccess(code);
  }, [data, error, code])
  return <div>Loading</div>
}

function ShowForm(props: { code: string, onSuccess: () => void }) {
  const {code, onSuccess} = props;
  const [changePassword] = useChangePasswordMutation();

  const handleFormSubmit = async (values: FormValues) => {
    const {newPassword} = values;
    const result = await changePassword({code, newPassword});
    if ('error' in result) return {[FORM_ERROR]: result.error.message};
    onSuccess();
  }

  const RenderForm = (props: FormRenderProps<FormValues>) => {
    const {submitError, handleSubmit, submitting} = props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="registration__title">Enter new password</div>
        <div>New Password</div>
        <PasswordField name="newPassword"/>
        <div>Retype new password</div>
        <PasswordField name="confirmPassword"/>
        {submitError && <div style={{marginTop: '1rem', marginBottom: '2rem'}}>{submitError}</div>}
        <button className="registration__btn" type="submit" disabled={submitting}>Save</button>
      </form>

    )
  }

  const validateForm = (values: FormValues) => {
    const errors: FormErrors = {};
    if (!values.newPassword) errors.newPassword = 'This field is required';
    if (!values.confirmPassword) errors.newPassword = 'This field is required';
    if (values.newPassword !== values.confirmPassword) errors.confirmPassword = 'Passwords do not match';
    return errors;
  }
  return (
    <Fragment>
      <div className="change-password-form-wrapper">
        <div className={styles.content}>
          <Form onSubmit={handleFormSubmit} validate={validateForm} render={RenderForm}/>
          <Link to={PATHS.auth.index}>Return to Log In</Link>
        </div>
      </div>
    </Fragment>
  )
}

function ShowError(props: { error: string }) {
  return (
    <div className={styles.container}>
      <div className={styles.img}></div>
      <div className={styles.text}>{props.error}</div>
    </div>
  )
}

const Status = {
  CHECKING_LINK: 'CHECKING_LINK',
  LINK_ERROR: 'LINK_ERROR',
  FILLING_FORM: 'FILLING_FORM'
} as const

type Status = typeof Status[keyof typeof Status]

type State = {
  status: typeof Status.CHECKING_LINK
} | {
  status: typeof Status.FILLING_FORM
  code: string
} | {
  status: typeof Status.LINK_ERROR
  error: string
}

export type FormValues = {
  newPassword: string
  confirmPassword: string
}

export type FormErrors = {
  newPassword?: string
  confirmPassword?: string
}