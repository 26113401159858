import {useGetTemporaryTokenQuery} from "../../api/sales/slice";
import React, {Fragment, useEffect} from "react";
import {useSafeNavigate, useWeb3} from 'hooks';
import {PATHS} from "../../../router/paths";

export default function GetTemporaryToken (props: {address: string}) {
  const {isConnected} = useWeb3();
  const navigate = useSafeNavigate();
  const {isFetching, error, refetch} = useGetTemporaryTokenQuery(props.address, {skip: !isConnected});
  useEffect(() => { if (!isConnected) navigate(PATHS.connect) }, [isConnected])
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <div><button type="button" onClick={refetch} disabled={isFetching} className="auth__connect">Try again</button></div>
    </Fragment>
  );
  return null;
}
