import styled from "styled-components";
import {Binance, Ethereum, Polygon} from "../../../components/icon";
import {IconProps} from "../../../components/icon/Icon";


export function BlockchainNotice(props: {className?: string}) {
  return (
    <Wrapper className={props.className}>
      <Title>Congrats! Your  domain is available and will be reserved for you in:</Title>
      <List>{chains.map(({name, icon}, key) => <Item key={key}><Icon icon={icon}/>{name}</Item>)}</List>
    </Wrapper>
  )
}

const chains: {name: string, icon: (props: IconProps) => JSX.Element}[] = [
  { name: 'BNB Chain', icon: Binance },
  { name: 'Polygon', icon: Polygon },
  { name: 'Ethereum', icon: Ethereum }
]

const Wrapper = styled.div`
  font-size: .875rem;
  @media (min-width: 768px) {
    padding: 1.375rem .25rem 0;
    font-size: 1rem;
  }
`
const Title = styled.div`
  margin-bottom: 1rem;
`
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: .75rem;
  list-style: none;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const Item = styled.li`
  display: flex;
  align-items: center;
`
const Icon = styled((props: {icon: (props: IconProps) => JSX.Element, className?: string}) => <props.icon className={props.className}/>)`
  margin-right: .5rem;
  font-size: 1.75rem;
`
