import clsx from "clsx";
import {ButtonHTMLAttributes, LinkHTMLAttributes} from 'react';
import {Link, LinkProps} from "react-router-dom";
import styles from './Button.module.scss';

interface CommonAttributes {
  shape?: "squared" | "rounded" | "icon"
  size?: "sm" | "md" | "lg"
  color?: "secondary" | "lima"
  block?: boolean
  variant?: "outlined"
}

interface ButtonAttributes extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button' | undefined
}
interface LinkAttributes extends LinkHTMLAttributes<HTMLAnchorElement> {
  type: 'link'
}

interface RouterLinkAttributes extends LinkProps {
  type: 'route'
}

export type ButtonInterface = (ButtonAttributes | LinkAttributes | RouterLinkAttributes) & CommonAttributes

export default function Button(props: ButtonInterface) {
  const className = clsx(
    styles.button,
    styles[`button_shape_${props.shape}`],
    styles[`button_size_${props.size}`],
    styles[`button_color_${props.color}`],
    props.block && styles.button_block,
    props.variant && styles[`button_variant_${props.variant}`],
    props.className
  );
  if (props.type === 'link') {
    const {shape, size, color, block, type, ...rest} = props;
    return <a {...rest} className={className}>{props.children}</a>
  } else if (props.type === 'route') {
    const {shape, size, color, block, type, ...rest} = props;
    return <Link {...rest} className={className}>{props.children}</Link>
  } else {
    const {shape, size, color, block, type = 'button', ...rest} = props;
    return <button {...rest} type={type} className={className}>{props.children}</button>
  }
}
