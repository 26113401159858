import {Currency} from "shared/types";
import {PaymentSystem} from "shared/model";
import {Chain} from "features/web3";

export type Payment = {
  status: typeof PaymentStatus.UNINITIALIZED
} | {
  status: typeof PaymentStatus.SENDING_TRANSACTION
  amount: string
  chain: Chain
  currency: Currency
  orderId: number
  recipient: string
} | {
  status: typeof PaymentStatus.AWAITING_TRANSACTION
  chain: Chain
  orderId: number
  transactionHash: string
} | {
  status: typeof PaymentStatus.REGISTERING_PAYMENT
  chain: Chain
  orderId: number
  transactionHash: string
  paymentSystem: PaymentSystem
  currency: Currency
} | {
  status: typeof PaymentStatus.FULFILLED
  orderId: number
}

export const PaymentStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  SENDING_TRANSACTION: 'SENDING_TRANSACTION',
  AWAITING_TRANSACTION: 'AWAITING_TRANSACTION',
  REGISTERING_PAYMENT: 'REGISTERING_PAYMENT',
  FULFILLED: 'FULFILLED'
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus]
