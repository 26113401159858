import {AppStartListening} from "store";
import {slice as salesApi} from "features/api/sales/slice";
import {api as web3Api} from "features/web3/store";
import {actions as accountActions} from "./index";
import {AddressAssignmentStatus} from "../types";
import {isAddressAssignmentSagaUpdate} from "./matchers";


export default function(startListening: AppStartListening) {

	const step1 = isAddressAssignmentSagaUpdate(AddressAssignmentStatus.REQUESTING_ADDRESS)
	startListening({
    matcher: step1,
    effect: async (action, api) => {
      const [{payload: {address}}] = await api.take(accountActions.setAddress.match);
      api.dispatch(accountActions.update({status: AddressAssignmentStatus.AWAITING_TOKEN, address}));
    }
  });

	const step2 = isAddressAssignmentSagaUpdate(AddressAssignmentStatus.AWAITING_TOKEN)
	startListening({
		matcher: step2,
		effect: async ({payload: {address}}, api) => {
			const [{payload: token}] = await api.take(salesApi.endpoints.getTemporaryToken.matchFulfilled);
			api.dispatch(accountActions.update({status: AddressAssignmentStatus.AWAITING_SIGNATURE, address, token}));
		}
	});

	const step3 = isAddressAssignmentSagaUpdate(AddressAssignmentStatus.AWAITING_SIGNATURE)
	startListening({
		matcher: step3,
		effect: async ({payload: {address, token}}, api) => {
			const [{payload: signature}] = await api.take(web3Api.endpoints.getPersonalSign.matchFulfilled);
			api.dispatch(accountActions.update({status: AddressAssignmentStatus.SENDING_SIGNATURE, address, token, signature}));
		}
	});

	const step4 = isAddressAssignmentSagaUpdate(AddressAssignmentStatus.SENDING_SIGNATURE)
	startListening({
		matcher: step4,
		effect: async ({payload: {address}}, api) => {
			await api.take(salesApi.endpoints.assignAddressWithAuth.matchFulfilled);
			api.dispatch(accountActions.update({status: AddressAssignmentStatus.FULFILLED, address}));
		}
	});

}
