import {useEffect, useState} from 'react';
import {RequestWrapper} from "components";
import {useRequestTokenReleaseStatusQuery} from "features/api/sales/slice";


export default function AwaitRelease (props: {tokenId: string}) {
  const {tokenId} = props;
  const [pollingInterval, setPollingInterval] = useState<number|undefined>(1000);
  const {error} = useRequestTokenReleaseStatusQuery({tokenId}, {pollingInterval});
  useEffect(() => { if (error) setPollingInterval(undefined) }, [error]);
  return <RequestWrapper action={() => setPollingInterval(3000)} isLoading={!error} error={error}/>
}


