export const UTM = {
  CAMPAIGN: 'utm_campaign'
} as const

export type UTM = typeof UTM[keyof typeof UTM]

export const UTMValue = {
  AIRDROP: 'airdrop',
  SHORT_NAME: 'short_name',
  IDENTITY: 'identity'
} as const

export type UTMValue = typeof UTMValue[keyof typeof UTMValue]