import React, {useState} from 'react'
import styled from "styled-components";
import {Button} from "components";
import {list} from './PartnersList'

export default function Partners() {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Block>
      <Title>Partners</Title>
      <ListContainer>
        {(isExpanded ? list : list.slice(0, 9)).map((partner, key) =>
          <ListItem key={key} href={partner.url}>
            <Image src={partner.image} alt={partner.title}/>
          </ListItem>
        )}
      </ListContainer>
      <Footer>
        <Button shape='rounded' onClick={() => setIsExpanded(!isExpanded)}>View {isExpanded ? 'less' : 'more'}</Button>
      </Footer>
    </Block>
  )
}

const Block = styled.div`
  background: #F5F5F5;
  padding-block: 6em;
`

const Title = styled.div`
  font-family: Fira Sans, serif;
  font-size: 71px;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0em;
  text-align: center;
`

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  padding: 4em 50px;
  max-width: 1300px;
  margin: auto;
  @media (min-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ListItem = styled.a.attrs(props => ({target: '_blank', rel: 'noreferrer'}))`
  padding: 0 4em;
  box-sizing: border-box;
  flex-basis: 386px;
  flex-grow: 1;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DBE1E8;
  box-shadow: 2px 4px 16px 2px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
`

const Image = styled.img`
  width: 100%;
  max-height: 75%;
`

const Footer = styled.div`
  text-align: center;
`