import {RequestWrapper} from "components";
import {useSetContentRouteMutation} from "features/web3";
import {Fragment, useEffect} from "react";

export function SendRouteTransaction(props: {tokenId: string, route: string}) {
	const {tokenId, route} = props;
	const [setContentRoute, {error, isLoading}] = useSetContentRouteMutation();
	useEffect(() => { setContentRoute({tokenId, route}) }, [])
	return (
		<RequestWrapper action={() => setContentRoute({tokenId, route})} isLoading={isLoading} error={error}>
			{{
				action: null,
				loader: { description: <Fragment>Sending transaction (2 of 2)<br/>Please open your wallet and click the "Confirm" button</Fragment>, button: null }
			}}
		</RequestWrapper>
	)
}