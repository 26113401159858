import {RootState} from "store";
import {SetExternalContentSaga, UploadSaga} from "../types";


export function selectUploadState(state: RootState): (tokenId: string | undefined) => UploadSaga | undefined {
  return (tokenId: string | undefined) => tokenId ? state.upload.sagas[tokenId] : undefined;
}

export function selectExternalContentState(state: RootState): (tokenId: string | undefined) => SetExternalContentSaga | undefined {
  return (tokenId: string | undefined) => tokenId ? state.upload.externalContentSagas[tokenId] : undefined;
}