export enum CompetitionType {
  BUYMORE = 'BUYMORE',
  W3DNA_earlyvangelist = 'W3DNA_earlyvangelist',
  PULSAR = 'PULSAR',
  REGISTRATION = 'REGISTRATION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  REFERRALS_L1 = 'REFERRALS_L1',
  REFERRALS_L2 = 'REFERRALS_L2',
  REFREG = 'REFREG',
  ADMIN = 'ADMIN'
}
