export function getHashCode(source: string, forcePositive = true) {
	let hash = 0, i, chr;
	if (source.length === 0) return hash.toString();
	for (i = 0; i < source.length; i++) {
		chr = source.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	if (forcePositive && hash < 0) hash = -hash;
	return hash.toString();
}