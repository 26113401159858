import mime from "mime-types";
import {AssetType} from "./AssetType";
import {getHashCode} from "../util/getHashCode";
import {getFileExtension, getFileExtensionFromFileName} from "../util/getFileExtension";


export type Asset = {
	type: AssetType
	extension: string
	name: string
	url: string
}

export const Asset = {
	from: (file: File): Asset => {
		const extension = mime.extension(file.type);
		if (!extension) throw new Error('Unsupported mime type');
		const url = URL.createObjectURL(file);		
		const name = getHashCode(url);
		return {type: AssetType.Image, name, extension, url}
	},
	fromUrl:(url : string) : Asset => {
		const contentType = mime.lookup(url)
		if (!contentType) throw new Error('Could not detect file extension');
		const extension = mime.extension(contentType);
		if (!extension) throw new Error('Unsupported mime type');
		return {type: AssetType.Image, name: getHashCode(url), extension, url};
	},
	forget: (asset: Asset) => URL.revokeObjectURL(asset.url),
	getUrl: (asset: Asset, isEdit?: boolean) => {
		return isEdit ? asset.url : `/assets/${asset.name}.${asset.extension}`
	},
	isAsset: (obj: any): obj is Asset => {
		return typeof obj === 'object' && 'type' in obj && obj.type in AssetType
	}
}