import React from 'react'
import styled from "styled-components";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

interface SliderProps{
    id: string,
    slides: Array<React.ReactNode>,
}

class Slider extends React.Component<SliderProps> {
    // Слайдер не поддерживает смещение позиции кнопок управления за пределы контейнера.
    // Но можно вытащить кнопки вручную, что мы здесь и делаем.
    componentDidMount() {
        const main = document.getElementById(this.props.id)

        const prev = main?.querySelector('.swiper-button-prev')
        prev && main?.prepend(prev)

        const next = main?.querySelector('.swiper-button-next')
        next && main?.appendChild(next)
    }

    render() {
        return (
            <ContainerForMobile>
                <SwiperContainer id={this.props.id}>
                    <StyledSwiper
                        spaceBetween={0}
                        slidesPerView={3}
                        loop={true}
                        navigation={true}
                        centeredSlides={true}
                        modules={[Navigation]}
                        initialSlide={1}
                    >
                        { this.props.slides.map((slide: React.ReactNode, index: number) => {
                            return (
                                <SwiperSlide key={index}>
                                    {slide}
                                </SwiperSlide>
                            )
                        }) }
                    </StyledSwiper>
                </SwiperContainer>
            </ContainerForMobile>
        )
    }
}

export default Slider

// На маленьких экранах показываем только центральную часть слайдера.
const ContainerForMobile = styled.div`
    @media (max-width: 775px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

// Контейнер для слайдера. Сюда в том числе помещаем вытащенные кнопки управления.
const SwiperContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 1150px;
    margin: auto;
    position: relative;
    
    @media (min-width: 1000px) AND (max-width: 1250px) {
        max-width: 1050px;
    }
    
    @media (min-width: 776px) AND (max-width: 999px) {
        max-width: 775px;
    }
    
    @media (max-width: 775px) {
        overflow: unset;
        width: 1200px;
        max-width: 350vw;
    }

    & .swiper-button-next, & .swiper-button-prev {
        --swiper-navigation-size: 20px;
        color: black;
        background-color: white;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        top: 186px;
        
        &:hover {
            opacity: 0.8;
        }
    }

    & .swiper-button-next {
        right: 0px;
    }
    
    & .swiper-button-prev {
        left: 0px;
    }
    
    @media (min-width: 776px) AND (max-width: 999px) {
        & .swiper-button-next, & .swiper-button-prev {
            width: 50px;
            height: 50px;
            top: 134px;
        }
    }
`

// Непосредственно слайдер.
const StyledSwiper = styled(Swiper)`
    margin-inline: 70px;
    width: 100%;

    & .swiper-wrapper {
        padding-block: 15px;
    }
    
    & .swiper-slide {
        transition: all 0.2s, position 0.2s;
        position: relative;
        top: 0;
    }

    & .swiper-button-next, & .swiper-button-prev {
        display: none;
    }
    
    @media (max-width: 775px) {
        & .swiper-slide-active {
            transform: scale(103%);
            position: relative;
            top: -6px;
        }
    }
`
