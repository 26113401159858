import {configureStore, TypedStartListening} from "@reduxjs/toolkit";
import {createLogger} from "redux-logger";
import {reducer as accountReducer} from "features/account/store";
import {reducer as addressAssignmentReducer} from "features/account/assign-address";
import {reducer as authReducer} from "features/auth/store";
import {reducer as claimReducer} from "features/claim/store";
import {reducer as cartReducer} from "features/cart/store";
import {reducer as mintReducer} from "features/mint";
import {reducer as modalReducer} from "features/modal/store";
import {reducer as orderReducer} from "features/order/store";
import {reducer as uploadReducer} from "features/website/upload/store";
import {slice as salesApi} from "features/api/sales/slice";
import {api as uploadApi} from "features/api/upload/slice";
import {api as gatewayApi} from "features/api/gateway/slice";
import {api as web3Api} from "features/web3/store";
import {listenerMiddleware} from "./listeners";
import {loadState, saveState} from "./localstorage";
import {debounce} from "lodash";
import {AuthStatus} from "../features/auth/store/types";

const loggerMiddleware = createLogger({
	collapsed: true,
	predicate: (getState, action) => !/\/subscriptions\//.test(action.type)
});

const store = configureStore({
	reducer: {
		[salesApi.reducerPath]: salesApi.reducer,
		[uploadApi.reducerPath]: uploadApi.reducer,
		[web3Api.reducerPath]: web3Api.reducer,
		[gatewayApi.reducerPath]: gatewayApi.reducer,
		account: accountReducer,
		addressAssignment: addressAssignmentReducer,
		auth: authReducer,
		cart: cartReducer,
		claim: claimReducer,
		mint: mintReducer,
		modal: modalReducer,
		order: orderReducer,
		upload: uploadReducer,
	},
	devTools: true,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
		loggerMiddleware,
		listenerMiddleware.middleware,
		salesApi.middleware,
		uploadApi.middleware,
		web3Api.middleware,
		gatewayApi.middleware
	]),
	preloadedState: (() => {
		const state = loadState();
		const auth = state?.auth?.status === AuthStatus.FULFILLED ? state.auth : undefined;
		const cart = state?.cart;
		return {auth, cart}
	})()
});

store.subscribe(debounce(() => {
	const {cart, auth} = store.getState();
	return saveState({cart, auth});
}, 800))

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
