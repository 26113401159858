import React from 'react';
import {RequestWrapper} from "components";
import {useRequestTokenReleaseStatusQuery} from "features/api/sales/slice";


export function RehydrateState (props: {tokenId: string}) {
  const {tokenId} = props;
  const {isLoading, error} = useRequestTokenReleaseStatusQuery({tokenId});
  return (
    <RequestWrapper isLoading={isLoading} error={error}>
      {{
        loader: {description: 'Checking status'}
      }}
    </RequestWrapper>
  )
}


