import {useMemo} from "react";
import {useSelector} from "../store/hooks";
import {selectAuthState} from "../features/auth/store/selectors";
import {AuthStatus} from "../features/auth/store/types";

export const useAuth = () => {
	const state = useSelector(selectAuthState);
	return {
		isAuthorized: useMemo(() => (state.status === AuthStatus.FULFILLED), [state.status]),
		subject: useMemo(() => 'subject' in state ? state.subject : undefined, [state]),
		state: useMemo(() => state, [state])
	};
}
