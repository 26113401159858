import {ChainItem} from "./ChainItem";
import {Chain} from "../../../web3";
import {exhaustiveMatchingGuard} from "../../../../shared/util";
import {UserDomainNameInfoStatus} from "../../../api/sales/model/UserDomainNameInfoStatus";
import styled from "styled-components";


export function ChainWidget(props: {tokens: {id: number, chain: Chain, status: UserDomainNameInfoStatus, tokenId?: string}[], account?: string}) {
  const tokens = props.tokens.reduce((acc, token) => {
    acc[getSortOrder(token.chain)] = token;
    return acc;
  }, {} as Record<number, {id: number, chain: Chain, status: UserDomainNameInfoStatus}>)
  return (
    <List>{Object.values(tokens).map((token, i) =>
      <ChainItem chain={token.chain} tokenId={token.id.toString()} tokenStatus={token.status} key={i} account={props.account}/>
    )}</List>
  )
}

function getSortOrder (chain: Chain) {
  switch (chain) {
    case Chain.BinanceSmartChain: return 0;
    case Chain.Polygon: return 1;
    case Chain.Ethereum: return 2;
    default: return exhaustiveMatchingGuard(chain);
  }
}

const List = styled.ul`
  display: grid;
  column-gap: 2.5rem;
  row-gap: 1rem;
  list-style: none;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`