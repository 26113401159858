import styles from './Input.module.scss';
import React, {InputHTMLAttributes} from "react";
import {Field} from "react-final-form";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  subText?: string
}

export default function Input (props: InputProps) {
  let {id, type, className, ...restProps} = props;
  if (!id) id = `id_${Math.random().toString().replace('.', '')}`;
  return (
    <div className={styles.wrapper}>
      {props.label && <label className={styles.label} htmlFor={id}>{props.label}</label>}
      <div className={styles.container}>
        <input
          id={id}
          type={type ?? 'text'}
          className={`${styles.input} ${className ?? ''}`}
          {...restProps}
        />
        {props.subText && <div className={styles.feedback}>{props.subText}</div>}
      </div>
    </div>
  );
}

export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
}

export function FormInput(props: FormInputProps) {
  const {type, name, id, label, className, ...restProps} = props;
  return (
    <Field name={name}>{({ input, meta }) => (
      <div className={styles.container}>
        { label && <label htmlFor={name} className={styles.label}>{label}</label> }
        <input
          type={type ?? 'text'}
          id={id ?? `id_${Math.random().toString().replace('.', '')}`}
          className={`${styles.input} ${className ?? ''} ${(meta.error || meta.submitError) && meta.touched ? styles.input_error : ''}`}
          {...input}
          {...restProps}
        />
        { (meta.error || meta.submitError) && meta.touched && <div className={styles.feedback}>{meta.error || meta.submitError}</div> }
      </div>
    )}</Field>
  );
}
