import Avatar1 from './images/avatar1.svg'
import Avatar2 from './images/avatar2.svg'
import Avatar3 from './images/avatar3.svg'

import Image1 from './images/image1.svg'
import Image2 from './images/image2.svg'
import Image3 from './images/image3.svg'

const commentsList = [{
  id: 'comment_2',
  author_avatar: Avatar3,
  author_name: 'Livine Sanchez',
  pub_title: '*W2DNA Overview: Benefits Of Web3 Domains.',
  pub_url: 'https://zycrypto.com/w3dna-overview-benefits-of-web3-domains',
  pub_date: 'February 6, 2023',
  izd_title: 'zycrypto.com',
  category: 'Influencers',
  image: Image3,
}, {
  id: 'comment_2',
  author_avatar: Avatar2,
  author_name: 'Adrian Barkley',
  pub_title: 'W3DNA Review: The Pros, The Cons, And How You Can Make A Fortune From It',
  pub_url: 'https://cryptodaily.co.uk/2022/12/w3dna-review-the-pros-the-cons-and-how-you-can-make-a-fortune-from-it',
  pub_date: 'December 20, 2022',
  izd_title: 'cryptodaily.co.uk',
  category: 'Influencers',
  image: Image2,
}, {
  id: 'comment_1',
  author_avatar: Avatar1,
  author_name: 'Navid Ladani',
  pub_title: 'W3DNA Facilitates the Buying and Selling of Crypto Domains',
  pub_url: 'https://www.benzinga.com/markets/cryptocurrency/22/12/30130478/w3dna-facilitates-the-buying-and-selling-of-crypto-domains',
  pub_date: 'December 19, 2022',
  izd_title: 'benzinga.com',
  category: 'Influencers',
  image: Image1
}]

export default commentsList
