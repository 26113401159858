import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from "../util/axios";
import {RootState} from "store";
import {AuthStatus} from "features/auth/store/types";
import {actions as authActions} from 'features/auth/store';
import {interceptResponseError, nameTheError} from "../sales/util";
import {slice as salesApi, tags} from "../sales/slice";


export const api = createApi({
  reducerPath: 'api/upload',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/upload',
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as RootState).auth;
      if (auth.status === AuthStatus.FULFILLED) headers.set('authorization', `Bearer ${auth.token}`);
      return headers;
    },
    transformError: (error, api) => {
      if (error.response?.status === 401) {
        api.dispatch(authActions.invalidate());
        api.dispatch(salesApi.util.invalidateTags([tags.account]));
      }
      const responseError = interceptResponseError(error.response);
      console.log('here')
      if (responseError) return { error: nameTheError(responseError) };
      return { error: nameTheError(error) }
    }
  }),
  endpoints: builder => ({})
});
