import {AppStartListening} from "store";
import {actions as authActions, selectors as authSelectors} from "./index";
import {slice as salesApi} from "../../api/sales/slice";
import {api as web3Api} from "features/web3/store";
import {AuthStatus, AuthType, Subject} from "./types";
import {isAnyOf} from "@reduxjs/toolkit";
import {analytics, AuthMethod, EventType} from "features/analytics";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(authActions.init.match, authActions.cancel.match, authActions.invalidate.match),
    effect: async (action, api) => {
      if (authActions.invalidate.match(action)) {
        api.cancelActiveListeners();
        api.dispatch(authActions.clear());
        return;
      }
      if (authActions.cancel.match(action)) {
        const auth = authSelectors.selectAuthState(api.getState());
        if (auth.status === AuthStatus.FULFILLED) return;
        api.cancelActiveListeners();
        api.dispatch(authActions.clear());
        return;
      }
      const authType = action.payload;
      if (authType === AuthType.WEB3) {
        const [{payload: address}] = await api.take(authActions.setAddress.match);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_TEMPORARY_TOKEN, address}));
        const [{payload: temporaryToken}] = await api.take(salesApi.endpoints.getTemporaryToken.matchFulfilled);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_SIGNATURE, address, temporaryToken}));
        const [{payload: signature}] = await api.take(web3Api.endpoints.getPersonalSign.matchFulfilled);
        api.dispatch(authActions.update({type: AuthType.WEB3, status: AuthStatus.AWAITING_TOKEN, signature, temporaryToken}));
        const [{payload: token}] = await api.take(salesApi.endpoints.getJWTToken.matchFulfilled);
        analytics.push({type: EventType.LogIn, method: mapAuthTypeToAuthMethod(authType)});
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      } else if (authType === AuthType.PRIMARY) {
        const [{payload: token}] = await api.take(salesApi.endpoints.signIn.matchFulfilled);
        analytics.push({type: EventType.LogIn, method: mapAuthTypeToAuthMethod(authType)});
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      } else if (authType === AuthType.OTP) {
        const [{payload: oneTimePassword}] = await api.take(authActions.setOTP.match);
        api.dispatch(authActions.update({type: AuthType.OTP, status: AuthStatus.AWAITING_TOKEN, oneTimePassword}));
        const [{payload: token}] = await api.take(salesApi.endpoints.signInWithOTP.matchFulfilled);
        analytics.push({type: EventType.LogIn, method: mapAuthTypeToAuthMethod(authType)});
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      } else {
        const [{payload: {token}}] = await api.take(salesApi.endpoints.auth.matchFulfilled);
        api.dispatch(authActions.update({status: AuthStatus.FULFILLED, token, subject: extractSubject(token)}));
      }
    }
  });
}

function extractSubject(token: string): Subject {
  const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  const {id, email, address, needPassword} = JSON.parse(payload.sub); // TODO: remove JSON.parse() when subject serialization is fixed on backend
  return {id, email, address, needPassword};
}

function mapAuthTypeToAuthMethod(authType: AuthType): AuthMethod {
  switch (authType) {
    case AuthType.FACEBOOK: return AuthMethod.Facebook;
    case AuthType.GOOGLE: return AuthMethod.Google;
    case AuthType.OTP: return AuthMethod.OTP;
    case AuthType.PRIMARY: return AuthMethod.Primary;
    case AuthType.WEB3: return AuthMethod.Web3;
  }
}