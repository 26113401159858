import {Icon, IconProps} from "../Icon";


export function Cart (props: IconProps & { count: number }) {
	const {count, viewBox, ...restProps} = props;
	return (
		<Icon {...restProps}>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox={count ? "0 0 34 32" : "0 5 30 28"}>
				<path stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth="2.743" d="M6.022 12.16H25.5l-2.47 8.766a3.123 3.123 0 0 1-2.997 2.292H9.591c-1.588 0-2.922-1.21-3.097-2.81L5.244 9H2.126"/>
				<path fill="currentColor" stroke="currentColor" strokeWidth=".686" d="M21.604 31.04a1.912 1.912 0 0 0 1.901-1.922 1.912 1.912 0 0 0-1.9-1.922 1.912 1.912 0 0 0-1.902 1.922c0 1.058.847 1.923 1.901 1.923Z"/>
				<path fill="currentColor" stroke="currentColor" strokeWidth=".686" d="M9.139 31.04a1.912 1.912 0 0 0 1.9-1.922 1.912 1.912 0 0 0-1.9-1.922 1.912 1.912 0 0 0-1.901 1.922c0 1.058.846 1.923 1.9 1.923Z"/>
				{count &&
					<svg style={{overflow: 'visible'}} x='40%' y='0' >
						<ellipse cx="10" cy="10" fill="#4C37CA" rx="10" ry="10"/>
						<text x="10" y="14" textAnchor="middle" fill="#fff" fontSize="12" fontWeight="bold" letterSpacing="0em" style={{whiteSpace: 'pre'}}>
							{count}
						</text>
					</svg>
				}
			</svg>
		</Icon>
	)
}