import {useAuth, useReferrer, useSafeNavigate} from 'hooks';
import styles from './RegisterEmail.module.scss';
import ModalLayout from "components/layout/ModalLayout/ModalLayout";
import {Form} from "react-final-form";
import TextInput from "../components/TextInput";
import Button from "../../../components/button/Button";
import {useRegisterEmailMutation} from "../../api/sales/slice";
import {FORM_ERROR} from "final-form";
import {useEffect, useState} from "react";


export default function RegisterEmail () {

  const {isAuthorized} = useAuth();
  const navigate = useSafeNavigate();
  const [registerEmail] = useRegisterEmailMutation();
  const referrer = useReferrer();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!isAuthorized) navigate(-1);
  }, [isAuthorized]);

  const handleFormSubmit = async (values: {[k: string]: any}) => {
    const {email} = values;
    const result = await registerEmail({email, referrer});
    if ('error' in result) return {[FORM_ERROR]: result.error.message};
    setIsSuccess(true);
  }

  const validateForm = (values: {[k: string]: any}) => {
    const errors: {[k: string]: any} = {};
    if (!values.email) {
      errors.email = 'This field is equired';
    } else {
      const isEmailValid = values.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isEmailValid) errors.email = 'Must be a valid email format (e.g., username@coolexample.com)';
    }
    return errors;
  }

  if (isSuccess) return (
    <ModalLayout>
      <div className={styles.wrapper}>
        Success! Please check your email.
      </div>
    </ModalLayout>
  )

  return (
    <ModalLayout>
      <div className={styles.wrapper}>
        This operation requires your email.
        <Form onSubmit={handleFormSubmit} validate={validateForm} render={(props) => {
          const {submitError, handleSubmit, submitting} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextInput name='email' label='Email' placeholder='example@email.com'/>
              {submitError && <div className="">{submitError}</div>}
              <div style={{textAlign: 'center'}}>
                <Button type="submit" disabled={submitting} shape='squared'>Submit</Button>
              </div>
            </form>
          );
        }}/>
      </div>
    </ModalLayout>
  );
}
