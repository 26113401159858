import {Field, Form, FormRenderProps} from "react-final-form";
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import './PartnersPage.css';
import {useSubmitPartnersFormMutation} from "../api/sales/slice";

export default function PartnersPage() {

  const [submitForm, {data, error}] = useSubmitPartnersFormMutation();

  const handleFormSubmit = (values: any) => {
    const {username, website, email, company, comment} = values;
    submitForm({name: username, url: website, email, company, comment});
  }

  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className="developers">
          <div className="developers-header">
            <div className="inner">
              <div className="developers-header__title">
                Great team of developers<br/>
                and partners!
              </div>
              <div className="developers-header__text">
                The project is being developed by a large community of
                WEB 3.0 ideologists and web 2.0 version domain name market professionals
              </div>
            </div>
          </div>
          <div className="developers-form-wrapper">
            <div className="inner">
              {(() => {
                if (error) return (
                  <div style={{textAlign: 'center'}}>
                    <div>Something went wrong</div>
                    <div>{error.message}</div>
                  </div>
                )
                if (data) return (
                  <div style={{textAlign: 'center'}}>
                    <div>Thank You!</div>
                    <div>Your request has been sent successfully.</div>
                  </div>
                )
                return (
                  <Form onSubmit={handleFormSubmit} validate={validateForm} render={renderForm}/>
                )
              })()}
            </div>
          </div>
          <div className="partners">
            <div className="inner">
              <div className="partners__list">
                {['opera', 'bitcoin', 'huobi', 'blockchain', 'mew', 'linch', 'brave', 'trust', 'polygon'].map( icon => (
                  <div className="partners__item" key={icon}>
                    <div className={`partners__item-logo ${icon}-img`}></div>
                  </div>
                ))}
              </div>
              {/*<button className="partners__view-all">*/}
              {/*  View All Partners*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );

}

function validateForm(values: any) {
  const errors: {[k: string]: any} = {};
  if (!values.username) {
    errors.username = "Required";
  }
  if (!values.email) {
    errors.email = 'Required';
  } else {
    const isEmailValid = values.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (!isEmailValid) {
      errors.email = 'Must be a valid email format (e.g., username@coolexample.com)';
    }
  }
  const isWebsiteValid = typeof values.website === 'undefined' || values.website.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
  if (!isWebsiteValid) {
    errors.website = 'Must be a valid website format (e.g., example.com)';
  }
  return errors;
}

function renderForm(props: FormRenderProps) {
  const {submitError, handleSubmit, submitting} = props;
  return (
    <form className="developers-form" onSubmit={handleSubmit}>
      <div className="developers-form__row _col">
        <div className="developers-form__cell">
          <Field name="username">
            {({ input, meta }) => (
              <div className="developers-form__field-wrap">
                <label htmlFor="username" className="developers-form__field-label">Name</label>
                <input {...input} type="text" placeholder="John Doe" className={ 'developers-form__field ' + ((meta.error || meta.submitError) && meta.touched ? 'developers-form__field_error': '') }/>
                {(meta.error || meta.submitError) && meta.touched && (
                  <span className="developers-form__field-error">{meta.error || meta.submitError}</span>
                )}
              </div>
            )}
          </Field>
          <Field name="email">
            {({ input, meta }) => (
              <div className="developers-form__field-wrap">
                <label htmlFor="email" className="developers-form__field-label">Email</label>
                <input {...input} type="text" placeholder="john@email.com" className={ 'developers-form__field ' + ((meta.error || meta.submitError) && meta.touched ? 'developers-form__field_error': '') }/>
                {(meta.error || meta.submitError) && meta.touched && (
                  <span className="developers-form__field-error">{meta.error || meta.submitError}</span>
                )}
              </div>
            )}
          </Field>
        </div>
        <div className="developers-form__cell">
          <Field name="website">
            {({ input, meta }) => (
              <div className="developers-form__field-wrap">
                <label htmlFor="website" className="developers-form__field-label">App website URL</label>
                <input {...input} type="text" placeholder="https://www.nostragamus.com" className={ 'developers-form__field ' + ((meta.error || meta.submitError) && meta.touched ? 'developers-form__field_error': '') }/>
                {(meta.error || meta.submitError) && meta.touched && (
                  <span className="developers-form__field-error">{meta.error || meta.submitError}</span>
                )}
              </div>
            )}
          </Field>
          <Field name="company">
            {({ input, meta }) => (
              <div className="developers-form__field-wrap">
                <label htmlFor="company" className="developers-form__field-label">Company Name</label>
                <input {...input} type="text" className={ 'developers-form__field ' + ((meta.error || meta.submitError) && meta.touched ? 'developers-form__field_error': '') }/>
                {(meta.error || meta.submitError) && meta.touched && (
                  <span className="developers-form__field-error">{meta.error || meta.submitError}</span>
                )}
              </div>
            )}
          </Field>
        </div>
      </div>
      <div className="developers-form__row">
        <Field name="comment">
          {({ input, meta }) => (
            <div className="developers-form__field-wrap">
              <label htmlFor="comment" className="developers-form__field-label">Company Name</label>
              <textarea {...input} placeholder="LLC" className={ 'developers-form__field _textarea' + ((meta.error || meta.submitError) && meta.touched ? 'developers-form__field_error': '') } style={{width: '100%', resize: 'vertical'}}/>
              {(meta.error || meta.submitError) && meta.touched && (
                <span className="developers-form__field-error">{meta.error || meta.submitError}</span>
              )}
            </div>
          )}
        </Field>
      </div>
      {/**<div className="developers-form__row">
        <Field name="attach-file">
          {({ input, meta }) => (
            <div className="attach-file-block">
              <label htmlFor="attach-file" className="developers-form__file-button">
                Attach a file
                <input {...input} className="developers-form__file-field" type="file"  />
              </label>
              {(meta.error || meta.submitError) && meta.touched && (
                <span className="developers-form__file-text">{meta.error || meta.submitError}</span>
              )}
            </div>
          )}
        </Field>
      </div>**/}
      {submitError && <div className="developers-form__row">{submitError}</div>}
      <div className="developers-form__row">
        <button type="submit" disabled={submitting} className="developers-form__submit-button">Submit</button>
      </div>
    </form>
  );
}
