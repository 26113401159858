import {Fragment} from "react";

export function Closed(props: {isDisabled?: boolean, onClick?: () => void, error?: string}) {
  const {isDisabled, onClick, error} = props;
  return (
    <Fragment>
      <div className="wrapper summary">
        <div>Password</div>
        <div className="fake-password"><span>*********</span></div>
        <button type="button" className='edit-button' disabled={isDisabled} onClick={onClick}>Edit</button>
      </div>
      {error &&
          <div className="status status_error">{error}</div>}
    </Fragment>
  );
}