import {createContext, useContext, useRef, useState, useLayoutEffect, PropsWithChildren} from "react"
import {BrowserHistory, createBrowserHistory, To} from "history"
import {Path, Router} from "react-router-dom"

const HistoryContext = createContext({} as BrowserHistory);

export const browserHistory = tuneHistory(createBrowserHistory({ window }), /^(otp|referrer|utm_\S+)$/);

export const useHistory = () => useContext(HistoryContext);

export default function BrowserRouter({ children }: PropsWithChildren) {
  const { current: history } = useRef(browserHistory);
  const [{ location }, setHistoryState] = useState({ location: history.location })

  useLayoutEffect(() => history.listen(setHistoryState), [history])

  return (
    <Router location={location} navigator={history}>
      <HistoryContext.Provider value={history}>
        {children}
      </HistoryContext.Provider>
    </Router>
  )
}

function preserveQueryParams(history: BrowserHistory, preserveParams: RegExp, to: To, state?: any): [To, any] {
  const _to: Partial<Path> = typeof to === 'string' ? {pathname: to} : to;
  const previous = new URLSearchParams(history.location.search);
  const preserved: Record<string, string> = {};
  previous.forEach((value, key) => { if (preserveParams.test(key)) preserved[key] = value });
  const next = new URLSearchParams(_to.search);
  _to.search = new URLSearchParams({...preserved, ...Object.fromEntries(next)}).toString();
  return [_to, state];
}

function tuneHistory(history: BrowserHistory, preserveParams: RegExp) {
  const push = history.push;
  const replace = history.replace;
  history.push = (to: To, state?: any) => push.apply(history, preserveQueryParams(history, preserveParams, to, state));
  history.replace = (to: To, state?: any) => replace.apply(history, preserveQueryParams(history, preserveParams, to, state));
  return history;
}
