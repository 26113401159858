import {Button} from "components";
import {Xmark} from "components/icon";
import {TemplateName} from "../types/TemplateName";
import {ComingSoon, defaultProps as comingSoonDefaultProps} from "../templates/ComingSoon";
import {
	ComingSoonTemplateProps,
	ForSaleTemplateProps,
	ProfileTemplateProps,
	TemplateDescriptor,
	TemplateProps
} from "../types/Template";
import {defaultProps as forSaleDefaultProps, ForSale} from "../templates/ForSale";
import {defaultProps as profileDefaultProps, Profile} from "../templates/Profile";
import {useState} from "react";
import styles from './PageEditor.module.scss';
import {prepareMarkup} from "../util/prepareMarkup";
import {prepareAssets} from "../util/prepareAssets";
import {ThemeProvider} from "./Theme/ThemeProvider";
import {PATHS} from "../../../../router/paths";


type PageEditorProps = {
	domainName: string
	templateName: TemplateName
	onPublish: (html: string, assets?: string) => void
}

export function PageEditor(props: PageEditorProps) {
	const {domainName, templateName, onPublish} = props;
	const [templateProps, setTemplateProps] = useState<TemplateProps>(getDefaultTemplateProps(templateName, {domainName}));
	const template = getTemplateDescriptor(templateName, templateProps);
	const onPropsChange = (props: TemplateProps) => {
		setTemplateProps(props);
	}
	const handleClickPublish = async () => {
		const markup = prepareMarkup(template);
		const htmlBlob = new Blob([markup], {type: "text/html"});
		const assetsBlob = await prepareAssets(template)
		onPublish(URL.createObjectURL(htmlBlob), assetsBlob ? URL.createObjectURL(assetsBlob) : undefined);
	}
	return (
		<div className={styles.wrapper}>
			<Button className={styles.publish} onClick={handleClickPublish}>Publish</Button>
			<Button type='route' to={PATHS.account.domains.index} className={styles.close} shape='icon'><Xmark/></Button>
			<RenderTemplate template={template} onPropsChange={onPropsChange}/>
		</div>
	)
}

function getTemplateDescriptor(name: TemplateName, props: TemplateProps): TemplateDescriptor {
	switch (name) {
		case TemplateName.ComingSoon: return { name: TemplateName.ComingSoon, props: props as ComingSoonTemplateProps }
		case TemplateName.ForSale: return { name: TemplateName.ForSale, props: props as ForSaleTemplateProps }
		case TemplateName.Profile: return { name: TemplateName.Profile, props: props as ProfileTemplateProps }
	}
}

function getDefaultTemplateProps(name: TemplateName, props: {domainName: string}): TemplateProps {
	const {domainName} = props;
	switch (name) {
		case TemplateName.ComingSoon: return comingSoonDefaultProps;
		case TemplateName.ForSale: return {...forSaleDefaultProps, domainName};
		case TemplateName.Profile: return profileDefaultProps;
	}
}

function RenderTemplate(props: {template: TemplateDescriptor, onPropsChange: (props: TemplateProps) => void}) {
	const {template, onPropsChange} = props;
	return (
		<ThemeProvider theme={template.props.theme}>
			{(() => { switch (template.name) {
				case TemplateName.ComingSoon: return <ComingSoon isEdit={true} props={template.props} onPropsChange={onPropsChange}/>
				case TemplateName.ForSale: return <ForSale isEdit={true} props={template.props} onPropsChange={onPropsChange}/>
				case TemplateName.Profile: return <Profile isEdit={true} props={template.props} onPropsChange={onPropsChange}/>
			} })()}
		</ThemeProvider>
	)
}
