import {useEffect} from "react";
import {useDispatch, useSelector} from "store/hooks";
import {AwaitTransaction, Chain, ChainGuard} from "features/web3";
import {actions as uploadActions, selectors} from "../store";
import {UploadSagaStatus} from "../types";
import {UploadBundle} from "./UploadBundle";
import {UploadCustomBundle} from "./UploadCustomBundle";
import {SendContentTransaction} from "./SendContentTransaction";
import {SendRouteTransaction} from "./SendRouteTransaction";
import {Congratulations} from "./Congratulations";
import {AwaitingBundle} from "./AwaitingBundle";


export function UploadPage(props: {account: string, tokenId: string, domainName: string, template: string | undefined}) {
	const {account, tokenId, domainName, template} = props;
	const saga = useSelector(selectors.selectUploadState)(tokenId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!saga) dispatch(uploadActions.upload.update({status: UploadSagaStatus.INITIAL, tokenId, template}))
	}, []);

	switch (saga?.status) {
		case UploadSagaStatus.AWAITING_BUNDLE:
			return <AwaitingBundle tokenId={saga.tokenId} template={saga.template}/>
		case UploadSagaStatus.UPLOADING_BUNDLE:
			return <UploadBundle tokenId={saga.tokenId} html={saga.html} assets={saga.assets}/>
		case UploadSagaStatus.UPLOADING_CUSTOM_BUNDLE:
			return <UploadCustomBundle tokenId={saga.tokenId}/>
		case UploadSagaStatus.SENDING_CONTENT_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<SendContentTransaction tokenId={saga.tokenId} content={saga.content} contentProvider={saga.contentProvider}/>
			</ChainGuard>
		)
		case UploadSagaStatus.AWAITING_CONTENT_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<AwaitTransaction transactionHash={saga.transactionHash} chain={Chain.BinanceSmartChain}/>
			</ChainGuard>
		)
		case UploadSagaStatus.SENDING_ROUTE_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<SendRouteTransaction tokenId={saga.tokenId} route={saga.route}/>
			</ChainGuard>
		)
		case UploadSagaStatus.AWAITING_ROUTE_TX: return (
			<ChainGuard chain={Chain.BinanceSmartChain} address={account}>
				<AwaitTransaction transactionHash={saga.transactionHash} chain={Chain.BinanceSmartChain}/>
			</ChainGuard>
		)
		case UploadSagaStatus.FULFILLED:
			return <Congratulations domainName={domainName}/>
		default:
			return null;
	}
}