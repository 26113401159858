import {AxiosResponse} from "axios";
import {SerializedError} from "@reduxjs/toolkit";
import {SalesErrorName} from "./model";

export function interceptResponseError(response?: AxiosResponse): SerializedError | undefined {
  if (typeof response?.data === 'object' && 'status' in response.data && response.data.status !== 'OK') {
    return { message: response.data.message, code: response.data.status };
  } else return undefined;
}

export function mapError(error: SerializedError): string {
  switch (error.code) {
    case 'WRONG_VERIFICATION_CODE': return 'Wrong verification code';
    case 'CHAIN_ADDRESS_ALREADY_ASSIGN': return 'Chain address already assigned';
    case 'ORDER_CONTAINS_RESERVED_DOMAINS': return 'Order contains reserved domains';
    case 'UNAUTHENTICATED_USER_TRY_APPLY_COUPON': return 'Coupon cannot be applied by an authorized user';
    case 'EMAIL_ALREADY_TAKEN': return 'Email already taken';
    case 'LOGIN_ALREADY_TAKEN': return 'Login already taken';
    case 'BAD_CREDENTIALS': return 'Invalid password or email';
    case 'USER_NOT_FOUND': return 'User not found';
    case 'USER_DISABLED': return 'User disabled';
    default: return error.message ?? 'Something went wrong while sending the request';
  }
}

export function errorCodeToName(code: string | undefined): SalesErrorName | undefined {
  if (typeof code === 'undefined') return SalesErrorName.UnknownError;
  switch (code) {
    case 'CHAIN_ADDRESS_ALREADY_ASSIGN': return SalesErrorName.AddressAlreadyAssigned;
  }
}

export function nameTheError(error: SerializedError): SerializedError {
  const { name, code, ...rest } = error;
  return { name: name ?? errorCodeToName(code), code, ...rest };
}
