import React from 'react'
import styled from "styled-components";
import {PATHS} from "router/paths";
import {Button as ButtonComponent} from "./Button";

interface CouponParams {
  lg: string,
  sm: string,
  bg: string,
  text: string,
  color: string,
  className?: string
}

export default function Coupon(props: CouponParams) {
  const {lg, sm, bg, text, color, className} = props;
  return (
    <Background url={bg} className={className}>
      <Container>
        <Picture>
          <source srcSet={lg} media="(min-width: 768px)"/>
          <Image src={sm} alt={text}/>
        </Picture>
        <Button to={PATHS.account.coupons.index} style={{color}}>Get rich!</Button>
      </Container>
    </Background>
  )
}

const Background = styled.div<{url: string}>`
  width: 100%;
  position: relative;
  @media(min-width: 768px) {
    background: url(${(props) => props.url}) center / cover;
  }
`
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  @media(min-width: 768px) {
    padding-right: 140px;
  }
`
const Picture = styled.picture`
  width: 100%;
  user-select: none;
`
const Image = styled.img`
  display: block;
  width: 100%;
`
const Button = styled(ButtonComponent)`
  position: absolute;
  left: 4vw;
  bottom: 8vw;
  width: 31vw;
  @media (min-width: 768px) {
    right: 140px;
    bottom: 3vw;
    left: auto;
    width: 20vw;
  }
  @media (min-width: 1440px) {
    width: 312px;
    right: 140px;
    bottom: 63px;
  }
`