import styled from "styled-components";
import enabled from './assets/enabled.svg';
import disabled from './assets/disabled.svg';


type Props = {
	checked: boolean
	onChange: () => void
}

export function Toggle (props: Props) {
	const {checked, onChange} = props;
	return <Button checked={checked} onClick={onChange}/>
}

const Button = styled.button<{checked: boolean}>`
	width: 3.625em;
	height: 2em;
	display: inline-block;
 	background: url('${({checked}) => checked ? enabled : disabled}') center / contain no-repeat; 
	border: none;
	cursor: pointer;
`