export type AddressAssignmentState = {
  status: typeof AddressAssignmentStatus.UNINITIALIZED
} | {
  status: typeof AddressAssignmentStatus.SENDING_TRANSACTION
} | {
  status: typeof AddressAssignmentStatus.AWAITING_TOKEN
  address: string
} | {
  status: typeof AddressAssignmentStatus.AWAITING_SIGNATURE
  token: string
} | {
  status: typeof AddressAssignmentStatus.SENDING_SIGNATURE
  signature: string
  token: string
} | {
  status: typeof AddressAssignmentStatus.FULFILLED
  address: string
}

export const AddressAssignmentStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  SENDING_TRANSACTION: 'AWAITING_ADDRESS',
  AWAITING_TOKEN: 'AWAITING_TOKEN',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  SENDING_SIGNATURE: 'SENDING_SIGNATURE',
  FULFILLED: 'FULFILLED'
} as const

export type AddressAssignmentStatus = typeof AddressAssignmentStatus[keyof typeof AddressAssignmentStatus];
