import React, {FormEvent, Fragment, useState} from 'react';
import {useDispatch, useReferrer, useSafeNavigate, useSelector} from 'hooks';
import {PATHS} from "router/paths";
import {actions as cartActions, selectors as cartSelectors} from "features/cart/store";
import {actions as modalActions} from "features/modal/store";
import {useSearchQuery} from "features/api/sales/slice";
import {DomainPrice, DomainPriceStatus} from "features/api/sales/model";
import './SearchDomain.scss';
import styles from './SearchDomain.module.scss';
import {useGetAccountQuery} from "features/web3/store";
import Button from "components/button/Button";
import ModalWindow from "../../ModalWindow";
import Loader from "../../../../components/loader/Loader";
import {Magnifier} from "../../../../components/icon";
import {formatToken} from "features/web3";
import {CouponStatus} from "../../../cart/store/model/CouponStatus";
import {SerializedError} from "@reduxjs/toolkit";


export default function SearchDomain(props?: {search?: string, coupon?: {name: string, discount: string}}) {
  const navigate = useSafeNavigate();
  const dispatch = useDispatch();
  const referrer = useReferrer();
  const isInCart = useSelector(cartSelectors.isInCart);
  const coupon = useSelector(cartSelectors.selectCoupon);
  const [name, setName] = useState(props?.search ?? '');
  const {data: account} = useGetAccountQuery();
  const {domains, error, isLoading} = useSearchQuery({
    domainName: name,
    account,
    coupon: props?.coupon?.name || (coupon?.status === CouponStatus.VALID ? coupon.value : undefined),
    referrer
  }, {
    selectFromResult: ({data, error, isLoading}) => ({ domains: data?.data.domainPrices.slice(0, 3).map(toDomain), error, isLoading }),
    skip: !name
  });

  const hasInCart = domains && !!domains.find(({name}) => isInCart(name));

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement & {elements: {domain: HTMLInputElement}}>) => {
    e.preventDefault();
    const domain = e.currentTarget.elements.domain.value;
    if (!domain.length) return;
    setName(domain);
  }

  const handleAddDomain = (domain: Domain) => {
    if (isInCart(domain.name)) {
      dispatch(modalActions.close());
      navigate(PATHS.cart);
    } else {
      dispatch(cartActions.addDomain(domain.name));
    }
    if (props?.coupon) {
      dispatch(cartActions.coupon.check({value: props.coupon.name}));
    }
  }

  const handleGoToCart = () => {
    dispatch(modalActions.close());
    navigate(PATHS.cart);
  }

  return (
    <ModalWindow style={{width: '100%', maxWidth: '50rem'}}>
      <form onSubmit={handleFormSubmit} className={styles.wrapper}>
        {props?.coupon && (
          <div className={styles.coupon}>
            <div>Find domain and add it to your cart.</div>
            <div>We will automatically add your ${formatToken(props.coupon.discount)} coupon to your cart.</div>
            <b>You just have to apply it!</b>
          </div>
        )}
        <div className={styles.group}>
          <input type="text" name="domain" defaultValue={name} autoFocus placeholder="Find your domain without Extension" className={styles.input}/>
          <button type="submit" disabled={isLoading} className={styles.search}><Magnifier/><span>Search</span></button>
        </div>
        <div className="search-result">
          <RenderFeedback error={error} domain={domains?.[0]}/>
          {!error && domains && domains.map(domain => (
            <RenderSearchItem domain={domain} isInCart={isInCart(domain.name)} isLoading={isLoading} onClickSubmit={handleAddDomain} />
          ))}
        </div>
        {hasInCart && (
          <div className="search-result__footer">
            <Button onClick={handleGoToCart} shape="squared">Go to Cart</Button>
          </div>
        )}
      </form>
    </ModalWindow>
  );
}

function RenderFeedback(props: {error?: SerializedError, domain?: Domain}) {
  const {error, domain} = props;
  if (domain?.error) return <div className={styles.feedback}>{domain.error}</div>
  if (error) return <div className={styles.feedback}>{error.message}</div>
  return null
}

function RenderSearchItem(props: {domain: Domain, isInCart: boolean, isLoading: boolean, onClickSubmit: (domain: Domain) => void}) {
  const {domain, isInCart, isLoading, onClickSubmit} = props;
  return (
    <div className={`search-result-item ${domain.isAvailable ? "available" : 'unavailable'}`}>
      <div className="search-result-item__name">{domain.name}</div>
      {isLoading ? (
        <Loader size='2em'/>
      ) : (
        <Fragment>
          <RenderStatus isAvailable={domain.isAvailable}/>
          <RenderPrice price={domain.price} discountedPrice={domain.discountedPrice} isAvailable={domain.isAvailable}/>
          <RenderButton domain={domain.name} isAvailable={domain.isAvailable} isInCart={isInCart} onClickSubmit={() => onClickSubmit(domain)}/>
        </Fragment>
      )}
    </div>
  )
}

function RenderStatus(props: {isAvailable: boolean}) {
  if (props.isAvailable) return <div className={styles.status}>available</div>
  else return <div className='search-result-item__status _unavailable'>taken</div>
}

function RenderPrice(props: { price?: string, discountedPrice?: string, isAvailable: boolean }) {
  const {price, discountedPrice, isAvailable} = props;
  if (!isAvailable) return <div className="search-result-item__price"></div>
  if (price && discountedPrice && price !== discountedPrice) return (
    <div className="search-result-item__price">
      <span className="search-result-item__price__secondary">(${formatToken(price)})</span>
      <span className="search-result-item__price__primary">${formatToken(discountedPrice)}</span>
    </div>
  );
  if (price) return (
    <div className="search-result-item__price _no-discount">
      <span className="search-result-item__price__primary">${formatToken(price)}</span>
    </div>
  );
  return null;
}

function RenderButton(props: {domain: string, isAvailable: boolean, isInCart: boolean, onClickSubmit: (name: string) => any}) {
  const {domain, isAvailable, isInCart, onClickSubmit} = props;
  const handleClickSubmit = () => onClickSubmit(domain);
  if (!isAvailable) return <div className='search-result-item__buy-btn search-result-item__buy-btn_disabled'>Add to cart</div>
  if (isInCart) return <div onClick={handleClickSubmit} className='search-result-item__buy-btn action-added'>Added</div>
  return <div onClick={handleClickSubmit} className='search-result-item__buy-btn'>Add to cart</div>
}

type Domain = {
  discountedPrice: string | undefined
  error: string | undefined
  name: string
  isAvailable: boolean
  price: string | undefined
}

function toDomain(domainPrice: DomainPrice): Domain {
  const {domainName, normalizedDomainName, price, priceWithDiscount, status} = domainPrice;
  return {
    discountedPrice: priceWithDiscount,
    error: getDomainError(status),
    name: normalizedDomainName || domainName,
    isAvailable: status === DomainPriceStatus.FREE,
    price
  }
}

function getDomainError(status: DomainPriceStatus): string | undefined {
  switch (status) {
    case DomainPriceStatus.ERROR_FORBIDDEN_DOMAIN_NAME: return 'this domain name is prohibited';
    case DomainPriceStatus.ERROR_FORBIDDEN_SYMBOL: return 'domain name contains prohibited characters';
    case DomainPriceStatus.ERROR_LATIN_AND_CYRILLIC_MIXED: return 'domain name contains characters from multiple languages'
  }
}
