import * as t from "io-ts";
import {Utils} from "../../util/util";
import {CompetitionType} from "./CompetitionType";
import {Coupon} from "./Coupon";


export const CompetitionDataCodec = t.type({
  type: Utils.enum<CompetitionType>(CompetitionType),
  url: t.union([t.string, t.null, t.undefined]),
  price: t.string,
  coupon: t.union([Coupon, t.null, t.undefined])
});

export type CompetitionData = t.TypeOf<typeof CompetitionDataCodec>;
