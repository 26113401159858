import {DomainPrice, DomainPriceStatus} from "../../api/sales";

export type SearchItem = {
  name: string
  price: string
  discountedPrice?: string
} | {
  name: string
  error: string
}

export const SearchItem = {
  from(domainPrice: DomainPrice): SearchItem {
    const {domainName, normalizedDomainName, price, priceWithDiscount, status} = domainPrice;
    const name = normalizedDomainName || domainName;
    const error = SearchItem.getError(status);
    if (error) return { name, error };
    const discountedPrice = priceWithDiscount && priceWithDiscount !== price ? priceWithDiscount : undefined;
    return { name, price: price!, discountedPrice };
  },
  getError(status: DomainPriceStatus): string | undefined {
    switch (status) {
      case DomainPriceStatus.FREE: return undefined;
      case DomainPriceStatus.ERROR_FORBIDDEN_DOMAIN_NAME: return 'this domain name is prohibited';
      case DomainPriceStatus.ERROR_FORBIDDEN_SYMBOL: return 'domain name contains prohibited characters';
      case DomainPriceStatus.ERROR_LATIN_AND_CYRILLIC_MIXED: return 'domain name contains characters from multiple languages'
      default: return 'Sorry, but this domain is taken'
    }
  }
}