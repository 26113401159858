import {useEffect} from 'react';
import {useAccount, useDispatch} from 'hooks';
import {AssignAddress} from "features/account/assign-address";
import {actions as mintActions} from '../store';


export default function RequestAddress (props: {tokenId: string}) {
  const {tokenId} = props;
  const dispatch = useDispatch();
  const account = useAccount();

  useEffect(() => {
    if (account?.address) dispatch(mintActions.mint.setAddress({tokenId, address: account.address}));
  }, [account?.address]);

  if (account?.address) return null;
  else return <AssignAddress/>
}


