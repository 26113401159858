import {useAuth} from "./useAuth";
import {useFetchAccountQuery, useFetchReferralDataQuery} from "../features/api/sales/slice";
import {useMemo} from "react";

type Account = {
	id: number
	address: string | undefined
	couponsSummaryDiscount: string
	domainsCount: number
	email: string | undefined
	login: string | undefined
	referralsCount: number
	referralId: string | undefined
}

export const useAccount = (pollingInterval?: number): Account | undefined => {
	const {subject} = useAuth();
	const id = subject?.id;
	const info = useFetchAccountQuery({id: id!}, {skip: !id, pollingInterval, selectFromResult: ({data}) => ({
		address: data?.data.address ?? undefined,
		domainsCount: data?.data.domainsCount ?? 0,
		email: data?.data.email ?? undefined,
		couponsSummaryDiscount: data?.data.couponsSummaryDiscount ?? '0',
		login: data?.data.login,
		referralsCount: data?.data.referralsCount ?? 0,
	})})
	const {referralId} = useFetchReferralDataQuery({accountId: id!}, {skip: !id || (info && !info.address), selectFromResult: ({data}) => ({
		referralId: data?.data?.referralId
	})});
	return useMemo(() => id && info ? {id, ...info, referralId} : undefined, [id, info, referralId])
}