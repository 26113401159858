import React, {useEffect} from 'react';
import {RequestWrapper} from "components";
import {useRequestTokenReleaseMutation} from "features/api/sales/slice";


export default function RequestRelease (props: {address: string, tokenId: string}) {
  const {address, tokenId} = props;
  const [requestRelease, {isLoading, error}] = useRequestTokenReleaseMutation();
  useEffect(() => { requestRelease({address, tokenId}) }, []);
  return (
    <RequestWrapper action={() => requestRelease({address, tokenId})} isLoading={isLoading} error={error}>
      {{
        action: null,
        loader: { description: 'Sending request' }
      }}
    </RequestWrapper>
  )
}
