import {NavLink} from "react-router-dom";
import {PATHS} from "../../router/paths";
import './Navbar.scss';
import {HashLink} from "react-router-hash-link";
import {useState} from "react";
import {useAuth, useDispatch, useSafeNavigate, useSelector} from 'hooks';
import {actions as authActions} from 'features/auth/store';
import {selectDomainsCount} from "../../features/cart/store/selectors";
import {Burger, Cart, Magnifier} from "components/icon";
import {analytics, EventTarget, EventType, TargetLocation, TargetName} from "../../features/analytics";
import {Drawer} from "./Drawer";
import {AccountMenu} from "./AccountMenu";


export function Navbar () {
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const domainsCount = useSelector(selectDomainsCount);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {isAuthorized, subject} = useAuth();
  const handleClickLogoutButton = () => dispatch(authActions.invalidate());
  const handleClickDomainButton = () => {
    setIsDrawerOpen(false);
    analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.Domain, targetLocation: TargetLocation.Header})
    navigate(PATHS.search.index());
  }
  const handleClickSearch = () => {
    analytics.push({type: EventType.Click, target: EventTarget.Button, targetName: TargetName.Search, targetLocation: TargetLocation.Header})
    navigate(PATHS.search.index());
  }

  return (
    <div className="header">
      <div className="inner">
        <NavLink to={PATHS.root.index} className="header__logo">
          <img src="/assets/img/header-logo.svg" alt="w3dna.net"/>
        </NavLink>
        <ul className="header__links">
          <li className="header__link-wrapper">
            <button className="header__link" onClick={handleClickDomainButton}>Domain</button>
          </li>
          <li className="header__link-wrapper">
            <HashLink to={PATHS.root.browser} smooth className="header__link">Web3 Browser</HashLink>
          </li>
          <li className="header__link-wrapper">
            <HashLink to={PATHS.root.browser} smooth className="header__link">Extensions</HashLink>
          </li>
          <li className="header__link-wrapper">
            <HashLink to={PATHS.root.news} smooth className="header__link">On the Track</HashLink>
          </li>
        </ul>
        <div className="header__search">
          <button onClick={handleClickSearch}><Magnifier/><span>Search domain</span></button>
        </div>
        <div className="header__cart">
          <NavLink to={PATHS.cart}><Cart count={domainsCount}/></NavLink>
        </div>
        <div className="header__burger">
          <button onClick={() => setIsDrawerOpen(true)} aria-label='toggle menu'><Burger aria-hidden/></button>
        </div>
        {subject ? <AccountMenu subject={subject} onClickLogout={handleClickLogoutButton}/> : <AuthLinks/>}
        <Drawer
          isLoggedIn={isAuthorized}
          isOpened={isDrawerOpen}
          onClickDomainButton={handleClickDomainButton}
          onClickCloseButton={() => setIsDrawerOpen(false)}
          onClickLogoutButton={handleClickLogoutButton}
        />
      </div>
    </div>
  );
}

function AuthLinks() {
  return (
    <div className="header__auth">
      <NavLink to={PATHS.auth.index}><span>Log In</span></NavLink>
      <span>&nbsp;/&nbsp;</span>
      <NavLink to={PATHS.signup.page}>Sign Up</NavLink>
    </div>
  );
}

