import {RequestWrapper} from "components";
import {Chain, formatTxLink, useWaitForTransactionQuery} from "features/web3";

export default function AwaitTransaction(props: {transactionHash: string, chain: Chain}) {
  const {transactionHash, chain} = props;
  const {isLoading, error} = useWaitForTransactionQuery({chain, transactionHash});
  return (
    <RequestWrapper action={() => {}} isLoading={isLoading} error={error}>
      {{
        action: null,
        loader: { description: <div>Please wait until your <a href={formatTxLink(transactionHash!, chain)} target='_blank' rel='noreferrer'>transaction</a> is mined</div> }
      }}
    </RequestWrapper>
  )
}
