export const EventType = {
	AddToCart: 'AddToCart',
	BeginCheckout: 'BeginCheckout',
	Click: 'Click',
	LogIn: 'LogIn',
	Purchase: 'Purchase',
	Search: 'Search',
	SignUp: 'SignUp',
	ViewPage: 'ViewPage'
} as const

export type EventType = typeof EventType[keyof typeof EventType]