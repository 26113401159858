import React, {useEffect} from 'react';
import Button from "../../../components/button/Button";
import {useClaimDomainsMutation} from "../../api/sales/slice";
import Loader from "../../../components/loader/Loader";
import {useDispatch} from 'hooks';
import {actions as claimActions} from '../store';

export default function SendRequest (props: {address: string, uuid: string}) {
  const {address, uuid} = props;
  const dispatch = useDispatch();
  const [claimDomains, {error, isLoading}] = useClaimDomainsMutation();

  useEffect(() => {
    claimDomains({address, code: uuid});
  },[])

  const onClickSumbit = (shouldCreateNewAccount?: boolean) => () => {
    if (typeof shouldCreateNewAccount === "boolean" && !shouldCreateNewAccount) {
      return dispatch(claimActions.initEmailRegistration());
    } else {
      claimDomains({address, code: uuid, shouldCreateNewAccount});
    }
  }

  if (error && error.code === 'UNREGISTERED_CHAIN_ADDRESS') return (
    <div className="claim__inner">
      <div style={{marginBottom: '2rem'}}>Do you want to connect the specified address to your account?</div>
      <div style={{marginLeft: '-.5rem', marginRight: '-.5rem', display: 'flex'}}>
        <div style={{marginLeft: '.5rem', marginRight: '.5rem'}}>
          <Button onClick={onClickSumbit(false)}>Yes</Button>
        </div>
        <div style={{marginLeft: '.5rem', marginRight: '.5rem'}}>
          <Button onClick={onClickSumbit(true)}>No</Button>
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="claim__inner">
      <div style={{marginBottom: '2rem'}}>{error?.message}</div>
      <Button onClick={onClickSumbit()}>Retry</Button>
    </div>
  );

  if (isLoading) return (
    <div className="claim__inner">
      <div style={{marginBottom: '2rem'}}>Sending request to complete your purchase.</div>
      <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
    </div>
  );

  return (
    <div className="claim__inner"/>
  );

}
