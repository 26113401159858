import React from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";

const DummyPage = () => {

  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className="inner" style={({flexGrow: 1})}>
          <h1>Ooops...</h1>
          <h2>We havent finished this page</h2>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );
}

export default DummyPage;