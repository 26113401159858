import {BackgroundWidget} from "../components/BackgroundWidget/BackgroundWidget";
import {ContactsWidget} from "../components/ContactsWidget";
import {LayoutWidget} from "../components/LayoutWidget";
import {ProfileTemplateProps, TemplateComponentProps} from "../types/Template";
import {AvatarWidget} from "../components/AvatarWidget";
import {TextWidget} from "../components/TextWidget";
import styled from "styled-components";
import {Asset} from "../types/Asset";
import {backgrounds} from "../components/BackgroundWidget/backgrounds";


export const defaultProps: ProfileTemplateProps = {
	contacts: [],
	description: '',
	name: '',
	background: Asset.fromUrl(backgrounds[2].url),
	theme: backgrounds[2].theme
}

export function Profile(props: TemplateComponentProps<ProfileTemplateProps>) {
	const {isEdit, props: fields} = props;
	const handlePropChange = (prop: Partial<ProfileTemplateProps>) => {
		if ('onPropsChange' in props) props.onPropsChange({...fields, ...prop})
		console.log(prop)
	}
	return (
		<Body isEdit={isEdit} background={fields.background} title={fields.name}>
			<Wrapper>
				<Avatar isEdit={isEdit} image={fields.avatar} onChange={avatar => handlePropChange({avatar})}/>
				<Heading
					isEdit={isEdit}
					defaultValue={'Name'}
					value={fields.name}
					onChange={name => handlePropChange({name})}
					tagName='h1'
				/>
				<About
					isEdit={isEdit}
					defaultValue={'Write a text about yourself (up to 300 characters)'}
					value={fields.description}
					onChange={description => handlePropChange({description})}
					tagName='div'
				/>
				<ContactsWidget isEdit={isEdit} theme={fields.theme} contacts={fields.contacts} onChange={contacts => handlePropChange({contacts})}/>
				<BackgroundButton isEdit={isEdit} theme={fields.theme} image={fields.background} onChange={(background, theme) => handlePropChange({background, theme})}/>
			</Wrapper>
		</Body>
	)
}
const Body = styled(LayoutWidget)`
  display: grid;
  grid-template-rows: 38fr min-content 62fr;	
`
const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  grid-row-start: 2;
  max-width: 38rem;
  text-align: center;
`
const Avatar = styled(AvatarWidget)`
	margin: 0 auto 1.5rem;
`
const Heading = styled(TextWidget)`
	margin-bottom: 1.5rem;
	font-size: 2rem;	
	color: ${props => props.theme.palette.text.primary}
`
const About = styled(TextWidget)`
	margin-bottom: 2rem;
	padding: 1.5rem;
	background-color: ${({theme: {name, palette}}) => name === 'dark' ? 'rgba(255,255,255,.3)' : palette.primary.main};
	backdrop-filter: ${({theme: {name}}) => name === 'dark' ? 'blur(50px)' : undefined};
	color: ${props => props.theme.palette.common.white};
	border-radius: .875rem;
`
const BackgroundButton = styled(BackgroundWidget)`
	margin-top: 2rem;
`