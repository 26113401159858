import {Fragment, PropsWithChildren, useState} from "react";
import styled from 'styled-components';
import {Contact} from "../../types/Contact";
import {ContactType} from "../../types/ContactType";
import {ContactsModal} from "./ContactsModal";
import {Theme} from "../../types/Theme";
import {PenLineIcon} from "../Icon";


type Props = {
	isEdit: boolean
	contacts: Contact[]
	onChange: (value: Contact[]) => void
	theme: Theme
}

export function ContactsWidget(props: Props) {
	const {isEdit, contacts, onChange, theme} = props;
	const [isPristine, setIsPristine] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const items = (isEdit && isPristine && !contacts.length) ? Contact.types.map(type => ({type, value: Contact.getPlaceholder({type})})) : contacts;
	const handleChange = (contacts: Contact[]) => {
		if (isPristine) setIsPristine(false);
		onChange(contacts);
	}
	return (
		<Fragment>
			<List>
				{items.map((contact, idx) =>
					<Item key={idx}><ContactButton contact={contact}><Icon src={Contact.getIconUrl({type: contact.type, theme, isEdit})} alt={contact.type}/></ContactButton></Item>
				)}
				{isEdit && <Item><EditButton onClick={() => setIsModalOpen(true)}><PenLineIcon/></EditButton></Item>}
			 </List>
			{isEdit && <ContactsModal contacts={contacts} onChange={handleChange} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>}
		</Fragment>
	)
}

const List = styled.ul`
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 3.125rem;
	list-style: none;
	color: ${({theme: {name, palette}}) => name === 'dark' ? palette.common.white : palette.common.grey}
`
const Item = styled.li`
	margin: 0 .75rem;
	line-height: 1em;
`

const EditButton = styled.button`
	background: none;
	border: none;
  color: inherit;
  font-size: 1.5rem;
	cursor: pointer;
	&:hover {
		opacity: .75;
	}
`

const ContactButton = styled((props: PropsWithChildren & {className?: string, contact: Contact}) => {
	const {contact, children, ...rest} = props;
	return props.contact.type === ContactType.Email
		? <EmailButton data-email={contact.value}>{children}</EmailButton>
		: <a href={Contact.getLink(contact)} {...rest} target='_blank' rel='noreferrer'>{children}</a>
})`
	display: inline-block;
	background: none;
	border: none;
  font-size: 2.25rem;
	cursor: pointer;
	color: inherit;
`

const EmailButton = styled(props => {
	const {children, ...rest} = props;
	return (
		<span {...rest}>
			<button type='button' {...props} id='email-button'>
				<span id='email-tooltip'>Copy to clipboard</span>
				{props.children}
			</button>
			<script dangerouslySetInnerHTML={{__html: `
				var button = document.getElementById('email-button');
    		var tooltip = document.getElementById('email-tooltip');
    		button.onclick = function() { navigator.clipboard.writeText(button.dataset.email); tooltip.innerHTML = "Copied!"; };
    		button.onmouseout = function() { tooltip.innerHTML = "Copy to clipboard"; };
			`}}/>
		</span>
	)
})`
	position: relative;
	display: inline-block;
	& button {
		display: inline-block;
		height: 2.25rem;
		background: none;
		border: none;
		font-size: 2.25rem;
		cursor: pointer;
		color: inherit;
	}
	& span {
		visibility: hidden;
		width: 8.75rem;
		background-color: ${({theme}) => theme.palette.common.grey};
		color: #fff;
		text-align: center;
		font-size: 1rem;
		border-radius: 6px;
		padding: 5px;
		position: absolute;
		z-index: 1;
		bottom: 105%;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s;
		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent;
			border-top-color: ${({theme}) => theme.palette.common.grey};	
		}
	}
	&:hover span {
		visibility: visible;
		opacity: 1;
	}
`
const Icon = styled.img`
	width: 1em;
	height: 1em;
`