import {useEffect} from "react";
import {useDispatch, useSelector} from 'hooks';
import {ChainGuard} from "features/web3";
import AwaitConfirmation from "./AwaitConfirmation";
import AwaitRelease from "./AwaitRelease";
import RequestAddress from "./RequestAddress";
import RequestRelease from "./RequestRelease";
import SendTransaction from "./SendTransaction";
import AwaitTransaction from "./AwaitTransaction";
import Congratulations from "./Congratulations";
import {RehydrateState} from "./RehydrateState";
import {MintSagaStatus} from "../types";
import {selectMintSaga} from "../store/selectors";
import {actions as mintActions} from '../store';


export function MintDomain(props: {tokenId: string}) {
	const {tokenId} = props;
	const dispatch = useDispatch();
	const saga = useSelector(selectMintSaga)(tokenId);

	useEffect(() => {
		if (!saga) dispatch(mintActions.mint.update({status: MintSagaStatus.INITIAL, tokenId}));
	}, []);

	switch (saga?.status) {
		case MintSagaStatus.INITIAL: return (
			<RehydrateState tokenId={saga.tokenId}/>
		)
		case MintSagaStatus.AWAITING_ADDRESS: return (
			<RequestAddress tokenId={saga.tokenId}/>
		)
		case MintSagaStatus.SENDING_RELEASE_REQUEST: return (
			<RequestRelease address={saga.address} tokenId={saga.tokenId}/>
		)
		case MintSagaStatus.AWAITING_RELEASE: return (
			<AwaitRelease tokenId={saga.tokenId}/>
		)
		case MintSagaStatus.SENDING_TRANSACTION: return (
			<ChainGuard chain={saga.chain} address={saga.address}>
				<SendTransaction domainName={saga.domainName} chain={saga.chain}/>
			</ChainGuard>
		)
		case MintSagaStatus.AWAITING_TRANSACTION: return (
			<ChainGuard chain={saga.chain}>
				<AwaitTransaction transactionHash={saga.transactionHash} chain={saga.chain}/>
			</ChainGuard>
		)
		case MintSagaStatus.AWAITING_CONFIRMATION: return (
			<AwaitConfirmation/>
		)
		case MintSagaStatus.FULFILLED: return (
			<Congratulations/>
		)
		default: return null;
	}
}

