import JSZip from "jszip";
import {TemplateDescriptor} from "../types/Template";
import {Asset} from "../types/Asset";
import {Contact} from "../types/Contact";


export async function prepareAssets(template: TemplateDescriptor): Promise<Blob|undefined> {
	const files: Record<string, Blob> = {};
	for (const key in template.props) {
		const prop = template.props[key as keyof typeof template.props]
		if (Asset.isAsset(prop)) {
			files[`${prop.name}.${prop.extension}`] = await fetch(prop.url).then(result => result.blob());
		} else if (Contact.isContacts(prop)) {
			for (const contact of prop) {
				const {name, extension, url} = Contact.getIcon({type: contact.type, theme: template.props.theme});
				files[`${name}.${extension}`] = await fetch(url).then(result => result.blob());
			}
		}
	}
	if (!Object.keys(files).length) return Promise.resolve(undefined);
	const zip = new JSZip();
	zip.folder('assets');
	for (const name in files) zip.file(`assets/${name}`, files[name]);
	return zip.generateAsync({type: 'blob'});
}