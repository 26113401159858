import React, {Fragment, useState} from 'react';
import {AuthType} from "../store/types";
import {useSelector} from 'hooks';
import {selectors as authSelectors} from '../store';
import {Field, Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR, SubmissionErrors} from "final-form";
import {useSignInMutation} from "features/api/sales/slice";
import {SignUpButton} from "./SignUpButton";
import {mapError} from "../../api/sales/util";
import {ResetPasswordModal} from "./ResetPasswordModal";

export function PrimaryAuthButton (props: {onClick: (authType: typeof AuthType.PRIMARY) => void}) {
  return <SignUpButton onClick={() => props.onClick(AuthType.PRIMARY)} imageUrl="/assets/img/cont-email-icon.svg" textButton="Continue with Email"/>
}

export function PrimaryAuthPage () {
  const state = useSelector(authSelectors.selectAuthState);
  const [signIn] = useSignInMutation();

  const handleFormSubmit = async (values: {[k: string]: any}): Promise<SubmissionErrors> => {
    const {login, password} = values;
    const result = await signIn({login, password});
    if ('error' in result) return {[FORM_ERROR]: mapError(result.error)};
  }

  const validateForm = (values: {[k: string]: string}) => {
    const errors: {[k: string]: string} = {};
    if (!values.login) errors.login = 'This field is required';
    if (!values.password) errors.password = 'This field is required';
    return errors;
  }

  if (!('type' in state) || state.type !== AuthType.PRIMARY) return null;

  return <Form onSubmit={handleFormSubmit} validate={validateForm} component={FormComponent}/>
}

function FormComponent(props: FormRenderProps) {
  const [isVisiblePassword, setVisiblePassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {submitError, handleSubmit, submitting} = props;

  return (
    <Fragment>
      <form className="login-form" onSubmit={handleSubmit}>
        {submitError && <div style={{color:'red', fontFamily:'sans-serif', marginBottom:'0.5rem'}}>{submitError}</div>}
        <div className="login-form__fields">
          <Field name="login">
            {({ input, meta: {error, submitError, touched} }) => (
              <div className="login-form__field-wrap">
                {(error || submitError) && touched && <div className="login-form__field-error _top">{error || submitError}</div>}
                <input {...input} type="text" placeholder="Email" className="login-form__field"/>
              </div>
          )}
          </Field>
          <Field name="password">
            {({ input, meta: {error, submitError, touched} }) => (
              <div className="login-form__field-wrap">
                <input {...input} type={isVisiblePassword ? "text" : "password"} placeholder="Password" className="login-form__field _pass"/>
                <div className={"login-form__field-icon-eye" + (isVisiblePassword ? "" : " _show")} onClick={() => setVisiblePassword(!isVisiblePassword)}/>
                {(error || submitError) && touched && <div className="login-form__field-error _bottom">{error || submitError}</div>}
              </div>
            )}
          </Field>
          <button type='button' className="forgot-password-btn" onClick={() => setIsModalOpen(true)}>Forgot password?</button>
        </div>
        <button className="registration__btn" type="submit" disabled={submitting}>Log in</button>
      </form>
      <ResetPasswordModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>
    </Fragment>
  );
}

