import React from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import './WelcomePage.css';

export default function WelcomePage() {
  return (
      <BaseLayout>
        <BaseLayout.Body>
          <div className="welcome-page-wrapper">
            <div className="inner">
              <h1 className="welcome-title">
                Welcome to *W3DNA
              </h1>
              <div className="welcome-text">
                We welcome your desire to purchase a WEB3-domain using a bank card. <br/>
                Please fill in the fields to successfully complete your purchase.
              </div>

              <input type="text" className="welcome-field" placeholder="Enter your ethereum-address..."/>
              <button className="welcome-button">
                release a domain
              </button>
            </div>
          </div>

          {/*<div className="loader-circle">
              <div className="loader-circle-inner"></div>
          </div>*/}
        </BaseLayout.Body>
      </BaseLayout>
  );

}
