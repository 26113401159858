import styles from './ConfirmEmail.module.scss';
import ModalLayout from "components/layout/ModalLayout/ModalLayout";
import {useConfirmEmailQuery} from "../../api/sales/slice";
import {useSearchParams} from "react-router-dom";


export default function ConfirmEmail () {

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const {isFetching, error} = useConfirmEmailQuery({code: code!}, {skip: !code});
  const isSuccess = !isFetching && !error;

  if (!code) return (
    <ModalLayout>
      <div className={styles.wrapper}>
        Sorry. No confirmation code detected.
      </div>
    </ModalLayout>
  )
  if (isSuccess) return (
    <ModalLayout>
      <div className={styles.wrapper}>
        Congratulations! You've successfully confirmed your email.
      </div>
    </ModalLayout>
  )
  if (error) return (
    <ModalLayout>
      <div className={styles.wrapper}>
        <div>Something went wrong.</div>
        <div>{error.message}</div>
      </div>
    </ModalLayout>
  )
  return (
    <ModalLayout>
      <div className={styles.wrapper}>
        <div>Confirming email</div>
        <div>Please wait</div>
      </div>
    </ModalLayout>
  );
}
