import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import {PageEditor as PageEditorComponent} from "../components/PageEditor";
import {useParams, useSearchParams} from "react-router-dom";
import {useFetchDomainsQuery} from "features/api/sales/slice";
import {uploadBundle} from "features/website/upload";
import {useSafeNavigate} from "hooks";
import {PATHS} from "router/paths";
import {useDispatch} from "store/hooks";
import {TemplateName} from "../types/TemplateName";
import {RequestWrapper} from "../../../../components";
import SidebarLayout from "../../../../components/layout/SidebarLayout/SidebarLayout";


export function PageEditor() {
	const dispatch = useDispatch();
	const navigate = useSafeNavigate();
	const {id} = useParams();
	const [search] = useSearchParams();
	const templateName = getTemplateName(search.get('template'));
	const {domainName, isLoading} = useFetchDomainsQuery(undefined, {
		selectFromResult: ({data, isLoading}) => ({domainName: (data?.data.domainNameInfos ?? []).find(domain => domain.id === Number(id))?.domainName, isLoading}),
		skip: !id
	});
	let error: Error | undefined;
	if (!templateName) error = new Error('Template not found');
	if (!id || !domainName) error = new Error('Domain not found');
	const handlePublish = (html: string, assets?: string) => {
		if (domainName && id) {
			dispatch(uploadBundle({domainName, html, assets}))
			navigate(PATHS.account.domains.upload(id));
		}
	}

	if (!templateName || !id || !domainName) return (
		<SidebarLayout style={{display: 'flex'}}>
			<RequestWrapper isLoading={isLoading} error={error}>
				{{loader: {description: null}, error: {button: null}}}
			</RequestWrapper>
		</SidebarLayout>
	)
	return (
		<PageEditorComponent domainName={domainName} templateName={templateName} onPublish={handlePublish}/>
	)
}

function getTemplateName(templateName: string | null): TemplateName | undefined {
	return templateName ? TemplateName[templateName as keyof typeof TemplateName] : undefined;
}
