import {AppStartListening, RootState} from "store";
import * as orderActions from "./actions";
import * as orderSelectors from './selectors';
import {actions as authActions} from 'features/auth/store';
import {actions as cartActions} from 'features/cart/store';
import {isAnyOf, ListenerEffectAPI} from "@reduxjs/toolkit";
import {OrderStatus} from "./model/OrderStatus";
import {getOrThrow} from "shared/util";
import {matchers as salesMatchers, slice as salesApi} from 'features/api/sales';
import {api as web3Api, ContractName, formatToken, getContractAddress, matchers as web3Matchers, parseWei} from 'features/web3';
import {BuyTaskStatus} from "../../api/sales/model";
import {PaymentSystem} from "shared/model";
import {PaymentStatus} from "./model/Payment";
import isEqual from "lodash/isEqual";
import {take} from "../../../store/util";
import {analytics, EventType, PaymentType} from "features/analytics";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(orderActions.payment.init.match, orderActions.payment.cancel.match),
    effect: async (action, api)  => {
      if (orderActions.payment.cancel.match(action)) {
        api.dispatch(orderActions.payment.clear);
        api.cancelActiveListeners();
        return;
      }
      const {orderId, amount, currency, chain} = action.payload;
      const paymentSystem = PaymentSystem.Chain;
      const recipient = getContractAddress(chain, ContractName.DomainMarket);
      api.dispatch(orderActions.payment.update({status: PaymentStatus.SENDING_TRANSACTION, chain, orderId, recipient, amount, currency}));
      const [{payload: transactionHash}] = await api.take(action =>
        web3Api.endpoints.transfer.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {recipient, amount, currency, chain})
      );
      api.dispatch(orderActions.payment.update({status: PaymentStatus.AWAITING_TRANSACTION, chain, orderId, transactionHash}));
      await api.condition(web3Matchers.isSuccessfulTx(transactionHash));
      api.dispatch(orderActions.payment.update({status: PaymentStatus.REGISTERING_PAYMENT, chain, orderId, paymentSystem, currency, transactionHash}));
      await api.take(action => salesApi.endpoints.registerCryptoPayment.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {orderId, paymentSystem, currency, transactionHash, chain}));
      api.dispatch(orderActions.payment.update({status: PaymentStatus.FULFILLED, orderId}));
      api.dispatch(orderActions.markOrderAsPaid({id: orderId}));
    }
  });

  startListening({
    matcher: isAnyOf(orderActions.createOrder.match, orderActions.cancelOrder.match),
    effect: async (action, api) => {
      if (orderActions.cancelOrder.match(action)) return handleOrderCancellation(api, action.payload);
      api.dispatch(cartActions.removeDomains());
      let order = getOrThrow(orderSelectors.selectOrder(api.getState()));
      if (order.paymentSystem === PaymentSystem.Chain && order.discountedAmount !== '0') {
        api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.CHECKING_BALANCE}))
        const total = parseWei(order.discountedAmount);
        await take({api, query: web3Api.endpoints.fetchBalance, condition: payload => total.lte(payload)})
      }
      analytics.push({
        type: EventType.BeginCheckout,
        paymentType: paymentSystemToPaymentType(order.paymentSystem),
        value: formatToken(order.totalAmount),
        promoValue: formatToken(order.promoAmount),
        discountedValue: formatToken(order.discountedAmount),
        items: order.items.map(({name}) => ({name})),
        coupon: order.coupon,
        referrer: order.referrer
      })
      api.dispatch(orderActions.updateOrder({...order, status: OrderStatus.PLACING_ORDER}))
      const [{payload: {task: {taskId: id}}}] = await api.take(action => salesApi.endpoints.placeOrder.matchFulfilled(action) && !!action.payload.task);
      if (order.discountedAmount !== '0') {
        api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.AWAITING_PAYMENT}));
        await api.condition(action => orderActions.markOrderAsPaid.match(action) && action.payload.id === id);
      }
      api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.AWAITING_ORDER_FULFILMENT}));
      const [{payload: {status}}] = await api.take(salesMatchers.isFulfilledOrder);
      if (status === BuyTaskStatus.FINISHED || status === BuyTaskStatus.RESERVATION_FINISHED) {
        analytics.push({
          type: EventType.Purchase,
          paymentType: paymentSystemToPaymentType(order.paymentSystem),
          value: formatToken(order.totalAmount),
          promoValue: formatToken(order.promoAmount),
          discountedValue: formatToken(order.discountedAmount),
          items: order.items.map(({name}) => ({name})),
          coupon: order.coupon,
          referrer: order.referrer
        })
        api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.ORDER_FULFILLED}));
      } else if (status === BuyTaskStatus.CANCELLED) {
        api.dispatch(orderActions.updateOrder({...order, id, status: OrderStatus.ORDER_CANCELLED}));
      }
    }
  });
  startListening({
    matcher: authActions.invalidate.match,
    effect: async (_, api) => {
      api.dispatch(orderActions.clear());
    }
  });
}

function handleOrderCancellation(api: ListenerEffectAPI<RootState, any>, shouldRestoreCart: boolean | undefined) {
  api.cancelActiveListeners();
  const order = getOrThrow(orderSelectors.selectOrder(api.getState()), 'Could not retrieve Order during order cancellation');
  if (shouldRestoreCart) {
    const isForbiddenToRestore =
      order.status === OrderStatus.AWAITING_ORDER_FULFILMENT ||
      order.status === OrderStatus.ORDER_FULFILLED
    if (!isForbiddenToRestore) api.dispatch(cartActions.restore({currency: order.currency, domainNames: order.items.map(item => item.name)}));
  }
  api.dispatch(orderActions.deleteOrder());
}

function paymentSystemToPaymentType(paymentSystem: PaymentSystem): PaymentType {
  switch (paymentSystem) {
    case PaymentSystem.Chain: return PaymentType.USDT;
    case PaymentSystem.Wert: return PaymentType.CreditCart;
    case PaymentSystem.Heropayments: return PaymentType.Crypto;
  }
}