import {Fragment} from "react";
import {UploadForm} from "./UploadForm";
import {useUploadMutation} from "../store/api";
import {FORM_ERROR} from "final-form";

export function UploadCustomBundle(props: {tokenId: string}) {
	const [uploadDataToServer] = useUploadMutation();
	const handleFormSubmit = async (values: any) => {
		const data = new FormData();
		data.append('tokenId', props.tokenId);
		data.append('index', values.page[0]);
		if (values.assets) data.append('zip', values.assets[0]);
		const result = await uploadDataToServer(data);
		if ('data' in result) return {};
		const error = result.error.message ?? 'Something went wrong';
		return {[FORM_ERROR]: error};
	}
	return (
		<Fragment>
			<UploadForm onSubmit={handleFormSubmit}/>
		</Fragment>
	);
}