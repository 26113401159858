import {createApi} from '@reduxjs/toolkit/query/react'
import {Currency} from "shared/types";
import {Chain, TxReceipt, TxReceiptCodec, Web3Address, Web3ClientName, Web3Hash} from "../model";
import {contracts, wagmi} from "./client";
import {web3BaseQuery} from "./Web3BaseQuery";
import decodeWith from "../../api/util/decodeWith";


export const tags = {
  account: 'account',
  chain: 'chain',
  connection: 'connection'
} as const;

export const api = createApi({
  reducerPath: 'api/web3',
  baseQuery: web3BaseQuery(),
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({
    connect: builder.mutation<string, {client: Web3ClientName, chain?: Chain}>({
      query: ({client, chain}) => async () => {
        await wagmi.connect({chain, connector: client});
        const {address} = await wagmi.getAccount();
        return address;
      },
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
          wagmi.watchAccount(({address}) => {
            if (!address) dispatch(api.util.invalidateTags([tags.account, tags.connection, tags.chain]));
            else dispatch(api.util.invalidateTags([tags.account]))
          });
          wagmi.watchNetwork(() => dispatch(api.util.invalidateTags([tags.account, tags.chain])));
        } catch {}
      },
      invalidatesTags: [tags.account, tags.chain, tags.connection]
    }),
    fetchBalance: builder.query<string, {account: string, currency: Currency, chain: Chain}>({
      query: ({account, currency, chain}) => async () => {
        if (currency !== Currency.USDT) throw new Error('Unsupported currency');
        const contract = contracts.USDT({chain});
        return contract.fetchBalance(account);
      }
    }),
    fetchDomains: builder.query<{id: string, name: string}[], {account: string}>({
      query: ({account}) => async () => {
        const contract = contracts.DomainNFT(Chain.BinanceSmartChain);
        return contract.fetchDomains(account)
      },
    }),
    fetchDomainName: builder.query<string, string>({
      query: tokenId => async () => {
        const contract = contracts.DomainNFT(Chain.BinanceSmartChain);
        return contract.fetchDomainName(tokenId);
      },
    }),
    fetchTokenId: builder.query<string, {domainName: string}>({
      query: ({domainName}) => async () => {
        const contract = contracts.DomainNFT(Chain.BinanceSmartChain);
        return contract.fetchTokenId(domainName);
      },
    }),
    getAccount: builder.query<string, void>({
      query: () => async () => {
        const {address} = await wagmi.getAccount();
        return address ?? null;
      },
      providesTags: [tags.account]
    }),
    getChainName: builder.query<Chain | null, void>({
      query: () => async () => {
        const chainName = await wagmi.getNetwork();
        return chainName ?? null;
      },
      providesTags: [tags.chain]
    }),
    getPersonalSign: builder.query<string, string>({
      query: (message: string) => async () => await wagmi.signMessage(message)
    }),
    isConnected: builder.query<boolean, void>({
      query: () => wagmi.isConnected,
      providesTags: [tags.connection]
    }),
    mintToken: builder.mutation<string, {domainName: string, chain: Chain}>({
      query: ({domainName, chain}) => async () => {
        const contract = contracts.DomainMarket(chain);
        const { hash } = await contract.mintToken(domainName);
        return hash;
      },
    }),
    setContent: builder.mutation<string, {tokenId: string, content: string, contentProvider: string}>({
      query: ({tokenId, content, contentProvider}) => async () => {
        const contract = contracts.ContentProvider(Chain.BinanceSmartChain, contentProvider as Web3Address);
        const { hash } = await contract.setContent(tokenId, content);
        return hash;
      },
    }),
    setContentRoute: builder.mutation<string, {tokenId: string, route: string}>({
      query: ({tokenId, route}) => async () => {
        const contract = contracts.DomainNFT(Chain.BinanceSmartChain);
        const { hash } = await contract.setContentRoute(tokenId, route)
        return hash;
      },
    }),
    switchChain: builder.mutation<void, Chain>({
      query: chain => async () => {
        await wagmi.switchNetwork(chain);
        return null;
      },
      invalidatesTags: [tags.account, tags.chain, tags.connection]
    }),
    transfer: builder.mutation<string, {recipient: string, amount: string, currency: Currency, chain: Chain}>({
      query: ({recipient, amount, currency, chain}) => async () => {
        if (currency !== Currency.USDT) throw new Error('Unsupported currency');
        const contract = contracts.USDT({chain});
        const { hash } = await contract.transfer(recipient, amount);
        return hash;
      }
    }),
    waitForTransaction: builder.query<TxReceipt, {chain: Chain, transactionHash: string}>({
      query: ({chain, transactionHash}) => async () => await wagmi.waitForTransaction(chain, transactionHash as Web3Hash),
      transformResponse: decodeWith(TxReceiptCodec)
    })
  }),
});

export const {
  useConnectMutation,
  useFetchBalanceQuery,
  useFetchDomainNameQuery,
  useFetchDomainsQuery,
  useFetchTokenIdQuery,
  useGetAccountQuery,
  useGetChainNameQuery,
  useGetPersonalSignQuery,
  useIsConnectedQuery,
  useMintTokenMutation,
  useSetContentMutation,
  useSetContentRouteMutation,
  useSwitchChainMutation,
  useTransferMutation,
  useWaitForTransactionQuery,
} = api
