import {Currency} from "shared/types";
import {useDispatch, useSelector} from 'hooks';
import {actions as orderActions, selectors as orderSelectors} from '../../store';
import {PaymentStatus} from "../../store/model/Payment";
import SendTransaction from "./SendTransaction";
import AwaitTransaction from "./AwaitTransaction";
import {useEffect} from "react";
import RegisterPayment from "./RegisterPayment";
import {Chain, ChainGuard} from "features/web3";


export default function PayWithCrypto(props: {orderId: number, amount: string, currency: Currency, chain: Chain}) {
  const {orderId, amount, currency, chain} = props;
  const dispatch = useDispatch();
  const payment = useSelector(orderSelectors.selectPayment);

  useEffect(() => {
    if (payment.status === PaymentStatus.UNINITIALIZED || payment.orderId !== orderId) dispatch(orderActions.payment.init({orderId, amount, currency, chain}))
  }, []);

  return <ChainGuard chain={chain}>{(() => {
    switch (payment.status) {
      case PaymentStatus.SENDING_TRANSACTION:
        return <SendTransaction recipient={payment.recipient} amount={payment.amount} currency={payment.currency} chain={payment.chain}/>
      case PaymentStatus.AWAITING_TRANSACTION:
        return <AwaitTransaction transactionHash={payment.transactionHash} chain={payment.chain}/>
      case PaymentStatus.REGISTERING_PAYMENT:
        return <RegisterPayment orderId={payment.orderId} transactionHash={payment.transactionHash} paymentSystem={payment.paymentSystem} currency={payment.currency} chain={payment.chain}/>
      default:
        return null;
    }
  })()}</ChainGuard>
}
