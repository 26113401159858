import {TemplateDescriptor} from "../types/Template";
import {TemplateName} from "../types/TemplateName";
import {ComingSoon} from "../templates/ComingSoon";
import {ForSale} from "../templates/ForSale";
import {Profile} from "../templates/Profile";
import {renderToStaticMarkup} from "react-dom/server";
import {ServerStyleSheet} from 'styled-components';
import {ThemeProvider} from "../components/Theme/ThemeProvider";


export function prepareMarkup(template: TemplateDescriptor) {
	const sheet = new ServerStyleSheet();
	try {
		const markup = (
			<ThemeProvider theme={template.props.theme}>
				{(() => { switch (template.name) {
					case TemplateName.ComingSoon: return <ComingSoon isEdit={false} props={template.props}/>
					case TemplateName.ForSale: return <ForSale isEdit={false} props={template.props}/>
					case TemplateName.Profile: return <Profile isEdit={false} props={template.props}/>
				} })()}
			</ThemeProvider>
		)
		const html = renderToStaticMarkup(sheet.collectStyles(markup));
		const styles = sheet.getStyleTags();
		const idx = html.indexOf('</head>');
		return `<!DOCTYPE html>${html.slice(0, idx)}${styles}${html.slice(idx)}`;
	} finally {
		sheet.seal();
	}
}