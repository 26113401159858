import {useEffect, useState} from "react";
import {SerializedError} from "@reduxjs/toolkit";
import {RequestWrapper} from "components";
import {serializeError} from "shared/util";
import {useUploadMutation} from "../store/api";

export function UploadBundle(props: {tokenId: string, html: string, assets: string | undefined}) {
	const {tokenId, html, assets} = props;
	const [upload, {isLoading, error}] = useUploadMutation();
	const [data, setData] = useState<FormData>();
	useEffect(() => { if (data) upload(data) }, [data]);
	if (!data) return (
		<GetFormData tokenId={tokenId} html={html} assets={assets} onData={setData}/>
	);
	return (
		<RequestWrapper action={() => upload(data)} isLoading={isLoading} error={error}>
			{{
				action: { button: null },
				loader: { description: 'Uploading data', button: null }
			}}
		</RequestWrapper>
	);
}

function GetFormData (props: {tokenId: string, html: string, assets: string | undefined, onData: (data: FormData) => void}) {
	const {tokenId, html, assets, onData} = props;
	const [getData, {data, isLoading, error}] = useGetFormData(tokenId, html, assets);
	useEffect(() => { getData() }, []);
	useEffect(() => { if (data) onData(data) }, [data]);
	return (
		<RequestWrapper action={() => getData()} isLoading={isLoading} error={error}>
			{{
				action: { button: null },
				loader: { description: 'Preparing data', button: null }
			}}
		</RequestWrapper>
	);
}

function useGetFormData(tokenId: string, html: string, assets?: string): [() => void, {data?: FormData, isLoading: boolean, error?: SerializedError}] {
	const [data, setData] = useState<FormData>();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<SerializedError>();
	async function get() {
		try {
			setIsLoading(true);
			const data = new FormData();
			data.append('tokenId', tokenId);
			const htmlFile = await fetch(html)
				.then(result => result.blob())
				.then(blobFile => new File([blobFile], "index.html", { type: "text/html" }));
			data.append('index', htmlFile);
			if (assets) {
				const assetsFile = await fetch(assets)
					.then(result => result.blob())
					.then(blobFile => new File([blobFile], "assets.zip", { type: "application/zip" }));
				data.append('zip', assetsFile);
			}
			setData(data);
		} catch (e) {
			setError(serializeError(e));
		} finally {
			setIsLoading(false);
		}
	}
	return [get, {data, isLoading, error}];
}