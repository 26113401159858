import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'hooks';
import './ClaimPage.scss'
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import {Navigate, useSearchParams} from "react-router-dom";
import {selectClaimSaga} from "./store/selectors";
import {ClaimSagaStatus} from "./store/types";
import RequestAddress from "./components/RequestAddress";
import SendRequest from "./components/SendRequest";
import AwaitFulfillment from "./components/AwaitFulfillment";
import {PATHS} from "../../router/paths";
import Loader from "../../components/loader/Loader";
import {actions as claimActions} from './store';
import GetTemporaryToken from "./components/GetTemporaryToken";
import SignMessage from "./components/SignMessage";
import AssignAddress from "./components/AssignAddress";

export default function ClaimPage () {

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const state = useSelector(selectClaimSaga);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code && 'code' in state && state.code !== code) {
      dispatch(claimActions.cancelSaga({code}));
    }
    if (code && state.status === ClaimSagaStatus.UNINITIALIZED) {
      searchParams.delete('code');
      setSearchParams(searchParams);
      dispatch(claimActions.createSaga({code}));
    }
  },[state])

  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className="claim__wrapper">
          {(() => { switch (state.status) {
            case ClaimSagaStatus.SENDING_TRANSACTION: return <RequestAddress/>
            case ClaimSagaStatus.AWAITING_TRANSACTION: return <SendRequest address={state.address} uuid={state.code}/>
            case ClaimSagaStatus.REGISTERING_PAYMENT: return <GetTemporaryToken address={state.address}/>
            case ClaimSagaStatus.AWAITING_EMAIL_REGISTRATION_SIGNATURE: return <SignMessage temporaryToken={state.temporaryToken}/>
            case ClaimSagaStatus.ASSIGNING_ADDRESS: return <AssignAddress signature={state.signature} temporaryToken={state.temporaryToken} code={state.code}/>
            case ClaimSagaStatus.AWAITING_FULFILLMENT: return <AwaitFulfillment id={state.id}/>
            case ClaimSagaStatus.FULFILLED: return <Navigate to={PATHS.congratulations.index}/>
            default: return <div className="claim__inner"><div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div></div>
          }})()}
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );

}
