import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import styled from "styled-components";

export default function DocumentsPage() {
  const code = `
    const Web3 = require('web3');
    const ethers = require('ethers');
    const BSC_PROVIDER = 'https://data-seed-prebsc-1-s1.binance.org:8545';
    const NFT_ABI = [
      'function getTokenIdByDomainName(string calldata domainName) external view returns (uint256)',
      'function ownerOf(uint256 tokenId) public view virtual override returns (address)'
    ];
    const NFT_ADDRESS = '0xDC3F9F9fD554Fdf4a28795Df81Dc2Cb35F22CE54';
    const w3dnaNFTContract = (web3provider) => {
      return new ethers.Contract(NFT_ADDRESS, NFT_ABI, web3provider);
    };
    async function getTokenIdByDomainName(web3Provider, domainName) { 
      const stakingContract = w3dnaNFTContract(web3Provider);
      return await stakingContract.getTokenIdByDomainName(domainName);
    }
    async function ownerOf(web3Provider, tokenId) {
      const stakingContract = w3dnaNFTContract(web3Provider);
      return await stakingContract.ownerOf(tokenId);
    }
    async function getDomainNameOwnerAddress(web3Provider, domainName) {
      return await getTokenIdByDomainName(web3Provider, domainName).then(tokenId => {
        return ownerOf(web3Provider, tokenId);
      });
    }
    const web3 = new Web3(BSC_PROVIDER);
    const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    // Take owner address by domain name and print it
    getDomainNameOwnerAddress(provider, "blockwit").then(console.log);
  `;
  return (
    <BaseLayout>
      <BaseLayout.Body>
        <Wrapper>
          <h1>How to get domain name's owner address</h1>
          <p>Draft 1.0</p>
          <ul>
            <li><a href="#introduction">Introduction</a></li>
            <li><a href="#algorythm">Common algorithm</a></li>
            <li><a href="#example">Node.js example</a>
              <ul>
                <li><a href="#input">Input data</a></li>
                <li><a href="#preparations">Preparations</a></li>
                <li><a href="#code">Code</a></li>
              </ul>
            </li>
          </ul>
          <h2 id="introduction">Introduction</h2>
          <p>Before You start, please ask W3DNA developers for NFT contract address. Specify in which network you want to work (BNB Chain Mainnet or BNB Chain Testnet).</p>
          <p>Current addresses of contracts (production):
            <ul>
              <li><a href="https://bscscan.com/address/0xe1e538acCAD918C2F5924fbebbd11867Ff89F473#code" target="_blank" rel="noreferrer">BNB Chain Mainnet</a></li>
              <li><a href="https://etherscan.io/address/0x2E84A0351447A3eCED8D9F47a65F32EFcE237f8A#code" target="_blank" rel="noreferrer">Ethereum Mainnet</a></li>
              <li><a href="https://polygonscan.com/address/0x604fC029e73f340bAAabfEECA61651508690d653" target="_blank" rel="noreferrer">Polygon Mainnet</a></li>
            </ul>
          </p>
          <p>In the following examples we use the contract deployed in BNB Chain Testnet: <a href="https://testnet.bscscan.com/address/0xdc3f9f9fd554fdf4a28795df81dc2cb35f22ce54#readContract" target="_blank" rel="noreferrer">0xdc3f9f9fd554fdf4a28795df81dc2cb35f22ce54</a>.</p>
          <p>User has sent You a domain name. Ok. Let’s determine the owner's address</p>
          <h2 id="algorythm">Common algorithm</h2>
          <ol>
            <li>Get token id of domain name by calling method <code>getTokenIdByDomainName(domainName)</code> of NFT contract.</li>
            <li>Call ownerOf(tokenId)</li>
          </ol>
          <h2 id="example">Node.js example</h2>
          <h3 id="input">Input data</h3>
          <p>NFT contract address: 0xdc3f9f9fd554fdf4a28795df81dc2cb35f22ce54</p>
          <h3 id="preparations">Preparations</h3>
          <p>
            <code>npm install web3 ethers</code>
          </p>
          <h2 id="code">Code</h2>
          <pre>{code}</pre>
        </Wrapper>
      </BaseLayout.Body>
    </BaseLayout>
  );
}

const Wrapper = styled.div`
  margin: 2rem auto;
  max-width: 768px;
  h1,h2,h3 {
    margin-bottom: 1rem;
  }
  ul, ol, p {
    margin-bottom: 2rem;
  }
  li {
    margin-left: 1rem;
  }
`