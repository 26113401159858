import {AppStartListening} from "store";
import {slice as salesApi} from "features/api/sales/slice";
import {api as web3Api, matchers as web3Matchers} from "features/web3/store";
import {actions as mintActions} from "./index";
import {MintSagaStatus} from "../types";
import isEqual from "lodash/isEqual";
import {ReplicationTaskStatus} from "../../api/sales/model/ReplicationTaskStatus";
import {isMintSagaUpdate} from "./matchers";
import {isAnyOf} from "@reduxjs/toolkit";
import {UserDomainNameInfoStatus} from "../../api/sales/model/UserDomainNameInfoStatus";


export default function(startListening: AppStartListening) {

  const step1 = isMintSagaUpdate(MintSagaStatus.INITIAL);
  startListening({
    matcher: step1,
    effect: async ({payload: {tokenId}}, api) => {
      const [action] = await api.take(isAnyOf(
        salesApi.endpoints.requestTokenReleaseStatus.matchFulfilled,
        salesApi.endpoints.requestTokenReleaseStatus.matchRejected
      ));
      if (!isEqual(action.meta.arg.originalArgs, {tokenId}) || !action.payload) return;
      if (salesApi.endpoints.requestTokenReleaseStatus.matchRejected(action)) {
        api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_ADDRESS, tokenId}));
        return;
      }
      const {status, address, chain, domainName} = action.payload.data;
      if (status === ReplicationTaskStatus.PURCHASED) {
        api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_ADDRESS, tokenId}));
      } else if (status === ReplicationTaskStatus.WRITING_MINT_RIGHTS) {
        api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_RELEASE, tokenId, address}));
      } else if (status === ReplicationTaskStatus.READY_FOR_MINT) {
        api.dispatch(mintActions.mint.update({status: MintSagaStatus.SENDING_TRANSACTION, tokenId, address, domainName, chain}));
      } else if (status === ReplicationTaskStatus.MINTED) {
        api.dispatch(mintActions.mint.update({status: MintSagaStatus.FULFILLED, tokenId}));
      }
    }
  });

  const step2 = isMintSagaUpdate(MintSagaStatus.AWAITING_ADDRESS);
  startListening({
    matcher: step2,
    effect: async ({payload: {tokenId}}, api) => {
      const [{payload: {address}}] = await api.take(mintActions.mint.setAddress.match);
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.SENDING_RELEASE_REQUEST, address, tokenId}));
    }
  });

  const step3 = isMintSagaUpdate(MintSagaStatus.SENDING_RELEASE_REQUEST);
  startListening({
    matcher: step3,
    effect: async ({payload: {tokenId, address}}, api) => {
      await api.condition(action =>
        salesApi.endpoints.requestTokenRelease.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {address, tokenId})
      );
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_RELEASE, tokenId, address}));
    }
  });

  const step4 = isMintSagaUpdate(MintSagaStatus.AWAITING_RELEASE);
  startListening({
    matcher: step4,
    effect: async ({payload: {tokenId, address}}, api) => {
      const [{payload: {data: {chain, domainName}}}] = await api.take(action =>
        salesApi.endpoints.requestTokenReleaseStatus.matchFulfilled(action)
        && isEqual(action.meta.arg.originalArgs, {tokenId})
        && action.payload.data.status === ReplicationTaskStatus.READY_FOR_MINT
      );
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.SENDING_TRANSACTION, tokenId, address, chain, domainName}));
    }
  });

  const step5 = isMintSagaUpdate(MintSagaStatus.SENDING_TRANSACTION);
  startListening({
    matcher: step5,
    effect: async ({payload: {tokenId, address, chain, domainName}}, api) => {
      const [{payload: transactionHash}] = await api.take(action =>
        web3Api.endpoints.mintToken.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {domainName, chain})
      );
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_TRANSACTION, tokenId, transactionHash, chain}));
    }
  })

  const step6 = isMintSagaUpdate(MintSagaStatus.AWAITING_TRANSACTION);
  startListening({
    matcher: step6,
    effect: async ({payload: {tokenId, transactionHash}}, api) => {
      await api.condition(web3Matchers.isSuccessfulTx(transactionHash));
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.AWAITING_CONFIRMATION, tokenId}));
    }
  });

  const step7 = isMintSagaUpdate(MintSagaStatus.AWAITING_CONFIRMATION);
  startListening({
    matcher: step7,
    effect: async ({payload: {tokenId}}, api) => {
      await api.condition(action => {
        if (!salesApi.endpoints.fetchDomains.matchFulfilled(action)) return false;
        const token = action.payload.data.domainNameInfos.find(({id}) => id === Number(tokenId));
        return token?.status === UserDomainNameInfoStatus.MINTED;
      });
      api.dispatch(mintActions.mint.update({status: MintSagaStatus.FULFILLED, tokenId}));
    }
  });

}
