import {BackgroundWidget} from "../components/BackgroundWidget/BackgroundWidget";
import {ContactsWidget} from "../components/ContactsWidget";
import {LayoutWidget} from "../components/LayoutWidget";
import {ForSaleTemplateProps, TemplateComponentProps} from "../types/Template";
import styled from "styled-components";
import {Asset} from "../types/Asset";
import {backgrounds} from "../components/BackgroundWidget/backgrounds";


export const defaultProps: ForSaleTemplateProps = {
	contacts: [],
	domainName: '',
	background: Asset.fromUrl(backgrounds[4].url),
	theme: backgrounds[4].theme
}

export function ForSale(props: TemplateComponentProps<ForSaleTemplateProps>) {
	const {isEdit, props: fields} = props;
	const handlePropChange = (prop: Partial<ForSaleTemplateProps>) => {
		if ('onPropsChange' in props) props.onPropsChange({...fields, ...prop})
	}
	return (
		<Body isEdit={isEdit} background={fields.background} title={`${fields.domainName} is for sale`}>
			<Wrapper>
				<Heading>
					<div>The domain name</div>
					<DomainName>{fields.domainName}</DomainName>
					<CurrentlyForSale>is currently for sale!</CurrentlyForSale>
				</Heading>
				<ContactOwner>Contact  owner:</ContactOwner>
				<ContactsWidget isEdit={isEdit} theme={fields.theme} contacts={fields.contacts} onChange={contacts => handlePropChange({contacts})}/>
				<BackgroundButton isEdit={isEdit} theme={fields.theme} onChange={(background, theme) => handlePropChange({background, theme})}/>
			</Wrapper>
		</Body>
	)
}
const Body = styled(LayoutWidget)`
  display: grid;
  grid-template-rows: 38fr min-content 62fr;	
`
const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  grid-row-start: 2;
  max-width: 38rem;
  text-align: center;
`
const Heading = styled.h1`
	font-size: 2rem;
	color: ${({theme}) => theme.palette.text.primary}
`
const DomainName = styled.div`
	padding: 1rem;
	margin-top: 3.5rem;
	margin-bottom: 2rem;
	min-width: 25rem;
	font-size: 1.5rem;
	background-color: ${({theme: {name, palette}}) => name === 'dark' ? 'rgba(255,255,255,.3)' : palette.primary.main};
	backdrop-filter: ${({theme: {name}}) => name === 'dark' ? 'blur(50px)' : undefined};
	color: ${props => props.theme.palette.common.white};
	border-radius: .875rem;
`
const CurrentlyForSale = styled.div`
	margin-bottom: 2.5rem;
	font-size: 1.5rem;
`
const ContactOwner = styled.div`
	margin-bottom: 2rem;
	color: ${({theme}) => theme.palette.text.primary}
`
const BackgroundButton = styled(BackgroundWidget)`
	margin-top: 2rem;
`