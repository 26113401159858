import {Field} from "react-final-form";
import {useState} from "react";
import styles from './PasswordField.module.scss';
import clsx from "clsx";

export function PasswordField(props: {name: string}) {
  const [isVisiblePassword, setVisiblePassword] = useState(false);
  return (
    <Field name={props.name}>
      {({input, meta}) => (
        <div className={styles.wrapper}>
          <input {...input} type={isVisiblePassword ? "text" : "password"} placeholder="*********" className={styles.input}/>
          <div className={clsx(styles.icon, isVisiblePassword && styles.icon_show)} onClick={() => setVisiblePassword(!isVisiblePassword)}/>
          {(meta.error || meta.submitError) && meta.touched &&
            <div className={styles.feedback}>{meta.error || meta.submitError}</div>}
        </div>
      )}
    </Field>
  );
}