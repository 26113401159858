import * as t from "io-ts";
import {Utils} from "../../util/util";
import {Chains} from "./Chains";
import {UserDomainNameInfoStatus} from "./UserDomainNameInfoStatus";

export const UserDomainNameInfoCodec = t.type({
  id: t.number,
  domainName: t.string,
  chain: Utils.enum<Chains>(Chains),
  status: Utils.enum<UserDomainNameInfoStatus>(UserDomainNameInfoStatus),
  tokenId: t.union([t.string, t.undefined])
});

export type UserDomainNameInfo = t.TypeOf<typeof UserDomainNameInfoCodec>;
