import React from 'react'
import styled from "styled-components";

import commentsList from "./commentsList";
import WhatOthersSayCard from "./WhatOthersSayCard";

import Slider from '../../../../components/slider/Slider'

export default function WhatOthersSay() {
    const [navs, setNavs] = React.useState([
        {name: 'People',active: true},
        {name: 'Media',active: false},
        {name: 'KOLs',active: false}
    ]);

    function updateNav(name: string) {
        setNavs(
            navs.map((nav) => {
                nav.active = nav.name === name
                return nav
            })
        )
    }

    const slides = commentsList.map((comment, index) => {
        return (
            <WhatOthersSayCard {...comment} key={index} />
        )
    })

    return (
        <Block>
            <Title>
                What others say
            </Title>
            {/*<Nav>*/}
            {/*    { navs.map((nav) => {*/}
            {/*        return (*/}
            {/*            <div className={ nav.active ? 'active' : '' } onClick={()=>updateNav(nav.name)}>{nav.name}</div>*/}
            {/*        )*/}
            {/*    }) }*/}
            {/*</Nav>*/}
            <Slider slides={slides} id="what_others_say" />
        </Block>
    )
}


const Nav = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-bottom: 3em;

    @media (max-width: 775px) {
        gap: 6vw;
    }
    
    & div {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em;
        text-align: center;
        color: #62626A;
        color: white;
        padding: 2px 15px;
        border-radius: 26px;
        cursor: pointer;
        
        &.active {
            color: #62626A;
            background: #FFFFFF;
        }
        
        &:hover {
            opacity: 0.8;
        }
    }
`

const Title = styled.div`
    font-family: Fira Sans;
    font-size: 5em;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
`

const Block = styled.div`
    background: #6A35FF;
    padding-block: 4em;
    color: white;
    
    @media (max-width: 775px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 2.5vw;
    }
`
