import React from 'react';
import Button from "components/button/Button";
import ModalLayout from "components/layout/ModalLayout/ModalLayout";
import {Form} from "react-final-form";
import TextInput from "../../account/components/TextInput";
import {isAddress} from "features/web3";
import {useDispatch} from 'hooks';
import {actions as claimActions} from '../store';

export default function RequestAddress () {

  const dispatch = useDispatch();
  const handleFormSubmit = async (values: {[k: string]: any}) => {
    const {address} = values;
    dispatch(claimActions.setAddress({address}))
  }

  const validateForm = (values: {[k: string]: any}) => {
    const errors: {[k: string]: any} = {};
    if (!values.address) {
      errors.address = 'This field is equired';
    } else if (!isAddress(values.address)) {
      errors.address = 'Invalid Ethereum address ';
    }
    return errors;
  }

  return (
    <ModalLayout>
      <div className='claim__inner'>
        <p>Thank you for using your credit card to purchase the web3 domain.</p>
        <p>Please enter your ethereum address below.</p>
        <Form onSubmit={handleFormSubmit} validate={validateForm} render={(props) => {
          const {submitError, handleSubmit, submitting} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextInput name='address' label='ETH address' placeholder='0xa...89'/>
              {submitError && <div className="">{submitError}</div>}
              <div style={{textAlign: 'center'}}>
                <Button type="submit" disabled={submitting} shape='squared'>Submit</Button>
              </div>
            </form>
          );
        }}/>
      </div>
    </ModalLayout>
  );

}
