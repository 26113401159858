import styled from "styled-components";
import {SearchItem} from "../types/SearchItem";
import {SearchResult} from "./SearchResult";


type SuggestionsProps = {
  hasError: boolean
  suggestions: SearchItem[]
  isInCart: (name: string) => boolean
  onClickAddToCart: (item: SearchItem) => void
  className?: string
}

export function Suggestions(props: SuggestionsProps) {
  return (
    <div className={props.className}>
      {props.hasError ? (
        <Title>But wait! Check out this collection of related domains you may like, or refine your search and try again.</Title>
      ) : (
        <Title>Make sure to register related domains you may like:</Title>
      )}
      <List>
        {props.suggestions.map((suggestion, key) =>
        <Item key={key}>
          <SearchResult item={suggestion} isInCart={props.isInCart} onClickAddToCart={() => props.onClickAddToCart(suggestion)}></SearchResult>
        </Item>
        )}
      </List>
    </div>
  )
}

const Title = styled.div`
  margin-bottom: .5rem;  
`
const List = styled.ul`
  list-style: none;
`
const Item = styled.li`
  margin-bottom: .5rem;
`
