import React from "react";
import {useSafeNavigate} from 'hooks';
import {PATHS} from "router/paths";
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import styles from "./ErrorPage.module.scss";


export function Error404Page() {
  const navigate = useSafeNavigate();
  const handleClickSearch = () => navigate(PATHS.search.index());
  return (
    <BaseLayout>
      <BaseLayout.Body>
        <div className={styles.container}>
          <h1 className={styles.title}>404</h1>
          <div className={styles.text}>Page Not Found</div>
          <div className={styles.img}></div>
          <div className={styles.text}>But you can find and purchase your perfect domain</div>
          <button onClick={handleClickSearch} className={styles.button}>Search domain</button>
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  );
}
