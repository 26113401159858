import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';


export interface ProtectedRouteInterfacte {
  predicate: (() => boolean) | boolean
  redirect: string | React.ReactElement
}

export default function ProtectedRoute(props: ProtectedRouteInterfacte): React.ReactElement {
  const location = useLocation();
  const { predicate, redirect } = props;
  if ((typeof predicate === 'function' && predicate()) || predicate) {
    return <Outlet/>;
  } else if (typeof redirect === 'string') {
    return <Navigate to={redirect} state={{from: location.pathname}}/>;
  } else {
    return (redirect);
  }
};
