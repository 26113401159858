import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from "../util/axios";
import {RootState} from "store";
import {AuthStatus} from "features/auth/store/types";


export const api = createApi({
  reducerPath: 'api/gateway',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/gateway',
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as RootState).auth;
      if (auth.status === AuthStatus.FULFILLED) headers.set('authorization', `Bearer ${auth.token}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    isWebsiteOnline : builder.query<boolean, {domainName: string}>({
      query :({domainName}) => ({url : `content?domainName=${domainName}`, method : 'head'}),
      transformResponse: () => true
    }),
  })
});

export const {
  useIsWebsiteOnlineQuery
} = api;