import {selectAddressAssignmentState} from "../store/selectors";
import {AddressAssignmentStatus} from "../types";
import React from "react";
import {InitAddressAssignment} from "./InitAddressAssignment";
import {RequestAddress} from "./RequestAddress";
import {GetTemporaryToken} from "./GetTemporaryToken";
import {SignMessage} from "./SignMessage";
import {SendSignature} from "./SendSignature";
import {useSelector} from "store/hooks";
import {Congratulations} from "./Congratulations";


export function AssignAddress() {
	const state = useSelector(selectAddressAssignmentState);
	switch (state.status) {
		case AddressAssignmentStatus.REQUESTING_ADDRESS:
			return <RequestAddress/>
		case AddressAssignmentStatus.AWAITING_TOKEN:
			return <GetTemporaryToken address={state.address}/>
		case AddressAssignmentStatus.AWAITING_SIGNATURE:
			return <SignMessage token={state.token}/>
		case AddressAssignmentStatus.SENDING_SIGNATURE:
			return <SendSignature signature={state.signature} token={state.token}/>
		case AddressAssignmentStatus.FULFILLED:
			return <Congratulations address={state.address}/>
		default:
			return <InitAddressAssignment/>
	}
}