import './ReferralProgram.scss';
import React, {Fragment, MouseEvent, useEffect} from "react";
import SidebarLayout from "../../../components/layout/SidebarLayout/SidebarLayout";
import {
  useAssignAddressWithAuthMutation,
  useFetchAccountQuery,
  useFetchReferralDataQuery,
  useFetchReferralsQuery,
  useGetTemporaryTokenQuery
} from "../../api/sales/slice";
import {useAuth, useDispatch, useSafeNavigate, useSelector, useWeb3} from 'hooks';
import {useGetPersonalSignQuery} from "features/web3/store";
import {selectAddressAssignmentState} from "../store/selectors";
import {AddressAssignmentStatus} from "../store/types";
import Button from "components/button/Button";
import {PATHS} from "router/paths";
import {actions as accountActions} from '../store';
import {formatToken, parseWei} from "features/web3/util";

export default function ReferralProgram() {
  const {subject} = useAuth();
  const {data: account} = useFetchAccountQuery({id: subject?.id!}, {skip: !subject?.id});

  const address = subject?.address || account?.data.address || undefined;
  const {data, isFetching} = useFetchReferralDataQuery({accountId: subject?.id!}, {skip: !address});
  const link = window && data ? `${window.location.protocol}//${window.location.host}?referrer=${data.data.referralId}` : undefined;

  const handleClickLink = (link: string) => (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigator.clipboard.writeText(link);
  }

  return (
    <SidebarLayout>
      <div className="referral-content">
        <h1>Referral Program</h1>
        <RenderAddress address={address}/>
        <div className="referral-content__attention">
          Attention: all referral rewards will be transferred
          in USDT(BEP20)
        </div>
        {link &&
          <div className="referral-link">
            <div className="referral-link__label">Your referral link:</div>
            <div className="referral-link__field-wrapper">
              <input type="text" className="referral-link__field" disabled value={link}/>
              <button type='button' className="referral-link__copy-btn" onClick={handleClickLink(link)}/>
            </div>
          </div>
        }
        <div style={{borderTop: 'none'}} className="referral-link-message">Upon purchase with your referral link you receive 20% from all the purchases made with your referral link.</div>
        <RenderReferrals accountId={subject?.id}/>
      </div>
      {/*<div className="myprofile__container">
        { (isAccountLoading || isDataLoading) ? (
          <div style={{display: 'flex', justifyContent: 'center'}}><Loader size='2rem'/></div>
        ) : link ? (
          <Fragment>
            <p>Share your referral link with your friends</p>
            <p style={{marginBottom: '3rem'}}>When they purchase a domain using your link, we'll transfer 5% of it's value to your ETH account</p>
            <a onClick={handleClickLink(link)} href={link}>{link}</a>
          </Fragment>
        ) : null }
      </div>*/}
    </SidebarLayout>
  );
}

function RenderAddress(props: { address: string | undefined }) {
  const state = useSelector(selectAddressAssignmentState);
  return (
    <div className="referral-form">
      <div className="referral-form__label">Your wallet for referral rewards:</div>
      {(() => {
        if (props.address) return <div>{props.address}</div>
        else switch (state.status) {
          case AddressAssignmentStatus.UNINITIALIZED:
            return <InitAddressAssignment/>
          case AddressAssignmentStatus.SENDING_TRANSACTION:
            return <RequestAddress/>
          case AddressAssignmentStatus.AWAITING_TOKEN:
            return <GetTemporaryToken address={state.address}/>
          case AddressAssignmentStatus.AWAITING_SIGNATURE:
            return <SignMessage token={state.token}/>
          case AddressAssignmentStatus.SENDING_SIGNATURE:
            return <SendSignature signature={state.signature} token={state.token}/>
          case AddressAssignmentStatus.FULFILLED:
            return <div>{state.address}</div>
        }
      })()}

      {/*<div className="referral-form__field-row">
          <input type="text" className="referral-form__field" placeholder="Indicate your EVM compatible wallet"/>
          <button className="referral-form__btn"></button>
        </div>*/}

    </div>
  )
}

function RenderReferrals(props: { accountId?: number }) {
  const {accountId} = props;
  const {referrals} = useFetchReferralsQuery({accountId: accountId!}, {
    skip: !accountId,
    selectFromResult: ({data}) => ({referrals: data?.data.referrals || []}),
    pollingInterval: 3000
  });
  const totalPurchased = referrals.reduce((sum, current) => sum.add(current.purchasedAmount), parseWei('0'));
  const totalCommission = referrals.reduce((sum, current) => sum.add(current.commissionAmount), parseWei('0'));

  if (!referrals.length) return null;

  return (
    <div className="referral-stat-block">
      <div className="referral-stat-block__header">
        <div className="referral-stat-block__total-referrals">Total Referrals: <span>{referrals.length}</span></div>
        <div className="referral-stat-block__paid-commissions">Paid Commissions: <span>${formatToken(totalCommission)}</span></div>
      </div>
      <div className="referral-stat-block__table-wrapper">
        <table className="referral-stat-block__table">
          <thead>
            <tr>
              <th className="referral-stat-block__table-head-cell _num">#</th>
              <th className="referral-stat-block__table-head-cell">Registration <br/> time</th>
              <th className="referral-stat-block__table-head-cell">Wallet/Email/Username</th>
              <th className="referral-stat-block__table-head-cell _align-right">Purchased <br/> amount</th>
              <th className="referral-stat-block__table-head-cell _align-right">Your <br/> commission</th>
            </tr>
          </thead>
          <tbody>
            {referrals.map(({createTime, referralName, purchasedAmount, commissionAmount}, index) => (
              <tr key={index}>
                <td className="referral-stat-block__table-cell _num">{index + 1}</td>
                <td className="referral-stat-block__table-cell _nowrap">{(new Date(createTime)).toLocaleString()}</td>
                <td className="referral-stat-block__table-cell">{referralName}</td>
                <td className="referral-stat-block__table-cell _align-right">${formatToken(purchasedAmount)}</td>
                <td className="referral-stat-block__table-cell _align-right">${formatToken(commissionAmount)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="referral-stat-block__table-cell _num _total">Total</td>
              <td className="referral-stat-block__table-cell _nowrap"></td>
              <td className="referral-stat-block__table-cell"></td>
              <td className="referral-stat-block__table-cell _align-right _bold">${formatToken(totalPurchased)}</td>
              <td className="referral-stat-block__table-cell _align-right _bold">${formatToken(totalCommission)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="referral-stat-block__mobile-message">Detailed report on your activity is available in the desktop version</div>
    </div>
  )
}

function InitAddressAssignment() {
  const dispatch = useDispatch();
  return <Button onClick={() => dispatch(accountActions.assignAddress.init())}> Assign address to my account</Button>
}

function RequestAddress() {
  const {address} = useWeb3();
  const navigate = useSafeNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!address) navigate(PATHS.connect);
    else dispatch(accountActions.assignAddress.setAddress({address}));
  }, [address])
  return <Button onClick={() => navigate(PATHS.connect)}>Connect to WEB3</Button>
}

function GetTemporaryToken(props: { address: string }) {
  const {isConnected} = useWeb3();
  const navigate = useSafeNavigate();
  const {isFetching, error, refetch} = useGetTemporaryTokenQuery(props.address, {skip: !isConnected});
  useEffect(() => {
    if (!isConnected) navigate(PATHS.connect)
  }, [isConnected])
  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '1rem'}}>{error.message}</div>
      <div><Button type="button" onClick={refetch} disabled={isFetching}>Try again</Button></div>
    </Fragment>
  );
  return null;
}

function SignMessage(props: { token: string }) {
  const {isFetching, error, refetch} = useGetPersonalSignQuery(props.token);
  return (
    <div>
      {error ? (
        <Fragment>
          <div>Something went wrong</div>
          <div style={{marginBottom: '2rem'}}>{error.code}</div>
        </Fragment>
      ) : (
        <div style={{marginBottom: '2rem'}}>Please, sign message to continue</div>
      )}
      <div><Button type="button" onClick={refetch} disabled={isFetching}>Sign message</Button></div>
    </div>
  );
}

function SendSignature(props: { signature: string, token: string }) {
  const {signature, token} = props
  const [assignAddress, {isLoading, error}] = useAssignAddressWithAuthMutation();
  useEffect(() => {
    assignAddress({signature, token})
  }, []);
  return (
    <div>
      {error ? (
        <Fragment>
          <div>Something went wrong</div>
          <div style={{marginBottom: '2rem'}}>{error.message}</div>
        </Fragment>
      ) : (
        <div style={{marginBottom: '2rem'}}>Please, sign message to continue</div>
      )}
      <div><Button type="button" onClick={() => assignAddress({signature, token})} disabled={isLoading}>Try again</Button></div>
    </div>
  );
}
