export const TargetName = {
	ChooseAccount: 'ChooseAccount',
	ChooseDomain: 'ChooseDomain',
	ChooseName: 'ChooseName',
	Domain: 'Domain',
	DownloadForLinux: 'DownloadForLinux',
	DownloadForMac: 'DownloadForMac',
	DownloadForWin: 'DownloadForWin',
	LogIn: 'LogIn',
	Search: 'Search',
	SignUp: 'SignUp'
} as const

export type TargetName = typeof TargetName[keyof typeof TargetName]