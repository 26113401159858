import {createSlice} from "@reduxjs/toolkit";
import {ModalState, ModalStatus} from "./types";
import {open, close} from "./actions";


const initialState: ModalState = {
  status: ModalStatus.CLOSED
}

const slice = createSlice({
  name: 'modal',
  initialState: initialState as ModalState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(open, (state, {payload: {name, params}}) => {
      return {status: ModalStatus.OPENED, name, params};
    })
    .addCase(close, () => {
      return {status: ModalStatus.CLOSED};
    })
});

export const { actions, reducer } = slice;
