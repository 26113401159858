export const UploadSagaStatus = {
	INITIAL: 'INITIAL',
	AWAITING_BUNDLE: 'AWAITING_BUNDLE',
	UPLOADING_BUNDLE: 'UPLOADING_BUNDLE',
	UPLOADING_CUSTOM_BUNDLE: 'UPLOADING_CUSTOM_BUNDLE',
	SENDING_CONTENT_TX: 'SENDING_CONTENT_TX',
	AWAITING_CONTENT_TX: 'AWAITING_CONTENT_TX',
	SENDING_ROUTE_TX: 'SENDING_ROUTE_TX',
	AWAITING_ROUTE_TX: 'AWAITING_ROUTE_TX',
	FULFILLED: 'FULFILLED'
} as const;

export type UploadSagaStatus = typeof UploadSagaStatus[keyof typeof UploadSagaStatus]
