import {useEffect} from 'react';
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useAccount, useSafeNavigate} from 'hooks';
import SidebarLayout from "components/layout/SidebarLayout/SidebarLayout";
import {useFetchDomainsQuery} from "features/api/sales/slice";
import {UploadPage as UploadPageComponent} from "../components/UploadPage";
import {RequestWrapper} from "../../../../components";
import {PATHS} from "../../../../router/paths";


export function UploadPage() {
	const navigate = useSafeNavigate();
	const {id} = useParams();
	const [search] = useSearchParams();
	const template = search.get('template') ?? undefined;
	const account = useAccount();
	const {domainName, tokenId, isLoading} = useFetchDomainsQuery(undefined, {
		selectFromResult: ({data, isLoading}) => {
			const {domainName, tokenId} = (data?.data.domainNameInfos ?? []).find(domain => domain.id === Number(id)) ?? {};
			return {domainName, tokenId, isLoading};
		}
	});
	const error = !account?.address ? {message: 'Account address not found'} : (!domainName || !tokenId) ? {message: 'Domain not found'} : undefined;

	useEffect(() => { if (!id) navigate(-1) }, []);

	if (isLoading || !account?.address || !domainName || !tokenId) return (
		<SidebarLayout style={{display: 'flex'}}>
			<RequestWrapper isLoading={isLoading} error={error}>
				{{loader: {description: null}, error: {button: null}}}
			</RequestWrapper>
		</SidebarLayout>
	)
	return (
		<SidebarLayout>
			<h1>Upload Content</h1>
			{/*<p>You can set link to external content <Link to={PATHS.account.domains.setExternalContent(id ?? '')}>here</Link></p>*/}
			{/*<br/>*/}
			<UploadPageComponent account={account.address} tokenId={tokenId} domainName={domainName} template={template}/>
		</SidebarLayout>
	);
}
