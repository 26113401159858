export const Web3ErrorName = {
	InvalidInput: 'InvalidInput',
	ResourceNotFound: 'ResourceNotFound',
	ResourceUnavailable: 'ResourceUnavailable',
	TransactionRejected: 'TransactionRejected',
	MethodNotSupported: 'MethodNotSupported',
	LimitExceeded: 'LimitExceeded',
	Parse: 'Parse',
	InvalidRequest: 'InvalidRequest',
	MethodNotFound: 'MethodNotFound',
	InvalidParams: 'InvalidParams',
	Internal: 'Internal',
	UserRejectedRequest: 'UserRejectedRequest',
	Unauthorized: 'Unauthorized',
	UnsupportedMethod: 'UnsupportedMethod',
	Disconnected: 'Disconnected',
	ChainDisconnected: 'ChainDisconnected',
	UnrecognizedChainId: 'UnrecognizedChainId',
} as const

export type Web3ErrorName = typeof Web3ErrorName[keyof typeof Web3ErrorName]
