import React, {useEffect} from 'react';
import {AuthStatus, AuthType} from "../store/types";
import {useSelector} from 'hooks';
import {selectors as authSelectors} from '../store';
import {useSignInWithOTPMutation} from "features/api/sales/slice";
import Button from "../../../components/button/Button";

export function OneTimePassword () {
  const state = useSelector(authSelectors.selectAuthState);
  const [signIn, {error}] = useSignInWithOTPMutation();
  const isCorrectState = ('type' in state) && state.type === AuthType.OTP && state.status === AuthStatus.AWAITING_TOKEN;

  useEffect(() => {
    if (!isCorrectState) return;
    signIn({oneTimePassword: state.oneTimePassword});
  }, [isCorrectState]);

  if (!isCorrectState) return null;

  if (error) return (
    <div style={{textAlign: 'center'}}>
      <div style={{marginBottom: '2rem'}}>{error?.message}</div>
      <Button onClick={() => signIn({oneTimePassword: state.oneTimePassword})}>Retry</Button>
    </div>
  );

  return null;

}
