import React, {useEffect} from 'react';
import Button from "../../../components/button/Button";
import {useAssignAddressMutation} from "../../api/sales/slice";

export default function AssignAddress (props: {signature: string, temporaryToken: string, code: string}) {
  const {signature, temporaryToken, code} = props;
  const [assignAddress, {error}] = useAssignAddressMutation();

  useEffect(() => {
    assignAddress({signature, temporaryToken, code});
  },[])

  const onClickSumbit = () => {
    assignAddress({signature, temporaryToken, code});
  }

  if (error) return (
    <div className="claim__inner">
      <div style={{marginBottom: '2rem'}}>{error?.message}</div>
      <Button onClick={onClickSumbit}>Retry</Button>
    </div>
  );

  return (
    <div className="claim__inner"/>
  );

}
