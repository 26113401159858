import React from 'react'
import styled from "styled-components";

export default function CardBody(props: React.PropsWithChildren) {
	return (
		<CardElement className="slider-card">
			{ props.children }
		</CardElement>
	)
}

const CardElement = styled.div`
	width: 331px;
	height: 418px;
	background: #FFFFFF;
	border-radius: 13px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 95%;
	max-height: 135vw;
	margin: auto;

	@media (min-width: 1000px) AND (max-width: 1250px) {
		height: 380px;
	}

	@media (min-width: 776px) AND (max-width: 999px) {
		height: 280px;
	}

	@media (max-width: 400px) {
		max-width: 95vw;
		height: 120vw;
	}
`
