import React, {useState} from 'react';
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import './BlogPageItem.css';
import {PATHS} from "../../router/paths";
import Loader from "../../components/loader/Loader";
import {useParams} from "react-router-dom";
import Share, {Socials} from "../../components/share/Share";
import {getBlogArticle} from "../blog/BlogApi";
import FormattedDate from "../../components/date/FormattedDate";
import BlogSubscribeForm from "../blog/BlogSubscribeForm";
import {useSafeNavigate} from 'hooks';

export function BlogPageItem() {
  const navigate = useSafeNavigate();
  const [content, setContent] = useState<any>(undefined);

  let {path} = useParams();

  if (!content)
    getBlogArticle(path || "").then(resp => {
      if (!resp)
        return navigate(PATHS.error_404);

      setContent(resp);
    });

  return (
      <BaseLayout>
        <BaseLayout.Body>
          {content ? renderContent(content) : renderLoader()}
        </BaseLayout.Body>
      </BaseLayout>
  );
}

function renderContent(info: any) {
  return (
      <div className="blog-item-wrap">
        <div className="inner">
          <a href={PATHS.blog} className="back-button">
            BACK TO BLOG POSTS
          </a>

          <div className="blog-item-content">
            <div className="blog-item-content__header">
              <div className="section">
                {info.categories.map((i: { name: string }) => i.name).sort().join(', ')}
              </div>
              <div className="date">
                <FormattedDate date={info.datetime} lang={info.lang}/>
                {info.datetime_updated &&
                  <>•‎ Last updated on <FormattedDate date={info.datetime_updated} lang={info.lang}/></>}
              </div>
            </div>

            <div className="title">
              {info.name}
            </div>

            {info.subtitle &&
              <div className="subtitle">
                {info.subtitle}
              </div>
            }
            <div className="preview" style={{backgroundImage: `url(${info.image.url})`}}></div>

            <div className="blog-item-content-half">
              <div className="info">
                <div className="text" dangerouslySetInnerHTML={{__html: info.html}}></div>
              </div>

              <div className="share">
                <div className="share__title">Share this article:</div>
                <div className="share__items">
                  <Share className="share__item _tg" social={Socials.tg}/>
                  <Share className="share__item _tw" social={Socials.tw}/>
                  <Share className="share__item _in" social={Socials.in}/>
                  {/*<Share className="share__item _fb" social={Socials.fb} />*/}
                </div>
              </div>

              <BlogSubscribeForm/>

              <div className="recommended-posts">
                <div className="recommended-posts__title">RECOMMENDED POSTS</div>

                <div className="posts">

                  {info.recommendations.map((item: any) =>
                      <div key={item.id} className="item-wrap">
                        <a className="item" href={PATHS.blog_item.replace(':path', item.path)}>
                          <div className="item__preview" style={{backgroundImage: `url(${item.image.thumb})`}}></div>
                          <div className="item__info">
                            <div className="item__title">{item.name}</div>
                            <div className="item__intro">{item.description}</div>
                            <div className="item__date"><FormattedDate date={item.datetime}/></div>
                          </div>
                        </a>
                      </div>
                  )}

                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
  );
}

function renderLoader() {
  return (
      <div className="blog-item-loader">
        <Loader size='50px'/>
      </div>
  );
}
