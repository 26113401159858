import React from 'react';
import {Route, Routes} from "react-router-dom";
import {PATHS} from "./paths";
import HomePage from "../features/home/HomePage";
import CartPage from "../features/cart/CartPage";
import {Connect} from "features/web3";
import MyDomains from "../features/account/MyDomains/MyDomains";
import MyProfile from "../features/account/MyProfile/MyProfile";
import DummyPage from "../features/dummy/DummyPage";
import ProtectedRoute from "./ProtectedRoute";
import ExternalRedirect from "./ExternalRedirect";
import PartnersPage from "../features/partners/PartnersPage";
import ScrollToTop from "./ScrollToTop";
import OrderPage from "../features/order/OrderPage";
import {BlogPage} from "../features/blog/BlogPage";
import {BlogPageItem} from "../features/blog-page-item/BlogPageItem";
import {Error404Page} from "../features/error/Error404Page";
import {UploadPage} from "features/website/upload";
import BrowserRouter from "./BrowserRouter";
import ReferralProgram from "../features/account/ReferralProgram/ReferralProgram";
import MyCoupons from "../features/account/MyCoupons/MyCoupons";
import WelcomePage from "../features/welcome/WelcomePage";
import ClaimPage from "../features/claim/ClaimPage";
import CongratulationsPage from "../features/congratulations/CongratulationsPage";
import {LogInPage, SignUpPage} from "features/auth/AuthPage";
import {useAuth} from 'hooks';
import RegisterEmail from "../features/account/RegisterEmail/RegisterEmail";
import ConfirmEmail from "../features/account/ConfirmEmail/ConfirmEmail";
import SignUp from "../features/auth/signup/SignUp";
import {MintDomain} from "features/mint";
import ChangePassword from "../features/auth/password/ChangePassword";
import {Terms} from "../features/documents/Terms";
import {PrivacyPolicy} from "../features/documents/PrivacyPolicy";
import ModalRoot from "../features/modal/ModalRoot";
import {PageEditor} from "../features/website/constructor/pages/PageEditor";
import {SearchPage} from "../features/search";
import {CookiesModal} from "../features/cookies/Cookies";
import DocumentsPage from "../features/misc/DocumentsPage";
import {Success} from "../features/auth/signup/Success";
import {SetExternalContentPage} from "../features/website/upload/pages/SetExternalContentPage";
import Settings from "../features/account/Settings/Settings";
import {ResetPassword} from "features/account/password/ResetPassword";
import {PasswordOutlet} from "features/account/password/PasswordOutlet";

export default function Router() {
	const { isAuthorized } = useAuth();

	return (
			<BrowserRouter>
				<ScrollToTop/>
				<Routes>
					<Route path={PATHS.root.index} element={<HomePage/>}/>
					<Route path={PATHS.welcome} element={<WelcomePage/>}/>
					<Route path={PATHS.congratulations.index} element={<CongratulationsPage/>}/>
					<Route path={PATHS.partners} element={<PartnersPage/>}/>
					<Route path={PATHS.investors} element={<DummyPage/>}/>
					<Route path={PATHS.developers} element={<DummyPage/>}/>
					<Route path={PATHS.cart} element={<CartPage/>}/>
					<Route path={PATHS.connect} element={<Connect/>}/>
					<Route path={PATHS.documents} element={<DocumentsPage/>}/>
					<Route path={PATHS.sales_forecast} element={<ExternalRedirect link={PATHS.external_sales_forecast}/>}/>
					<Route path={PATHS.presentation} element={<ExternalRedirect link={PATHS.external_presentation}/>}/>
					<Route path={PATHS.blog} element={<BlogPage/>}/>
					<Route path={PATHS.blog_item} element={<BlogPageItem/>}/>
					<Route path={PATHS.auth.index} element={<LogInPage/>}/>
					<Route path={PATHS.signup.index} element={<SignUp/>}/>
					<Route path={PATHS.account.register.email.confirm.index} element={<ConfirmEmail/>}/>
					<Route path={PATHS.account.password.change.index} element={<PasswordOutlet isAuthorized={isAuthorized}/>}/>
					<Route path={PATHS.account.password.recover.index} element={<ResetPassword/>}/>
					<Route path={PATHS.search.index()} element={<SearchPage/>}/>
					<Route path={PATHS.signup.page} element={<SignUpPage/>}/>
					<Route path={PATHS.signup.success} element={<Success/>}/>
					<Route path={PATHS.terms} element={<Terms/>}/>
					<Route path={PATHS.policy} element={<PrivacyPolicy/>}/>
					<Route element={<ProtectedRoute predicate={isAuthorized} redirect={PATHS.auth.index}/>}>
						<Route path={PATHS.account.profile.index} element={<MyProfile/>}/>
						<Route path={PATHS.account.domains.index} element={<MyDomains/>}/>
						<Route path={PATHS.account.coupons.index} element={<MyCoupons/>}/>
						<Route path={PATHS.account.referralprogram.index} element={<ReferralProgram/>}/>
						<Route path={PATHS.account.settings.index} element={<Settings/>}/>
						<Route path={PATHS.account.register.email.index} element={<RegisterEmail/>}/>
						<Route path={PATHS.account.domains.build(':id')} element={<PageEditor/>}/>
						<Route path={PATHS.account.domains.mint(':id')} element={<MintDomain/>}/>
						<Route path={PATHS.account.domains.setExternalContent(':id')} element={<SetExternalContentPage/>}/>
						<Route path={PATHS.account.domains.upload(':id')} element={<UploadPage/>}/>
						<Route path={PATHS.order.index} element={<OrderPage/>}/>
					</Route>
					<Route path={PATHS.claim.index} element={<ClaimPage/>}/>
					<Route path={PATHS.all} element={<Error404Page/>}/>
				</Routes>
				<ModalRoot/>
				<CookiesModal/>
			</BrowserRouter>
	);
}
