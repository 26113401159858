import {RootState} from "store";
import {Order} from "./model/Order";
import {Payment} from "./model/Payment";

export function selectOrder(state: RootState): Order | undefined {
  return state.order.order;
}

export function selectPayment(state: RootState): Payment {
  return state.order.payment;
}
