import {Fragment, useEffect} from "react";
import {Currency} from "shared/types";
import {Button} from "components";
import {useRegisterCryptoPaymentMutation} from "features/api/sales/slice";
import {PaymentSystem} from "shared/model";
import {Chain} from "features/web3";


export default function RegisterPayment(props: {orderId: number, transactionHash: string, paymentSystem: PaymentSystem, currency: Currency, chain: Chain}) {
  const {orderId, transactionHash, paymentSystem, currency, chain} = props;
  const [sendRequest, {error}] = useRegisterCryptoPaymentMutation();
  useEffect(() => {
    sendRequest({orderId, transactionHash, paymentSystem, currency, chain});
  }, [])

  if (error) return (
    <Fragment>
      <div>Something went wrong</div>
      <div style={{marginBottom: '2rem'}}>{error.message}</div>
      <Button onClick={() => sendRequest({orderId, transactionHash, paymentSystem, currency, chain})}>Try again</Button>
    </Fragment>
  );
  return null;
}
