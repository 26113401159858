import {RequestWrapper} from "components";
import {useSetContentMutation} from "features/web3";
import {Fragment, useEffect} from "react";

export function SendContentTransaction(props: {tokenId: string, content: string, contentProvider: string}) {
	const {tokenId, content, contentProvider} = props;
	const [setContent, {error, isLoading}] = useSetContentMutation();
	useEffect(() => { setContent({tokenId, content, contentProvider}) }, [])
	return (
		<RequestWrapper action={() => setContent({tokenId, content, contentProvider})} isLoading={isLoading} error={error}>
			{{
				action: null,
				loader: { description: <Fragment>Sending transaction (1 of 2)<br/>Please open your wallet and click the "Confirm" button</Fragment>, button: null }
			}}
		</RequestWrapper>
	)
}