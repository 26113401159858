import {createAction} from "@reduxjs/toolkit";
import {SetExternalContentSaga, UploadSaga} from "../types";


export const upload = {
  update: createAction<UploadSaga>('website/upload/update'),
  delete: createAction<string>('website/upload/delete')
};

export const setExternalContent = {
  update: createAction<SetExternalContentSaga>('website/setExternalContent/update'),
  delete: createAction<string>('website/setExternalContent/delete')
};

export const uploadBundle = createAction<{domainName: string, html: string, assets?: string}>('website/upload/bundle');