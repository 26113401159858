import isEqual from "lodash/isEqual";
import {api as uploadAPI} from "./api";
import {api as web3Api, matchers as web3Matchers} from "features/web3";
import {AppStartListening} from "store";
import {actions as uploadActions} from "./index";
import {SetExternalContentSagaStatus, UploadSagaStatus} from "../types";
import {isSetExternalContentSagaUpdate, isUploadSagaUpdate} from "./matchers";


export default function(startListening: AppStartListening) {
  startListeningUploadSaga(startListening);
  startListeningSetExternalContentSaga(startListening);
}

function startListeningUploadSaga(startListening: AppStartListening) {
  const step1 = isUploadSagaUpdate(UploadSagaStatus.INITIAL)
  startListening({
    matcher: step1,
    effect: async ({payload: {tokenId, template}}, api) => {
      if (!template) api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.UPLOADING_CUSTOM_BUNDLE, tokenId}));
      else api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.AWAITING_BUNDLE, tokenId, template}));
    }
  })

  const step2 = isUploadSagaUpdate(UploadSagaStatus.AWAITING_BUNDLE)
  startListening({
    matcher: step2,
    effect: async ({payload: {tokenId}}, api) => {
      const [{payload: {html, assets}}] = await api.take(uploadActions.uploadBundle.match);
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.UPLOADING_BUNDLE, assets, html, tokenId}));
    }
  })

  startListening({
    matcher: uploadActions.uploadBundle.match,
    effect: async ({payload: {domainName, html, assets}}, api) => {
      const {data: tokenId} = await api.dispatch(web3Api.endpoints.fetchTokenId.initiate({domainName}));
      if (!tokenId) return;
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.UPLOADING_BUNDLE, assets, html, tokenId}));
    }
  })

  const step3a = isUploadSagaUpdate(UploadSagaStatus.UPLOADING_BUNDLE)
  startListening({
    matcher: step3a,
    effect: async ({payload: {tokenId}}, api) => {
      const [{payload: {content, contentProvider, nft, route}}] = await api.take(action =>
        uploadAPI.endpoints.upload.matchFulfilled(action) && action.meta.arg.originalArgs.get('tokenId') === tokenId
      );
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.SENDING_CONTENT_TX, content, contentProvider, nft, route, tokenId}));
    }
  })

  const step3b = isUploadSagaUpdate(UploadSagaStatus.UPLOADING_CUSTOM_BUNDLE)
  startListening({
    matcher: step3b,
    effect: async ({payload: {tokenId}}, api) => {
      const [{payload: {content, contentProvider, nft, route}}] = await api.take(action =>
        uploadAPI.endpoints.upload.matchFulfilled(action) && action.meta.arg.originalArgs.get('tokenId') === tokenId
      );
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.SENDING_CONTENT_TX, content, contentProvider, nft, route, tokenId}));
    }
  })

  const step4 = isUploadSagaUpdate(UploadSagaStatus.SENDING_CONTENT_TX)
  startListening({
    matcher: step4,
    effect: async ({payload: {tokenId, content, contentProvider, nft, route}}, api) => {
      const [{payload: transactionHash}] = await api.take(action =>
        web3Api.endpoints.setContent.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {tokenId, content, contentProvider})
      );
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.AWAITING_CONTENT_TX, tokenId, content, contentProvider, nft, route, transactionHash}));
    }
  })

  const step5 = isUploadSagaUpdate(UploadSagaStatus.AWAITING_CONTENT_TX)
  startListening({
    matcher: step5,
    effect: async ({payload: {tokenId, content, contentProvider, nft, route, transactionHash}}, api) => {
      await api.condition(web3Matchers.isSuccessfulTx(transactionHash));
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.SENDING_ROUTE_TX, tokenId, content, contentProvider, nft, route}));
    }
  })

  const step6 = isUploadSagaUpdate(UploadSagaStatus.SENDING_ROUTE_TX)
  startListening({
    matcher: step6,
    effect: async ({payload: {tokenId, content, contentProvider, nft, route}}, api) => {
      const [{payload: transactionHash}] = await api.take(action =>
        web3Api.endpoints.setContentRoute.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {tokenId, route})
      );
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.AWAITING_ROUTE_TX, tokenId, content, contentProvider, nft, route, transactionHash}));
    }
  })

  const step7 = isUploadSagaUpdate(UploadSagaStatus.AWAITING_ROUTE_TX)
  startListening({
    matcher: step7,
    effect: async ({payload: {tokenId, content, contentProvider, nft, route, transactionHash}}, api) => {
      await api.condition(web3Matchers.isSuccessfulTx(transactionHash));
      api.dispatch(uploadActions.upload.update({status: UploadSagaStatus.FULFILLED, tokenId, content, contentProvider, nft, route}));
    }
  })
}

function startListeningSetExternalContentSaga(startListening: AppStartListening) {
  const step1 = isSetExternalContentSagaUpdate(SetExternalContentSagaStatus.INITIAL)
  startListening({
    matcher: step1,
    effect: async ({payload: {tokenId}}, api) => {
      const [{payload: {nft, route}}] = await api.take(action =>
        uploadAPI.endpoints.setExternalUrl.matchFulfilled(action) && action.meta.arg.originalArgs.tokenId === tokenId
      );
      api.dispatch(uploadActions.setExternalContent.update({status: SetExternalContentSagaStatus.SENDING_ROUTE_TX, nft, route, tokenId}));
    }
  })

  const step2 = isSetExternalContentSagaUpdate(SetExternalContentSagaStatus.SENDING_ROUTE_TX)
  startListening({
    matcher: step2,
    effect: async ({payload: {tokenId, nft, route}}, api) => {
      const [{payload: transactionHash}] = await api.take(action =>
        web3Api.endpoints.setContentRoute.matchFulfilled(action) && isEqual(action.meta.arg.originalArgs, {tokenId, route})
      );
      api.dispatch(uploadActions.setExternalContent.update({status: SetExternalContentSagaStatus.AWAITING_ROUTE_TX, tokenId, nft, route, transactionHash}));
    }
  })

  const step3 = isSetExternalContentSagaUpdate(SetExternalContentSagaStatus.AWAITING_ROUTE_TX)
  startListening({
    matcher: step3,
    effect: async ({payload: {tokenId, nft, route, transactionHash}}, api) => {
      await api.condition(web3Matchers.isSuccessfulTx(transactionHash));
      api.dispatch(uploadActions.setExternalContent.update({status: SetExternalContentSagaStatus.FULFILLED, tokenId, nft, route}));
    }
  })
}
