import React from "react";
import {AuthType, OAuthData} from "../store/types";
import {FacebookLogin} from './facebook/component';
import {SignUpButton} from "./SignUpButton";
import {ReactFacebookFailureResponse, ReactFacebookLoginInfo} from "./facebook/types";

export function FacebookAuthButton (props: {
  onClick: (authType: typeof AuthType.FACEBOOK) => void
  onSuccess: (data: OAuthData) => void
  onError?: (error: any) => void
}) {

  const handleCallback = (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if ('accessToken' in response) {
      props.onSuccess({authType: AuthType.FACEBOOK, id: response.id, accessToken: response.accessToken})
    } else {
      props.onError?.(response);
    }
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
      autoLoad={false}
      fields="name,email,picture"
      onClick={() => props.onClick(AuthType.FACEBOOK)}
      callback={handleCallback}
    >{({onClick, isDisabled, isProcessing, isSdkLoaded}) =>
      <SignUpButton
        onClick={onClick}
        disabled={isDisabled || !isSdkLoaded || isProcessing}
        textButton="Continue with Facebook" imageUrl="/assets/img/cont-fb-icon.svg"
      />
    }</FacebookLogin>
  );
}
