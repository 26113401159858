import React from "react";
import useGoogleLogin from "./google_auth"
import {AuthType, OAuthData} from "../store/types";
import {SignUpButton} from "./SignUpButton";

export function GoogleAuthButton(props: {
  onClick: (authType: typeof AuthType.GOOGLE) => void,
  onSuccess: (data: OAuthData) => void
  onFailure?: (authType: typeof AuthType.GOOGLE, error: any) => void
}) {

  const handleSuccess = (response : any) : void => {
    if ('tokenId' in response) props.onSuccess({authType: AuthType.GOOGLE, tokenId: response.tokenId})    
  }

  const handleFailure = (error: any) => {
    props.onFailure?.(AuthType.GOOGLE, error);
  }
  
  const {signIn, loaded } = useGoogleLogin(        {
    onSuccess : handleSuccess,
    onFailure : handleFailure,
    onRequest : () => props.onClick(AuthType.GOOGLE),
    onScriptLoadFailure : handleFailure,
    clientId : process.env.REACT_APP_GOOGLE_CLIENT_ID!,
    scope : 'profile email',
    accessType : 'online',
    prompt : '',    
    cookiePolicy : 'single_host_origin',
    uxMode: 'popup',
    isSignedIn : false,    
    responseType : "",
    discoveryDocs : "https://docs.googleapis.com/$discovery/rest?version=v1",
    hostedDomain : "",
    fetchBasicProfile : true,
    redirectUri : "",
    loginHint : ""
  });  
  return <SignUpButton textButton="Continue with Google" imageUrl="/assets/img/cont-gmail-icon.svg" disabled={!loaded} onClick={signIn}/>;   
 
}