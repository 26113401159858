import React, {Fragment} from "react";
import {useChangePasswordMutation} from "../../api/sales/slice";
import {FORM_ERROR} from "final-form";
import {Form, FormRenderProps} from "react-final-form";
import {PasswordField} from "./components/PasswordField";
import styles from './Opened.module.scss';
import clsx from "clsx";
import {hasEthereum, isMobile, Web3ClientName} from "../../web3";

export function Opened(props: { code: string, onSuccess: () => void, onRequestClose: () => void }) {
    const {code, onSuccess, onRequestClose} = props;
    const [changePassword] = useChangePasswordMutation();

    const handleFormSubmit = async (values: FormValues) => {
        const {newPassword} = values;
        const result = await changePassword({code, newPassword});
        if ('error' in result) return {[FORM_ERROR]: result.error.message};
        onSuccess();
    }

    const RenderForm = (props: FormRenderProps<FormValues>) => {
        const {submitError, handleSubmit, submitting} = props;
        return (
            <form onSubmit={handleSubmit}>
                <div>New Password</div>
                <PasswordField name="newPassword"/>
                <div>Retype new password</div>
                <PasswordField name="confirmPassword"/>
                {submitError && <div style={{marginTop: '1rem', marginBottom: '2rem'}}>{submitError}</div>}
                <ul className={styles.buttons}>
                    <li>
                        <button type="submit" disabled={submitting} className={styles.button}>Save</button>
                    </li>
                    <li>
                        <button onClick={onRequestClose} className={clsx(styles.button, styles.cancel)}>Cancel</button>
                    </li>
                </ul>
            </form>
        )
    }

    const validateForm = (values: FormValues) => {
        const errors: FormErrors = {};
        if (!values.newPassword) errors.newPassword = 'This field is required';
        if (!values.confirmPassword) errors.newPassword = 'This field is required';
        if (values.newPassword !== values.confirmPassword) errors.confirmPassword = 'Passwords do not match';
        return errors;
    }

    return (
        <Fragment>
            <div className="wrapper">
                <div className="summary">
                    <div>Password</div>
                    <div className="fake-password"><span>*********</span></div>
                    <div className="edit-button">Edit</div>
                </div>
                <div className="change-password-form-wrapper">
                    <div className="content">
                        <Form onSubmit={handleFormSubmit} validate={validateForm} render={RenderForm}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export type FormValues = {
    newPassword: string
    confirmPassword: string
}

export type FormErrors = {
    newPassword?: string
    confirmPassword?: string
}