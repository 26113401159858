import {Chain} from "features/web3/model";

export type MintSaga = {
  status: typeof MintSagaStatus.INITIAL
  tokenId: string
} | {
  status: typeof MintSagaStatus.AWAITING_ADDRESS
  tokenId: string
} | {
  status: typeof MintSagaStatus.SENDING_RELEASE_REQUEST
  tokenId: string
  address: string
} | {
  status: typeof MintSagaStatus.AWAITING_RELEASE
  tokenId: string
  address: string
} | {
  status: typeof MintSagaStatus.SENDING_TRANSACTION
  tokenId: string
  address: string
  domainName: string
  chain: Chain
} | {
  status: typeof MintSagaStatus.AWAITING_TRANSACTION
  tokenId: string
  transactionHash: string
  chain: Chain
} | {
  status: typeof MintSagaStatus.AWAITING_CONFIRMATION
  tokenId: string
} | {
  status: typeof MintSagaStatus.FULFILLED
  tokenId: string
}

export const MintSagaStatus = {
  INITIAL: 'INITIAL',
  AWAITING_ADDRESS: 'AWAITING_ADDRESS',
  SENDING_RELEASE_REQUEST: 'SENDING_RELEASE_REQUEST',
  AWAITING_RELEASE: 'AWAITING_RELEASE',
  SENDING_TRANSACTION: 'SENDING_TRANSACTION',
  AWAITING_TRANSACTION: 'AWAITING_TRANSACTION',
  AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
  FULFILLED: 'FULFILLED'
} as const;

export type MintSagaStatus = typeof MintSagaStatus[keyof typeof MintSagaStatus]
