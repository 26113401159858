import React, {HTMLAttributes, PropsWithChildren, useEffect, useRef} from 'react';
import {actions as modalActions} from './store';
import {useDispatch, useOutsideClick} from 'hooks';
import styles from './Modal.module.scss';
import ReactPortal from "../../components/portal/ReactPortal";


export default function ModalWindow (props: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
  const {children, ...restProps} = props;

  const dispatch = useDispatch();
  const nodeRef = useRef(null);

  const handleClose = () => {
    dispatch(modalActions.close());
  };

  useOutsideClick(nodeRef, () => {}); // fixme: replace it with handleClose

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === "Escape" ? handleClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, []);

  return (
    <ReactPortal wrapperId="modal">
      <div className={styles.overlay}></div>
      <div className={styles.wrapper}>
        <div className={styles.window} ref={nodeRef} {...restProps} >
          <button className={styles.close} onClick={handleClose}></button>
          {children}
        </div>
      </div>
    </ReactPortal>
  );
}
