import styled from "styled-components";
import {ChevronLeft, ChevronRight} from "../../../components/icon";
import {useSafeNavigate} from "../../../hooks";
import {PATHS} from "../../../router/paths";

export function Nav(props: {isGoToCartDisabled?: boolean, className?: string}) {
  const navigate = useSafeNavigate();
  return (
    <Wrapper className={props.className}>
      <Button type='button' onClick={() => navigate(-1)}><ChevronLeft/>&nbsp;back</Button>
      <Button type='button' onClick={() => navigate(PATHS.cart)} disabled={props.isGoToCartDisabled}>Go&nbsp;to&nbsp;cart&nbsp;<ChevronRight/></Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  font-weight: 700;
  background: none;
  color: #4C37CA;
  &:disabled {
    color: #a4a4a4;
  }
  &:hover:not(:disabled) {
    opacity: .75;
    cursor: pointer;
  }
  &:first-child {
    color: #a4a4a4;
  }
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`