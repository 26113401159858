import {useAssignAddressWithAuthMutation} from "../../../api/sales/slice";
import React, {useEffect} from "react";
import {Button, RequestWrapper} from "components";
import {SalesErrorName} from "../../../api/sales";
import {useDispatch} from "../../../../store/hooks";
import {actions} from '../store';
import {AddressAssignmentStatus} from "../types";


export function SendSignature(props: { signature: string, token: string }) {
	const {signature, token} = props
	const dispatch = useDispatch();
	const [assignAddress, {isLoading, error}] = useAssignAddressWithAuthMutation();
	const onClickChooseAnotherAddress = () => dispatch(actions.update({status: AddressAssignmentStatus.REQUESTING_ADDRESS}))
	useEffect(() => { assignAddress({signature, token}) }, []);
	return (
		<RequestWrapper isLoading={isLoading} error={error} action={() => assignAddress({signature, token})}>
			{{
				action: { button: 'Check signature' },
				loader: { description : 'Checking signature' },
				error: {
					button: ({error, action}) =>
						error.name === SalesErrorName.AddressAlreadyAssigned
						? <Button onClick={onClickChooseAnotherAddress}>Choose another address</Button>
						: <Button onClick={action}>Retry</Button>
				}
			}}
		</RequestWrapper>
	);
}
