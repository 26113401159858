import styled from "styled-components";
import {getWebsiteUrl} from "../../../website/util";
import {Copy} from "components/icon";
import placeholder from './assets/placeholder.jpg';
import {Button} from "../../../../components";


export function Preview(props: {domainName: string, className?: string}) {
  return (
    <Wrapper  className={props.className} >
      <Image domainName={props.domainName}/>
      <Title>{props.domainName}</Title>
      <Button disabled shape='icon'><Copy/></Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) min-content;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border: 1px solid #dcdcdc;
  border-radius: .5rem;
  color: #a4a4a4;
  overflow: hidden;
`

const Image = styled((props: {className?: string, domainName: string}) => (
  <a href={getWebsiteUrl(props.domainName)} className={props.className} target='_blank' rel='noreferrer'>
    <img src={placeholder} alt={props.domainName}/>
  </a>
))`
  position: relative;
  grid-column: span 2;
  padding-top: 40%;
  overflow: hidden;
  &:hover {
    opacity: .75;
  }
  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.div`
  margin: 1rem;
  color: black; 
  overflow: hidden;
  text-overflow: ellipsis;
`
