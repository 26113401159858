import './CartPage.scss'
import {useEffect} from 'react';
import {PATHS} from "../../router/paths";
import {default as RenderCartItem} from "./CartItem";
import {actions as cartActions, selectors as cartSelectors} from "./store";
import {actions as orderActions} from "features/order/store";
import {useAuth, useDispatch, useReferrer, useSafeNavigate, useSelector} from 'hooks';
import {useFetchPricesQuery} from "features/api/sales/slice";
import {CartItem} from "./store/model/CartItem";
import {formatToken, parseWei, useGetAccountQuery} from "features/web3";
import PaymentOptions from "./PaymentOptions";
import {PaymentOption} from "./store/model/PaymentOption";
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";
import Button from "../../components/button/Button";
import {DomainPriceStatus} from "features/api/sales/model";
import {CouponWidget} from "./components/CouponWidget";
import {CouponStatus} from "./store/model/CouponStatus";


export default function CartPage() {
  const dispatch = useDispatch();
  const navigate = useSafeNavigate();
  const {isAuthorized} = useAuth();
  const domainNames = useSelector(cartSelectors.selectDomainNames);
  const coupon = useSelector(cartSelectors.selectCoupon);
  const paymentOption = useSelector(cartSelectors.selectPaymentOption);
  const referrer = useReferrer();
  const {data: account} = useGetAccountQuery();
  const {domains, totalAmount, couponDiscount, discountedAmount, discountedAmountWithoutCoupon, discount, isLoading} = useFetchPricesQuery({
    domainNames,
    account,
    coupon: isAuthorized && coupon?.status === CouponStatus.VALID ? coupon.value : undefined,
    referrer
  }, {
    selectFromResult: ({data, error, isLoading}) => ({
      domains: data ? toCartItems(data.data.domainPrices) : [],
      totalAmount: data?.data.price || '0',
      discountedAmount: data?.data.priceWithDiscount || '0',
      discountedAmountWithoutCoupon: data?.data.priceWithoutPromo ?? '0',
      discount: data?.data.price !== '0' && data?.data.priceWithDiscount ? parseWei(data.data.price).sub(data.data.priceWithDiscount).mul(100).div(data.data.price).toNumber() : 0,
      couponDiscount: data?.data.couponDiscount && data.data.couponDiscount !== '0' ? data.data.couponDiscount : undefined,
      error,
      isLoading
    }),
    refetchOnMountOrArgChange: true
  });
  const canCreateOrder = domains?.length > 0

  const handleRemoveDomain = (domainName: string) => {
    dispatch(cartActions.removeDomain(domainName));
  }

  const handleChangePaymentOption = (paymentOption: PaymentOption) => {
    dispatch(cartActions.setPaymentOption(paymentOption));
  }

  const handleConfirmButton = () => {
    if (!canCreateOrder) return;

    dispatch(orderActions.createOrder({
      paymentOption,
      items: domains,
      totalAmount,
      discountedAmount,
      promoAmount: discountedAmountWithoutCoupon,
      coupon: isAuthorized && coupon?.status === CouponStatus.VALID ? coupon.value : undefined,
      referrer
    }));
    if (isAuthorized && coupon?.status === CouponStatus.VALID) dispatch(cartActions.coupon.clear());
    navigate(PATHS.order.index);
  }

  useEffect(() => {
    dispatch(cartActions.validate());
  }, [])

  return (
      <BaseLayout>
        <BaseLayout.Body>
          <div className="inner cart-modal">
            {!isLoading && domains && domains.length ?
                <div className="cart-modal__content">
                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">1</div>
                      Cart
                    </div>
                    <div className="added-items">
                      {domains.map(({name, price, discountedPrice}, i) =>
                          <RenderCartItem key={i} name={name} price={price || '0'} discountedPrice={discountedPrice}
                                          onClickRemove={handleRemoveDomain}/>)
                      }
                    </div>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">2</div>
                      Coupon/Promo code
                    </div>
                    <CouponWidget/>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="added-items-label">
                      <div className="added-items-label__counter">3</div>
                      Order summary
                    </div>
                    <div className="order-summary">
                      <div className="order-summary__item">
                        <div className="order-summary__item-name">
                          {domains.length}&nbsp;{domains.length > 1 ? 'domains' : 'domain'}
                        </div>
                        <div className="order-summary__item-price">
                          {totalAmount !== discountedAmountWithoutCoupon &&
                            <div className="order-summary__item-old-price">${formatToken(totalAmount)}</div>
                          }
                          <div className="order-summary__item-current-price">${formatToken(discountedAmountWithoutCoupon)}</div>
                        </div>
                      </div>
                      {couponDiscount &&
                        <div className="order-summary__item">
                          <div className="order-summary__item-name">Coupon</div>
                          <div className="order-summary__item-price">
                            <div className="order-summary__item-coupon-amount">-${formatToken(couponDiscount)}</div>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="total-price">
                      <div className="total-price__label">Total:</div>
                      <div>
                       <span className="full-price"
                             style={{display: (discount === 0 ? 'none' : 'inline')}}>${formatToken(totalAmount)}</span>
                        <span className="total-price-main">${formatToken(discountedAmount)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="cart-modal__content-block">
                    <div className="select-pay">
                      <PaymentOptions value={paymentOption} onChange={handleChangePaymentOption}/>
                    </div>
                  </div>

                  <div className="confirm-block">
                    <button type='button' onClick={handleConfirmButton} className="confirm-button"
                            disabled={!canCreateOrder}>BUY
                    </button>
                  </div>
                </div>
                :
                <EmptyCart/>
            }
          </div>
        </BaseLayout.Body>

      </BaseLayout>
  );
}

function EmptyCart() {
  const navigate = useSafeNavigate();
  const handleClickSearch = () => navigate(PATHS.search.index());
  return (
      <div className="cart-modal__empty">
        <h1>Your cart is empty!</h1>
        <div><Button shape="squared" onClick={handleClickSearch}>Search domain</Button></div>
      </div>
  )
}

function toCartItems(domains: { normalizedDomainName: string | undefined, price: string | undefined, priceWithDiscount: string | undefined, status: DomainPriceStatus }[]): CartItem[] {
  return domains.reduce((acc, {normalizedDomainName, price, priceWithDiscount, status}) => {
    if (typeof price === 'undefined' || typeof normalizedDomainName === 'undefined') return acc;
    return [...acc, {name: normalizedDomainName, price, discountedPrice: priceWithDiscount || price, isAvailable: status === DomainPriceStatus.FREE}];
  }, [] as CartItem[]);
}
