import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {PATHS} from "router/paths";

export default function HomeContentFAQ() {
  const [activeItem, setActiveItemState] = useState(0);

  const setActiveItem = (newActiveItem: number) => {
    setActiveItemState(activeItem === newActiveItem ? -1 : newActiveItem);
  }

  return (
      <div className="faq" id="faq">
        <div className="inner">
          <div className="faq__title">Frequently asked questions</div>
          <div className={'faq-tabs-content _is-active'}>
            {questions.map(({q, a}, idx) => (
              <div className="dropdown" key={idx}>
                <div className={'title ' + (activeItem === idx ? 'expanded' : '')} onClick={() => setActiveItem(idx)}>{q}</div>
                <div className={'text ' + (activeItem === idx ? 'expanded' : '')}>{a}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
  );
}

const questions: {q: string, a: JSX.Element}[] = [{
  q: 'What are the rules to participate in “Get 2 for the Price of 1”?',
  a: (
    <Fragment>
    <p>Every W3DNA Domain Check is Rewarded with the Same Worth Coupon!</p><br/>
    <p style={{marginBottom: '.5em'}}>Make sure you are eligible and follow these steps to get your free domains:</p>
      <ol>
        <li>Make a purchase using your bank card or wallet before June 1 (Purchases made with W3DNA coupons are not eligible).</li>
        <li>Use your coupon(s) to pay up to 100% for domains of your choice (Your coupon(s) will be available in your W3DNA account automatically after purchase).</li>
      </ol>
      <br/>
      <p>PN: There is no limit on the participating checks. Feel free to come back with new domain ideas as many times as you like. Hurry up to enjoy this wonderful offer</p>
    </Fragment>
  )
}, {
  q: 'What are the advantages of buying *W3DNA domain names?',
  a: (
    <div>
      *W3DNA domains will be registered in any language, even in hieroglyphs in any language and
      symbol with no extension and length limits.
    </div>
  )
}, {
  q: 'How can I use my *DNA?',
  a: (
    <div>
      <p>*W3DNA domain usage:</p>
      <p>- Resell the best domain names</p>
      <p>- Launch your own indexed sites</p>
      <p>- Receive payments in cryptocurrency to your domain from friends and clients</p>
      <p>- Create your personal pages in a decentralized social network</p>
      <p>- Communicating in the decentralized messenger</p>
    </div>
  )
}, {
  q: 'Why does *W3DNA have no extensions?',
  a: <div>The *W3DNA domain has no extension: Vitalik is simply better than Vitalik.eth</div>
}, {
  q: 'Are *W3DNA NFT domains part of IPFS or ICANN?',
  a: (
    <div>
      *W3DNA is an NFT domain in Web3, they are not part of the current DNS. *W3DNA protocol
      is a blockchain-built-in tool to index content that allows refuse IPFS and other bridging.
    </div>
  )
}, {
  q: 'What is the maximum length of a name I can register?',
  a: <div>*W3DNA domains will be registered with no length limit.</div>
}, {
  q: 'How can I get W3DNA coupons?',
  a: (
    <Fragment>
      There are different coupons for different actions:
      <ul>
        <li>Register on https://w3dna.net and get your first coupon, worth $5.</li>
        <li>Complete all social tasks on Gleam and get a $10 coupon.</li>
      </ul>
    </Fragment>
  )
}, {
  q: 'How can I buy a W3DNA domain?',
  a: (
    <Fragment>
      Find the full instruction on <a href="https://medium.com/@web3dna/how-to-mint-your-w3dna-domain-on-bsc-f5ea15be4bfc" target="_blank" rel="noreferrer">Medium</a> or <a href="https://youtu.be/w4yAeBbE8Kw" target="_blank" rel="noreferrer">YouTube</a>
    </Fragment>
  )
}, {
  q: 'If I log in from a mobile, which wallet should I use?',
  a: <Fragment>Buying a W3DNA domain using a mobile device is only available via WalletConnect.</Fragment>
}, {
  q: 'Are there any payment limits?',
  a: <Fragment>Unfortunately, there are. Due to some technical reasons, payments by debit cards are only possible for purchases starting from $5. If the domain price is equal or less than your coupon value, you can pay up to 100% of the price with it.</Fragment>
}, {
  q: 'Are there any geographical limitations for those who pay with a bank card?',
  a: (
    <Fragment>
      <p>Yes, unfortunately there are. Please find <a href="https://docs.google.com/spreadsheets/d/1i2t8KiGIGmv_Tc8GJ7lsrp0YOna2mA2lnzFpRKqxrps" target="_blank" rel="noreferrer">here</a> the list of the countries that are not supported by our payment partner wert.io.</p>
      <p>Please notice: if you are physically located in a country listed <a href="https://docs.google.com/spreadsheets/d/1i2t8KiGIGmv_Tc8GJ7lsrp0YOna2mA2lnzFpRKqxrps" target="_blank" rel="noreferrer">here</a>, you will not be able to make a payment, even with a card issued by a bank from a supported country (unless you use VPN).</p>
      <p>In case you find yourself under these limitations, please use your crypto funds.</p>
    </Fragment>
  )
}, {
  q: 'What should I do if the purchase is not going through?',
  a: <Fragment>Please check if you are a registered user. If you are not, create an account <Link to={PATHS.auth.index}>here</Link> and get your guaranteed registration coupon.</Fragment>
}]