import {Fragment} from "react";
import {getWebsiteUrl} from "../../util";

export function Congratulations(props: {domainName?: string}) {
	const {domainName} = props;
	return (
		<Fragment>
			<div>Congratulations!</div>
			<div>You've successfully uploaded your content to blockchain.</div>
			{domainName && <div>Please follow <a href={getWebsiteUrl(domainName)} target='_blank' rel='noreferrer'>this link</a> to see the result.</div>}
		</Fragment>
	)
}