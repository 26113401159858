import coupon_5 from './assets/coupon_5.svg';
import coupon_5_bg from './assets/coupon_5_bg.svg';
import coupon_5_sm from './assets/coupon_5_sm.svg';
import coupon_10 from './assets/coupon_10.svg';
import coupon_10_bg from './assets/coupon_10_bg.svg';
import coupon_10_sm from './assets/coupon_10_sm.svg';
import coupon_40 from './assets/coupon_40.svg';
import coupon_40_bg from './assets/coupon_40_bg.svg';
import coupon_40_sm from './assets/coupon_40_sm.svg';
import coupon_50 from './assets/coupon_50.svg';
import coupon_50_bg from './assets/coupon_50_bg.svg';
import coupon_50_sm from './assets/coupon_50_sm.svg';


type Coupon = {
  sm: string
  lg: string
  bg: string
  text: string
  color: string
}

export const coupons: Coupon[] = [{
  sm: coupon_5_sm,
  lg: coupon_5,
  bg: coupon_5_bg,
  text: 'Guaranteed $5. Worth for registration.',
  color: '#4F25C4',
}, {
  sm: coupon_10_sm,
  lg: coupon_10,
  bg: coupon_10_bg,
  text: 'Guaranteed $10. Worth for gleam.',
  color: '#4BAC00',
}, {
  sm: coupon_40_sm,
  lg: coupon_40,
  bg: coupon_40_bg,
  text: 'Guaranteed $40. Worth invite 5 friends.',
  color: '#E98C00',
}, {
  sm: coupon_50_sm,
  lg: coupon_50,
  bg: coupon_50_bg,
  text: 'Guaranteed $50. Worth invite 20 friends.',
  color: '#2723F4',
}]