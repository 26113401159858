import {HTMLAttributes, PropsWithChildren} from "react";
import {Navbar} from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
import AccountSidebar from "../../../features/account/AccountSidebar/AccountSidebar";
import styles from './SidebarLayout.module.scss';
import clsx from "clsx";

export default function SidebarLayout (props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const {children, className, ...rest} = props;
  return (
    <div className={styles.layout}>
      <div className={styles.header}><Navbar/></div>
      <div className={styles.main}>
        <div className={styles.sidebar}><AccountSidebar/></div>
        <div className={clsx(styles.content, className)} {...rest}>{children}</div>
      </div>
      <Footer/>
    </div>
  )
}
