import {useGetTemporaryTokenQuery} from "../../../api/sales/slice";
import React from "react";
import {RequestWrapper} from "components";


export function GetTemporaryToken(props: { address: string }) {
	const {isFetching, error, refetch} = useGetTemporaryTokenQuery(props.address);
	return (
		<RequestWrapper isLoading={isFetching} error={error} action={refetch}>
			{{ loader: {description: 'Requesting temporary token'} }}
		</RequestWrapper>
	)
}