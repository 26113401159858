import {RootState} from "store";
import {Coupon} from "./model/Coupon";
import {PaymentOption} from "./model/PaymentOption";


export function isInCart(state: RootState) {
  return (domainName: string) => state.cart.domainNames.indexOf(domainName) >= 0;
}

export function selectDomainNames(state: RootState): string[] {
  return state.cart.domainNames;
}

export function selectDomainsCount(state: RootState): number {
  return state.cart.domainNames.length;
}

export function selectCoupon(state: RootState): Coupon | undefined {
  return state.cart.coupon;
}

export function selectPaymentOption(state: RootState): PaymentOption {
  return state.cart.paymentOption;
}
