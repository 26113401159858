import {useEffect, useState} from 'react';
import {useFinishAccountRegistrationMutation} from "../../api/sales/slice";
import '../templates/AuthPage.scss'
import {Field, Form, FormRenderProps} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {PATHS} from "../../../router/paths";
import {useSafeNavigate} from 'hooks';
import {analytics, AuthMethod, EventType} from 'features/analytics';

export default function ConfirmEmail (props: {code: string}) {

	const navigate = useSafeNavigate();
	const [confirmEmail] = useFinishAccountRegistrationMutation();
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => { if (isSuccess) navigate(PATHS.signup.success) }, [isSuccess]);

	const handleFormSubmit = async (values: {[k: string]: any}) => {
		const {password} = values;
		const result = await confirmEmail({password, code: props.code});
		if ('error' in result) return {[FORM_ERROR]: result.error.message};
		analytics.push({type: EventType.SignUp, method: AuthMethod.Primary});
		setIsSuccess(true);
	}

	const validateForm = (values: {[k: string]: string}) => {
		const errors: {[k: string]: string} = {};
		const {password, confirm} = values;
		if (!password) errors.password = 'This field is required';
		if (!confirm) errors.confirm = 'Please confirm password';
		if (password !== confirm) errors.confirm = 'Passwords do not match';
		return errors;
	}

	return (
		<Form onSubmit={handleFormSubmit} validate={validateForm} render={RenderForm}/>
	)

}

function RenderForm(props: FormRenderProps) {
	const {submitError, handleSubmit, submitting} = props;
	return (
		<form className="login-form" onSubmit={handleSubmit}>
			<div className="login-form__fields">
				<Field name="password">
					{({input, meta}) => (
						<div className='login-form__field-wrap'>
							<input {...input} type="password" placeholder="Password" className='login-form__field'/>
							{(meta.error || meta.submitError) && meta.touched &&
								<div className='login-form__field-error _bottom'>{meta.error || meta.submitError}</div>
							}
						</div>
					)}
				</Field>
				<Field name="confirm">
					{({input, meta}) => (
						<div className='login-form__field-wrap'>
							<input {...input} type="password" placeholder="Confirm password" className='login-form__field'/>
							{(meta.error || meta.submitError) && meta.touched &&
								<div className='login-form__field-error _bottom'>{meta.error || meta.submitError}</div>
							}
						</div>
					)}
				</Field>
			</div>
			{submitError && <div style={{marginTop: '1rem', marginBottom: '2rem'}}>{submitError}</div>}
			<button type="submit" disabled={submitting} className='registration__btn'>Submit</button>
		</form>
	)
}
