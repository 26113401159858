import {Fragment, useState} from "react";
import {useIsWebsiteOnlineQuery} from "../../../api/gateway/slice";
import {TemplateSelector} from "./TemplateSelector";
import {ControlPanel} from "./ControlPanel";


export function WebsiteWidget(props: {domainId: number | undefined, domainName: string, isActive: boolean, tokenId: string | undefined}) {
  const {domainId, domainName, isActive, tokenId} = props;
  const [isEdit, setIsEdit] = useState(false);
  const {isOnline} = useIsWebsiteOnlineQuery({domainName}, {selectFromResult: ({data, error}) => ({isOnline: !error && data}), skip: !isActive});
  if (typeof isOnline === 'undefined') return <Fragment>Loading website info. Please wait.</Fragment>
  if (!domainId || !tokenId) return <Fragment>To create a website, first mint your domain token on the BNB Chain.</Fragment>
  if (!isOnline || isEdit) return <TemplateSelector domainId={domainId} onClickClose={isOnline ? () => setIsEdit(false) : undefined}/>
  return <ControlPanel domainName={domainName} onClickEdit={() => setIsEdit(true)}/>
}
