export type ClaimSaga = {
  status: typeof ClaimSagaStatus.UNINITIALIZED
} | {
  status: typeof ClaimSagaStatus.SENDING_TRANSACTION
  code: string
} | {
  status: typeof ClaimSagaStatus.AWAITING_TRANSACTION
  address: string
  code: string
} | {
  status: typeof ClaimSagaStatus.REGISTERING_PAYMENT
  address: string
  code: string
} | {
  status: typeof ClaimSagaStatus.AWAITING_EMAIL_REGISTRATION_SIGNATURE
  address: string
  code: string
  temporaryToken: string
} | {
  status: typeof ClaimSagaStatus.ASSIGNING_ADDRESS
  address: string
  code: string
  signature: string
  temporaryToken: string
} | {
  status: typeof ClaimSagaStatus.AWAITING_FULFILLMENT
  address: string
  id: number
  code: string
} | {
  status: typeof ClaimSagaStatus.FULFILLED
  address: string
  id: number
  code: string
}

export const ClaimSagaStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  SENDING_TRANSACTION: 'AWAITING_ADDRESS',
  AWAITING_TRANSACTION: 'SENDING_REQUEST',
  REGISTERING_PAYMENT: 'AWAITING_EMAIL_REGISTRATION_TOKEN',
  AWAITING_EMAIL_REGISTRATION_SIGNATURE: 'AWAITING_EMAIL_REGISTRATION_SIGNATURE',
  ASSIGNING_ADDRESS: 'ASSIGNING_ADDRESS',
  AWAITING_FULFILLMENT: 'AWAITING_FULFILLMENT',
  FULFILLED: 'FULFILLED'
} as const;

export type ClaimSagaStatus = typeof ClaimSagaStatus[keyof typeof ClaimSagaStatus]
