import {PayloadAction} from "@reduxjs/toolkit";
import {setExternalContent, upload} from "./actions";
import {UploadSagaStatus} from "../types/UploadSagaStatus";
import {SetExternalContentSaga, SetExternalContentSagaStatus, UploadSaga} from "../types";


export function isUploadSagaUpdate<Status extends UploadSagaStatus>(status: Status) {
	return (action: PayloadAction<any>): action is PayloadAction<UploadSaga & { status: Status }> =>
		upload.update.match(action) && action.payload.status === status
}

export function isSetExternalContentSagaUpdate<Status extends SetExternalContentSagaStatus>(status: Status) {
	return (action: PayloadAction<any>): action is PayloadAction<SetExternalContentSaga & { status: Status }> =>
		setExternalContent.update.match(action) && action.payload.status === status
}
