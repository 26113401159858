import {isAnyOf} from "@reduxjs/toolkit";
import {AppStartListening} from "store";
import {slice as salesApi} from "features/api/sales/slice";
import {api as web3Api} from "features/web3";
import {actions as accountActions} from "./index";
import {AddressAssignmentStatus} from "./types";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(accountActions.assignAddress.init.match, accountActions.assignAddress.cancel.match),
    effect: async (action, api) => {
      if (accountActions.assignAddress.cancel.match(action)) {
        api.cancelActiveListeners();
        api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.UNINITIALIZED}));
        return;
      }
      api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.SENDING_TRANSACTION}));
      const [{payload: {address}}] = await api.take(accountActions.assignAddress.setAddress.match);
      api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.AWAITING_TOKEN, address}));
      const [{payload: token}] = await api.take(salesApi.endpoints.getTemporaryToken.matchFulfilled);
      api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.AWAITING_SIGNATURE, token}));
      const [{payload: signature}] = await api.take(web3Api.endpoints.getPersonalSign.matchFulfilled);
      api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.SENDING_SIGNATURE, signature, token}));
      await api.take(salesApi.endpoints.assignAddressWithAuth.matchFulfilled);
      api.dispatch(accountActions.assignAddress.update({status: AddressAssignmentStatus.FULFILLED, address}));
    }
  });
}
