import {useEffect} from "react";
import {useVerifyRecoveryCodeQuery} from "features/api/sales/slice";
import {Closed} from "./Closed";

export function CheckLink(props: {code: string, onSuccess: () => void, onFail: (error: string) => void}) {
  const {data, error} = useVerifyRecoveryCodeQuery({code: props.code});
  useEffect(() => {
    if (data) props.onSuccess();
    if (error) props.onFail(error.message ?? 'Something went wrong');
  }, [data, error])

  return <Closed/>
}