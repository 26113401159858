import styles from './Footer.module.scss';
import {PATHS} from "../../router/paths";
import {Link} from 'react-router-dom';
import {Discord, LinkedIn, Logo, Medium, Telegram, Twitter, YouTube} from "../icon";


export default function Footer() {

	return (
		<div className={styles.footer}>
			<div className={styles.wrapper}>
				<div className={styles.copy}><Logo/>&nbsp;<span>©2023</span></div>
				<div className={styles.links}>
					<ul className={styles.linkItems}>
						<li className={styles.linkItem}><Link to={PATHS.documents}>Documents</Link></li>
					</ul>
					<ul className={styles.linkItems}>
						<li className={styles.linkItem}><Link to={PATHS.terms}>Terms</Link></li>
						<li className={styles.linkItem}><Link to={PATHS.policy}>Privacy Policy</Link></li>
					</ul>
				</div>
				<ul className={styles.socialItems}>
					<li className={styles.socialItem}><a href='https://discord.com/invite/nfZ9jKy7qK' target="_blank" rel="noreferrer"><Discord/></a></li>
					<li className={styles.socialItem}><a href='https://medium.com/@web3dna' target="_blank" rel="noreferrer"><Medium/></a></li>
					<li className={styles.socialItem}><a href='https://t.me/w3dna_official' target="_blank" rel="noreferrer"><Telegram/></a></li>
					<li className={styles.socialItem}><a href='https://twitter.com/web3dna' target="_blank" rel="noreferrer"><Twitter/></a></li>
					<li className={styles.socialItem}><a href='https://www.youtube.com/channel/UCRYkT0xsxgjxNlqss-cp8Qg' target="_blank" rel="noreferrer"><YouTube/></a></li>
					<li className={styles.socialItem}><a href='https://www.linkedin.com/company/w3dna' target="_blank" rel="noreferrer"><LinkedIn/></a></li>
				</ul>
			</div>
		</div>
	);

}
