import {CompetitionData} from "../../api/sales/model/CompetitionData";
import {Fragment, ReactElement} from "react";
import {CompetitionType} from "../../api/sales/model/CompetitionType";
import {PATHS} from "../../../router/paths";
import {formatToken} from "../../web3";
import checkInCircle from './assets/check-in-circle.png';
import icon1 from './assets/coupon-icon1.svg';
import icon2 from './assets/coupon-icon2.svg';
import icon3 from './assets/coupon-icon3.svg';
import icon4 from './assets/coupon-icon4.svg';
import {useSafeNavigate} from "../../../hooks";
import {CouponStatus} from "../../api/sales/model/CouponStatus";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import {Coupon} from "../../api/sales/model/Coupon";

export function Competition(props: {data: CompetitionData}) {
  const {type, url, price, coupon} = props.data;
  const navigate = useSafeNavigate()
  const handleClickRedeem = (coupon: Coupon) => () => {
    const {name, discount} = coupon;
    navigate(PATHS.search.index(), {state: {coupon: {name, discount}}});
  }
  let icon: string, description: ReactElement, link: ReactElement;
  switch (type) {
    case CompetitionType.REGISTRATION:
      icon = icon1;
      description = <>Registration</>;
      link = <OuterLink href={url ?? ''} target='_blank' rel="noreferrer">Complete registration</OuterLink>;
      break;
    case CompetitionType.SUBSCRIPTION:
      icon = icon2;
      description = <>Subscribe to our socials <br/> and get extra</>;
      link = <OuterLink href={url ?? ''} target='_blank' rel="noreferrer">Subscribe via Gleam</OuterLink>;
      break;
    case CompetitionType.REFERRALS_L1:
      icon = icon3;
      description = <>Invite 5 friends<br/>and get extra</>;
      link = <InnerLink to={PATHS.account.referralprogram.index}>Get referral link</InnerLink>;
      break;
    case CompetitionType.REFERRALS_L2:
      icon = icon4;
      description = <>Invite additional 15 friends<br/>and get extra</>;
      link = <InnerLink to={PATHS.account.referralprogram.index}>Get referral link</InnerLink>;
      break;
    default:
      return null;
  }
  return (
    <Wrapper>
      <Card $isReceived={!!coupon}>
        <Icon><img src={icon} alt=""/></Icon>
        <Description>{description}</Description>
        <Price>${formatToken(price)}</Price>
        {!!coupon ? (
          <Button $isReceived disabled={coupon.status !== CouponStatus.RELEASED} onClick={handleClickRedeem(coupon)}>
            {coupon.status === CouponStatus.USED ? 'Benefit received' : 'Use coupon now!'}
          </Button>
        ) : (
          <Fragment>{link}</Fragment>
        )}
      </Card>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (min-width: 640px) {
    width: 50%;
    padding: 13px;
  }
`
const Card = styled.div<{$isReceived: boolean}>`
  width: 100%;
  padding: 44px 26px;
  margin-bottom: 18px;
  background: #FFFFFF;
  border: 0.951955px solid #DBE1E8;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 9.51955px;
  position: relative;
  &:before {
    content: ${({$isReceived}) => $isReceived ? '' : undefined};
    display: block;
    position: absolute;
    right: 18px;
    top: 18px;
    width: 26px;
    height: 26px;
    background: url(${checkInCircle});
  }
  @media (min-width: 640px) {
    margin-bottom: 0;
  }
`
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  padding-bottom: 31px;
  @media (min-width: 640px) {
    width: 62px;
    height: 62px;
    padding-bottom: 0;
    margin: 0 auto 20px auto;
    img {
      display: block;
    }
  }
`
const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  @media (min-width: 640px) {
    font-size: 18px;
  }
`
const Price = styled.div`
  margin-bottom: 32px;
  font-size: 36px;
  text-align: center;
`
const ButtonCSS = css<{$isReceived?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({$isReceived}) => $isReceived ? '#69D714' : '#4C37CA'};
  border: 1px solid ${({$isReceived}) => $isReceived ? '#69D714' : '#4C37CA'};
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 51px;
  max-width: 300px;
  margin: 0 auto;
  border-radius: .5rem;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  transition: opacity 0.3s;
  text-decoration: none;
  cursor: pointer;
  &:disabled {
    border-color: #cecece;
    background-color: #cecece;
    cursor: default;
  }
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
`
const Button = styled.button`${ButtonCSS}`
const OuterLink = styled.a`${ButtonCSS}`
const InnerLink = styled(Link)`${ButtonCSS}`