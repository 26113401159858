import {Web3ErrorName} from "../model";

export const errors = [{
	"name": Web3ErrorName.UserRejectedRequest,
	"code": 4001,
	"standard": "EIP-1193",
	"message": "User rejected the request."
}, {
	"name": Web3ErrorName.Unauthorized,
	"code": 4100,
	"standard": "EIP-1193",
	"message": "The requested account and/or method has not been authorized by the user."
}, {
	"name": Web3ErrorName.UnsupportedMethod,
	"code": 4200,
	"standard": "EIP-1193",
	"message": "The requested method is not supported by this Ethereum provider."
}, {
	"name": Web3ErrorName.Disconnected,
	"code": 4900,
	"standard": "EIP-1193",
	"message": "The provider is disconnected from all chains."
}, {
	"name": Web3ErrorName.ChainDisconnected,
	"code": 4901,
	"standard": "EIP-1193",
	"message": "The provider is disconnected from the specified chain."
}, {
	"name": Web3ErrorName.UnrecognizedChainId,
	"code": 4902,
	"standard": "",
	"message": "Unrecognized chain ID."
}, {
	"name": Web3ErrorName.Parse,
	"code": -32700,
	"standard": "JSON RPC 2.0",
	"message": "Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text."
}, {
	"name": Web3ErrorName.InvalidRequest,
	"code": -32600,
	"standard": "JSON RPC 2.0",
	"message": "The JSON sent is not a valid Request object."
}, {
	"name": Web3ErrorName.MethodNotFound,
	"code": -32601,
	"standard": "JSON RPC 2.0",
	"message": "The method does not exist / is not available."
}, {
	"name": Web3ErrorName.InvalidParams,
	"code": -32602,
	"standard": "JSON RPC 2.0",
	"message": "Invalid method parameter(s)."
}, {
	"name": Web3ErrorName.Internal,
	"code": -32603,
	"standard": "JSON RPC 2.0",
	"message": "Internal JSON-RPC error."
}, {
	"name": Web3ErrorName.InvalidInput,
	"code": -32000,
	"standard": "EIP-1474",
	"message": "Invalid input."
}, {
	"name": Web3ErrorName.ResourceNotFound,
	"code": -32001,
	"standard": "EIP-1474",
	"message": "Resource not found."
}, {
	"name": Web3ErrorName.ResourceUnavailable,
	"code": -32002,
	"standard": "EIP-1474",
	"message": "Resource unavailable."
}, {
	"name": Web3ErrorName.TransactionRejected,
	"code": -32003,
	"standard": "EIP-1474",
	"message": "Transaction rejected."
}, {
	"name": Web3ErrorName.MethodNotSupported,
	"code": -32004,
	"standard": "EIP-1474",
	"message": "Method not supported."
}, {
	"name": Web3ErrorName.LimitExceeded,
	"code": -32005,
	"standard": "EIP-1474",
	"message": "Request limit exceeded."
}] as const;