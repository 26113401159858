import {To} from 'history';
import {NavigateFunction, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {NavigateOptions} from 'react-router';
import {RefObject, useEffect, useMemo} from "react";
import {useGetAccountQuery, useGetChainNameQuery, useIsConnectedQuery} from "features/web3/store";
import {actions as modalActions} from 'features/modal/store';
import {ModalName, ModalParams} from "../features/modal/store/types";
import {useDispatch} from "../store/hooks";
import {Chain} from "features/web3";


export {useAccount} from './useAccount';
export {useAuth} from './useAuth';
export {useDispatch} from "../store/hooks";
export {useReferrer} from './useReferrer';
export {useSelector} from "../store/hooks";

export function useModal () {
  const dispatch = useDispatch();
  const open = (payload: {name: ModalName, params?: ModalParams}) => dispatch(modalActions.open(payload));
  const close = () => dispatch(modalActions.close());
  return {open, close};
}

export function useOutsideClick<T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: (event: MouseEvent) => void) {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);
}

export function useSafeNavigate(): NavigateFunction {
  const navigate = useNavigate();
  const location = useLocation();
  return (to: To | number, options?: NavigateOptions) => {
    if (typeof to !== 'number') return navigate(to, options);
    if (to < 0 && location.key === 'default') return navigate('/');
    return navigate(to);
  }
}

export function useWeb3(): { isConnected: boolean, address: string | undefined, chainName: Chain | undefined } {
  const { isConnected } = useIsConnectedQuery(undefined, {selectFromResult: ({data}) => ({isConnected: !!data})});
  const { address } = useGetAccountQuery(undefined, {skip: !isConnected, selectFromResult: ({data}) => ({address: data ?? undefined})});
  const { chainName } = useGetChainNameQuery(undefined, {skip: !isConnected, selectFromResult: ({data}) => ({chainName: data ?? undefined})});
  return {
    isConnected: useMemo(() => isConnected, [isConnected]),
    address: useMemo(() => address, [address]),
    chainName: useMemo(() => chainName, [chainName])
  };
}