import * as t from "io-ts";

export const AccountCodec = t.type({
  id: t.number,
  login: t.union([t.string, t.undefined]),
  email: t.union([t.string, t.null]),
  address: t.union([t.string, t.undefined, t.null]),
  domainsCount: t.number,
  referralsCount: t.number,
  couponsSummaryDiscount: t.string
});

export type Account = t.TypeOf<typeof AccountCodec>;
