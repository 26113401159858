import {Fragment, ReactElement, SVGAttributes} from "react";
import styled from "styled-components";
import {Button, Loader} from "components";
import {ArrowUpRightFromSquare, Binance, Ethereum, Polygon} from "components/icon";
import {Chain, getTokenUrl} from "features/web3";
import {UserDomainNameInfoStatus} from "features/api/sales/model/UserDomainNameInfoStatus";
import {PATHS} from "router/paths";


export function ChainItem (props: {chain: Chain, tokenId: string, tokenStatus: UserDomainNameInfoStatus, account?: string}) {
  const {account, chain, tokenId, tokenStatus} = props;
  let name, status: string
  let action: ReactElement
  let hasButton, isButtonHidden = false

  if (chain === Chain.BinanceSmartChain) {
    name = 'BNB Chain';
  } else if (chain === Chain.Ethereum) {
    name = 'Ethereum';
    isButtonHidden = true;
  } else if (chain === Chain.Polygon) {
    name = 'Polygon';
    isButtonHidden = true;
  }

  if (tokenStatus === UserDomainNameInfoStatus.MINTED) {
    status = 'Minted';
    action = <Link href={getTokenUrl(chain, {account})} target='_blank' rel="noreferrer"><ArrowUpRightFromSquare style={{position: 'relative', bottom: '.125em'}}/></Link>;
  } else  if (tokenStatus === UserDomainNameInfoStatus.WRITING_MINT_RIGHTS || tokenStatus === UserDomainNameInfoStatus.MINT_PROCESSING) {
    if (isButtonHidden) {
      status = 'Reserved';
      action = <Fragment/>;
    } else {
      status = 'In progress...';
      action = <Loader/>;
    }
  } else {
    status = 'Reserved';
    action = isButtonHidden ? <Fragment/> : <ActionButton type='route' to={PATHS.account.domains.mint(tokenId)} size='sm' style={{lineHeight: '1.25em'}}>Mint</ActionButton>;
    hasButton = true;
  }

  return (
    <Item>
      <Icon chain={chain}/>
      <Name>{name}</Name>
      <Action>{action}</Action>
      <Status $span={!hasButton}>{status}</Status>
    </Item>
  )
}

function getIcon(props: {chain: Chain} & SVGAttributes<SVGElement>): JSX.Element {
  const {chain, ...rest} = props;
  switch (chain) {
    case Chain.BinanceSmartChain: return <Binance {...rest}/>
    case Chain.Ethereum: return <Ethereum {...rest}/>
    case Chain.Polygon: return <Polygon {...rest}/>
  }
}

const Item = styled.li`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  column-gap: .75rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, max-content);
  }
`
const Icon = styled(getIcon)`
  font-size: 1.75rem;
  grid-column: 1;
  grid-row: span 2;
  align-self: center;
  @media (min-width: 768px) {
    grid-row: 1;
  }
`
const Name = styled.div`
  align-self: center;
  font-weight: 400;
`
const Action = styled.div`
  grid-column: 3;
  grid-row: span 2;
  align-self: center;
  justify-self: end;
  @media (min-width: 768px) {
    justify-self: auto;
    grid-row: 1;
  }
`
const Status = styled.div<{$span: boolean}>`
  grid-column: 2;
  font-size: .75rem;
  color: #62626a;
  @media (min-width: 768px) {
    grid-column: ${({$span}) => $span ? '2 / 4' : '2 / 3'};
  }
`
const Link = styled.a`
  line-height: 1;
  text-decoration: none;
`
const ActionButton = styled(Button)`
  font-size: .75rem;
`
