import type {TypedAddListener, TypedStartListening} from '@reduxjs/toolkit'
import {addListener, createListenerMiddleware} from '@reduxjs/toolkit'
import type {AppDispatch, RootState} from './index'
import {listeners as accountListeners} from 'features/account/store';
import {listeners as addressAssignmentListeners} from 'features/account/assign-address';
import {listeners as authListeners} from 'features/auth/store';
import {listeners as cartListeners} from 'features/cart/store'
import {listeners as claimListeners} from 'features/claim/store'
import {listeners as mintListeners} from 'features/mint'
import {listeners as orderListeners} from 'features/order/store'
import {listeners as uploadListener} from 'features/website/upload'

export const listenerMiddleware = createListenerMiddleware()
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export const startAppListening = listenerMiddleware.startListening as AppStartListening
export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>

accountListeners(startAppListening);
addressAssignmentListeners(startAppListening);
authListeners(startAppListening);
cartListeners(startAppListening);
claimListeners(startAppListening);
mintListeners(startAppListening);
orderListeners(startAppListening);
uploadListener(startAppListening);
