import {AnalyticsEvent} from "./types/AnalyticsEvent";
import {EventType} from "./types/EventType";


const gtag = (window as any).gtag;
const gtagID = process.env.REACT_APP_GOOGLE_TAG_ID;
const isDebug = process.env.REACT_APP_GOOGLE_TAG_IS_DEBUG === 'true';

export const ga = {
	push: (event: AnalyticsEvent, fields?: Record<string, string>) => {
    try {
      const {type, ...rest} = event;
      const params: Record<string, any> = {...fields, ...rest}
      if (isDebug) params.debug_mode = true;
      gtag('event', toGTAGEvent(type), {...rest, debug_mode: true});
      // gtag('get', gtagID, 'client_id', (clientID: string) => {
      //   const params: Record<string, any> = {...fields, ...rest}
      //   if (isDebug) params.debug_mode = true;
      //   sendGMP(prepareGMPParams(clientID, toGTAGEvent(type), params));
      // });
    } catch {}
	}
}

function toGTAGEvent(event: EventType): string {
	switch (event) {
		case EventType.AddToCart: return 'add_to_cart';
		case EventType.BeginCheckout: return 'begin_checkout';
		case EventType.Click: return 'click';
		case EventType.Purchase: return 'purchase';
		case EventType.Search: return 'search';
		case EventType.SignUp: return 'signup';
		case EventType.LogIn: return 'login';
		default: throw new Error(`Unknown event ${event}`);
	}
}

function prepareGMPParams(clientID: string, name: string, params: object):object{
  return {
    client_id: clientID,
    events: [{ name, params }],
  };
}
 
function sendGMP(params: object) {
  return fetch('/api/analytics', {
    method: 'POST',
    body: JSON.stringify(params)
  })
}