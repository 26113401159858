import {getContract} from "../util";
import {BigNumber} from "ethers";


const abi = [
  'function balanceOf(address owner) view returns (uint256)',
  'function getTokenIdByDomainName(string memory domainName) view returns (uint256)',
  'function isDomainNameExists(string memory domainName) view returns (bool)',
  'function setContentOrAddressByTokenId(uint256 tokenId, string content, uint8 contentType, address contentProvider)',
  'function tokenIdToDomainNames(uint256) view returns (string)',
  'function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)'
];

export const DomainNFT = getContract(abi, (read, write) => ({
  async isAvailable(domainName: string) {
    const isExists = await read('isDomainNameExists', [domainName]);
    return !isExists;
  },
  async fetchDomains(owner: string) {
    const amount: any = await read('balanceOf', [owner]);
    return await Promise.all(
      Array.from(Array(amount.toNumber()).keys()).map(async (index) => {
        const tokenId: any = await read('tokenOfOwnerByIndex', [owner, index]);
        const tokenName = await read('tokenIdToDomainNames', [tokenId]);
        return {id: tokenId.toString(), name: tokenName};
      })
    );
  },
  async fetchDomainName(tokenId: string) {
    return await read('tokenIdToDomainNames', [tokenId]);
  },
  async fetchTokenId(domainName: string) {
    const tokenId = await read('getTokenIdByDomainName', [domainName]) as BigNumber;
    return tokenId.toString();
  },
  async setContentRoute(tokenId: string, path: string) {
    return await write('setContentOrAddressByTokenId', [tokenId, path, 1, '0x0000000000000000000000000000000000000000']);
  },
}));
