import {getContract} from "../util";
import {BigNumber} from "ethers";


const abi = [
	'function totalSupply() external view returns (uint256)',
	'function balanceOf(address account) external view returns (uint256)',
	'function transfer(address recipient, uint256 amount) external returns (bool)',
	'function allowance(address owner, address spender) external view returns (uint256)',
	'function approve(address spender, uint256 amount) external returns (bool)'
];

export const ERC20 = getContract(abi, (read, write, params?: {decimals?: number}) => ({
	async approve(spender: string, amount: string) {
		return write('approve', [spender, fromWei(amount, params?.decimals)]);
	},
	async fetchAllowance(owner: string, spender: string): Promise<string> {
		const allowance = await read('allowance', [owner, spender]) as BigNumber;
		return toWei(allowance, params?.decimals);
	},
	async fetchBalance(account: string): Promise<string> {
		const balance = await read('balanceOf', [account]) as BigNumber;
		return toWei(balance, params?.decimals);
	},
	async transfer(recipient: string, amount: string) {
		return write('transfer', [recipient, fromWei(amount, params?.decimals)]);
	}
}))

/**
 * Convert our internal representation of the number to the internal representation used by the smart contract
 * @param amount - amount of currency in our internal representation (18 decimal places)
 * @param decimals - decimal places that smart contract uses
 */
function fromWei(amount: string, decimals: number = 18): string {
	if (decimals === 18) return amount;
	if (decimals > 18) throw new Error('Wrong decimal places');
	const multiplier = Number(`1e${18 - decimals}`);
	const input = BigNumber.from(amount);
	const output = input.div(multiplier);
	if (!output.mul(multiplier).eq(input)) throw new Error('Could not convert internal number representation to the one smart contract uses');
	return output.toString();
}

/**
 * Convert the internal representation of the number returned from the smart contract to the one we use
 * @param amount - amount returned from smart contract
 * @param decimals - decimal places that smart contract uses
 */
function toWei(amount: BigNumber, decimals: number = 18): string {
	if (decimals === 18) return amount.toString();
	if (decimals > 18) throw new Error('Wrong decimal places');
	return amount.mul(Number(`1e${18 - decimals}`)).toString();
}