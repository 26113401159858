import {ThemeProvider as ThemeProviderComponent} from 'styled-components';
import {Theme} from "../../types/Theme";
import {dark} from "./dark";
import {light} from "./light";
import {PropsWithChildren} from "react";


export function ThemeProvider (props: PropsWithChildren & {theme: Theme}) {
  const {theme, ...rest} = props
  return <ThemeProviderComponent theme={getTheme(props.theme)} {...rest}/>
}

function getTheme(theme: Theme) {
  switch (theme) {
    case Theme.Dark: return dark;
    case Theme.Light: return light;
  }
}
