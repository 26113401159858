import {useEffect, useState} from 'react';
import {useFetchDomainsQuery} from "features/api/sales/slice";
import {RequestWrapper} from "components";


export default function AwaitConfirmation() {
  const [pollingInterval, setPollingInterval] = useState<number|undefined>(3000);
  const {error} = useFetchDomainsQuery(undefined, {pollingInterval});
  useEffect(() => { if (error) setPollingInterval(undefined) }, [error]);
  return (
    <RequestWrapper action={() => setPollingInterval(3000)} isLoading={!error} error={error}>
      {{ loader: {description: 'Awaiting confirmation' }}}
    </RequestWrapper>
  )
}
