import {formatToken} from "features/web3";


export default function CartItem(props: { name: string, price: string, discountedPrice?: string, onClickRemove: (domainName: string) => any }) {
  const {discountedPrice, name, onClickRemove, price} = props;
  return (
    <div className="item">
      <div className="item__name">{name}</div>
      {discountedPrice && price !== discountedPrice ? (
          <div className="item__price _discount">
            <span style={{textDecoration: 'line-through'}}>${formatToken(price)}</span>
            ${formatToken(discountedPrice)}
          </div>
      ) : (
          <div className="item__price">${formatToken(price)}</div>
      )}
      <div className="item__clear" onClick={() => onClickRemove(name)}>
        <img src="assets/img/Desktop-retina-delete-cart.svg" alt=""/>
      </div>
    </div>
  );
}
