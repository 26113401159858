import styled from "styled-components";
import {Button} from "components";
import {Socials} from "./Socials";
import {Preview} from "./Preview";

export function ControlPanel (props: {domainName: string, onClickEdit: () => void}) {
  return (
    <Wrapper>
      <PreviewBlock domainName={props.domainName}/>
      <ActionButton onClick={props.onClickEdit}>Edit</ActionButton>
      <ActionButton disabled variant='outlined'>Delete</ActionButton>
      <SocialsBlock domainName={props.domainName}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  @media(min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content;
    column-gap: 2.5rem;
    list-style: none;
  }
`
const PreviewBlock = styled(Preview)`
  @media(min-width: 768px) {
    grid-row: 1 / span 2;
    align-content: start;
  }
`
const ActionButton = styled(Button)`
  align-self: start;
`
const SocialsBlock = styled(Socials)`
  @media(min-width: 768px) {
    grid-column: 2 / span 2;
    align-self: end;
  }
`