import {Chain} from "./model";
import {BigNumberish, utils} from "ethers";


export function getChainName(chain: Chain): string {
	switch (chain) {
		case Chain.BinanceSmartChain: return 'BNB Chain';
		case Chain.Ethereum: return 'Ethereum';
		case Chain.Polygon: return 'Polygon';
	}
}

export function getChainPrefix(chain: Chain) {
	switch (chain) {
		case Chain.BinanceSmartChain:
			return 'BSC'
		case Chain.Ethereum:
			return 'ETH'
		case Chain.Polygon:
			return 'POLYGON'
	}
}

export function getChainExplorerUrl(chain: Chain) {
	return process.env[`REACT_APP_${getChainPrefix(chain)}_EXPLORER_URL`]!;
}

export function getTokenUrl(chain: Chain, properties?: {account?: string} | {tokenId?: string}) {
	let url = `${getChainExplorerUrl(chain)}/token/${process.env[`REACT_APP_${getChainPrefix(chain)}_DOMAIN_NFT_ADDRESS`]!}`;
	if (properties) {
		if ('account' in properties) url = `${url}?a=${properties.account}`;
		if ('tokenId' in properties) url = `${url}?a=${properties.tokenId}#inventory`;
	}
	return url;
}

export function formatAddress(address: string) {
	return address ? `${address.slice(0, 5)}...${address.slice(-4)}` : '';
}

export function formatToken(amount: BigNumberish, decimals: number = 2) {
	const wei = utils.parseUnits(amount.toString(), 'wei');
	if (wei.isZero()) return '0';
	const remainder = wei.mod(Number(`1e${18 - decimals}`).toString());
	return utils.formatEther(wei.sub(remainder).toString()).replace(/\.0+$/, '');
}

export function formatTxLink(txHash: string, chain: Chain) {
	const url = (() => {
		switch (chain) {
			case Chain.BinanceSmartChain:
				return process.env.REACT_APP_BSC_EXPLORER_URL
			case Chain.Ethereum:
				return process.env.REACT_APP_ETH_EXPLORER_URL
			case Chain.Polygon:
				return process.env.REACT_APP_POLYGON_EXPLORER_URL
		}
	})()
	return `${url}/tx/${txHash}`
}

export const {isAddress} = utils;

export function parseWei(wei: string) {
	return utils.parseUnits(wei, 'wei');
}

export const isBrowser = typeof window !== 'undefined';
export const isNode = !isBrowser;
export const userAgent = isBrowser ? window.navigator.userAgent : '';
export const hasEthereum = isBrowser && typeof window.ethereum !== 'undefined';
export const isAndroid = /(android)/i.test(userAgent);
export const isIphone = /(iphone|ipod)/i.test(userAgent);
export const isIpad = /(ipad)/i.test(userAgent);
export const isMobile = isIphone || isAndroid;
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamask = isMobile && hasEthereum;
