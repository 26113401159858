import bg_dark_01 from './assets/bg_dark_01.svg';
import bg_dark_02 from './assets/bg_dark_02.svg';
import bg_dark_03 from './assets/bg_dark_03.svg';
import bg_light_01 from './assets/bg_light_01.svg';
import bg_light_02 from './assets/bg_light_02.svg';
import {BackgroundDescriptor} from "../../types/BackgroundDescriptor";
import {Theme} from "../../types/Theme";

export const backgrounds: BackgroundDescriptor[] = [
  {theme: Theme.Dark, url: bg_dark_01},
  {theme: Theme.Dark, url: bg_dark_02},
  {theme: Theme.Dark, url: bg_dark_03},
  {theme: Theme.Light, url: bg_light_01},
  {theme: Theme.Light, url: bg_light_02}
]