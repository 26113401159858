import {Fragment, PropsWithChildren, useEffect} from 'react';
import {RequestWrapper} from "components";
import {useSafeNavigate, useWeb3} from "hooks";
import {PATHS} from "router/paths";
import {useSwitchChainMutation} from "../store";
import {Chain, Web3ErrorName} from "../model";
import {formatAddress, getChainName} from "../util";


type ClassNames = {
  wrapper?: string
  top?: string
  bottom?: string
}

export interface ChainGuardProps extends PropsWithChildren {
  chain?: Chain
  address?: string
  doNotRedirectIfNotConnected?: boolean
  classNames?: ClassNames
}

export function ChainGuard(props: ChainGuardProps) {
  const {isConnected, address, chainName} = useWeb3();
  const navigate = useSafeNavigate();
  useEffect(() => { if (!isConnected && props.doNotRedirectIfNotConnected !== false) navigate(PATHS.connect) }, [isConnected])
  if (!isConnected) return (
    <RequestWrapper isLoading={false} error={undefined} classNames={props.classNames}>
      {{ action: { description: 'Please, connect your wallet', button: null } }}
    </RequestWrapper>
  )
  if (props.chain && props.chain !== chainName) return (
    <SwitchChain chainName={props.chain} classNames={props.classNames}/>
  )
  if (props.address && (!address || props.address.toUpperCase() !== address.toUpperCase())) return (
    <RequestWrapper isLoading={false} error={undefined} classNames={props.classNames}>
      {{ action: { description: `Please, select address ${formatAddress(props.address)} in your Web3 wallet`, button: null } }}
    </RequestWrapper>
  )
  return <Fragment>{props.children}</Fragment>
}

function SwitchChain (props: {chainName: Chain, classNames?: ClassNames}) {
  const [switchChain, {isLoading, error}] = useSwitchChainMutation();
  useEffect(() => { switchChain(props.chainName) }, []);
  return (
    <RequestWrapper action={() => switchChain(props.chainName)} isLoading={isLoading} error={error}>
      {{
        action: { description: `Please, switch network to ${getChainName(props.chainName)}`, button: 'Switch network' },
        loader: { description: `Please, switch network to ${getChainName(props.chainName)}`, button: null },
        error: { description: ({error}) =>
          error.name === Web3ErrorName.UnrecognizedChainId ? (
            <Fragment>
              <div>The network switch request is already pending.</div>
              <div>Please open your wallet and click the "Switch network" button.</div>
            </Fragment>
          ) : (
            <Fragment>
              <div>Something went wrong</div>
              <div>{error.message}</div>
            </Fragment>
          )
        }
      }}
    </RequestWrapper>
  )
}
