import * as t from "io-ts";

export const ReferralCodec = t.type({
    id: t.number,
    referralName: t.string,
    createTime: t.number,
    purchasedAmount: t.string,
    commissionAmount : t.string
});

export const ReferralsCodec = t.type({
    referrals : t.array(ReferralCodec)
});

export type Referrals = t.TypeOf<typeof ReferralsCodec>;
export type Referral = t.TypeOf<typeof ReferralCodec>;
