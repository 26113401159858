import ReactModal from "react-modal";
import styled from "styled-components";


export function Modal (props: ReactModal.Props) {
	ReactModal.setAppElement('#root');
	return(
		<ReactModal
			overlayElement={(props, children) => <Overlay {...props}>{children}</Overlay>}
			contentElement={({style, ...props}, children) => <Content {...props}>{children}</Content>}
			{...props}
		/>
	)
}

const Overlay = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Content = styled.div`
  position: absolute;
	margin: 0 auto;
	padding: 1rem;
	width: 100%;
	max-width: 39rem;
  border: 1px solid #d9d9d9;
  background: #fff;
  overflow: auto;
  border-radius: .5rem;
  outline: none;
`