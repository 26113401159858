import React from 'react';
import BaseLayout from "../../components/layout/BaseLayout/BaseLayout";


const html = `
    <style type="text/css">ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c0 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
    }

    .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

    .c8 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal
    }

    .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c4 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline
    }

    .c7 {
        margin: 1rem auto;
        background-color: #ffffff;
        max-width: 800px;
        padding: 72pt 72pt 72pt 72pt
    }

    .c5 {
        color: inherit;
        text-decoration: inherit
    }

    .c6 {
        height: 11pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }</style>
<div class="c7 doc-content"><p class="c3"><span class="c0">Terms and Conditions</span></p>
<p class="c3"><span class="c1">&nbsp;</span></p>
<p class="c3"><span class="c1">Welcome to w3nda.net (the &ldquo;Platform&rdquo;). The Platform is provided and controlled by W3DNA OU&#776;, a company incorporated in Estonia with its registered office at Harju maakond, Tallinn, Kesklinna linnaosa, Vesiva&#776;rava tn 50-201, 10152 , Estonia (&ldquo;w3dna&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;). We are committed to protecting and respecting your privacy.</span>
</p>
<p class="c3"><span class="c1">By accessing or using our Platform, you acknowledge that you have read this policy and that you understand your rights in relation to the personal data you provide and how we will collect, use and process it. If you do not agree with this policy in general or any part of it, you should refrain from visiting our Platform.</span>
</p>
<p class="c3"><span class="c1">Our offer is subject to Federal Act on Data Protection and any applicable foreign data protection law, in particular General Data Protection Regulation of the European Union (EU) (hereinafter &ldquo;GDPR&rdquo;).</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">1. Data controller</span></p>
<p class="c3"><span class="c1">W3DNA OU&#776;</span></p>
<p class="c3"><span
        class="c1">Harju maakond, Tallinn, Kesklinna linnaosa, Vesiva&#776;rava tn 50-201, 10152 , Estonia</span></p>
<p class="c3"><span class="c1">nfo.w3dna@gmail.com</span></p>
<p class="c3"><span class="c1">In some instances, there may be other persons responsible for the processing of personal data. In such a case, we inform you.</span>
</p>
<p class="c3 c6"><span class="c8"></span></p>
<p class="c3"><span class="c0">2. Processing of personal data</span></p>
<p class="c3"><span class="c2">2.1. Terms</span></p>
<p class="c3"><span
        class="c1">Personal data: all information that relates to an identified or identifiable person;</span></p>
<p class="c3"><span class="c1">Data subject: natural or legal person whose data is processed;</span></p>
<p class="c3"><span class="c1">Processing: any operation with personal data, irrespective of the means applied and the procedure, and in particular the collection, storage, use, revision, disclosure, archiving or destruction of data;</span>
</p>
<p class="c3"><span class="c1">European Economic Area (EEA) comprises the European Union (EU) as well as the Principality of Liechtenstein, Iceland and Norway.</span>
</p>
<p class="c3"><span class="c2">2.2. Extent of personal data processing</span></p>
<p class="c3"><span class="c1">In principle, we process personal data of our users only to the extent necessary to provide a functional Platform and present our content. The processing of personal data of our users generally occurs only with the consent of the user. An exception applies to cases in which prior consent cannot be obtained for factual reasons and the processing of the data is permitted by law.</span>
</p>
<p class="c3"><span class="c2">2.3. Legal basis</span></p>
<p class="c3"><span class="c1">We process personal data in accordance with Swiss data protection law, in particular the Federal Act on Data Protection (FATP) and the Ordinance to the Federal Act on Data Protection (OFATP).</span>
</p>
<p class="c3"><span class="c1">We process personal data &ndash; if and insofar as GDPR is applicable &ndash; in accordance with at least one of the following legal bases:</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 letter b GDPR for the processing of personal data necessary for the performance of a contract with the data subject and for the implementation of precontractual measures.</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 lit. f GDPR for the necessary processing of personal data in order to safeguard the legitimate interests of us or of third parties, unless the fundamental freedoms and rights and interests of the data subject outweigh the data subject. Legitimate interests are in particular our interest in being able to permanently provide our offer in a safe and reliable way and to advertise it user-friendly, to offer protection against misuse and unauthorized use and compliance with Swiss law.</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 lit. c GDPR for the necessary processing of personal data for the fulfilment of a legal obligation to which we are subject according to any applicable law of member states of the European Economic Area (EEA).</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 letter e GDPR for the processing of personal data necessary for the performance of a task carried out in the public interest.</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 letter a GDPR for the processing of personal data with the consent of the data subject.</span>
</p>
<p class="c3"><span class="c1">&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Art. 6 para. 1 letter d GDPR for the processing of personal data necessary to protect vital interests of the data subject or of another natural person.</span>
</p>
<p class="c3"><span class="c2">&nbsp;2.4 Type, scope and purpose</span></p>
<p class="c3"><span class="c1">We process those personal data that are necessary to provide our services in a permanent, user-friendly, secure and reliable manner. Such personal data can fall into the categories of inventory and contact data, browser and device data, content data, meta or marginal data and usage data, location data.</span>
</p>
<p class="c3"><span class="c1">We process personal data for the period of time required for the respective purpose or purposes or as required by law. Personal data whose processing is no longer required will be made anonymous or deleted. Persons whose data we process have a right to deletion.</span>
</p>
<p class="c3"><span class="c1">In line of principle, we process personal data only with the consent of the data subject, unless the processing is permitted for other legal reasons. In this context, we process in particular information that a person concerned voluntarily and personally submits to us when contacting us &ndash; for example by letter post, e-mail, contact form, social media or telephone. We may store such information in an address book or with comparable tools. If you transmit personal data about third parties to us, you are obliged to guarantee data protection against such third parties and to ensure the accuracy of such personal data.</span>
</p>
<p class="c3"><span class="c1">We also process personal data that we receive from third parties, obtain from publicly accessible sources or collect when providing our services, if and to the extent that such processing is permitted by law.</span>
</p>
<p class="c3"><span class="c2">2.5 Processing of personal data by third parties, also abroad</span></p>
<p class="c3"><span class="c1">We may have personal data processed by commissioned third parties or process it together with third parties or with the help of third parties or transfer it to third parties. Such third parties are in particular providers whose services we make use of. We also guarantee appropriate data protection for such third parties.</span>
</p>
<p class="c3"><span class="c1">Such third parties are generally located in Switzerland and in the European Economic Area (EEA). However, such third parties may also be located in other states and territories, provided that their data protection law guarantees adequate data protection in the opinion of the Federal Data Protection and Information Commissioner (FDPIC) and &ndash; if and insofar as the GDPR is applicable &ndash; in the opinion of the European Commission, or if adequate data protection is guaranteed for other reasons, such as by a corresponding contractual agreement, in particular on the basis of standard contractual clauses, or by appropriate certification. Exceptionally, such a third party may be located in a country without adequate data protection, provided that the conditions under data protection law are met, such as the express consent of the data subject.</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">3. Rights of data subjects</span></p>
<p class="c3"><span class="c1">Data subjects whose personal data we process have the rights granted by Swiss data protection law. This includes the right to information and the right to correct, delete or block the processed personal data.</span>
</p>
<p class="c3"><span class="c1">Data subjects whose personal data we process may &ndash; if and to the extent that the GDPR is applicable &ndash; obtain free of charge confirmation as to whether we are processing their personal data and, if so, request information about the processing of their personal data, have the processing of their personal data restricted, exercise their right to data transferability, and have their personal data corrected, deleted, blocked or completed.</span>
</p>
<p class="c3"><span class="c1">Data subjects whose personal data we process may &ndash; if and to the extent that the GDPR is applicable &ndash; revoke their consent at any time with effect for the future and object to the processing of their personal data at any time.</span>
</p>
<p class="c3"><span class="c1">Data subjects whose personal data we process have a right of appeal to a competent supervisory authority. The supervisory authority for data protection in Switzerland is the Federal Data Protection and Information Commissioner (FDPIC).</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">4. Data security</span></p>
<p class="c3"><span class="c1">We take appropriate and suitable technical and organizational measures to ensure data protection and in particular data security. However, despite such measures, the processing of personal data on the internet can always have security gaps. We can therefore not guarantee absolute data security.</span>
</p>
<p class="c3"><span class="c1">The access to our online offer is carried out by means of transport encryption (SSL /TLS, especially with the Hypertext Transfer Protocol Secure, abbreviated HTTPS). Most browsers mark transport encryption with a padlock in the address bar.</span>
</p>
<p class="c3"><span class="c1">Access to our online offer is subject &ndash; as basically all internet traffic &ndash; to mass surveillance without cause or suspicion and other monitoring by security authorities in Switzerland, in the European Union (EU), in the United States of America (USA) and in other countries. We cannot directly influence the corresponding processing of personal data by secret services, police forces and other security authorities.</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">5. Use of the Platform</span></p>
<p class="c3"><span class="c2">5.1 Cookies</span></p>
<p class="c3"><span class="c1">We may use cookies for our website. Cookies &ndash; both our own cookies (first-party cookies) and cookies from third parties whose services we use (third-party cookies) &ndash; are data that is stored in your browser. Such stored data does not need be limited to traditional cookies in text form. Cookies cannot execute programs or transmit malware such as Trojans and viruses.</span>
</p>
<p class="c3"><span class="c1">When you visit our website, cookies can be stored temporarily in your browser as &ldquo;session cookies&rdquo; or for a certain period of time as so-called permanent cookies. &ldquo;Session cookies&rdquo; are automatically deleted when you close your browser. Permanent cookies have a certain storage period. In particular, they make it possible to recognize your browser the next time you visit our Platform and thus to measure the range of our Platform. Permanent cookies can also be used for online marketing purposes.</span>
</p>
<p class="c3"><span class="c1">You can deactivate or delete cookies in your browser settings at any time, either in whole or in part. Without cookies, our website may no longer be fully available. We actively ask you &ndash; if and to the extent necessary &ndash; for your express consent to the use of cookies.</span>
</p>
<p class="c3"><span>For cookies that are used to measure success and reach or for advertising, a general optout is possible for many services via the</span><span
        class="c4"><a class="c5"
                      href="https://www.google.com/url?q=https://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;ust=1672143704351817&amp;usg=AOvVaw1MZD1EMIwjQPRiR8zVlTYZ">&nbsp;YourAdChoices (Digital Advertising Alliance)</a></span><span>, the</span><span
        class="c4"><a class="c5"
                      href="https://www.google.com/url?q=https://optout.networkadvertising.org/&amp;sa=D&amp;source=editors&amp;ust=1672143704352141&amp;usg=AOvVaw2LLQ_BUhoAUQkURBafZL8m">&nbsp;Network Advertising Initiative (NAI)</a></span><span>, or</span><span
        class="c4"><a class="c5"
                      href="https://www.google.com/url?q=https://www.youronlinechoices.com/ch-de/praferenzmanagement&amp;sa=D&amp;source=editors&amp;ust=1672143704352384&amp;usg=AOvVaw2khDWmjx_aoK1aoiQ63kRn">&nbsp;Your Online Choices (European Interactive Digital Advertising Alliance, EDAA)</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c2">5.2 Server log files</span></p>
<p class="c3"><span class="c1">We may collect the following information for each access to our website, provided that this information is transmitted by your browser to our server infrastructure or can be determined by our web server: Date and time including time zone, Internet Protocol (IP) address, access status (HTTP status code), operating system including user interface and version, browser including language and version, individual sub-pages of our website called up including transferred data volume, last website called up in the same browser window.</span>
</p>
<p class="c3"><span class="c1">We store such information, which may also represent personal data, in server log files. This information is required to provide our online services in a permanent, user-friendly and reliable manner and to ensure data security and thus in particular the protection of personal data &ndash; also by third parties or with the help of third parties.</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">6. Notifications and messages</span></p>
<p class="c3"><span class="c1">We send notifications and communications, such as newsletters, by e-mail and through other communication channels such as instant messaging.</span>
</p>
<p class="c3"><span class="c2">6.1 Measurement of success and range</span></p>
<p class="c3"><span class="c1">Notifications and messages can contain web links or tracking pixels that record whether an individual message was opened and which web links were clicked. Such web links and tracking pixels may also record the use of notifications and messages on a personalized basis. We need this statistical recording of usage to measure success and reach in order to provide notifications and messages in an effective, user-friendly, permanent, secure and reliable manner based on the needs and reading habits of the recipients.</span>
</p>
<p class="c3"><span class="c2">6.2 Consent and objection</span></p>
<p class="c3"><span class="c1">You expressly consent to the use of your e-mail address and other contact addresses when you submit this information to us. Use of your e-mail address and other contact addresses may also be permitted for other legal reasons.</span>
</p>
<p class="c3"><span class="c1">You can unsubscribe from notifications and communications such as newsletters at any time. However, we reserve the right to send you notifications and messages that are absolutely necessary for our offer. By unsubscribing, you may in particular object to the statistical recording of usage for the measurement of success and reach.</span>
</p>
<p class="c3"><span class="c2">6.3 Service provider for notifications and messages</span></p>
<p class="c3"><span class="c1">We send notifications and communications about third party services or with the help of service providers. Cookies may also be used in this process. We also guarantee appropriate data protection for such services.</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">7. Social media</span></p>
<p class="c3"><span class="c1">We are present on social media platforms and other online platforms to communicate with interested people and to inform them about our offer. Personal data may also be processed outside Switzerland and the European Economic Area (EEA). The General Terms and Conditions (GTC) and Terms of Use as well as data protection declarations and other provisions of the individual operators of such online platforms also apply in each case. These provisions provide information in particular about the rights of affected persons, which includes the right to information.</span>
</p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">8. Success and reach metrics</span></p>
<p class="c3"><span class="c2">8.1 Google Analytics</span></p>
<p class="c3"><span>We use Google Analytics to analyze how our website is used, and we can also measure the reach of our website and the success of links from third parties to our website, for example. This is a service provided by &nbsp;Google LLC, an American company. The Irish</span><span
        class="c4"><a class="c5"
                      href="https://www.google.com/url?q=https://www.blog.google/around-the-globe/google-europe/some-changes-our-service-model-europe/&amp;sa=D&amp;source=editors&amp;ust=1672143704354420&amp;usg=AOvVaw1xfGDWDdpTWFvDc1n2LXL2">&nbsp;Google Ireland Limited</a></span><span
        class="c1">&nbsp;is responsible for users in the European Economic Area (EEA) and Switzerland.</span></p>
<p class="c3"><span class="c1">Google also tries to track individual visitors to our website when they use different browsers or devices (cross-device tracking). Cookies are also used for this purpose. Your Internet Protocol (IP) address is required for Google Analytics, but is not combined with other data from Google.</span>
</p>
<p class="c3"><span class="c1">In any case, we will have your Internet Protocol (IP) address anonymized by Google before the analysis. As a result, your complete IP address will not be transmitted to Google in the USA.</span>
</p>
<p class="c3"><span
        class="c1">Further information on the type, scope and purpose of data processing can be found here:</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://safety.google/principles/?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704354988&amp;usg=AOvVaw1nPM-_taHm3vQQ9K2WhKuj">Our Privacy and Security Principle</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704355317&amp;usg=AOvVaw2amXxVC8PQ9bZy8zXPOQwL">Google privacy policy</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/product-privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704355659&amp;usg=AOvVaw14AT0yEhBlKs1ZuDo8gmZV">Google product privacy guide</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/partner-sites?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704356049&amp;usg=AOvVaw0Hh3Fbe5GgMSs-Q4oLMFoK">How Google uses information from sites or apps that use our services</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/cookies?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704356366&amp;usg=AOvVaw2wzxC5fA8diPRYKNmeQ6fJ">How Google uses cookies</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c1">You also have the following options:</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704356759&amp;usg=AOvVaw3frQl27f2EEKq620ITQqOl">Deactivate Google Analytics with a browser add-on</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://adssettings.google.com/&amp;sa=D&amp;source=editors&amp;ust=1672143704357075&amp;usg=AOvVaw3i4LBwMSY-s5ARGTLt6i1c">Object to personalized advertising</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c1">&nbsp;</span></p>
<p class="c3"><span class="c2">8.2 ActiveCampaign</span></p>
<p class="c3"><span class="c1">Our marketing automation application of choice is Active Campaign. This database system stores data of both individuals and companies. Personal data stored may contain name, job title, company name, e-mail address, phone number, website, and address. When a user accepts cookies, the website will also keeps track of pages visited by the user and will forward it to Active Campaign. This provides us a way to offer you personal and relevant content.We also send our newsletters and email campaigns via Active Campaign. We keep track of links clicked by individuals, to know which type of content gains the most attention.</span>
</p>
<p class="c3"><span>You can find more information in the</span><span class="c4"><a class="c5"
                                                                                   href="https://www.google.com/url?q=https://www.activecampaign.com/legal/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1672143704357682&amp;usg=AOvVaw3Jc_4vPwZ9AofYFNpC_s4X">&nbsp;privacy policy</a></span><span
        class="c1">&nbsp;of ActiveCampaign.</span></p>
<p class="c3 c6"><span class="c1"></span></p>
<p class="c3"><span class="c0">9. Services of third parties</span></p>
<p class="c3"><span class="c1">We use third party services to provide our services in a stable, user-friendly, secure and reliable manner. Such services also serve to embed content into our website. Such services &ndash; such as hosting and storage services, video services and payment services &ndash; require your Internet Protocol (IP) address, otherwise such services cannot transmit the corresponding content. Such services may be located outside Switzerland and the European Economic Area (EEA), provided that adequate data protection is guaranteed.</span>
</p>
<p class="c3"><span class="c1">For their own security, statistical and technical purposes, third parties whose services we use may also process data in connection with our offer and from other sources &ndash; including cookies, log files and counting pixels &ndash; in aggregated, anonymized or pseudonymized form.</span>
</p>
<p class="c3"><span class="c2">9.1 Digital Infrastructure</span></p>
<p class="c3"><span class="c1">We use services of third parties in order to be able to make use of the digital infrastructure required for our services. These include in particular hosting and storage services from specialized providers. Such providers process &ndash; usually exclusively on our behalf &ndash; the data required to operate this infrastructure. This includes in particular your Internet Protocol (IP) address. We also guarantee appropriate data protection with such providers.</span>
</p>
<p class="c3"><span>WordPress.com: Website construction kit; Provider: Automattic Inc. (USA) / Aut O&rsquo;Mattic A8C Ireland Ltd. (Ireland) for users on the European mainland, among others; Information on data protection:</span><span
        class="c4"><a class="c5"
                      href="https://www.google.com/url?q=https://automattic.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1672143704358574&amp;usg=AOvVaw2EAiNsWKhU-LiUOtumASn2">&nbsp;Automattic&rsquo;s Privacy Policy</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c2">9.2 Entertainment</span></p>
<p class="c3"><span class="c1">We use YouTube to embed videos into our website. Cookies are also used for this purpose. YouTube is a service of the Google LLC. Google Ireland Limited is responsible for users in the European Economic Area (EEA) and Switzerland.</span>
</p>
<p class="c3"><span
        class="c1">Further information about the nature, extent and purpose of data processing can be found:</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://safety.google/principles/?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704359124&amp;usg=AOvVaw2-JWAFeI_PiCgUzpOV6nqX">Our Privacy and Security Principle</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704359433&amp;usg=AOvVaw1Y2ywzWhiUMozZazpbQtTN">Google privacy policy</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/product-privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704359780&amp;usg=AOvVaw0GvT8GXwmdrIXt110F1vva">Google product privacy guide</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/partner-sites?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704360143&amp;usg=AOvVaw0500rqLGfOS3kWuAE819kj">How Google uses information from sites or apps that use our services</a></span><span
        class="c1">;</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://policies.google.com/technologies/cookies?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1672143704360501&amp;usg=AOvVaw03EhmTNz6xkf47b3Yn7kko">How Google uses cookies</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c1">You also have the following option:</span></p>
<p class="c3"><span>&#9679; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c4"><a class="c5"
                                                                                                       href="https://www.google.com/url?q=https://adssettings.google.com/&amp;sa=D&amp;source=editors&amp;ust=1672143704360937&amp;usg=AOvVaw0iGze8nhscrMLVR_nDHve4">Object to personalized advertising</a></span><span
        class="c1">.</span></p>
<p class="c3"><span class="c1">&nbsp;</span></p>
<p class="c3"><span class="c0">10. Final provisions</span></p>
<p class="c3"><span class="c1">We may amend and supplement this privacy policy at any time. We will provide information about such adaptations and additions in a suitable form, in particular by publishing the respective current data protection declaration on our Platform.</span>
</p>
<p class="c3"><span class="c1">&nbsp;</span></p>
<p class="c3 c6"><span class="c1"></span></p></div>
`;

export function Terms() {
    return (
        <BaseLayout>
            <BaseLayout.Body>
                <div dangerouslySetInnerHTML={{__html : html}}/>
            </BaseLayout.Body>
        </BaseLayout>);
}
