import {NavLink} from "react-router-dom";
import Sidebar from "components/sidebar/Sidebar";
import {PATHS} from "router/paths";
import {useAccount} from 'hooks';
import {formatToken} from "features/web3";


export default function AccountSidebar() {
  const account = useAccount();
  const couponsSummaryDiscount = account?.couponsSummaryDiscount ?? '0';
  const domainsCount = account?.domainsCount ?? 0;
  const referralsCount = account?.referralsCount ?? 0;

  return (
    <Sidebar title="Account" footerEl={null}>
      {/*<NavLink end to={PATHS.account.profile.index}>my profile</NavLink>*/}
      <NavLink end to={PATHS.account.coupons.index}>Coupons <span className="counter">${formatToken(couponsSummaryDiscount)}</span></NavLink>
      <NavLink end to={PATHS.account.domains.index}>Domains <span className="counter">{domainsCount}</span></NavLink>
      <NavLink end to={PATHS.account.referralprogram.index}>Referrals <span className="counter">{referralsCount}</span></NavLink>
      <NavLink end to={PATHS.account.settings.index}>Settings</NavLink>
      {/*<NavLink end to={PATHS.root.index}>help</NavLink>*/}
    </Sidebar>
  );

}
