import styles from './Connect.module.scss';
import React, {useEffect} from 'react';
import {useSafeNavigate, useWeb3} from 'hooks';
import {useConnectMutation} from "../store";
import BaseLayout from "components/layout/BaseLayout/BaseLayout";
import {Web3ClientName} from "../model";
import {hasEthereum, isMobile} from "../util";
import {PATHS} from "../../../router/paths";

export function Connect () {

	const navigate = useSafeNavigate();
	const {isConnected} = useWeb3();
	const [connect, {isLoading}] = useConnectMutation();

	useEffect(() => { if (isConnected) navigate(-1) }, [isConnected])

	const handleClickConnect = (client: Web3ClientName) => async () => {
		if (client === Web3ClientName.MetaMask && isMobile && !hasEthereum) {
			return window.location.replace(`https://metamask.app.link/dapp/${window.location.host}${PATHS.auth.index}`);
		}
		await connect({client});
	}

	return (
		<BaseLayout>
			<BaseLayout.Body>
				<div className={styles.wrapper}>
					<div className={styles.title}>Connect your Wallet</div>
					<ul className={styles.clients}>
						<li className={styles.client}>
							<button className={styles.client__button} type='button' disabled={isLoading || isConnected} onClick={handleClickConnect(Web3ClientName.WalletConnect)}>
								<div className={styles.client__logo}>
									<img src="/assets/img/wallet-img.svg" alt="WalletConnect logo" style={{maxHeight: '106px'}}/>
								</div>
								<div className={styles.client__title}>WalletConnect</div>
							</button>
						</li>
						<li className={styles.client}>
							<button className={styles.client__button} type='button' disabled={isLoading || isConnected} onClick={handleClickConnect(Web3ClientName.MetaMask)}>
								<div className={styles.client__logo}>
									<img src="/assets/img/metamask-img.svg" alt="MetaMask logo"/>
								</div>
								<div className={styles.client__title}>MetaMask</div>
								{isMobile && !hasEthereum &&
									<div className={styles.client__warning}>To  use  the  Metamask<br/>wallet, open w3dna.net<br/>in a Metamask browser.</div>
								}
							</button>
						</li>
					</ul>
				</div>
			</BaseLayout.Body>
		</BaseLayout>
	);

}
