import {BaseQueryFn} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {serializeError} from "./util";

export type Web3BaseQuery = BaseQueryFn<(() => any), any, SerializedError>

export const web3BaseQuery = (): Web3BaseQuery => async (method) => {
	try {
		const data = await method();
		return { data };
	} catch (error: any) {
		return { error: serializeError(error) }
	}
}
