import {Fragment} from "react";
import {Button, RequestWrapper} from "components";
import {PATHS} from "router/paths";


export default function Congratulations() {
  return (
    <RequestWrapper isLoading={false} error={undefined}>
      {{
        action: {
          description: (
            <Fragment>
              <div>Congratulations!</div>
              <div>Your token has been successfully minted!</div>
            </Fragment>
          ),
          button: (
            <Button type='route' to={PATHS.account.domains.index}>Back to my domains</Button>
          )
        }
      }}
    </RequestWrapper>
  )
}
