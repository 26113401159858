import {createSlice} from "@reduxjs/toolkit";
import {MintSaga} from "../types";
import {mint} from "./actions";


export interface MintState {
  sagas: Record<string, MintSaga>
}

const initialState: MintState = {
  sagas: {}
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(mint.update, (state, {payload}) => {
      state.sagas[payload.tokenId] = payload;
    })
});

export const { actions, reducer } = slice;
