import {createAction} from "@reduxjs/toolkit";
import {AddressAssignmentState} from "./types";


export const assignAddress = {
  init: createAction('account/assignAddress/init'),
  update: createAction<AddressAssignmentState>('account/assignAddress/update'),
  cancel: createAction('account/assignAddress/cancel'),
  setAddress: createAction<{address: string}>('account/setAddress/init'),
}
