import {isPlainObject} from "@reduxjs/toolkit";

export {serializeError} from './serializeError';

export function assert(condition: any, message?: string): asserts condition {
  if (!condition) throw Error(message || 'Assertion failed');
}

export function assertNonNullish<T>(value: T, message?: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) throw Error(message || 'Assertion failed: tested value is null or undefined');
}

export function getOrThrow<T>(value: T, message?: string): NonNullable<T> {
  assertNonNullish(value, message);
  return value;
}

export function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export function exhaustiveMatchingGuard(_: never): never {
  throw new Error('Should not have reached here');
}

export function isValidEmail(email: string): boolean {
  return !!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (v === undefined) delete copy[k];
  }
  return copy;
}

export function getBrowserUrl(platform: 'Win32' | 'Linux' | 'MacIntel'){
  const links = {
    Win32 : process.env.REACT_APP_CHROMIUM_WIN_URL,
    Linux : process.env.REACT_APP_CHROMIUM_LINUX_URL,
    MacIntel : process.env.REACT_APP_CHROMIUM_MAC_INTEL_URL
  };
  return links[platform];
}

export function getCurrentUrl(): string | undefined {
  return window?.location.href;
}

export function getReferralUrl(referralId: string): string | undefined {
  return getUrl(`?referrer=${referralId}`);
}

export function getUrl(relativePath: string): string | undefined {
  return window ? `${window.location.protocol}//${window.location.host}/${relativePath}` : undefined;
}

