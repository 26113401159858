import {useGetPersonalSignQuery} from "features/web3";
import {RequestWrapper} from "components";


export function SignMessage(props: { token: string }) {
	const {isFetching, error, refetch} = useGetPersonalSignQuery(props.token);
	return (
		<RequestWrapper isLoading={isFetching} error={error} action={refetch}>
			{{
				action: { description: 'Please use your wallet to sign message', button: 'Sign message' },
				loader: { description: 'Awaiting signature' }
			}}
		</RequestWrapper>
	)
}
