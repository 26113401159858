import {PATHS} from "router/paths";
import {Navigate, useSearchParams} from "react-router-dom";

export function PasswordOutlet(props: {isAuthorized: boolean}) {
  const [searchParams] = useSearchParams();
  let path;
  if (props.isAuthorized) {
    path = PATHS.account.settings.index;
    searchParams.set('action', 'password');
  } else {
    path = PATHS.account.password.recover.index
  }
  return <Navigate to={`${path}?${searchParams.toString()}`} replace/>
}