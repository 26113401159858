import {useEffect} from "react";
import {RequestWrapper} from "components";
import {Chain, useMintTokenMutation} from "features/web3";


export default function SendTransaction(props: {domainName: string, chain: Chain}) {
  const {domainName, chain} = props;
  const [mint, {isLoading, error}] = useMintTokenMutation();
  useEffect(() => { mint({domainName, chain}) }, [])

  return (
    <RequestWrapper action={() => mint({domainName, chain})} isLoading={isLoading} error={error}>
      {{
        action: null,
        loader: { description: 'Please open your wallet and click the "Confirm" button to mint a token', button: null }
      }}
    </RequestWrapper>
  )
}
