export type ModalState = {
  status: typeof ModalStatus.CLOSED
} | {
  status: typeof ModalStatus.OPENED
  name: ModalName
  params?: ModalParams
} | {
  status: typeof ModalStatus.OPENED
  name: typeof ModalName.SEARCH_DOMAIN
  params?: { search?: string, coupon?: {name: string, discount: string} }
}

export const ModalStatus = {
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
} as const

export type ModalStatus = typeof ModalStatus[keyof typeof ModalStatus]

export const ModalName = {
  SEARCH_DOMAIN: 'SEARCH_DOMAIN',
} as const

export type ModalName = typeof ModalName[keyof typeof ModalName]

export type ModalParams = Record<string, any>
