import {ContactType} from "../../types/ContactType";
import email from './assets/email.svg';
import email_white from './assets/email_white.svg';
import instagram from './assets/instagram.svg';
import instagram_white from './assets/instagram_white.svg';
import telegram from './assets/telegram.svg';
import telegram_white from './assets/telegram_white.svg';
import twitter from './assets/twitter.svg';
import twitter_white from './assets/twitter_white.svg';
import {Theme} from "../../types/Theme";

export const icons = {
  [Theme.Light]: {
    [ContactType.Email]: email,
    [ContactType.Instagram]: instagram,
    [ContactType.Telegram]: telegram,
    [ContactType.Twitter]: twitter
  },
  [Theme.Dark]: {
    [ContactType.Email]: email_white,
    [ContactType.Instagram]: instagram_white,
    [ContactType.Telegram]: telegram_white,
    [ContactType.Twitter]: twitter_white
  }
}
