import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Currency} from "shared/types";
import {Coupon} from "./model/Coupon";
import {PaymentOption} from "./model/PaymentOption";
import {clear, coupon} from "./actions";
import {analytics, EventType} from "features/analytics";

export interface CartState {
  coupon: Coupon | undefined
  domainNames: string[]
  paymentOption: PaymentOption
}

const initialState: CartState = {
  coupon: undefined,
  domainNames: [],
  paymentOption: PaymentOption.Wert,
}

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addDomain: (state, {payload}: PayloadAction<string>) => {
      if (state.domainNames.indexOf(payload) < 0) {
        state.domainNames.push(payload);
        analytics.push({type: EventType.AddToCart, items: [{name: payload}]})
      }
    },
    addDomains: (state, {payload}: PayloadAction<string[]>) => {
      for (const item of payload) {
        if (state.domainNames.indexOf(item) < 0) {
          state.domainNames.push(item);
        }
      }
    },
    removeDomain: (state, {payload}: PayloadAction<string>) => {
      state.domainNames = state.domainNames.filter(name => name !== payload)
    },
    removeDomains: (state, {payload: domainNames}: PayloadAction<string[] | undefined>) => {
      if (domainNames) {
        state.domainNames = state.domainNames.filter(domainName => !domainNames.some(name => name === domainName))
      } else {
        state.domainNames = [];
      }
    },
    restore: (state, {payload: {currency, domainNames}}: PayloadAction<{currency: Currency, domainNames: string[]}>) => {
      state.domainNames = domainNames;
    },
    setPaymentOption: (state, {payload}: PayloadAction<PaymentOption>) => {
      state.paymentOption = payload;
    },
  },
  extraReducers: builder =>
    builder
    .addCase(clear, () => {
      return initialState;
    })
    .addCase(coupon.clear, (state) => {
      state.coupon = undefined;
    })
    .addCase(coupon.update, (state, {payload: coupon}: PayloadAction<Coupon>) => {
      state.coupon = coupon;
    })
});

export const { actions, reducer } = slice;
