import {createIcon} from "./Icon";


export const ArrowUpRightFromSquare = createIcon(require('./assets/arrow-up-right-from-square.svg'))
export const Binance = createIcon(require('./assets/binance.svg'))
export const Burger = createIcon(require('./assets/burger.svg'))
export { Cart } from './icons/Cart'
export const CartCheck = createIcon(require('./assets/cart-check.svg'))
export const CartPlus = createIcon(require('./assets/cart-plus.svg'))
export const ChevronLeft = createIcon(require('./assets/chevron-left.svg'))
export const ChevronRight = createIcon(require('./assets/chevron-right.svg'))
export const CircleCheck = createIcon(require('./assets/circle-check.svg'))
export const CircleUser = createIcon(require('./assets/circle-user.svg'))
export const CircleXmark = createIcon(require('./assets/circle-xmark.svg'))
export const Copy = createIcon(require('./assets/copy.svg'))
export const Discord = createIcon(require('./assets/discord.svg'))
export const DiscordCircled = createIcon(require('./assets/discord_circled.svg'))
export const Ethereum = createIcon(require('./assets/ethereum.svg'))
export const FacebookCircled = createIcon(require('./assets/facebook_circled.svg'))
export const FacebookMessengerCircled = createIcon(require('./assets/facebook-messenger.svg'))
export const LinkedIn = createIcon(require('./assets/linkedin.svg'))
export const Logo = createIcon(require('./assets/logo.svg'))
export const Magnifier = createIcon(require('./assets/magnifier.svg'))
export const Medium = createIcon(require('./assets/medium.svg'))
export const Metamask = createIcon(require('./assets/metamask.svg'))
export const Polygon = createIcon(require('./assets/polygon.svg'))
export const Telegram = createIcon(require('./assets/telegram.svg'))
export const TelegramCircled = createIcon(require('./assets/telegram_circled.svg'))
export const Twitter = createIcon(require('./assets/twitter.svg'))
export const TwitterCircled = createIcon(require('./assets/twitter_circled.svg'))
export const Xmark = createIcon(require('./assets/xmark.svg'))
export const YouTube = createIcon(require('./assets/youtube.svg'))