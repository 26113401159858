import {AppDispatch, AppStartListening, RootState} from "store";
import {slice as salesApi} from "features/api/sales/slice";
import {actions as claimActions} from "./index";
import {isAnyOf, ListenerEffectAPI} from "@reduxjs/toolkit";
import {BuyTaskStatus} from "../../api/sales/model";
import {ClaimSagaStatus} from "./types";
import {api as web3Api} from "features/web3/store";


export default function(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(claimActions.createSaga.match, claimActions.cancelSaga.match),
    effect: async ({type, payload: {code}}, api) => {
      if (type === claimActions.cancelSaga.type) {
        api.cancelActiveListeners();
        api.dispatch(claimActions.deleteSaga({code}));
        return;
      }
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.SENDING_TRANSACTION, code}));
      const [{payload: {address}}] = await api.take(claimActions.setAddress.match);
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_TRANSACTION, address, code}));
      const [{payload: {data: {taskId: id}}}] = await Promise.any([
        api.take( action => salesApi.endpoints.claimDomains.matchFulfilled(action) && action.payload.data.status === BuyTaskStatus.IN_PROGRESS),
        api.pause(handleEmailRegistration(api, address, code))
      ]);
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_FULFILLMENT, address, id, code}));
      await api.take( action => salesApi.endpoints.fetchOrder.matchFulfilled(action) && action.payload.status === BuyTaskStatus.FINISHED);
      api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.FULFILLED, address, id, code}));
    }
  });
}

async function handleEmailRegistration(api: ListenerEffectAPI<RootState, AppDispatch>, address: string, code: string) {
  await api.take(claimActions.initEmailRegistration.match);
  api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.REGISTERING_PAYMENT, address, code}));
  const [{payload: temporaryToken}] = await api.take(salesApi.endpoints.getTemporaryToken.matchFulfilled);
  api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_EMAIL_REGISTRATION_SIGNATURE, address, code, temporaryToken}));
  const [{payload: signature}] = await api.take(web3Api.endpoints.getPersonalSign.matchFulfilled);
  api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.ASSIGNING_ADDRESS, address, code, signature, temporaryToken}));
  await api.take( action => salesApi.endpoints.assignAddress.matchFulfilled(action));
  api.dispatch(claimActions.updateSaga({status: ClaimSagaStatus.AWAITING_TRANSACTION, address, code}));
  return api.take( action => salesApi.endpoints.claimDomains.matchFulfilled(action) && action.payload.data.status === BuyTaskStatus.IN_PROGRESS);
}
