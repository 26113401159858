import {createSlice} from "@reduxjs/toolkit";
import {assignAddress} from "./actions";
import {AddressAssignmentState, AddressAssignmentStatus} from "./types";


export interface AccountState {
  addressAssignment: AddressAssignmentState
}

const initialState: AccountState = {
  addressAssignment: {status: AddressAssignmentStatus.UNINITIALIZED},
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
    .addCase(assignAddress.update, (state, {payload}) => {
      state.addressAssignment = payload;
    })
});

export const { actions, reducer } = slice;
