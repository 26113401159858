import {Theme} from "styled-components";

export const dark: Theme  = {
  name: 'dark',
  palette: {
    common: {
      black: '#000',
      grey: '#62626a',
      white: '#fff'
    },
    primary: {
      main: '#4C37CA',
      contrastText: '#000'
    },
    text: {
      primary: 'rgb(255,255,255)',
      secondary: 'rgba(255,255,255,.6)',
      disabled: 'rgba(255,255,255,.4)'
    }
  }
}