import {Fragment} from "react";
import {NavLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {analytics, EventTarget, EventType, TargetLocation, TargetName} from "features/analytics";
import {Drawer as DrawerComponent} from "components";
import {PATHS} from "router/paths";
import {Logo, Xmark} from "components/icon";
import styles from "./Drawer.module.scss";

export function Drawer(props: {
	isLoggedIn: boolean,
	isOpened: boolean,
	onClickCloseButton: () => void,
	onClickDomainButton: () => void,
	onClickLogoutButton: () => void
}) {
	const handleClickLogin = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetLocation: TargetLocation.Header, targetName: TargetName.LogIn});
	const handleClickSignUp = () => analytics.push({type: EventType.Click, target: EventTarget.Button, targetLocation: TargetLocation.Header, targetName: TargetName.SignUp});

	return (
		<DrawerComponent isOpen={props.isOpened} onClose={props.onClickCloseButton} position='right'>
			<div className={styles.header}>
				<div className={styles.logo}><Logo/></div>
				<button className={styles.close} onClick={props.onClickCloseButton}><Xmark/></button>
			</div>
			<ul className={styles.items}>
				<li className={styles.item}>
					<button className={styles.link} onClick={props.onClickDomainButton}>Domain</button>
				</li>
				<li className={styles.item}>
					<HashLink to={PATHS.root.browser} className={styles.link} onClick={props.onClickCloseButton}>Web3 Browser</HashLink>
				</li>
				<li className={styles.item}>
					<HashLink to={PATHS.root.browser} className={styles.link} onClick={props.onClickCloseButton}>Extensions</HashLink>
				</li>
				<li className={styles.item}>
					<HashLink to={PATHS.root.news} className={styles.link} onClick={props.onClickCloseButton}>On The Track</HashLink>
				</li>
				{props.isLoggedIn ? (
					<li className={styles.item}>
						<NavLink to={PATHS.account.coupons.index} className={styles.link}>Account</NavLink>
						<ul className={styles.items}>
							<li className={styles.item}>
								<NavLink to={PATHS.account.coupons.index} className={styles.link}>My Coupons</NavLink>
							</li>
							<li className={styles.item}>
								<NavLink to={PATHS.account.domains.index} className={styles.link}>My Domains</NavLink>
							</li>
							<li className={styles.item}>
								<NavLink to={PATHS.account.referralprogram.index} className={styles.link}>Referrals</NavLink>
							</li>
							<li className={styles.item}>
								<button className={styles.link} onClick={props.onClickLogoutButton}>Log out</button>
							</li>
						</ul>
					</li>
				) : (
					<Fragment>
						<li className={styles.item}>
							<NavLink to={PATHS.auth.index} className={styles.link} onClick={handleClickLogin}>Log In</NavLink>
						</li>
						<li className={styles.item}>
							<NavLink to={PATHS.signup.page} className={styles.link} onClick={handleClickSignUp}>Sign Up</NavLink>
						</li>
					</Fragment>
				)}
			</ul>
		</DrawerComponent>
	)
}