import * as t from "io-ts";


export const TxReceiptCodec = t.exact(t.type({
  blockHash: t.string,
  blockNumber: t.number,
  from: t.string,
  status: t.number,
  to: t.string,
  transactionHash: t.string
}));

export type TxReceipt = {
  blockHash: string,
  blockNumber: number,
  from: string,
  status: number,
  to: string,
  transactionHash: string
}
