import {getContract} from "../util";


const abi = [
  'function setContentAsBytes(uint id, bytes memory newContent)'
];

export const ContentProvider = getContract(abi, (read, write) => ({
  async setContent(tokenId: string, content: string) {
    return await write('setContentAsBytes', [tokenId, content]);
  },
}));
