import {Children, Component, PropsWithChildren} from "react";
import Footer from "components/footer/Footer";
import styles from './BaseLayout.module.scss';
import {Navbar} from "components/navbar/Navbar";

export default class BaseLayout extends Component {
  static Navbar = NavbarSlot;
  static Body = BodySlot;

  componentDidMount() {
    if (window.innerWidth <= 500)
      // @ts-ignore
      document.body.style.zoom = (window.innerWidth / 414)
  }

  render() {

    const {children}: PropsWithChildren = this.props;
    // @ts-ignore
    const navbar = Children.toArray(children).find(child => child.type === NavbarSlot)
    // @ts-ignore
    const body = Children.toArray(children).find(child => child.type === BodySlot)

    return (
      <div className={styles.layout}>
        <div className={styles.header}>
          {/* @ts-ignore */}
          {navbar ? navbar.props.children : <Navbar/>}
        </div>
        <div className={styles.main}>
          {/* @ts-ignore */}
          {body ? body.props.children : null}
        </div>
        <div className={styles.footer}>
          <Footer/>
        </div>
      </div>
    );
  }
}

function NavbarSlot(props: PropsWithChildren) {
  return null;
}

function BodySlot(props: PropsWithChildren) {
  return null;
}
