import {createAction} from "@reduxjs/toolkit";
import {actions} from "./slice";

export const {
  createSaga,
  deleteSaga,
  updateSaga
} = actions;

export const cancelSaga = createAction<{code: string}>('claim/cancel');
export const setAddress = createAction<{address: string}>('claim/setAddress');
export const initEmailRegistration = createAction('claim/initEmailRegistration');
