import {useDispatch, useWeb3} from "hooks";
import Button from "components/button/Button";
import {ChainGuard, formatAddress} from "features/web3";
import {actions} from "../store";
import styles from "./AssignAddress.module.scss";

export function RequestAddress() {
	return (
		<ChainGuard>
			<ConfirmAddress/>
		</ChainGuard>
	)
}

function ConfirmAddress() {
	const {address} = useWeb3();
	const dispatch = useDispatch();
	const onClickContinue = (address: string) => () => {
		dispatch(actions.setAddress({address}));
	}
	if (!address) return null
	return (
		<div className={styles.wrapper}>
			<div className={styles.top}>
				<div>You are about to assign the address {formatAddress(address)} to your account.</div>
				<div>Would you like to continue?</div>
				<div>If you would like to assign a different address, please select it in your wallet.</div>
			</div>
			<div className={styles.bottom}>
				<Button onClick={onClickContinue(address)}>Continue</Button>
			</div>
		</div>
	)
}