import * as t from "io-ts";
import {DomainPrice} from "./DomainPrice";
import {UserDomainsBuyTask} from "./UserDomainsBuyTask";

export const CreateBuyTaskData = t.type({
  domainPrices: t.array(DomainPrice),
  task: t.union([UserDomainsBuyTask, t.undefined])
});

export type CreateBuyTaskData = t.TypeOf<typeof CreateBuyTaskData>;
