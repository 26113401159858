import { api as base } from 'features/api/upload/slice'


export const api = base.injectEndpoints({
	endpoints: builder => ({
		setExternalUrl: builder.mutation<{nft: string, route: string}, {tokenId: string, url: string}>({
			query: ({tokenId, url}) => ({url: `sites/link`, method: 'post', data: {tokenId, link: url} }),
			transformResponse: (response) => {
				const { addressForContent, bnsNftContractAddress } = (response as any).data;
				return {
					nft: bnsNftContractAddress,
					route: addressForContent
				};
			}
		}),
		upload: builder.mutation<{content: string, contentProvider: string, nft: string, route: string}, FormData>({
			query: data => ({url: `sites/upload`, method: 'post', data }),
			transformResponse: (response) => {
				const { addressForContent, bnsNftContractAddress, contentProviderContractAddress, zippedEncryptedHtml } = (response as any).data;
				return {
					content: zippedEncryptedHtml,
					contentProvider: contentProviderContractAddress,
					nft: bnsNftContractAddress,
					route: addressForContent
				};
			}
		})
	}),
	overrideExisting: false,
})

export const {
	useSetExternalUrlMutation,
	useUploadMutation
} = api